import React, { useEffect, useRef } from 'react';
import { Card } from 'semantic-ui-react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Row, Col, CardBody } from 'reactstrap';
import * as echarts from 'echarts/core';
import { TooltipComponent, LegendComponent } from 'echarts/components';
import { PieChart } from 'echarts/charts';
import { CanvasRenderer } from 'echarts/renderers';
import GeneralOverviewCard from './GeneralOverviewCard';


const GoogleAdsIcon = `${process.env.PUBLIC_URL}/images/integrations_images/GoogleADS.svg`;
const FacebookAdsIcon = `${process.env.PUBLIC_URL}/images/integrations_images/Meta_Platforms.svg`;

function GenralOverviewCard(props) {
  const formatNumberWithSpaces = (value) => {
    return value ? value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ') : '';
  };

  const googleCost = props.data.GoogleMetrics?.totalCost || 0;
  const facebookCost = props.data.facebookMetrics?.totalCost || 0;
  const Globalspend = googleCost + facebookCost;
  const totalCosts = [googleCost, facebookCost];
  const totalPercentage = Globalspend !== 0 ? totalCosts.map((cost) => (cost / Globalspend) * 100) : [0, 0];

  // Revenue calculations
  const googleRevenue = props.data.GoogleMetrics?.totalconversionValue || 0;
  const facebookRevenue = props.data.facebookMetrics?.totalconversionValue || 0;
  const globalRevenue = googleRevenue + facebookRevenue;
  const totalRevenues = [googleRevenue, facebookRevenue];
  const totalRevenuePercentage = globalRevenue !== 0 ? totalRevenues.map((revenue) => (revenue / globalRevenue) * 100) : [0, 0];

  let globalConversionRate;

  if (props.data.GoogleMetrics && props.data.facebookMetrics) {
    // Case: Both Google and Facebook data are available
    const googleConversionRate = props.data.GoogleMetrics.totalCVR;
    const facebookConversionRate =
      (props.data.facebookMetrics.totalConversions /
        props.data.facebookMetrics.totalLandingPageViews) *
      100;
    globalConversionRate = (googleConversionRate + facebookConversionRate) / 2;
  } else if (props.data.GoogleMetrics) {
    // Case: Only Google data is available
    globalConversionRate = props.data.GoogleMetrics.totalCVR;
  } else if (props.data.facebookMetrics) {
    // Case: Only Facebook data is available
    globalConversionRate =
      (props.data.facebookMetrics.totalConversions /
        props.data.facebookMetrics.totalLandingPageViews) *
      100;
  } else {
    // Case: No data available, set globalConversionRate to a default value or handle as needed
    globalConversionRate = 0;
  }

  return (
    <div>
      <Col>
        <Row className="align-items-center">
          <Col>
            <Row className="align-items-center mb-4">
              <Col>
                <GeneralOverviewCard
                  data={[
                    {
                      value: googleCost,
                      name: 'Google Ads',
                      itemStyle: { color: '#FAC858' },
                      logo: GoogleAdsIcon,
                    },
                    {
                      value: facebookCost,
                      name: 'Facebook Ads',
                      itemStyle: { color: '#0080F9' },
                      icon: FacebookAdsIcon,
                    },
                  ]}
                  title="Spend Split"
                />
              </Col>
              <Col>
                <GeneralOverviewCard
                  data={[
                    {
                      value: googleRevenue,
                      name: 'Google Ads',
                      itemStyle: { color: '#FAC858' },
                    },
                    {
                      value: facebookRevenue,
                      name: 'Facebook Ads',
                      itemStyle: { color: '#0080F9' },
                    },
                  ]}
                  title="Revenue Split"
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Col xl={12} md={4}>
          <Row>
            <Col>
              <Card
                className="card-h-100"
                style={{ border: '1px solid #D3E3FD', borderRadius: '6px' }}
              >
                <CardBody>
                  <div className="my-0 text-primary d-flex justify-content-between align-items-center">
                    <h5
                      className="my-0 text-primary"
                      style={{
                        fontWeight: '700',
                        fontSize: '14px',
                        color: '#0044BD',
                      }}
                    >
                      {props.t('Average Order Value')}
                    </h5>
                    <div>
                      <img
                        className="img-intgration-icon"
                        src={GoogleAdsIcon}
                        alt="Google Ads"
                      />
                      <img
                        className="img-intgration-icon"
                        src={FacebookAdsIcon}
                        alt="Facebook Ads"
                      />
                    </div>
                  </div>
                  <Row className="align-items-center">
                    <Col xs={12}>
                      <div className="mt-3">
                        <h3
                          style={{
                            fontWeight: '700',
                            color: '#000000',
                            fontSize: '24px',
                          }}
                        >
                          {props.data.GoogleMetrics &&
                          props.data.facebookMetrics
                            ? `$${(
                                (props.data.GoogleMetrics.totalAOV +
                                  props.data.facebookMetrics
                                    .totalconversionValue /
                                    props.data.facebookMetrics
                                      .totalConversions) /
                                2
                              ).toFixed(2)}`
                            : '-'}
                        </h3>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
            <Col>
              <Card
                className="card-h-100"
                style={{ border: '1px solid #D3E3FD', borderRadius: '6px' }}
              >
                <CardBody>
                  <div className="my-0 text-primary d-flex justify-content-between align-items-center">
                    <h5
                      className="my-0 text-primary"
                      style={{
                        fontWeight: '700',
                        fontSize: '14px',
                        color: '#0044BD',
                      }}
                    >
                      {props.t('Conversion Rate')}
                    </h5>
                    <div>
                      <img
                        className="img-intgration-icon"
                        src={GoogleAdsIcon}
                        alt="Google Ads"
                      />
                      <img
                        className="img-intgration-icon"
                        src={FacebookAdsIcon}
                        alt="Facebook Ads"
                      />
                    </div>
                  </div>
                  <Row className="align-items-center">
                    <Col xs={12}>
                      <div className="mt-3">
                        <h3
                          style={{
                            fontWeight: '700',
                            color: '#000000',
                            fontSize: '24px',
                          }}
                        >
                          {globalConversionRate
                            ? `%${globalConversionRate.toFixed(2)}`
                            : '-'}
                        </h3>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
            <Col>
              <Card
                className="card-h-100"
                style={{ border: '1px solid #D3E3FD', borderRadius: '6px' }}
              >
                <CardBody>
                  <div className="my-0 text-primary d-flex justify-content-between align-items-center">
                    <h5
                      className="my-0 text-primary"
                      style={{
                        fontWeight: '700',
                        fontSize: '14px',
                        color: '#0044BD',
                      }}
                    >
                      {props.t('ROAS')}
                    </h5>
                    <div>
                      <img
                        className="img-intgration-icon"
                        src={GoogleAdsIcon}
                        alt="Google Ads"
                      />
                      <img
                        className="img-intgration-icon"
                        src={FacebookAdsIcon}
                        alt="Facebook Ads"
                      />
                    </div>
                  </div>
                  <Row className="align-items-center">
                    <Col xs={12}>
                      <div className="mt-3">
                        <h3
                          style={{
                            fontWeight: '700',
                            color: '#000000',
                            fontSize: '24px',
                          }}
                        >
                          {props.data.GoogleMetrics &&
                          props.data.facebookMetrics
                            ? `$${(
                                (props.data.GoogleMetrics.totalconversionValue /
                                  googleCost) *
                                100
                              ).toFixed(2)}`
                            : '-'}
                        </h3>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
            <Col>
              <Card
                className="card-h-100"
                style={{ border: '1px solid #D3E3FD', borderRadius: '6px' }}
              >
                <CardBody>
                  <div className="my-0 text-primary d-flex justify-content-between align-items-center">
                    <h5
                      className="my-0 text-primary"
                      style={{
                        fontWeight: '700',
                        fontSize: '14px',
                        color: '#0044BD',
                      }}
                    >
                      {props.t('Total Conversions')}
                    </h5>
                    <div>
                      <img
                        className="img-intgration-icon"
                        src={GoogleAdsIcon}
                        alt="Google Ads"
                      />
                      <img
                        className="img-intgration-icon"
                        src={FacebookAdsIcon}
                        alt="Facebook Ads"
                      />
                    </div>
                  </div>
                  <Row className="align-items-center">
                    <Col xs={12}>
                      <div className="mt-3">
                        <h3
                          style={{
                            fontWeight: '700',
                            color: '#000000',
                            fontSize: '24px',
                          }}
                        >
                          {props.data.GoogleMetrics &&
                          props.data.facebookMetrics
                            ? props.data.GoogleMetrics.totalConversions +
                              props.data.facebookMetrics.totalConversions
                            : '-'}
                        </h3>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Col>
      </Col>
    </div>
  );
}

GenralOverviewCard.propTypes = {
  t: PropTypes.any,
  data: PropTypes.object.isRequired,
};

export default withRouter(withTranslation()(GenralOverviewCard));
