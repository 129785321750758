import gql from 'graphql-tag';

const getProductCategory = gql`query($limit:Int!,$page:Int!){
    all(limit:$limit,page:$page){
        pages,
        type,
        ProductCategory{
            id,
            name,
            slug,
            count,
        },
}}
`


export { getProductCategory};