import { SET_DATE_RANGE } from './actions';

// Define the initial state
const initialState = {
  dateRange: {
    selection1: {
      startDate: null,
      endDate: null
    },
    selection2: {
      startDate: null,
      endDate: null
    }
  }
};

// Define the reducer
const dateRangeReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_DATE_RANGE:
      return {
        ...state,
        dateRange: action.payload
      };
    default:
      return state;
  }
};

export default dateRangeReducer;
