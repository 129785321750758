import React from 'react';

const ChartFilter = ({ selectedSeries, handleRadioChange, props }) => {
  return (
    <div className="chart-filter">
      <label style={{ cursor: 'pointer', marginRight: '16px' }}>
        <input
            type="radio"
            value="sales"
            checked={selectedSeries === 'sales'}
            onChange={handleRadioChange}
            style={{ display: 'none' }}
          />
          <span style={{
            width: '16px',
            height: '16px',
            borderRadius: '50%',
            backgroundColor: selectedSeries === 'sales' ? '#0044BD' : '#fff',
            border: '2px solid #0044BD',
            display: 'inline-block',
            marginRight: '8px',
            position: 'relative',
          }}>
            {selectedSeries === 'sales' && (
              <span style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: '8px',
                height: '8px',
                borderRadius: '50%',
                backgroundColor: '#0044BD',
              }} />
            )}
          </span>
          <span style={{ color: '#000', fontWeight: selectedSeries === 'sales' ? '500' : 'normal' }}>{props.t("Sales")}</span>
      </label>

      <label style={{ cursor: 'pointer', marginRight: '16px' }}>
      <input
            type="radio"
            value="grossProfit"
            checked={selectedSeries === 'grossProfit'}
            onChange={handleRadioChange}
            style={{ display: 'none' }}
          />
          <span style={{
            width: '16px',
            height: '16px',
            borderRadius: '50%',
            backgroundColor: selectedSeries === 'grossProfit' ? '#0044BD' : '#fff',
            border: '2px solid #0044BD',
            display: 'inline-block',
            marginRight: '8px',
            position: 'relative',
          }}>
            {selectedSeries === 'grossProfit' && (
              <span style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: '8px',
                height: '8px',
                borderRadius: '50%',
                backgroundColor: '#0044BD',
              }} />
            )}
          </span>
          <span style={{ color: '#000', fontWeight: selectedSeries === 'grossProfit' ? '500' : 'normal' }}>{props.t("Gross Profit")}</span>
      </label>

      <label style={{ cursor: 'pointer', marginRight: '16px' }}>
      <input
            type="radio"
            value="nmbrOfOrders"
            checked={selectedSeries === 'nmbrOfOrders'}
            onChange={handleRadioChange}
            style={{ display: 'none' }}
          />
          <span style={{
            width: '16px',
            height: '16px',
            borderRadius: '50%',
            backgroundColor: selectedSeries === 'nmbrOfOrders' ? '#0044BD' : '#fff',
            border: '2px solid #0044BD',
            display: 'inline-block',
            marginRight: '8px',
            position: 'relative',
          }}>
            {selectedSeries === 'nmbrOfOrders' && (
              <span style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: '8px',
                height: '8px',
                borderRadius: '50%',
                backgroundColor: '#0044BD',
              }} />
            )}
          </span>
          <span style={{ color: '#000', fontWeight: selectedSeries === 'nmbrOfOrders' ? '500' : 'normal' }}>{props.t("Number Of Orders")}</span>
      </label>
    </div>
  );
};

export default ChartFilter;
