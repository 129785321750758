import React from 'react';
import { Link } from 'react-router-dom';
import { Card, CardBody, Col } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

function PlanDetaills(props) {
    const { yearData, onChoosePlan, t } = props;

    return (
        <React.Fragment>
            {(yearData || []).map((year, key) => (
                <Col xl={4} sm={6} key={key}>
                    <Card className={year.isFeatured ? "bg-primary mb-xl-0" : "mb-xl-0"}>
                        <CardBody style={{ filter: year.title === "Meduim" || year.title === "Full-Metrics" ? 'blur(10px)' : 'none', pointerEvents: year.title === "Meduim" || year.title === "Full-Metrics" ? 'none' : 'auto' }}>
                            <div className="p-2">
                                {year.isFeatured && (
                                    <div className="pricing-badge">
                                        <span className="badge">{t('Current Plan')}</span>
                                    </div>
                                )}
                                <h5 className={year.isFeatured ? "font-size-16 text-white" : "font-size-16 text-muted"}>{year.title}</h5>
                                <h1 className={year.isFeatured ? "mt-3 text-white" : "mt-3 text-muted"}>TND{year.price / 1000} <span className={year.isFeatured ? "text-white font-size-16 fw-medium" : "text-muted font-size-16 fw-medium"}>/ {t('Month')}</span></h1>
                                <p className={year.isFeatured ? "text-white-50 mt-3 font-size-15" : "text-muted-50 mt-3 font-size-15"}>{year.desc}</p>
                                <div className={year.isFeatured ? "mt-4 pt-2 text-white" : "mt-4 pt-2 text-muted"}>
  <p className="mb-3 font-size-15">
    <i className="mdi mdi-check-circle text-success font-size-18 me-2"></i>
    {t('Integration')}: {year.integration}
  </p>
  <p className="mb-3 font-size-15">
    <i className="mdi mdi-check-circle text-success font-size-18 me-2"></i>
    {t('Users')}: {year.users}
  </p>
  <p className="mb-3 font-size-15">
    <i className="mdi mdi-check-circle text-success font-size-18 me-2"></i>
    {t('Reporting')}: {year.reporting}
  </p>
  <p className="mb-3 font-size-15">
    <i className="mdi mdi-check-circle text-success font-size-18 me-2"></i>
    {t('Multicurrency')}: {year.Multicurrency}
  </p>
  <p className="mb-3 font-size-15">
    <i className="mdi mdi-check-circle text-success font-size-18 me-2"></i>
    {t('CogsZones')}: {year.CogsZones}
  </p>
  <p className="mb-3 font-size-15">
    <i className="mdi mdi-check-circle text-success font-size-18 me-2"></i>
    {t('Access to Historical Data')}: {year.HitoricalData}
  </p>
</div>

                                <div className="mt-4 pt-2">
                                    <button to="#" onClick={() => onChoosePlan(year.price, year.title)} className={year.isFeatured ? "btn btn-light w-100" : "btn btn-outline-primary w-100"}> {year.isFeatured ? t('Resubscribe Now') : t('Choose Plan')}</button>
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            ))}
        </React.Fragment>
    );
}

export default withRouter(withTranslation()(PlanDetaills));
