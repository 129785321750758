import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getKeywordsRequest } from '../../store/googleAds/actions';
import GoogleAdsTable from './GoogleAdsTable';

const KeywordsTable = () => {
  console.log('KeywordsTable');
  const dispatch = useDispatch();
  const { gaKeywords, isLoading } = useSelector((state) => state.GoogleAds);
  useEffect(() => {
    dispatch(getKeywordsRequest());
  }, []);
  let dataMaparamexTrackingNumbers = [];
  if (gaKeywords?.gaKeywords) {
    // Add optional chaining here
    dataMaparamexTrackingNumbers = gaKeywords.gaKeywords
      .filter((item) => item.cost !== 0 || item.conversions !== 0) // Filter out items with cost and conversions both equal to 0
      .map((item) => {
        return item;
      })
      .flat();
  }
  useEffect(() => {
    console.log('gaKeywords', gaKeywords, dataMaparamexTrackingNumbers);
    setFilteredData(dataMaparamexTrackingNumbers);
  }, [gaKeywords]);

  const calculateROAs = (record) => {
    const roasValue = (record.conversions_value / record.cost).toFixed(2);
    return roasValue !== '0.00' ? roasValue : '-';
  };
  const [filteredData, setFilteredData] = useState(
    dataMaparamexTrackingNumbers
  );
  const [sortedInfo, setSortedInfo] = useState({});
  const columns = [
    {
      title: 'KEYWORD ID',
      dataIndex: 'keyword_text',
      key: 'keyword_text',
      ellipsis: true,
      align: 'center',
      render: (text) => <strong className='table-cell-text'>{text}</strong>,
    },
    {
      title: 'CAMPAIGN',
      dataIndex: 'campaign_name',
      align: 'center',
      key: 'campaign_name',
     width: '200px',
      render: (text) => <strong className='table-cell-text'>{text}</strong>,
    },
    {
      title: 'CONVERSIONS',
      dataIndex: 'conversions',
      key: 'conversions',
      align: 'center',
      sorter: (a, b) => a.conversions - b.conversions,
      sortOrder:
        sortedInfo.columnKey === 'conversions' ? sortedInfo.order : null,
      ellipsis: true,
      render: (text, record) => (
        <strong className='table-cell-text'>
          {record.conversions && record.conversions
            ? record.conversions.toFixed(2)
            : '-'}
        </strong>
      ),
    },
    {
      title: 'CONVERSION VALUE',
      dataIndex: 'conversions_value',
      align: 'center',
      key: 'conversions_value',
      sorter: (a, b) => a.conversions_value - b.conversions_value,
      sortOrder:
        sortedInfo.columnKey === 'conversions_value' ? sortedInfo.order : null,
      ellipsis: true,
      render: (text, record) => (
        <strong className='table-cell-text'>
          {record.conversions_value && record.conversions_value
            ? record.conversions_value.toFixed(2)
            : '-'}
        </strong>
      ),
    },
    {
      title: 'COST',
      dataIndex: 'cost',
      key: 'cost',
      align: 'center',
      sorter: (a, b) => a.cost - b.cost,
      sortOrder: sortedInfo.columnKey === 'cost' ? sortedInfo.order : null,
      ellipsis: true,
      render: (text) => <strong className='table-cell-text'>{text}</strong>,
    },
    {
      title: ' CLICKS',
      dataIndex: 'clicks',
      key: 'clicks',
      sorter: (a, b) => a.clicks - b.clicks,
      sortOrder: sortedInfo.columnKey === 'clicks' ? sortedInfo.order : null,
      ellipsis: true,
      align: 'center',
      render: (text) => <strong className='table-cell-text'>{text}</strong>,
    },
    {
      title: 'IMPRESSIONS',
      dataIndex: 'impressions',
      align: 'center',
      key: 'impressions',
      sorter: (a, b) => a.impressions - b.impressions,
      sortOrder:
        sortedInfo.columnKey === 'impressions' ? sortedInfo.order : null,
      ellipsis: true,
      render: (text) => <strong className='table-cell-text'>{text}</strong>,
    },

    {
      title: 'ROAS',
      dataIndex: 'ROAs',
      align: 'center',
      key: 'ROAs',
      sorter: (a, b) => a.ROAs - b.ROAs,
      sortOrder: sortedInfo.columnKey === 'ROAs' ? sortedInfo.order : null,
      ellipsis: true,
      render: (text, record) => <strong className='table-cell-text'>{calculateROAs(record)}</strong>,
    },
  ];

  const filters = [
    {
      type: 'keyword',
      value: 'keyword_text',
      placeholder: 'Keyword Name ',
    },
    {
      type: 'campaign',
      value: 'campaign_name',
      placeholder: 'Campaign Name',
    },
  ];
  return (
    <GoogleAdsTable
      title={'Kewords table'}
      columns={columns}
      setFilteredData={setFilteredData}
      filteredData={filteredData}
      data={dataMaparamexTrackingNumbers}
      sortedInfo={sortedInfo}
      setSortedInfo={setSortedInfo}
      isLoading={isLoading}
      filters={filters}
    />
  );
};

export default KeywordsTable;
