import {FETCH_PRODUCTS_REQUEST, 
   FETCH_PRODUCTS_SUCCESS, 
   FETCH_PRODUCTS_FAILURE,
   FETCH_REFUNDS_REQUEST, 
   FETCH_REFUNDS_SUCCESS, 
   FETCH_REFUNDS_FAILURE,
   DELETE_INTEGRATION_PRESTASHOP_REQUEST,
   DELETE_INTEGRATION_PRESTASHOP_SUCCESS,
   DELETE_INTEGRATION_PRESTASHOP_FAILURE,
   INTEGRATION_PRESTASHOP_REQUEST,
    INTEGRATION_PRESTASHOP_SUCCESS,
    INTEGRATION_PRESTASHOP_FAILURE,
    FETCH_STORES_REQUEST,
    FETCH_STORES_SUCCESS,
    FETCH_STORES_FAILURE,
    FETCH_STORE_REQUEST,
    FETCH_STORE_SUCCESS,
    FETCH_STORE_FAILURE,
    CHECK_THIRD_STEP_PRESTASHOP_REQUEST,
    CHECK_THIRD_STEP_PRESTASHOP_SUCCESS,
    CHECK_THIRD_STEP_PRESTASHOP_FAILURE,
    FETCH_PRESTASHOP_DATA_REQUEST,
    FETCH_PRESTASHOP_DATA_SUCCESS,
    FETCH_PRESTASHOP_DATA_FAILURE
   } from "./actionsTypes"

export const fetchProductsRequest = (limit, page) => ({
  type: FETCH_PRODUCTS_REQUEST,
  payload: { limit, page },
});

export const fetchProductsSuccess = (data) => ({
  type: FETCH_PRODUCTS_SUCCESS,
  payload: data,
});

export const fetchProductsFailure = (error) => ({
  type: FETCH_PRODUCTS_FAILURE,
  payload: error,
});

export const fetchRefundsRequest = (limit, page) => ({
  type: FETCH_REFUNDS_REQUEST,
  payload: { limit, page },
});

export const fetchRefundsSuccess = (data) => ({
  type: FETCH_REFUNDS_SUCCESS,
  payload: data,
});

export const fetchRefundsFailure = (error) => ({
  type: FETCH_REFUNDS_FAILURE,
  payload: error,
});

//delete integration prestaShop
export const deleteIntegrationPrestaShopRequest = () => ({
  type: DELETE_INTEGRATION_PRESTASHOP_REQUEST,
})

export const deleteIntegrationPrestaShopSuccess = (data) => ({
  type: DELETE_INTEGRATION_PRESTASHOP_SUCCESS,
  payload: data,
});

export const deleteIntegrationPrestaShopFailure = (error) => ({
  type: DELETE_INTEGRATION_PRESTASHOP_FAILURE,
  payload: error,
})


// integration prestaShop
export const integrationPrestaShopRequest = (api,domain,protocol) => ({
  type: INTEGRATION_PRESTASHOP_REQUEST,
  payload: {api,domain,protocol},
})

export const integrationPrestaShopSuccess = (Store) => ({
  type: INTEGRATION_PRESTASHOP_SUCCESS,
  payload: Store,
})

export const integrationPrestaShopFailure = (error) => ({
  type: INTEGRATION_PRESTASHOP_FAILURE,
  payload: error,
})  

//get all prestashop stores

export const fetchStoresRequest = () => ({
  type: FETCH_STORES_REQUEST,

})

export const fetchStoresSuccess = (data) => ({
  type: FETCH_STORES_SUCCESS,
  payload: data,
})

export const fetchStoresFailure = (error) => ({
  type: FETCH_STORES_FAILURE,
  payload: error,
})


// get single prestashop store
export const fetchStoreRequest = () => ({
  type: FETCH_STORE_REQUEST,
})

export const fetchStoreSuccess = (firststore) => ({
  type: FETCH_STORE_SUCCESS,
  payload: firststore,
})

export const fetchStoreFailure = (error) => ({
  type: FETCH_STORE_FAILURE,
  payload: error,
})


// check third step prestashop
export const checkThirdStepPrestaShopRequest = () => ({
  type: CHECK_THIRD_STEP_PRESTASHOP_REQUEST,
})

export const checkThirdStepPrestaShopSuccess = (data) => ({
  type: CHECK_THIRD_STEP_PRESTASHOP_SUCCESS,
  payload: data,
})

export const checkThirdStepPrestaShopFailure = (error) => ({
  type: CHECK_THIRD_STEP_PRESTASHOP_FAILURE,
  payload: error,
})


// get  prestashop data
export const fetchPrestaShopDataRequest = (startDate, endDate) => ({

  type: FETCH_PRESTASHOP_DATA_REQUEST,
  payload:{
    startDate,
    endDate
  }
})

export const fetchPrestaShopDataSuccess = (data) => ({
  type: FETCH_PRESTASHOP_DATA_SUCCESS,
  payload: data,
})

export const fetchPrestaShopDataFailure = (error) => ({
  type: FETCH_PRESTASHOP_DATA_FAILURE,
  payload: error,
})


// get prestasshop data with compared 
export const fetchPrestaShopDataComparedRequest = (startDate, endDate, compareStartDate, compareEndDate) => ({

  type: FETCH_PRESTASHOP_DATA_REQUEST,
  payload:{
    startDate,
    endDate,
    compareStartDate,
    compareEndDate
  }
})