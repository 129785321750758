import React, { useState, useEffect, useMemo } from 'react';
import MetaTags from 'react-meta-tags';
import PropTypes from 'prop-types';
import '../../assets/scss/custom/pages/_daterange.scss';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import { Col, Container, Row } from 'reactstrap';
import PrestaShopOrders from './PrestaShopOrders';
import urls from '../../routes/apiUrls';
import { getSalesPlatform } from '../../queries/Shopify/queries';
import { alert } from '../Alerts/NotLinkedAccountAlert';
import ApolloClient from 'apollo-boost';
import { useHistory } from 'react-router-dom';
import secureSection from '../../MangeSecurity/MemberSecurity';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { formatDate } from '../GoogleAnalytics/common/HelperFunctions/utils';
import GoogleAdsCards from '../GoogleDashboard/Google Ads Overview/GoogleAdsCards';
import GoogleAdsChart from '../GoogleDashboard/Google Ads Overview/GoogleAdsChart/GoogleAdsChart';
import {
  fetchPrestaShopDataRequest,
  fetchPrestaShopDataComparedRequest,
} from '../../store/prestaShop/actions';

const PrestaShopIcon = `${process.env.PUBLIC_URL}/images/integrations_images/PrestaShop.svg`;

const apolloShopifyClient = new ApolloClient({
  uri: urls.shopify,
});

function PrestaShopDashboard({ t }) {
  secureSection(); // Security check

  const colors = useMemo(
    () => [
      {
        lineColor: '#E1006D',
        fillColor: [
          {
            offset: 0,
            color: '#E1006D',
          },
          {
            offset: 0.47,
            color: '#E1006D80',
          },
          {
            offset: 1,
            color: '#E1006D1A',
          },
        ],
      },
      {
        lineColor: '#F6B43B',
        fillColor: [
          {
            offset: 0,
            color: ' #F6B43B',
          },

          {
            offset: 1,
            color: '#D9D9D9',
          },
        ],
      },
    ],

    []
  );

  const history = useHistory();
  const dispatch = useDispatch();
  const { dateRange } = useSelector((state) => state.dateRangeReducer);
  const { dataDashboard } = useSelector((state) => state.prestashop);

  const [loadingState, setLoadingState] = useState(false);

  const [metrics, setMetrics] = useState({
    sales: [],
    compareSales: [],
    GrossProfit: [],
    compareGrossProfit: [],
    NumberOrders: [],
    compareNumberOrders: [],
    AOV: [],
    compareAOV: [],
  });

  // Handles the chart series
  const series = useMemo(
    () => [
      {
        name: 'Sales',
        data: metrics.sales,
        value: 'sales',
        compareData: metrics.compareSales,
      },
      {
        name: 'GrossProfit',
        data: metrics.GrossProfit,
        value: 'GrossProfit',
        compareData: metrics.compareGrossProfit,
      },
      {
        name: 'NumberOrders',
        data: metrics.NumberOrders,
        value: 'NumberOrders',
        compareData: metrics.compareNumberOrders,
      },
      {
        name: 'AOV',
        data: metrics.AOV,
        value: 'AOV',
        compareData: metrics.compareAOV,
      },
    ],
    [metrics]
  );

  // Fetch the data based on the date range
  useEffect(() => {
    if (dateRange?.selection1?.startDate && dateRange?.selection1?.endDate) {
      const newStartDate = formatDate(dateRange.selection1.startDate);
      const newEndDate = formatDate(dateRange.selection1.endDate);

      setLoadingState(true);
      if (dateRange.selection2?.startDate && dateRange.selection2?.endDate) {
        const newCompareStartDate = formatDate(dateRange.selection2.startDate);
        const newCompareEndDate = formatDate(dateRange.selection2.endDate);

        dispatch(
          fetchPrestaShopDataComparedRequest(
            newStartDate,
            newEndDate,
            newCompareStartDate,
            newCompareEndDate
          )
        );
      } else {
        dispatch(fetchPrestaShopDataRequest(newStartDate, newEndDate));
      }
      setLoadingState(false);
    }
  }, [dateRange, dispatch]);

  // Update metrics based on the data dashboard
  useEffect(() => {
    if (dataDashboard?.report?.length > 0) {
      const updatedMetrics = {
        sales: [],
        GrossProfit: [],
        NumberOrders: [],
        AOV: [],
      };
      const updatedCompareMetrics = {
        compareSales: [],
        compareGrossProfit: [],
        compareNumberOrders: [],
        compareAOV: [],
      };

      // First Report (Current Data)
      if (dataDashboard.report[0]?.length > 0) {
        dataDashboard.report[0].forEach((item) => {
          updatedMetrics.sales.push(item.sales || 0);
          updatedMetrics.GrossProfit.push(item.GrossProfit || 0);
          updatedMetrics.NumberOrders.push(item.NumberOrders || 0);
          updatedMetrics.AOV.push(item.AOV || 0);
        });
      }

      // Second Report (Comparison Data)
      if (
        dataDashboard.report.length > 1 &&
        dataDashboard.report[1]?.length > 0
      ) {
        dataDashboard.report[1].forEach((item) => {
          updatedCompareMetrics.compareSales.push(item.sales || 0);
          updatedCompareMetrics.compareGrossProfit.push(item.GrossProfit || 0);
          updatedCompareMetrics.compareNumberOrders.push(
            item.NumberOrders || 0
          );
          updatedCompareMetrics.compareAOV.push(item.AOV || 0);
        });
      }

      setMetrics((prevMetrics) => ({
        ...prevMetrics,
        ...updatedMetrics,
        ...updatedCompareMetrics,
      }));
    }
  }, [dataDashboard]);

  // Ensure Shopify sales platform integration
  useEffect(() => {
    let isSubscribed = true;
    apolloShopifyClient
      .query({
        query: getSalesPlatform,
        context: {
          headers: {
            Authorization: window.localStorage.getItem('token')
              ? `Bearer ${window.localStorage.getItem('token')}`
              : '',
          },
        },
        errorPolicy: 'all',
        fetchPolicy: 'network-only',
      })
      .then((data) => {
        if (!data.data.get && isSubscribed) {
          alert().then(() => {
            history.push('/integrations');
          });
        }
      });

    return () => (isSubscribed = false); // Cleanup subscription
  }, [history]);

  const handlePrint = () => {
    window.print();
  };

  return (
    <div className="page-content">
      <MetaTags>
        <title>{t('PrestaShop Dashboard')}</title>
      </MetaTags>
      <Container fluid>
        <div className="breadcrumbs-container">
          <Breadcrumbs
            title={t('Prestashop Dashboards')}
            breadcrumbItem={t('Prestashop Dashboard')}
            pageHeading={t(' Dashboard')}
            image={PrestaShopIcon}
          />
        </div>
        <GoogleAdsChart series={series} colors={colors} />
        <Row className="justify-content-center">
          <GoogleAdsCards data={series} colors={colors}/>
        </Row>

        <PrestaShopOrders dateRange={dateRange} />
      </Container>
    </div>
  );
}

PrestaShopDashboard.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withRouter(withTranslation()(React.memo(PrestaShopDashboard)));
