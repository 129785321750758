import React from 'react';

import { Card, CardBody, Col, Row } from 'reactstrap';

import GoogleAdsCardChart from './GoogleAdsCardChart';
import GoogleAdsCardPercentage from './GoogleAdsCardPercentage';

const GoogleAdsCard = ({ item,colors }) => {
  // console.log('item', item);
  return (
    <div className="dashboardMinorCardItem">
      <Card>
        <CardBody>
          <div className="text-primary d-flex justify-content-between align-items-center">
            <span
              style={{ fontSize: '15px', fontWeight: '400', color: '#9291A5' }}
            >
              {item.name}
            </span>
            {item.series.length > 1 ? (
              <GoogleAdsCardPercentage percentage={item.percentage} />
            ) : (
              <></>
            )}
          </div>
          <Row className="align-items-center">
            <Col xs={12}>
              <div className="mb-3">
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <h5
                    style={{
                      fontSize: '36px',
                      fontWeight: '700',
                      color: '#1E1B39',
                      marginTop: '5px',
                    }}
                  >
                    {item.totalValue}
                  </h5>
                </div>
              </div>
            </Col>
          </Row>
          <Col xs={12}>
            <GoogleAdsCardChart colors={colors} item={item.series} />
          </Col>
        </CardBody>
      </Card>
    </div>
  );
};

export default GoogleAdsCard;
