
import gql from 'graphql-tag';


const getFromInstagramSocial = gql`mutation($code:String!){
    integration(code:$code){
        name
    }
}
`

const getInstagramSocialMedia = gql`query{
    one{
        id,
        name
    }
}`

const getInstagramocialMediaAccounts = gql`query{
    instagramSocialMediaAccounts{
        instagram_username,
        fb_page_id,
        instagram_business_account_id,
        active,
    }
}`

const deletePlatformInstagramSocialMedia = gql`mutation{
    delete{
        isDeleted
    }
}`


const toggleInstaSocialMediaAccount = gql`mutation($platform_id:String!,$fb_page_id:String!){
    toggle(platform_id:$platform_id,fb_page_id:$fb_page_id){
        active
    }
}`

const getinstaPagePosts = gql`query{
    getinstagramPagePosts{
        media_id,
        media_url,
        permalink,
        media_type,
        impressions,
        reach,
        shares,
        video_views,
        saved,
        total_interactions,
        comments,
        timestamp,
        ig_reels_avg_watch_time,
        likes,
        plays

    }
}`

const getinstaPageData = gql`query($startDate:String!,$endDate:String!){
    getinstagramPageData(startDate:$startDate,endDate:$endDate){
        day,
        reach,
        impressions,
        profile_views,
        website_clicks,
        follower_count,
        instagram_username
    }
}`
const getinstaPageAudience = gql`query{
    getInstagramPageAudience{
        fb_page_id,
        instagram_business_account_id,
        fans_gender_age,
        profile_picture_url,
        media_count,
        followers_count,
    }
}`


export { getFromInstagramSocial, getInstagramSocialMedia, getInstagramocialMediaAccounts, deletePlatformInstagramSocialMedia, toggleInstaSocialMediaAccount, getinstaPagePosts, getinstaPageData, getinstaPageAudience };

