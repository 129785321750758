import React, { useState, useRef } from "react";
import { Checkbox, Table, Icon } from 'semantic-ui-react';
import { deleteSingleMember, editMember } from '../../../queries/Members/MembersQuery';
import ApolloClient from 'apollo-boost';
import Swal from 'sweetalert2';
import jwt from 'jsonwebtoken';
import urls from "../../../routes/apiUrls";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch, useSelector } from 'react-redux';
import { editMemberRequest, deleteMemberRequest } from '../../../store/Member/action';
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";

// Apollo Client instance for the account endpoint
const ApolloClientInstance = new ApolloClient({
    uri: urls.member
});

function CheckBoxMembers(props) {
    const { members, loadingState, successState, failureState, isDeleted } = useSelector((state) => state.member);
    const dispatch = useDispatch();

    const sectionsData = ["social", "ads platform", "woocommerce platform", "prestashop platform", "Aramex and emailing"];
    const [edit, setEdit] = useState(false);
    const checkBoxRef = useRef(null);

    const isMember = jwt.decode(window.localStorage.getItem("token")).type === "member";

    const SectionsChose = props.user.stores?.length > 0 
        ? props.user.stores.filter((val) => val.account_id === jwt.decode(window.localStorage.getItem("token")).account._id)[0]?.sections 
        : [];

    const deleteMember = () => {
        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: "Yes, delete it!"
        }).then((result) => {
            if (result.value) {
                dispatch(deleteMemberRequest(props.user.email));
                window.location.replace('/settings-members');
            }
        });
    };

    const notifySuccess = () => {
        const resolveWithSomeData = new Promise(resolve => setTimeout(() => resolve("world"), 3000));
        toast.promise(
            resolveWithSomeData,
            {
                pending: "Please wait a few seconds",
                success: "Member Edited successfully! 👌",
                error: {
                    render({ data }) {
                        return <span>{data.message}</span>;
                    }
                }
            }
        );
    };

    const notifyError = () => {
        toast.error('🦄 Cannot edit Member!', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });
    };

    const editSingleMember = async () => {
        const email = props.user.email;
        const sections = SectionsChose;
        await dispatch(editMemberRequest(email, sections));
        successState ? notifySuccess() : notifyError();
    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        if (isNaN(date.getTime())) return "---"; 
        return `${date.getFullYear()}-${padZero(date.getMonth() + 1)}-${padZero(date.getDate())} ${padZero(date.getHours())}:${padZero(date.getMinutes())}:${padZero(date.getSeconds())}`;
    };

    const padZero = (num) => (num < 10 ? `0${num}` : num);

    return (
        <Table.Row negative={isDeleted} className="storeRow">
            <Table.Cell>{props.user.email}</Table.Cell>
            <Table.Cell>
                {props.user.owner 
                    ? <span>{props.t("Account owner")}</span>
                    : sectionsData.map(section => (
                        <div className="section" key={section}>
                            {edit
                                ? <Checkbox ref={checkBoxRef} label={section} defaultChecked={SectionsChose.includes(section)}
                                    onChange={(e, data) => {
                                        if (data.checked) SectionsChose.push(data.label);
                                        if (!data.checked) SectionsChose.splice(SectionsChose.indexOf(data.label), 1);
                                    }}
                                  />
                                : <span className={SectionsChose.includes(section) ? "" : "disabledAccess"}>{section}</span>
                            }
                        </div>
                    ))
                }
            </Table.Cell>
            <Table.Cell>{props.user.last_login ? formatDate(props.user.last_login) : "---"}</Table.Cell>
            <Table.Cell>
                {props.user.owner ? null 
                    : !isDeleted 
                        ? !edit
                            ? <div className="d-flex flex-wrap gap-2">
                                <button className="btn btn-success w-15 waves-effect waves-light" onClick={() => setEdit(true)}>{props.t("edit")}</button>
                                <ToastContainer />
                                <button className="btn btn-danger w-15 waves-effect waves-light" onClick={deleteMember}>{props.t("delete")}</button>
                              </div>
                            : <div className="d-flex flex-wrap gap-2">
                                <button className="btn btn-success w-15 waves-effect waves-light" onClick={() => { setEdit(false); editSingleMember(); }}>{props.t("save")}</button>
                                <button className="btn btn-secondary w-15 waves-effect waves-light" onClick={() => setEdit(false)}>{props.t("Cancel")}</button>
                              </div>
                        : <Icon name='close' size="large" color="red" />
                }
            </Table.Cell>
        </Table.Row>
    );
}

export default withRouter(withTranslation()(CheckBoxMembers));