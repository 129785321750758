export const    GET_STRATEGIC_REPORTS_REQUEST = ' GET_STRATEGIC_REPORTS_REQUEST';
export const   GET_STRATEGIC_REPORTS_SUCCESS = 'GET_STRATEGIC_REPORTS_SUCCESS';
export const   GET_STRATEGIC_REPORTS_FAILURE = 'GET_STRATEGIC_REPORTS_FAILURE';


export const getStrategicCDataRequest = (startDate,endDate,channel) => ({
    type: GET_STRATEGIC_REPORTS_REQUEST,
    payload: {startDate,endDate,channel},
    
})

export const getStrategicCDataSuccess = (strat,
    series,
avg,
daily,
avgNetProfit,
avgCPS,
avgROA,
avgACPO,
avgMM,
avgAdCost,
totalFacebook,
totalGoogle,
avgFacebook,
avgGoogle,
totalNetProfit,
totalAdCost,
totalAov,
totalCps,
totalRoas,
totalAcpo,
totalMm,
tableaov) => ({
    type: GET_STRATEGIC_REPORTS_SUCCESS,
    payload: {strat,
        series,
    avg,
    daily,
    avgNetProfit,
    avgCPS,
    avgROA,
    avgACPO,
    avgMM,
    avgAdCost,
    totalFacebook,
    totalGoogle,
    avgFacebook,
    avgGoogle,
    totalNetProfit,
    totalAdCost,
    totalAov,
    totalCps,
    totalRoas,
    totalAcpo,
    totalMm,
    tableaov},
})

export const getStrategicCDataFailure = (error) => ({
    type: GET_STRATEGIC_REPORTS_FAILURE,
    payload: error,
})
