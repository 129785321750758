import React, { useState, useEffect } from 'react';
import { Card, CardBody, Col, Row, Table } from 'reactstrap';
import ApolloClient from 'apollo-boost';
import GAAvgValueChart from "./AvgValueChart";
import { getGAEventCategoryTracking } from "../../../../../queries/GoogleAnalytics/Queries";
import urls from "../../../../../routes/apiUrls";
import { withTranslation } from "react-i18next"
import { withRouter } from "react-router-dom"
const ApploCLI = new ApolloClient({
    uri: urls.analytics,
});

const AvgValue = (props) => {
    const [refetch, setRefetch] = useState(false);
    const [avgValue, setAvgValue] = useState([]);
    useEffect(() => {
        ApploCLI.query({
            query: getGAEventCategoryTracking,
            context: {
                headers: {
                    Authorization: window.localStorage.getItem("token")
                        ? `Bearer ${window.localStorage.getItem("token")}`
                        : "",
                },
            },
            errorPolicy: "all",
            fetchPolicy: "network-only",
        }).then((data) => {
            if (data.data.gaeventcategorytracking) {
                setAvgValue(data.data.gaeventcategorytracking[0].avgValue);
            } else {
                setRefetch(!refetch);
            }
        });
    }, []);
    const filteredAvgValueFiltered = avgValue.filter((e) =>
        e
    )
    return (
        <React.Fragment>
            <Col xl={6}>
                <Card className="card-h-100">
                    <CardBody>
                        <div className="d-flex flex-wrap align-items-center mb-4">
                            <h5 className="card-title me-2">{props.t("Average Value")}</h5>
                        </div>
                        <Row className="align-items-center">
                            <div className="col-sm">
                                <div id="wallet-balance" className="apex-charts">
                                    <GAAvgValueChart />
                                </div>
                            </div>
                            <div className="col-sm align-self-center">
                                <div className="mt-4 mt-sm-0">
                                    <Table className="table table-striped table-bordered">
                                        <thead>
                                            <tr>
                                                <th>{props.t("Event Tracking Name")}</th>
                                                <th>{props.t("Average Value")}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {filteredAvgValueFiltered.map((element, index) => (
                                                <tr key={index}>
                                                    <td>{element[0]}</td>
                                                    <td>{element[1]}</td>
                                                </tr>
                                            ))
                                            }
                                        </tbody>
                                    </Table>
                                </div>
                            </div>
                        </Row>
                    </CardBody>
                </Card>
            </Col>
        </React.Fragment>
    );
}
export default withRouter(withTranslation() (AvgValue));
