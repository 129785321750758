import React from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import SmallLineChart from './SmallLineChart'; // Assuming you're using this component

const Cardswithchart = ({ metrics, colors }) => {
  const formatNumberWithSpaces = (value) => {
    if (value !== undefined && value !== null) {
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    }
    return '';
  };

  return (
    <Row>
      {metrics.map((card, index) => (
        <Col xl={3} md={4} key={index}>
          <Card>
            <CardBody>
              <div className="d-flex flex-column justify-content-center align-items-start">
                <span style={{ color: '#9291A5', fontSize: '15px' }}>{card.title}</span>

                <div className="d-flex align-items-center mb-2">
                  <h4
                    className="mb-0"
                    style={{
                      fontSize: '36px',
                      fontWeight: '700',
                      color: '#1E1B39',
                    }}
                  >
                    {formatNumberWithSpaces(card.number)}
                  </h4>
                  <span
                    className="ms-2"
                    style={{
                      fontSize: '14px',
                      fontWeight: '500',
                      color: '#1E1B39',
                    }}
                  >
                    {card.percentage}
                    {card.trend === "up" ? (
                      <svg
                        width="14"
                        height="11"
                        viewBox="0 0 14 11"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        style={{ marginLeft: '4px' }}
                      >
                        <path
                          d="M4.64626 1.0673C5.66391 -0.355439 7.77894 -0.355442 8.79659 1.0673L12.9621 6.89099C14.1699 8.57949 12.963 10.9267 10.887 10.9267H2.55587C0.479889 10.9267 -0.727035 8.5795 0.48071 6.891L4.64626 1.0673Z"
                          fill="#04CE00"
                        />
                      </svg>
                    ) : (
                      <svg
                        width="14"
                        height="11"
                        viewBox="0 0 14 11"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        style={{ marginLeft: '4px' }}
                      >
                        <path
                          d="M8.79661 9.93319C7.77896 11.3559 5.66394 11.3559 4.64628 9.93319L0.480731 4.1095C-0.727014 2.421 0.479913 0.0738108 2.55589 0.0738106L10.887 0.0738098C12.963 0.0738097 14.1699 2.42099 12.9622 4.10949L8.79661 9.93319Z"
                          fill="#D30000"
                        />
                      </svg>
                    )}
                  </span>
                </div>

                <div style={{ width: '100%' }}>
                  <SmallLineChart data={card.graphData} colors={colors} />
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
      ))}
    </Row>
  );
};

export default Cardswithchart;
