import React, { useState, useEffect, useRef,useContext } from 'react';
import { SearchOutlined } from '@ant-design/icons';
import { CalendarOutlined, UserOutlined } from '@ant-design/icons'
import { Row, Col, CardBody, Nav, TabContent, TabPane, Progress, Alert, Card, CardHeader, CardTitle,Spinner } from 'reactstrap';
import NumberFormat from 'react-number-format';

import { Button, Form, Popconfirm } from 'antd';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import MetaTags from 'react-meta-tags';
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import secureSection from '../../../MangeSecurity/MemberSecurity';
import PropTypes from "prop-types";
import {
  Space, Table, Input, Checkbox, Dropdown, message,
  Menu,
} from 'antd';
import ApolloClient from "apollo-boost";
import urls from "../../../routes/apiUrls";
import { getInformations } from "../../../queries/Reports/PnLQuery";
import Chart from 'react-apexcharts';
import { Pie, Bar, Doughnut } from 'react-chartjs-2';
const ApploCLI = new ApolloClient({
  uri: urls.report,
});

function NProfitAndLoss(props) {
  secureSection();
  const currency = window.localStorage.getItem("currency") || "TND";
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [channel, setChannel] = useState("all");
  const [loadingState, setLoadingState] = useState(true);
  const [pnl, setPnl] = useState([]);
  const [newMinCosts, setNewMinCosts] = useState([]);  


  // get data from server
  const getData = (channel,selectedYear) => {
    let isSubscribed = true;
    setLoadingState(true);
    ApploCLI.query({
      query: getInformations,
      variables: {
        years: selectedYear + "",
        channel: channel
      },
      context: {
        headers: {
          Authorization: window.localStorage.getItem("token")
            ? `Bearer ${window.localStorage.getItem("token")}`
            : "",
        },
      },
      errorPolicy: "all",
      fetchPolicy: "network-only",
    }).then((data) => {
      if (isSubscribed) {
        setLoadingState(false);
        setPnl(data.data.pnl);
        var model = {
          name: "def",
          jan: 0,
          feb: 0,
          mar: 0,
          apr: 0,
          may: 0,
          jun: 0,
          jul: 0,
          aug: 0,
          sep: 0,
          oct: 0,
          nov: 0,
          dec: 0,
        };
        const months = [
          "jan",
          "feb",
          "mar",
          "apr",
          "may",
          "jun",
          "jul",
          "aug",
          "sep",
          "oct",
          "nov",
          "dec",
        ];
        var minCost = {};
        var test=[]
        data && data.data && data.data.pnl && data.data.pnl.length > 0 && data.data.pnl.forEach((pnlItem) => {
          const pnlYear = parseInt(pnlItem.created.split("-")[0]);
          if (pnlYear == selectedYear) {
            pnlItem.miscCosts.forEach((cost) => {
              if (cost.total > 0) { // Only consider costs with a total greater than 0
                
                const valMon = months[parseInt(pnlItem.created.split("-")[1]) - 1];
                if (!minCost[cost.name]) {
                  minCost[cost.name] = { ...model };
                  minCost[cost.name][valMon] = cost.total;
                  minCost[cost.name]["name"] = cost.name;
                  minCost[cost.name]["total"] = cost.total;
                } else {
                  minCost[cost.name][valMon] += cost.total;
                  minCost[cost.name]["total"] += cost.total;
                }
              }
            });
          }
        });
        
        Object.keys(minCost).forEach((name) => {
          test.push(minCost[name]);
        });
        
       
        setNewMinCosts(test);
      }
    });
    return () => (isSubscribed = false);
  };
  useEffect(() => {
    getData(channel,selectedYear);
  }, [selectedYear, channel]);
  console.log("pnl",pnl)
  let totalSales = 0;
  for (let i = 0; i < pnl.length; i++) {
    if (pnl[i] && pnl[i].sales) {
      // Ensure pnl[i].sales exists and is a valid number
      totalSales += parseFloat(pnl[i].sales); // Convert to number and add to totalSales
    }
  }
  let totalTaxes = 0;
  let totalSubProfit = 0;
  let totalCostOfGoods = 0;
  let totalDiscounts = 0;
  let totalMerchantFee = 0;
  let totalAds = 0;
  let totalGrossProfit = 0;
  let totalNetProfit = 0;
  let totalOrders = 0;
  let totalUnits = 0;
  let totalSnH = 0;
  let totalRefunds = 0;
  let sales = [];
  let taxes = [];
  let subtotal = [];
  let costOfGoods = [];
  let snh = [];
  let refunds = [];
  let discounts = [];
  let merchantFee = [];
  let ads = [];
  let gross = [];
  let netProfit = [];
  let orders = [];
  let units = [];
  let monthsMiscTemp = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
  let miscRows = [];






  let monthlyMisc = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
  for (let i = 0; i < pnl.length; i++) {
    if (pnl[i]) {
      // Calculating totals for each field
      totalTaxes += parseFloat(pnl[i].taxes) || 0;
      totalSubProfit += parseFloat(pnl[i].subProfit) || 0;
      totalCostOfGoods += parseFloat(pnl[i].costOfGoods) || 0;
      totalDiscounts += parseFloat(pnl[i].discounts) || 0;
      totalMerchantFee += parseFloat(pnl[i].merchantFee) || 0;
      totalAds += parseFloat(pnl[i].ads) || 0;
      totalGrossProfit += parseFloat(pnl[i].gross) || 0;
      totalNetProfit += parseFloat(pnl[i].netProfit) || 0;
      totalOrders += parseInt(pnl[i].orders) || 0;
      totalUnits += parseInt(pnl[i].units) || 0;
      totalSnH += parseFloat(pnl[i].SnH) || 0;
      totalRefunds += parseFloat(pnl[i].refunds) || 0;
    }
  }
  let avg_totalSales = totalSales / 12;
  let avg_taxes = totalTaxes / 12;
  let avg_subProfit = totalSubProfit / 12;
  let avg_costOfGoods = totalCostOfGoods / 12;
  let avg_discounts = totalDiscounts / 12;
  let avg_MerchantsFee = totalMerchantFee / 12;
  let avg_ads = totalAds / 12;
  let avg_gross = totalGrossProfit / 12;
  let avg_netProfit = netProfit / 12;
  let avg_orders = Math.ceil(totalOrders / 12);
  let avg_units = Math.ceil(totalUnits / 12);
  let avg_SnH = totalSnH / 12;
  let avg_refunds = totalRefunds / 12;
  let avg_sales = totalSales / 12;
  let avg_subToTal = subtotal / 12;

  const getTotalJSX = (totalValue) => {
    return totalValue !== null ? (
      <NumberFormat
        value={totalValue}
        decimalScale={2}
        displayType={'text'}
        thousandSeparator={true}
        prefix={'$'}
        renderText={value => <div>{value}</div>}
      />
    ) : '-';
  };

  const getTotalJSX2 = (totalValue) => {
    return totalValue !== null ? totalValue : '-';
  };

  const calculateAverage = (totalValue, count) => {
    if (totalValue === null || totalValue === 0) return '-';
    if (count === 0) return '-';

    const average = totalValue / count;
    return (
      <NumberFormat
        value={average}
        decimalScale={2}
        displayType={'text'}
        thousandSeparator={true}
        prefix={'$'}
        renderText={value => <div>{value}</div>}
      />
    );
  };

  const calculateAverage2 = (totalValue, count) => {
    if (count === 0) return '-';
    const average = Math.ceil(totalValue / count);
    return average;
  };



  const calculateMonthlyExpenses = (pnl) => {
    const monthlyExpenses = [];

    if (pnl && pnl.length > 0) {
      pnl.forEach((elem) => {
        let total = elem.costOfGoods + elem.SnH + elem.refunds + elem.discounts + elem.merchantFee + elem.ads;
        monthlyExpenses.push(total);
      });
    }

    return monthlyExpenses;
  };
  const monthlyExpenses = calculateMonthlyExpenses(pnl);

  const columns = [
    {
      title: props.t("IncomeSales"),
      dataIndex: 'IncomeSales',
      key: 'IncomeSales',
      width: 180,
    },
    {
      title: <span style={{ fontWeight: 'bold' }}>Jan</span>,
      dataIndex: 'jan',
      key: 'jan',
      width: 120,
    },
    {
      title: <span style={{ fontWeight: 'bold' }}>Feb</span>,
      dataIndex: 'feb',
      key: 'feb',
      width: 120,
    },
    {
      title: <span style={{ fontWeight: 'bold' }}>Mar</span>,
      dataIndex: 'mar',
      key: 'mar',
      width: 120,
    },
    {
      title: <span style={{ fontWeight: 'bold' }}>Apr</span>,
      dataIndex: 'apr',
      key: 'apr',
      width: 120,
    },
    {
      title: <span style={{ fontWeight: 'bold' }}>May</span>,
      dataIndex: 'may',
      key: 'may',
      width: 120,
    },
    {
      title: <span style={{ fontWeight: 'bold' }}>Jun</span>,
      dataIndex: 'jun',
      key: 'jun',
      width: 120,
    },
    {
      title: <span style={{ fontWeight: 'bold' }}>Jul</span>,
      dataIndex: 'jul',
      key: 'jul',
      width: 120,
    },
    {
      title: <span style={{ fontWeight: 'bold' }}>Aug</span>,
      dataIndex: 'aug',
      key: 'aug',
      width: 120,
    },
    {
      title: <span style={{ fontWeight: 'bold' }}>Sep</span>,
      dataIndex: 'sep',
      key: 'sep',
      width: 120,
    },
    {
      title: <span style={{ fontWeight: 'bold' }}>Oct</span>,
      dataIndex: 'oct',
      key: 'oct',
      width: 120,
    },
    {
      title: <span style={{ fontWeight: 'bold' }}>Nov</span>,
      dataIndex: 'nov',
      key: 'nov',
      width: 120,
    },
    {
      title: <span style={{ fontWeight: 'bold' }}>Dec</span>,
      dataIndex: 'dec',
      key: 'dec',
      width: 120,
    },
    // Add other months similarly
    {
      title: props.t("Total"),
      dataIndex: 'total',
      key: 'total',
      width: 180,
      fixed: 'right',
    },
    {
      title: props.t("Average"),
      dataIndex: 'average',
      key: 'average',
      fixed: 'right',
      width: 180,

    },
  ];
  const columns2 = [
    {
      title: props.t("Expenses"),
      dataIndex: 'Expenses',
      key: 'Expenses',
      width: 180,
    },
    {
      title: <span style={{ fontWeight: 'bold' }}>Jan</span>,
      dataIndex: 'jan',
      key: 'jan',
      width: 120,
    },
    {
      title: <span style={{ fontWeight: 'bold' }}>Feb</span>,
      dataIndex: 'feb',
      key: 'feb',
      width: 120,
    },
    {
      title: <span style={{ fontWeight: 'bold' }}>Mar</span>,
      dataIndex: 'mar',
      key: 'mar',
      width: 120,
    },
    {
      title: <span style={{ fontWeight: 'bold' }}>Apr</span>,
      dataIndex: 'apr',
      key: 'apr',
      width: 120,
    },
    {
      title: <span style={{ fontWeight: 'bold' }}>May</span>,
      dataIndex: 'may',
      key: 'may',
      width: 120,
    },
    {
      title: <span style={{ fontWeight: 'bold' }}>Jun</span>,
      dataIndex: 'jun',
      key: 'jun',
      width: 120,
    },
    {
      title: <span style={{ fontWeight: 'bold' }}>Jul</span>,
      dataIndex: 'jul',
      key: 'jul',
      width: 120,
    },
    {
      title: <span style={{ fontWeight: 'bold' }}>Aug</span>,
      dataIndex: 'aug',
      key: 'aug',
      width: 120,
    },
    {
      title: <span style={{ fontWeight: 'bold' }}>Sep</span>,
      dataIndex: 'sep',
      key: 'sep',
      width: 120,
    },
    {
      title: <span style={{ fontWeight: 'bold' }}>Oct</span>,
      dataIndex: 'oct',
      key: 'oct',
      width: 120,
    },
    {
      title: <span style={{ fontWeight: 'bold' }}>Nov</span>,
      dataIndex: 'nov',
      key: 'nov',
      width: 120,
    },
    {
      title: <span style={{ fontWeight: 'bold' }}>Dec</span>,
      dataIndex: 'dec',
      key: 'dec',
      width: 120,
    },
    // Add other months similarly
    {
      title: props.t("Total"),
      dataIndex: 'total2',
      key: 'total2',
      width: 100,
      fixed: 'right'
    },
    {
      title: props.t("Average"),
      dataIndex: 'average2',
      key: 'average2',
      width: 100,
      fixed: 'right'
    },
  ];
  const columns3 = [
    {
      title: props.t("TotalOrdersUnits"),
      dataIndex: 'TotalOrdersUnits',
      key: 'TotalOrdersUnits',
      width: 180,
    },
    {
      title: <span style={{ fontWeight: 'bold' }}>Jan</span>,
      dataIndex: 'jan',
      key: 'jan',
      width: 120,
    },
    {
      title: <span style={{ fontWeight: 'bold' }}>Feb</span>,
      dataIndex: 'feb',
      key: 'feb',
      width: 120,
    },
    {
      title: <span style={{ fontWeight: 'bold' }}>Mar</span>,
      dataIndex: 'mar',
      key: 'mar',
      width: 120,
    },
    {
      title: <span style={{ fontWeight: 'bold' }}>Apr</span>,
      dataIndex: 'Apr',
      key: 'Apr',
      width: 120,
    },
    {
      title: <span style={{ fontWeight: 'bold' }}>May</span>,
      dataIndex: 'May',
      key: 'May',
      width: 120,
    },
    {
      title: <span style={{ fontWeight: 'bold' }}>Jun</span>,
      dataIndex: 'Jun',
      key: 'Jun',
      width: 120,
    },
    {
      title: <span style={{ fontWeight: 'bold' }}>Jul</span>,
      dataIndex: 'Jul',
      key: 'Jul',
      width: 120,
    },
    {
      title: <span style={{ fontWeight: 'bold' }}>Aug</span>,
      dataIndex: 'Aug',
      key: 'Aug',
      width: 120,
    },
    {
      title: <span style={{ fontWeight: 'bold' }}>Sep</span>,
      dataIndex: 'Sep',
      key: 'Sep',
      width: 120,
    },
    {
      title: <span style={{ fontWeight: 'bold' }}>Oct</span>,
      dataIndex: 'Oct',
      key: 'Oct',
      width: 120,
    },
    {
      title: <span style={{ fontWeight: 'bold' }}>Nov</span>,
      dataIndex: 'Nov',
      key: 'Nov',
      width: 120,
    },
    {
      title: <span style={{ fontWeight: 'bold' }}>Dec</span>,
      dataIndex: 'Dec',
      key: 'Dec',
      width: 120,
    },
    // Add other months similarly
    {
      title: props.t("Total"),
      dataIndex: 'total3',
      key: 'total3',
      width: 100,
      fixed: 'right'
    },
    {
      title: props.t("Average"),
      dataIndex: 'average3',
      key: 'average3',
      width: 100,
      fixed: 'right'
    },
  ];
  const columns4 = [
    {
      title: props.t("Profit"),
      dataIndex: 'Profit',
      key: 'Profit',
      width: 180,
    },
    {
      title: <span style={{ fontWeight: 'bold' }}>Jan</span>,
      dataIndex: 'jan',
      key: 'jan',
      width: 120,
    },
    {
      title: <span style={{ fontWeight: 'bold' }}>Feb</span>,
      dataIndex: 'feb',
      key: 'feb',
      width: 120,
    },
    {
      title: <span style={{ fontWeight: 'bold' }}>Mar</span>,
      dataIndex: 'mar',
      key: 'mar',
      width: 120,
    },
    {
      title: <span style={{ fontWeight: 'bold' }}>Apr</span>,
      dataIndex: 'apr',
      key: 'apr',
      width: 120,
    },
    {
      title: <span style={{ fontWeight: 'bold' }}>May</span>,
      dataIndex: 'may',
      key: 'may',
      width: 120,
    },
    {
      title: <span style={{ fontWeight: 'bold' }}>Jun</span>,
      dataIndex: 'jun',
      key: 'jun',
      width: 120,
    },
    {
      title: <span style={{ fontWeight: 'bold' }}>Jul</span>,
      dataIndex: 'jul',
      key: 'jul',
      width: 120,
    },
    {
      title: <span style={{ fontWeight: 'bold' }}>Aug</span>,
      dataIndex: 'aug',
      key: 'aug',
      width: 120,
    },
    {
      title: <span style={{ fontWeight: 'bold' }}>Sep</span>,
      dataIndex: 'sep',
      key: 'sep',
      width: 120,
    },
    {
      title: <span style={{ fontWeight: 'bold' }}>Oct</span>,
      dataIndex: 'oct',
      key: 'oct',
      width: 120,
    },
    {
      title: <span style={{ fontWeight: 'bold' }}>Nov</span>,
      dataIndex: 'nov',
      key: 'nov',
      width: 120,
    },
    {
      title: <span style={{ fontWeight: 'bold' }}>Dec</span>,
      dataIndex: 'dec',
      key: 'dec',
      width: 120,
    },
    {
      title: props.t("Total"),
      dataIndex: 'total4',
      key: 'total4',
      width: 100,
      fixed: 'right'
    },
    {
      title: props.t("Average"),
      dataIndex: 'average4',
      key: 'average4',
      width: 100,
      fixed: 'right'
    },
  ];
  const columns5 = [
    {
      title: props.t("MiscExpenses"),
      dataIndex: 'MiscExpenses',
      key: 'MiscExpenses',
      width: 180,
    },
    {
      title: <span style={{ fontWeight: 'bold' }}>Jan</span>,
      dataIndex: 'jan',
      key: 'jan',
      width: 120,
    },
    {
      title: <span style={{ fontWeight: 'bold' }}>Feb</span>,
      dataIndex: 'feb',
      key: 'feb',
      width: 120,
    },
    {
      title: <span style={{ fontWeight: 'bold' }}>Mar</span>,
      dataIndex: 'mar',
      key: 'mar',
      width: 120,
    },
    {
      title: <span style={{ fontWeight: 'bold' }}>Apr</span>,
      dataIndex: 'apr',
      key: 'apr',
      width: 120,
    },
    {
      title: <span style={{ fontWeight: 'bold' }}>May</span>,
      dataIndex: 'may',
      key: 'may',
      width: 120,
    },
    {
      title: <span style={{ fontWeight: 'bold' }}>Jun</span>,
      dataIndex: 'jun',
      key: 'jun',
      width: 120,
    },
    {
      title: <span style={{ fontWeight: 'bold' }}>Jul</span>,
      dataIndex: 'jul',
      key: 'jul',
      width: 120,
    },
    {
      title: <span style={{ fontWeight: 'bold' }}>Aug</span>,
      dataIndex: 'aug',
      key: 'aug',
      width: 120,
    },
    {
      title: <span style={{ fontWeight: 'bold' }}>Sep</span>,
      dataIndex: 'sep',
      key: 'sep',
      width: 120,
    },
    {
      title: <span style={{ fontWeight: 'bold' }}>Oct</span>,
      dataIndex: 'oct',
      key: 'oct',
      width: 120,
    },
    {
      title: <span style={{ fontWeight: 'bold' }}>Nov</span>,
      dataIndex: 'nov',
      key: 'nov',
      width: 120,
    },
    {
      title: <span style={{ fontWeight: 'bold' }}>Dec</span>,
      dataIndex: 'dec',
      key: 'dec',
      width: 120,
    },
    {
      title: props.t("Total"),
      dataIndex: 'total5',
      key: 'total5',
      width: 100,
      fixed: 'right'
    },
  ];

  const data = [
    {
      key: '1',
      IncomeSales: 'TotalSales',
      jan: pnl[0] ? getTotalJSX(parseInt(pnl[0].sales) || '-') : '-',
      feb: pnl[1] ? getTotalJSX(parseInt(pnl[1].sales) || '-') : '-',
      mar: pnl[2] ? getTotalJSX(parseInt(pnl[2].sales)) : '-',
      apr: pnl[3] ? getTotalJSX(parseInt(pnl[3].sales)) : '-',
      may: pnl[4] ? getTotalJSX(parseInt(pnl[4].sales)) : '-',
      jun: pnl[5] ? getTotalJSX(parseInt(pnl[5].sales) || '-') : '-',
      jul: pnl[6] ? getTotalJSX(parseInt(pnl[6].sales) || '-') : '-',
      aug: pnl[7] ? getTotalJSX(parseInt(pnl[7].sales) || '-') : '-',
      sep: pnl[8] ? getTotalJSX(parseInt(pnl[8].sales) || '-') : '-',
      oct: pnl[9] ? getTotalJSX(parseInt(pnl[9].sales) || '-') : '-',
      nov: pnl[10] ? getTotalJSX(parseInt(pnl[10].sales) || '-') : '-',
      dec: pnl[11] ? getTotalJSX(parseInt(pnl[11].sales) || '-') : '-',
      total: getTotalJSX(totalSales !== null && totalSales !== 0 ? totalSales.toFixed(2) : '-'),
      average: getTotalJSX(avg_totalSales !== null && avg_totalSales !== 0 ? avg_totalSales.toFixed(2) : '-'),
    },
    // {
    //   key: '2',
    //   IncomeSales: 'Tax',
    //   jan: pnl[0] ? getTotalJSX(parseInt(pnl[0].taxes) || '-') : '-',
    //   feb: pnl[1] ? getTotalJSX(parseInt(pnl[1].taxes) || '-') : '-',
    //   mar: pnl[2] ? getTotalJSX(parseInt(pnl[2].taxes) || '-') : '-',
    //   apr: pnl[3] ? getTotalJSX(parseInt(pnl[3].taxes)) : '-',
    //   may: pnl[4] ? getTotalJSX(parseInt(pnl[4].taxes)) : '-',
    //   jun: pnl[5] ? getTotalJSX(parseInt(pnl[5].taxes) || '-') : '-',
    //   jul: pnl[6] ? getTotalJSX(parseInt(pnl[6].taxes) || '-') : '-',
    //   aug: pnl[7] ? getTotalJSX(parseInt(pnl[7].taxes) || '-') : '-',
    //   sep: pnl[8] ? getTotalJSX(parseInt(pnl[8].taxes) || '-') : '-',
    //   oct: pnl[9] ? getTotalJSX(parseInt(pnl[9].taxes) || '-') : '-',
    //   nov: pnl[10] ? getTotalJSX(parseInt(pnl[10].taxes) || '-') : '-',
    //   dec: pnl[11] ? getTotalJSX(parseInt(pnl[11].taxes) || '-') : '-',
    //   total: getTotalJSX(totalTaxes !== null && totalTaxes !== 0 ? totalTaxes.toFixed(2) : '-'),
    //   average: getTotalJSX(avg_taxes !== null && avg_taxes !== 0 ? avg_taxes.toFixed(2) : '-'),

    //  },
    // {
    //   key: '3',
    //   IncomeSales: 'Total Sales (including sales taxes)',
    //   jan: monthlyMisc[0] ? getTotalJSX(monthlyMisc[0]) : '-',
    //   feb: monthlyMisc[1] ? getTotalJSX(monthlyMisc[1]) : '-',
    //   mar: monthlyMisc[2] ? getTotalJSX(monthlyMisc[2]) : '-',
    //   apr: monthlyMisc[3] ? getTotalJSX(monthlyMisc[3]) : '-',
    //   may: monthlyMisc[4] ? getTotalJSX(monthlyMisc[4]) : '-',
    //   jun: monthlyMisc[5] ? getTotalJSX(monthlyMisc[5]) : '-',
    //   jul: monthlyMisc[6] ? getTotalJSX(monthlyMisc[6]) : '-',
    //   aug: monthlyMisc[7] ? getTotalJSX(monthlyMisc[7]) : '-',
    //   sep: monthlyMisc[8] ? getTotalJSX(monthlyMisc[8]) : '-',
    //   oct: monthlyMisc[9] ? getTotalJSX(monthlyMisc[9]) : '-',
    //   nov: monthlyMisc[10] ? getTotalJSX(monthlyMisc[10]) : '-',
    //   dec: monthlyMisc[11] ? getTotalJSX(monthlyMisc[11]) : '-',
    //   total: totalSales !== null && totalTaxes !== null ? getTotalJSX(totalSales + totalTaxes) : '-',
    //   average: avg_sales !== null && avg_taxes !== null ? getTotalJSX(avg_sales + avg_taxes) : '-',
    // },
  ];

  const data2 = [
    {
      key: '1',
      Expenses: 'DirectCostOfGoods',
      jan: pnl[0]?.costOfGoods !== undefined ? (parseInt(pnl[0].costOfGoods) !== 0 ? getTotalJSX(parseInt(pnl[0].costOfGoods)) : '-') : '-',
      feb: pnl[1]?.costOfGoods !== undefined ? (parseInt(pnl[1].costOfGoods) !== 0 ? getTotalJSX(parseInt(pnl[1].costOfGoods)) : '-') : '-',
      mar: pnl[2]?.costOfGoods !== undefined ? (parseInt(pnl[2].costOfGoods) !== 0 ? getTotalJSX(parseInt(pnl[2].costOfGoods)) : '-') : '-',
      apr: pnl[3]?.costOfGoods !== undefined ? (parseInt(pnl[3].costOfGoods) !== 0 ? getTotalJSX(parseInt(pnl[3].costOfGoods)) : '-') : '-',
      may: pnl[4]?.costOfGoods !== undefined ? (parseInt(pnl[4].costOfGoods) !== 0 ? getTotalJSX(parseInt(pnl[4].costOfGoods)) : '-') : '-',
      jun: pnl[5]?.costOfGoods !== undefined ? (parseInt(pnl[5].costOfGoods) !== 0 ? getTotalJSX(parseInt(pnl[5].costOfGoods)) : '-') : '-',
      jul: pnl[6]?.costOfGoods !== undefined ? (parseInt(pnl[6].costOfGoods) !== 0 ? getTotalJSX(parseInt(pnl[6].costOfGoods)) : '-') : '-',
      aug: pnl[7]?.costOfGoods !== undefined ? (parseInt(pnl[7].costOfGoods) !== 0 ? getTotalJSX(parseInt(pnl[7].costOfGoods)) : '-') : '-',
      sep: pnl[8]?.costOfGoods !== undefined ? (parseInt(pnl[8].costOfGoods) !== 0 ? getTotalJSX(parseInt(pnl[8].costOfGoods)) : '-') : '-',
      oct: pnl[9]?.costOfGoods !== undefined ? (parseInt(pnl[9].costOfGoods) !== 0 ? getTotalJSX(parseInt(pnl[9].costOfGoods)) : '-') : '-',
      nov: pnl[10]?.costOfGoods !== undefined ? (parseInt(pnl[10].costOfGoods) !== 0 ? getTotalJSX(parseInt(pnl[10].costOfGoods)) : '-') : '-',
      dec: pnl[11]?.costOfGoods !== undefined ? (parseInt(pnl[11].costOfGoods) !== 0 ? getTotalJSX(parseInt(pnl[11].costOfGoods)) : '-') : '-',
      total2: getTotalJSX(totalCostOfGoods !== null && totalCostOfGoods !== 0 ? totalCostOfGoods : '-'),
      average2: calculateAverage(totalCostOfGoods, 12),
    },
    {
      key: '2',
      Expenses: 'ShippingHandling',
      jan: pnl[0]?.SnH !== undefined ? (parseInt(pnl[0].SnH) !== 0 ? getTotalJSX(parseInt(pnl[0].SnH)) : '-') : '-',
      feb: pnl[1]?.SnH !== undefined ? (parseInt(pnl[1].SnH) !== 0 ? getTotalJSX(parseInt(pnl[1].SnH)) : '-') : '-',
      mar: pnl[2]?.SnH !== undefined ? (parseInt(pnl[2].SnH) !== 0 ? getTotalJSX(parseInt(pnl[2].SnH)) : '-') : '-',
      apr: pnl[3]?.SnH !== undefined ? (parseInt(pnl[3].SnH) !== 0 ? getTotalJSX(parseInt(pnl[3].SnH)) : '-') : '-',
      may: pnl[4]?.SnH !== undefined ? (parseInt(pnl[4].SnH) !== 0 ? getTotalJSX(parseInt(pnl[4].SnH)) : '-') : '-',
      jun: pnl[5]?.SnH !== undefined ? (parseInt(pnl[5].SnH) !== 0 ? getTotalJSX(parseInt(pnl[5].SnH)) : '-') : '-',
      jul: pnl[6]?.SnH !== undefined ? (parseInt(pnl[6].SnH) !== 0 ? getTotalJSX(parseInt(pnl[6].SnH)) : '-') : '-',
      aug: pnl[7]?.SnH !== undefined ? (parseInt(pnl[7].SnH) !== 0 ? getTotalJSX(parseInt(pnl[7].SnH)) : '-') : '-',
      sep: pnl[8]?.SnH !== undefined ? (parseInt(pnl[8].SnH) !== 0 ? getTotalJSX(parseInt(pnl[8].SnH)) : '-') : '-',
      oct: pnl[9]?.SnH !== undefined ? (parseInt(pnl[9].SnH) !== 0 ? getTotalJSX(parseInt(pnl[9].SnH)) : '-') : '-',
      nov: pnl[10]?.SnH !== undefined ? (parseInt(pnl[10].SnH) !== 0 ? getTotalJSX(parseInt(pnl[10].SnH)) : '-') : '-',
      dec: pnl[11]?.SnH !== undefined ? (parseInt(pnl[11].SnH) !== 0 ? getTotalJSX(parseInt(pnl[11].SnH)) : '-') : '-',
      total2: getTotalJSX(totalSnH !== null && totalSnH !== 0 ? totalSnH : '-'),
      average2: calculateAverage(totalSnH, 12),
    },
    {
      key: '3',
      Expenses: 'RefundsReturns',
      jan: pnl[0]?.refunds !== undefined ? (parseInt(pnl[0].refunds) !== 0 ? getTotalJSX(parseInt(pnl[0].refunds)) : '-') : '-',
      feb: pnl[1]?.refunds !== undefined ? (parseInt(pnl[1].refunds) !== 0 ? getTotalJSX(parseInt(pnl[1].refunds)) : '-') : '-',
      mar: pnl[2]?.refunds !== undefined ? (parseInt(pnl[2].refunds) !== 0 ? getTotalJSX(parseInt(pnl[2].refunds)) : '-') : '-',
      apr: pnl[3]?.refunds !== undefined ? (parseInt(pnl[3].refunds) !== 0 ? getTotalJSX(parseInt(pnl[3].refunds)) : '-') : '-',
      may: pnl[4]?.refunds !== undefined ? (parseInt(pnl[4].refunds) !== 0 ? getTotalJSX(parseInt(pnl[4].refunds)) : '-') : '-',
      jun: pnl[5]?.refunds !== undefined ? (parseInt(pnl[5].refunds) !== 0 ? getTotalJSX(parseInt(pnl[5].refunds)) : '-') : '-',
      jul: pnl[6]?.refunds !== undefined ? (parseInt(pnl[6].refunds) !== 0 ? getTotalJSX(parseInt(pnl[6].refunds)) : '-') : '-',
      aug: pnl[7]?.refunds !== undefined ? (parseInt(pnl[7].refunds) !== 0 ? getTotalJSX(parseInt(pnl[7].refunds)) : '-') : '-',
      sep: pnl[8]?.refunds !== undefined ? (parseInt(pnl[8].refunds) !== 0 ? getTotalJSX(parseInt(pnl[8].refunds)) : '-') : '-',
      oct: pnl[9]?.refunds !== undefined ? (parseInt(pnl[9].refunds) !== 0 ? getTotalJSX(parseInt(pnl[9].refunds)) : '-') : '-',
      nov: pnl[10]?.refunds !== undefined ? (parseInt(pnl[10].refunds) !== 0 ? getTotalJSX(parseInt(pnl[10].refunds)) : '-') : '-',
      dec: pnl[11]?.refunds !== undefined ? (parseInt(pnl[11].refunds) !== 0 ? getTotalJSX(parseInt(pnl[11].refunds)) : '-') : '-',
      total2: getTotalJSX(totalRefunds !== null && totalRefunds !== 0 ? totalRefunds : '-'),
      average2: calculateAverage(totalRefunds, 12),
    },
    {
      key: '4',
      Expenses: 'Discounts',
      jan: pnl[0]?.discounts !== undefined ? (parseInt(pnl[0].discounts) !== 0 ? getTotalJSX(parseInt(pnl[0].discounts)) : '-') : '-',
      feb: pnl[1]?.discounts !== undefined ? (parseInt(pnl[1].discounts) !== 0 ? getTotalJSX(parseInt(pnl[1].discounts)) : '-') : '-',
      mar: pnl[2]?.discounts !== undefined ? (parseInt(pnl[2].discounts) !== 0 ? getTotalJSX(parseInt(pnl[2].discounts)) : '-') : '-',
      apr: pnl[3]?.discounts !== undefined ? (parseInt(pnl[3].discounts) !== 0 ? getTotalJSX(parseInt(pnl[3].discounts)) : '-') : '-',
      may: pnl[4]?.discounts !== undefined ? (parseInt(pnl[4].discounts) !== 0 ? getTotalJSX(parseInt(pnl[4].discounts)) : '-') : '-',
      jun: pnl[5]?.discounts !== undefined ? (parseInt(pnl[5].discounts) !== 0 ? getTotalJSX(parseInt(pnl[5].discounts)) : '-') : '-',
      jul: pnl[6]?.discounts !== undefined ? (parseInt(pnl[6].discounts) !== 0 ? getTotalJSX(parseInt(pnl[6].discounts)) : '-') : '-',
      aug: pnl[7]?.discounts !== undefined ? (parseInt(pnl[7].discounts) !== 0 ? getTotalJSX(parseInt(pnl[7].discounts)) : '-') : '-',
      sep: pnl[8]?.discounts !== undefined ? (parseInt(pnl[8].discounts) !== 0 ? getTotalJSX(parseInt(pnl[8].discounts)) : '-') : '-',
      oct: pnl[9]?.discounts !== undefined ? (parseInt(pnl[9].discounts) !== 0 ? getTotalJSX(parseInt(pnl[9].discounts)) : '-') : '-',
      nov: pnl[10]?.discounts !== undefined ? (parseInt(pnl[10].discounts) !== 0 ? getTotalJSX(parseInt(pnl[10].discounts)) : '-') : '-',
      dec: pnl[11]?.discounts !== undefined ? (parseInt(pnl[11].discounts) !== 0 ? getTotalJSX(parseInt(pnl[11].discounts)) : '-') : '-',
      total2: getTotalJSX(totalDiscounts !== null && totalDiscounts !== 0 ? totalDiscounts : '-'),
      average2: calculateAverage(totalDiscounts, 12),
    },
    {
      key: '5',
      Expenses: 'Ads',
      jan: pnl[0]?.ads !== undefined ? (parseInt(pnl[0].ads) !== 0 ? getTotalJSX(parseInt(pnl[0].ads)) : '-') : '-',
      feb: pnl[1]?.ads !== undefined ? (parseInt(pnl[1].ads) !== 0 ? getTotalJSX(parseInt(pnl[1].ads)) : '-') : '-',
      mar: pnl[2]?.ads !== undefined ? (parseInt(pnl[2].ads) !== 0 ? getTotalJSX(parseInt(pnl[2].ads)) : '-') : '-',
      apr: pnl[3]?.ads !== undefined ? (parseInt(pnl[3].ads) !== 0 ? getTotalJSX(parseInt(pnl[3].ads)) : '-') : '-',
      may: pnl[4]?.ads !== undefined ? (parseInt(pnl[4].ads) !== 0 ? getTotalJSX(parseInt(pnl[4].ads)) : '-') : '-',
      jun: pnl[5]?.ads !== undefined ? (parseInt(pnl[5].ads) !== 0 ? getTotalJSX(parseInt(pnl[5].ads)) : '-') : '-',
      jul: pnl[6]?.ads !== undefined ? (parseInt(pnl[6].ads) !== 0 ? getTotalJSX(parseInt(pnl[6].ads)) : '-') : '-',
      aug: pnl[7]?.ads !== undefined ? (parseInt(pnl[7].ads) !== 0 ? getTotalJSX(parseInt(pnl[7].ads)) : '-') : '-',
      sep: pnl[8]?.ads !== undefined ? (parseInt(pnl[8].ads) !== 0 ? getTotalJSX(parseInt(pnl[8].ads)) : '-') : '-',
      oct: pnl[9]?.ads !== undefined ? (parseInt(pnl[9].ads) !== 0 ? getTotalJSX(parseInt(pnl[9].ads)) : '-') : '-',
      nov: pnl[10]?.ads !== undefined ? (parseInt(pnl[10].ads) !== 0 ? getTotalJSX(parseInt(pnl[10].ads)) : '-') : '-',
      dec: pnl[11]?.ads !== undefined ? (parseInt(pnl[11].ads) !== 0 ? getTotalJSX(parseInt(pnl[11].ads)) : '-') : '-',
      total2: getTotalJSX(totalAds !== null && totalAds !== 0 ? totalAds : '-'),
      average2: calculateAverage(totalAds, 12),
    },

    {
      key: '6',
      Expenses: 'Total',
      jan: getTotalJSX(monthlyExpenses[0]),
      feb: getTotalJSX(monthlyExpenses[1]),
      mar: getTotalJSX(monthlyExpenses[2]),
      apr: getTotalJSX(monthlyExpenses[3]),
      may: getTotalJSX(monthlyExpenses[4]),
      jun: getTotalJSX(monthlyExpenses[5]),
      jul: getTotalJSX(monthlyExpenses[6]),
      aug: getTotalJSX(monthlyExpenses[7]),
      sep: getTotalJSX(monthlyExpenses[8]),
      oct: getTotalJSX(monthlyExpenses[9]),
      nov: getTotalJSX(monthlyExpenses[10]),
      dec: getTotalJSX(monthlyExpenses[11]),

      //total: totalSales2.toFixed(2),

      //average: (avg_sales !== null && avg_taxes !== null) ? (avg_sales + avg_taxes).toFixed(2) : '-',

    },

  ];


  const data3 = [
    {
      key: '1',
      TotalOrdersUnits: 'Orders',
      jan: pnl[0] && pnl[0].orders !== undefined ? (parseInt(pnl[0].orders) !== 0 ? pnl[0].orders : '-') : '-',
      feb: pnl[1] && pnl[1].orders !== undefined ? (parseInt(pnl[1].orders) !== 0 ? pnl[1].orders : '-') : '-',
      mar: pnl[2] && pnl[2].orders !== undefined ? (parseInt(pnl[2].orders) !== 0 ? pnl[2].orders : '-') : '-',
      apr: pnl[3] && pnl[3].orders !== undefined ? (parseInt(pnl[3].orders) !== 0 ? pnl[3].orders : '-') : '-',
      may: pnl[4] && pnl[4].orders !== undefined ? (parseInt(pnl[4].orders) !== 0 ? pnl[4].orders : '-') : '-',
      jun: pnl[5] && pnl[5].orders !== undefined ? (parseInt(pnl[5].orders) !== 0 ? pnl[5].orders : '-') : '-',
      jul: pnl[6] && pnl[6].orders !== undefined ? (parseInt(pnl[6].orders) !== 0 ? pnl[6].orders : '-') : '-',
      aug: pnl[7] && pnl[7].orders !== undefined ? (parseInt(pnl[7].orders) !== 0 ? pnl[7].orders : '-') : '-',
      sep: pnl[8] && pnl[8].orders !== undefined ? (parseInt(pnl[8].orders) !== 0 ? pnl[8].orders : '-') : '-',
      oct: pnl[9] && pnl[9].orders !== undefined ? (parseInt(pnl[9].orders) !== 0 ? pnl[9].orders : '-') : '-',
      nov: pnl[10] && pnl[10].orders !== undefined ? (parseInt(pnl[10].orders) !== 0 ? pnl[10].orders : '-') : '-',
      dec: pnl[11] && pnl[11].orders !== undefined ? (parseInt(pnl[11].orders) !== 0 ? pnl[11].orders : '-') : '-',

      total3: getTotalJSX2(totalOrders),
      average3: calculateAverage2(totalOrders, 12),

    },
    {
      key: '2',
      TotalOrdersUnits: 'Units',
      jan: pnl[0] && pnl[0].units !== undefined ? (parseInt(pnl[0].units) !== 0 ? pnl[0].units : '-') : '-',
      feb: pnl[1] && pnl[1].units !== undefined ? (parseInt(pnl[1].units) !== 0 ? pnl[1].units : '-') : '-',
      mar: pnl[2] && pnl[2].units !== undefined ? (parseInt(pnl[2].units) !== 0 ? pnl[2].units : '-') : '-',
      apr: pnl[3] && pnl[3].units !== undefined ? (parseInt(pnl[3].units) !== 0 ? pnl[3].units : '-') : '-',
      may: pnl[4] && pnl[4].units !== undefined ? (parseInt(pnl[4].units) !== 0 ? pnl[4].units : '-') : '-',
      jun: pnl[5] && pnl[5].units !== undefined ? (parseInt(pnl[5].units) !== 0 ? pnl[5].units : '-') : '-',
      jul: pnl[6] && pnl[6].units !== undefined ? (parseInt(pnl[6].units) !== 0 ? pnl[6].units : '-') : '-',
      aug: pnl[7] && pnl[7].units !== undefined ? (parseInt(pnl[7].units) !== 0 ? pnl[7].units : '-') : '-',
      sep: pnl[8] && pnl[8].units !== undefined ? (parseInt(pnl[8].units) !== 0 ? pnl[8].units : '-') : '-',
      oct: pnl[9] && pnl[9].units !== undefined ? (parseInt(pnl[9].units) !== 0 ? pnl[9].units : '-') : '-',
      nov: pnl[10] && pnl[10].units !== undefined ? (parseInt(pnl[10].units) !== 0 ? pnl[10].units : '-') : '-',
      dec: pnl[11] && pnl[11].units !== undefined ? (parseInt(pnl[11].units) !== 0 ? pnl[11].units : '-') : '-',

      total3: getTotalJSX2(totalUnits),
      average3: calculateAverage2(totalUnits, 12),
    },
  ];
  const data4 = [
    {
      key: '1',
      Profit: 'GrossProfit',
      jan: pnl[0] && pnl[0].gross !== undefined ? (parseInt(pnl[0].gross) !== 0 ? getTotalJSX(parseInt(pnl[0].gross)) : '-') : '-',
      feb: pnl[1] && pnl[1].gross !== undefined ? (parseInt(pnl[1].gross) !== 0 ? getTotalJSX(parseInt(pnl[1].gross)) : '-') : '-',
      mar: pnl[2] && pnl[2].gross !== undefined ? (parseInt(pnl[2].gross) !== 0 ? getTotalJSX(parseInt(pnl[2].gross)) : '-') : '-',
      apr: pnl[3] && pnl[3].gross !== undefined ? (parseInt(pnl[3].gross) !== 0 ? getTotalJSX(parseInt(pnl[3].gross)) : '-') : '-',
      may: pnl[4] && pnl[4].gross !== undefined ? (parseInt(pnl[4].gross) !== 0 ? getTotalJSX(parseInt(pnl[4].gross)) : '-') : '-',
      jun: pnl[5] && pnl[5].gross !== undefined ? (parseInt(pnl[5].gross) !== 0 ? getTotalJSX(parseInt(pnl[5].gross)) : '-') : '-',
      jul: pnl[6] && pnl[6].gross !== undefined ? (parseInt(pnl[6].gross) !== 0 ? getTotalJSX(parseInt(pnl[6].gross)) : '-') : '-',
      aug: pnl[7] && pnl[7].gross !== undefined ? (parseInt(pnl[7].gross) !== 0 ? getTotalJSX(parseInt(pnl[7].gross)) : '-') : '-',
      sep: pnl[8] && pnl[8].gross !== undefined ? (parseInt(pnl[8].gross) !== 0 ? getTotalJSX(parseInt(pnl[8].gross)) : '-') : '-',
      oct: pnl[9] && pnl[9].gross !== undefined ? (parseInt(pnl[9].gross) !== 0 ? getTotalJSX(parseInt(pnl[9].gross)) : '-') : '-',
      nov: pnl[10] && pnl[10].gross !== undefined ? (parseInt(pnl[10].gross) !== 0 ? getTotalJSX(parseInt(pnl[10].gross)) : '-') : '-',
      dec: pnl[11] && pnl[11].gross !== undefined ? (parseInt(pnl[11].gross) !== 0 ? getTotalJSX(parseInt(pnl[11].gross)) : '-') : '-',

      total4: getTotalJSX(totalGrossProfit),
      average4: calculateAverage(totalGrossProfit, 12),
    },
  ];
  //console.log("ss", data4);
  const totalExpenses = {
    jan: getTotalJSX(newMinCosts.reduce((total, item) => total + parseInt(item.jan || 0), 0)),
    feb: getTotalJSX(newMinCosts.reduce((total, item) => total + parseInt(item.feb || 0), 0)),
    mar: getTotalJSX(newMinCosts.reduce((total, item) => total + parseInt(item.mar || 0), 0)),
    apr: getTotalJSX(newMinCosts.reduce((total, item) => total + parseInt(item.apr || 0), 0)),
    may: getTotalJSX(newMinCosts.reduce((total, item) => total + parseInt(item.may || 0), 0)),
    jun: getTotalJSX(newMinCosts.reduce((total, item) => total + parseInt(item.jun || 0), 0)),
    jul: getTotalJSX(newMinCosts.reduce((total, item) => total + parseInt(item.jul || 0), 0)),
    aug: getTotalJSX(newMinCosts.reduce((total, item) => total + parseInt(item.aug || 0), 0)),
    sep: getTotalJSX(newMinCosts.reduce((total, item) => total + parseInt(item.sep || 0), 0)),
    oct: getTotalJSX(newMinCosts.reduce((total, item) => total + parseInt(item.oct || 0), 0)),
    nov: getTotalJSX(newMinCosts.reduce((total, item) => total + parseInt(item.nov || 0), 0)),
    dec: getTotalJSX(newMinCosts.reduce((total, item) => total + parseInt(item.dec || 0), 0)),
  };

  // Function to calculate total expenses
  const calculateTotalExpenses = (items) => {
    const totalExpensess = {
      jan: items.reduce((total, item) => total + parseInt(item.jan || 0), 0),
      feb: items.reduce((total, item) => total + parseInt(item.feb || 0), 0),
      mar: items.reduce((total, item) => total + parseInt(item.mar || 0), 0),
      apr: items.reduce((total, item) => total + parseInt(item.apr || 0), 0),
      may: items.reduce((total, item) => total + parseInt(item.may || 0), 0),
      jun: items.reduce((total, item) => total + parseInt(item.jun || 0), 0),
      jul: items.reduce((total, item) => total + parseInt(item.jul || 0), 0),
      aug: items.reduce((total, item) => total + parseInt(item.aug || 0), 0),
      sep: items.reduce((total, item) => total + parseInt(item.sep || 0), 0),
      oct: items.reduce((total, item) => total + parseInt(item.oct || 0), 0),
      nov: items.reduce((total, item) => total + parseInt(item.nov || 0), 0),
      dec: items.reduce((total, item) => total + parseInt(item.dec || 0), 0),
    };
    return totalExpensess;
  };

  // Use the function to calculate total expenses
  const totalExpensess = calculateTotalExpenses(newMinCosts);

  // Calculate total sum of all months
  const totalSum = Object.values(totalExpensess).reduce((total, val) => total + val, 0);

  const totalRow = {
    key: 'total-all',
    MiscExpenses: 'Total',
    jan: totalExpenses.jan,
    feb: totalExpenses.feb,
    mar: totalExpenses.mar,
    apr: totalExpenses.apr,
    may: totalExpenses.may,
    jun: totalExpenses.jun,
    jul: totalExpenses.jul,
    aug: totalExpenses.aug,
    sep: totalExpenses.sep,
    oct: totalExpenses.oct,
    nov: totalExpenses.nov,
    dec: totalExpenses.dec,
    total5: `$${totalSum}`, // Total sum of all months
  };

const data5 = newMinCosts.map((item, index) => ({
  key: `row-${index}`,
  MiscExpenses: item.name,
  jan: getTotalJSX(item.jan || '-'),
  feb: getTotalJSX(item.feb || '-'),
  mar: getTotalJSX(item.mar || '-'),
  apr: getTotalJSX(item.apr || '-'),
  may: getTotalJSX(item.may || '-'),
  jun: getTotalJSX(item.jun || '-'),
  jul: getTotalJSX(item.jul || '-'),
  aug: getTotalJSX(item.aug || '-'),
  sep: getTotalJSX(item.sep || '-'),
  oct: getTotalJSX(item.oct || '-'),
  nov: getTotalJSX(item.nov || '-'),
  dec: getTotalJSX(item.dec || '-'),
}));

// Check if minCosts is not empty
if (newMinCosts.length > 0) {
  // Add totalRow only if minCosts is not empty
  data5.push(totalRow);
}

  const { direction } = props;


  // ** Convert data for series
  const seriesData = data.map(item => ({
    name: item.IncomeSales,
    data: [
      item.jan === '-' ? 0 : parseFloat(getRawValue(item.jan)),
      item.feb === '-' ? 0 : parseFloat(getRawValue(item.feb)),
      item.mar === '-' ? 0 : parseFloat(getRawValue(item.mar)),
      item.apr === '-' ? 0 : parseFloat(getRawValue(item.apr)),
      item.may === '-' ? 0 : parseFloat(getRawValue(item.may)),
      item.jun === '-' ? 0 : parseFloat(getRawValue(item.jun)),
      item.jul === '-' ? 0 : parseFloat(getRawValue(item.jul)),
      item.aug === '-' ? 0 : parseFloat(getRawValue(item.aug)),
      item.sep === '-' ? 0 : parseFloat(getRawValue(item.sep)),
      item.oct === '-' ? 0 : parseFloat(getRawValue(item.oct)),
      item.nov === '-' ? 0 : parseFloat(getRawValue(item.nov)),
      item.dec === '-' ? 0 : parseFloat(getRawValue(item.dec)),
    ]
  }));

  //** Chart Options
  const optionsd = {
    chart: {
      height: 150,
      type: 'bar',
      stacked: true,
      parentHeightOffset: 0,
      toolbar: {
        show: false
      }
    },
    plotOptions: {
      bar: {
        columnWidth: '25%',
        //borderRadius: 10 // Removed backgroundBarRadius
      }
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: false,
      position: 'top',
      horizontalAlign: 'start'
    },
    colors: ['#0044BD', '#6789F2'], // Moved colors here from plotOptions
    stroke: {
      show: true,
      colors: ['transparent']
    },
    grid: {
      show: true,
      borderColor: '#f0f0f0',
      xaxis: {
        lines: {
          show: true
        }
      }
    },
    fill: {
      opacity: 1
    },
    yaxis: {
      opposite: direction === 'rtl'
    },
    xaxis: {
      categories: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'Novembre', 'Decembre']
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return currency +' '+ formatNumberWithSpaces(val) ;
        }
      }
    }
  };

  //pie chart options

const options = {
  tooltips: {
    callbacks: {
      label: function(tooltipItem, data) {
        let dataset = data.datasets[tooltipItem.datasetIndex];
       
        let currentValue = dataset.data[tooltipItem.index];
        let formattedValue = formatNumberWithSpaces(currentValue);
        return currency +' '+ formatNumberWithSpaces(formattedValue);
      }
    }
  }
};


const EditableContext = React.createContext(null);
const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};
const EditableCell = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef(null);
  const form = useContext(EditableContext);
  useEffect(() => {
    if (editing) {
      inputRef.current?.focus();
    }
  }, [editing]);
  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({
      [dataIndex]: record[dataIndex],
    });
  };
  const save = async () => {
    try {
      const values = await form.validateFields();
      toggleEdit();
      handleSave({
        ...record,
        ...values,
      });
    } catch (errInfo) {
      console.log('Save failed:', errInfo);
    }
  };
  let childNode = children;
  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{
          margin: 0,
        }}
        name={dataIndex}
        rules={[
          {
            required: true,
            message: `${title} is required.`,
          },
        ]}
      >
        <Input ref={inputRef} onPressEnter={save} onBlur={save} />
      </Form.Item>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{
          paddingRight: 24,
        }}
        onClick={toggleEdit}
      >
        {children}
      </div>
    );
  }
  return <td {...restProps}>{childNode}</td>;
};

  const [dataSource, setDataSource] = useState([
    
  ]);
  const [count, setCount] = useState(2);
  const handleDelete = (key) => {
    const newData = dataSource.filter((item) => item.key !== key);
    setDataSource(newData);
  };
  const defaultColumns = [
    {
      title: <span style={{ fontWeight: 'bold' }}>Expenses</span>,
      dataIndex: 'Expenses',
      editable: true,
    },
    {
      title: <span style={{ fontWeight: 'bold' }}>Jan</span>,
      dataIndex: 'jan',
      editable: true,
    },
    {
      title: <span style={{ fontWeight: 'bold' }}>Feb</span>,
      dataIndex: 'feb',
      editable: true,
    },
    {
      title: <span style={{ fontWeight: 'bold' }}>Mar</span>,
      dataIndex: 'mar',
      editable: true,
    },
    {
      title: <span style={{ fontWeight: 'bold' }}>Apr</span>,
      dataIndex: 'apr',
      editable: true,
    },
    {
      title: <span style={{ fontWeight: 'bold' }}>May</span>,
      dataIndex: 'may',
      editable: true,
    },
    {
      title: <span style={{ fontWeight: 'bold' }}>Jun</span>,
      dataIndex: 'jun',
      editable: true,
    },
    {
      title: <span style={{ fontWeight: 'bold' }}>Jul</span>,
      dataIndex: 'jul',
      editable: true,
    },
    {
      title: <span style={{ fontWeight: 'bold' }}>Aug</span>,
      dataIndex: 'aug',
      editable: true,
    },
    {
      title: <span style={{ fontWeight: 'bold' }}>Sep</span>,
      dataIndex: 'sep',
      editable: true,
    },
    {
      title: <span style={{ fontWeight: 'bold' }}>Oct</span>,
      dataIndex: 'oct',
      editable: true,
    },
    {
      title: <span style={{ fontWeight: 'bold' }}>Nov</span>,
      dataIndex: 'nov',
      editable: true,
    },
    {
      title: <span style={{ fontWeight: 'bold' }}>Dec</span>,
      dataIndex: 'dec',
      editable: true,
    },
    {
      title: <span style={{ fontWeight: 'bold' }}>Operation</span>,
      dataIndex: 'operation',
      render: (_, record) =>
        dataSource.length >= 1 ? (
          <Popconfirm title="Sure to delete?" onConfirm={() => handleDelete(record.key)}>
            <a>Delete</a>
          </Popconfirm>
        ) : null,
    },
  ];
  const handleAdd = () => {
    const newData = {
      key: count,
      Expenses: `Expenses ${count}`,
      jan: 0,
      feb: 0,
      mar: 0,
      apr: 0,
      may: 0,
      jun: 0,
      jul: 0,
      aug: 0,
      sep: 0,
      oct: 0,
      nov: 0,
      dec: 0,      
    };
    setDataSource([...dataSource, newData]);
    setCount(count + 1);
  };
  const handleSave = (row) => {
    const newData = [...dataSource];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    setDataSource(newData);
  };
  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };
  const columns6 = defaultColumns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
      }),
    };
  });
  // Function to extract raw string value from JSX element
  function getRawValue(jsxElement) {
    if (typeof jsxElement === 'string') {
      // If jsxElement is already a string, return it directly
      return jsxElement;
    } else if (typeof jsxElement === 'object' && jsxElement !== null && jsxElement.props && jsxElement.props.value) {
      // If jsxElement is an object with props.value, return props.value
      return jsxElement.props.value;
    } else {
      // If jsxElement is not a string or an object with props.value, return an empty string
      return '';
    }
  }
 
  const formatNumberWithSpaces = (value) => {
    if (value !== undefined && value !== null) {
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    }
    return ''; // or any default value you want to return for undefined/null cases
  };
  const ordersData = {
    labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    datasets: [
      {
        label: props.t('Orders'),
        data: [
          pnl[0]?.orders || 0,
          pnl[1]?.orders || 0,
          pnl[2]?.orders || 0,
          pnl[3]?.orders || 0,
          pnl[4]?.orders || 0,
          pnl[5]?.orders || 0,
          pnl[6]?.orders || 0,
          pnl[7]?.orders || 0,
          pnl[8]?.orders || 0,
          pnl[9]?.orders || 0,
          pnl[10]?.orders || 0,
          pnl[11]?.orders || 0
        ],
        backgroundColor: [
          '#008FFB', '#00E396', '#FEB019', '#FF4560', '#775DD0', '#3F51B5',
          '#546E7A', '#D4526E', '#8D5B4C', '#F86624', '#D7263D', '#1B998B'
        ]

      },
      {
        label: props.t('Units'),
        data: [
          pnl[0]?.units || 0,
          pnl[1]?.units || 0,
          pnl[2]?.units || 0,
          pnl[3]?.units || 0,
          pnl[4]?.units || 0,
          pnl[5]?.units || 0,
          pnl[6]?.units || 0,
          pnl[7]?.units || 0,
          pnl[8]?.units || 0,
          pnl[9]?.units || 0,
          pnl[10]?.units || 0,
          pnl[11]?.units || 0
        ],
        backgroundColor: [
          '#FF5733', '#FFBD33', '#FF3333', '#FF5733', '#FF33A1', '#33FFC9',
          '#FFC933', '#33FF57', '#336DFF', '#E233FF', '#7A33FF', '#FF336D'
        ]

      }
    ]
  };

  // Chart data
  const formatNumber = (number) => {
    return parseFloat(number).toFixed(2);
    
  };
 
const grosssDatau = {
  labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
  datasets: [
    {
      label: 'Gross',
      data: [
        formatNumber(pnl[0]?.gross || 0),
        formatNumber(pnl[1]?.gross || 0),
        formatNumber(pnl[2]?.gross || 0),
        formatNumber(pnl[3]?.gross || 0),
        formatNumber(pnl[4]?.gross || 0),
        formatNumber(pnl[5]?.gross || 0),
        formatNumber(pnl[6]?.gross || 0),
        formatNumber(pnl[7]?.gross || 0),
        formatNumber(pnl[8]?.gross || 0),
        formatNumber(pnl[9]?.gross || 0),
        formatNumber(pnl[10]?.gross || 0),
        formatNumber(pnl[11]?.gross || 0)
      ],
      backgroundColor: [
        '#1f77b4', '#ff7f0e', '#2ca02c', '#d62728', '#9467bd', '#8c564b',
        '#e377c2', '#7f7f7f', '#bcbd22', '#17becf', '#aec7e8', '#ffbb78'
      ]
    }
  ]
};

 
  const generatePDF = () => {

 
    const doc = new jsPDF('landscape');
    const fontSize = 8;
    // Define the default options for autoTable
    const defaultOptions = {
      margin: { horizontal: 10 },
      styles: {
        headerFontStyle: 'bold',
        fontSize: fontSize,
      },
      columnStyles: {
        jan: { cellWidth: 15 },
        feb: { cellWidth: 15 },
        // Add other months and styles as needed...
        total: { fontStyle: 'bold' },
        average: { fontStyle: 'bold' },
        total2: { fontStyle: 'bold', cellWidth: 30 },
        average2: { fontStyle: 'bold' },
      },
    };
    // Define the columns for the table
    const columns = [
      { header: 'IncomeSales', dataKey: 'IncomeSales' },
      { header: 'Jan', dataKey: 'jan' },
      { header: 'Feb', dataKey: 'feb' },
      { header: 'Mar', dataKey: 'mar' },
      { header: 'Apr', dataKey: 'apr' },
      { header: 'May', dataKey: 'may' },
      { header: 'Jun', dataKey: 'jun' },
      { header: 'Jul', dataKey: 'jul' },
      { header: 'Aug', dataKey: 'aug' },
      { header: 'Sep', dataKey: 'sep' },
      { header: 'Oct', dataKey: 'oct' },
      { header: 'Nov', dataKey: 'nov' },
      { header: 'Dec', dataKey: 'dec' },
      { header: 'Total', dataKey: 'total' },
      { header: 'Average', dataKey: 'average' },
    ];

    // Define the data for the table
    const data = [
      {
        IncomeSales: 'TotalSales',
        jan: parseInt(pnl[0].sales),
        feb: parseInt(pnl[1].sales),
        mar: parseInt(pnl[2].sales).toLocaleString('en-US', { style: 'currency', currency: 'USD' }).slice(0, -3),
        apr: parseInt(pnl[3].sales).toLocaleString('en-US', { style: 'currency', currency: 'USD' }).slice(0, -3),
        may: parseInt(pnl[4].sales).toLocaleString('en-US', { style: 'currency', currency: 'USD' }).slice(0, -3),
        jun: parseInt(pnl[5].sales),
        jul: parseInt(pnl[6].sales),
        aug: parseInt(pnl[7].sales),
        sep: parseInt(pnl[8].sales),
        oct: parseInt(pnl[9].sales),
        nov: parseInt(pnl[10].sales),
        dec: parseInt(pnl[11].sales),
        total: totalSales.toFixed(2),
        average: avg_totalSales.toFixed(2),
      },

    ];


    // Add the table to the PDF document

    //doc.autoTable(columns, data);
    doc.autoTable({
      ...defaultOptions,
      startY: 10,
      columns: columns,
      body: data,
    });

    const columns2 = [
      { header: 'Expenses', dataKey: 'Expenses' },
      { header: 'Jan', dataKey: 'jan' },
      { header: 'Feb', dataKey: 'feb' },
      { header: 'Mar', dataKey: 'mar' },
      { header: 'Apr', dataKey: 'apr' },
      { header: 'May', dataKey: 'may' },
      { header: 'Jun', dataKey: 'jun' },
      { header: 'Jul', dataKey: 'jul' },
      { header: 'Aug', dataKey: 'aug' },
      { header: 'Sep', dataKey: 'sep' },
      { header: 'Oct', dataKey: 'oct' },
      { header: 'Nov', dataKey: 'nov' },
      { header: 'Dec', dataKey: 'dec' },
      { header: 'Total', dataKey: 'total2' },
      { header: 'Average', dataKey: 'average2' },
    ];
    const monthlyExpenses = calculateMonthlyExpenses(pnl);

    const data2 = [
      {
        Expenses: 'DirectCostOfGoods',
        jan: parseInt(pnl[0].costOfGoods),
        feb: parseInt(pnl[1].costOfGoods),
        mar: parseInt(pnl[2].costOfGoods),
        apr: parseInt(pnl[3].costOfGoods),
        may: parseInt(pnl[4].costOfGoods),
        jun: parseInt(pnl[5].costOfGoods),
        jul: parseInt(pnl[6].costOfGoods),
        aug: parseInt(pnl[7].costOfGoods),
        sep: parseInt(pnl[8].costOfGoods),
        oct: parseInt(pnl[9].costOfGoods),
        nov: parseInt(pnl[10].costOfGoods),
        dec: parseInt(pnl[11].costOfGoods),
        total2: totalCostOfGoods.toFixed(2),
        average2: avg_costOfGoods.toFixed(2),



      },
      {
        Expenses: 'ShippingHandling',
        jan: parseInt(pnl[0].SnH),
        feb: parseInt(pnl[1].SnH),
        mar: parseInt(pnl[2].SnH),
        apr: parseInt(pnl[3].SnH),
        may: parseInt(pnl[4].SnH),
        jun: parseInt(pnl[5].SnH),
        jul: parseInt(pnl[6].SnH),
        aug: parseInt(pnl[7].SnH),
        sep: parseInt(pnl[8].SnH),
        oct: parseInt(pnl[9].SnH),
        nov: parseInt(pnl[10].SnH),
        dec: parseInt(pnl[11].SnH),
        total2: totalSnH.toFixed(2),
        average2: avg_SnH.toFixed(2),


      },
      {
        Expenses: 'RefundsReturns',
        jan: parseInt(pnl[0].refunds),
        feb: parseInt(pnl[1].refunds),
        mar: parseInt(pnl[2].refunds),
        apr: parseInt(pnl[3].refunds),
        may: parseInt(pnl[4].refunds),
        jun: parseInt(pnl[5].refunds),
        jul: parseInt(pnl[6].refunds),
        aug: parseInt(pnl[7].refunds),
        sep: parseInt(pnl[8].refunds),
        oct: parseInt(pnl[9].refunds),
        nov: parseInt(pnl[10].refunds),
        dec: parseInt(pnl[11].refunds),

        total2: totalRefunds.toFixed(2),
        average2: avg_refunds.toFixed(2),


      },
      {
        Expenses: 'Discounts',
        jan: parseInt(pnl[0].discounts),
        feb: parseInt(pnl[1].discounts),
        mar: parseInt(pnl[2].discounts),
        apr: parseInt(pnl[3].discounts),
        may: parseInt(pnl[4].discounts),
        jun: parseInt(pnl[5].discounts),
        jul: parseInt(pnl[6].discounts),
        aug: parseInt(pnl[7].discounts),
        sep: parseInt(pnl[8].discounts),
        oct: parseInt(pnl[9].discounts),
        nov: parseInt(pnl[10].discounts),
        dec: parseInt(pnl[11].discounts),
        total2: totalDiscounts.toFixed(2),
        average2: avg_discounts.toFixed(2),

      },
      {
        Expenses: 'Ads',
        jan: parseInt(pnl[0].ads),
        feb: parseInt(pnl[1].ads),
        mar: parseInt(pnl[2].ads),
        apr: parseInt(pnl[3].ads),
        may: parseInt(pnl[4].ads),
        jun: parseInt(pnl[5].ads),
        jul: parseInt(pnl[6].ads),
        aug: parseInt(pnl[7].ads),
        sep: parseInt(pnl[8].ads),
        oct: parseInt(pnl[9].ads),
        nov: parseInt(pnl[10].ads),
        dec: parseInt(pnl[11].ads),

        total2: totalAds.toFixed(2),
        average2: avg_ads.toFixed(2),

      },
      {
        Expenses: 'Total',
        jan: monthlyExpenses[0].toFixed(2),
        feb: monthlyExpenses[1].toFixed(2),
        mar: monthlyExpenses[2].toFixed(2),
        apr: monthlyExpenses[3].toFixed(2),
        may: monthlyExpenses[4].toFixed(2),
        jun: monthlyExpenses[5].toFixed(2),
        jul: monthlyExpenses[6].toFixed(2),
        aug: monthlyExpenses[7].toFixed(2),
        sep: monthlyExpenses[8].toFixed(2),
        oct: monthlyExpenses[9].toFixed(2),
        nov: monthlyExpenses[10].toFixed(2),
        dec: monthlyExpenses[11].toFixed(2),
      }

    ];

  
    // Add the second table to the PDF document
    //doc.autoTable(columns2, data2, options2);
    doc.autoTable({
      ...defaultOptions,
      startY: doc.lastAutoTable.finalY + 10,
      columns: columns2,
      body: data2,
    });

    // Repeat the above process for other tables (expenses, miscellaneous, profit, total orders/units) as needed
    const columns3 = [
      { header: 'TotalOrdersUnits', dataKey: 'TotalOrdersUnits' },
      { header: 'Jan', dataKey: 'jan' },
      { header: 'Feb', dataKey: 'feb' },
      { header: 'Mar', dataKey: 'mar' },
      { header: 'Apr', dataKey: 'apr' },
      { header: 'May', dataKey: 'may' },
      { header: 'Jun', dataKey: 'jun' },
      { header: 'Jul', dataKey: 'jul' },
      { header: 'Aug', dataKey: 'aug' },
      { header: 'Sep', dataKey: 'sep' },
      { header: 'Oct', dataKey: 'oct' },
      { header: 'Nov', dataKey: 'nov' },
      { header: 'Dec', dataKey: 'dec' },
      { header: 'Total', dataKey: 'total3' },
      { header: 'Average', dataKey: 'average3' },
    ];
    //const monthlyExpenses = calculateMonthlyExpenses(pnl);
    const data3 = [
      {
        TotalOrdersUnits: 'orders',
        jan: parseInt(pnl[0].orders),
        feb: parseInt(pnl[1].orders),
        mar: parseInt(pnl[2].orders),
        apr: parseInt(pnl[3].orders),
        may: parseInt(pnl[4].orders),
        jun: parseInt(pnl[5].orders),
        jul: parseInt(pnl[6].orders),
        aug: parseInt(pnl[7].orders),
        sep: parseInt(pnl[8].orders),
        oct: parseInt(pnl[9].orders),
        nov: parseInt(pnl[10].orders),
        dec: parseInt(pnl[11].orders),
        total3: totalOrders.toFixed(2),
        average3: avg_orders.toFixed(2),



      },
      {
        TotalOrdersUnits: 'units',
        jan: parseInt(pnl[0].units),
        feb: parseInt(pnl[1].units),
        mar: parseInt(pnl[2].units),
        apr: parseInt(pnl[3].units),
        may: parseInt(pnl[4].units),
        jun: parseInt(pnl[5].units),
        jul: parseInt(pnl[6].units),
        aug: parseInt(pnl[7].units),
        sep: parseInt(pnl[8].units),
        oct: parseInt(pnl[9].units),
        nov: parseInt(pnl[10].units),
        dec: parseInt(pnl[11].units),
        total3: totalUnits.toFixed(2),
        average3: avg_units.toFixed(2),




      },

    ];
    // Add the second table to the PDF document
    //doc.autoTable(columns3, data3, options3);
    doc.autoTable({
      ...defaultOptions,
      startY: doc.lastAutoTable.finalY + 10,
      columns: columns3,
      body: data3,
    });

    const columns4 = [
      { header: 'Profit', dataKey: 'Profit' },
      { header: 'Jan', dataKey: 'jan' },
      { header: 'Feb', dataKey: 'feb' },
      { header: 'Mar', dataKey: 'mar' },
      { header: 'Apr', dataKey: 'apr' },
      { header: 'May', dataKey: 'may' },
      { header: 'Jun', dataKey: 'jun' },
      { header: 'Jul', dataKey: 'jul' },
      { header: 'Aug', dataKey: 'aug' },
      { header: 'Sep', dataKey: 'sep' },
      { header: 'Oct', dataKey: 'oct' },
      { header: 'Nov', dataKey: 'nov' },
      { header: 'Dec', dataKey: 'dec' },
      { header: 'Total', dataKey: 'total4' },
      { header: 'Average', dataKey: 'average4' },
    ];

    const data4 = [
      {
        Profit: 'GrossProfit',
        jan: parseInt(pnl[0].gross),
        feb: parseInt(pnl[1].gross),
        mar: parseInt(pnl[2].gross),
        apr: parseInt(pnl[3].gross),
        may: parseInt(pnl[4].gross),
        jun: parseInt(pnl[5].gross),
        jul: parseInt(pnl[6].gross),
        aug: parseInt(pnl[7].gross),
        sep: parseInt(pnl[8].gross),
        oct: parseInt(pnl[9].gross),
        nov: parseInt(pnl[10].gross),
        dec: parseInt(pnl[11].gross),
        total4: totalGrossProfit.toFixed(2),
        average4: avg_gross.toFixed(2),



      },
    ];
    // Add the second table to the PDF document
    //doc.autoTable(columns4, data4, options4);
    doc.autoTable({
      ...defaultOptions,
      startY: doc.lastAutoTable.finalY + 10,
      columns: columns4,
      body: data4,
    });
    // Define columns for the table
    const columns5 = [
      { header: 'MiscExpenses', dataKey: 'MiscExpenses' },
      { header: 'Jan', dataKey: 'jan' },
      { header: 'Feb', dataKey: 'feb' },
      { header: 'Mar', dataKey: 'mar' },
      { header: 'Apr', dataKey: 'apr' },
      { header: 'May', dataKey: 'may' },
      { header: 'Jun', dataKey: 'jun' },
      { header: 'Jul', dataKey: 'jul' },
      { header: 'Aug', dataKey: 'aug' },
      { header: 'Sep', dataKey: 'sep' },
      { header: 'Oct', dataKey: 'oct' },
      { header: 'Nov', dataKey: 'nov' },
      { header: 'Dec', dataKey: 'dec' },
      { header: 'Total', dataKey: 'total' }, // Add total column header
    ];

    // Calculate total expenses
    const totalExpenses = {
      jan: newMinCosts.reduce((total, item) => total + parseInt(item.jan || 0), 0),
      feb: newMinCosts.reduce((total, item) => total + parseInt(item.feb || 0), 0),
      mar: newMinCosts.reduce((total, item) => total + parseInt(item.mar || 0), 0),
      apr: newMinCosts.reduce((total, item) => total + parseInt(item.apr || 0), 0),
      may: newMinCosts.reduce((total, item) => total + parseInt(item.may || 0), 0),
      jun: newMinCosts.reduce((total, item) => total + parseInt(item.jun || 0), 0),
      jul: newMinCosts.reduce((total, item) => total + parseInt(item.jul || 0), 0),
      aug: newMinCosts.reduce((total, item) => total + parseInt(item.aug || 0), 0),
      sep: newMinCosts.reduce((total, item) => total + parseInt(item.sep || 0), 0),
      oct: newMinCosts.reduce((total, item) => total + parseInt(item.oct || 0), 0),
      nov: newMinCosts.reduce((total, item) => total + parseInt(item.nov || 0), 0),
      dec: newMinCosts.reduce((total, item) => total + parseInt(item.dec || 0), 0),
    };

    // Add total row
    const totalRow = {
      MiscExpenses: 'Total',
      jan: totalExpenses.jan,
      feb: totalExpenses.feb,
      mar: totalExpenses.mar,
      apr: totalExpenses.apr,
      may: totalExpenses.may,
      jun: totalExpenses.jun,
      jul: totalExpenses.jul,
      aug: totalExpenses.aug,
      sep: totalExpenses.sep,
      oct: totalExpenses.oct,
      nov: totalExpenses.nov,
      dec: totalExpenses.dec,
      total: Object.values(totalExpenses).reduce((total, val) => total + val, 0), // Calculate the total sum of all months
    };

    // Retrieve data for miscellaneous expenses
    const data5 = newMinCosts.map(item => ({
      MiscExpenses: item.name,
      jan: item.jan || '-',
      feb: item.feb || '-',
      mar: item.mar || '-',
      apr: item.apr || '-',
      may: item.may || '-',
      jun: item.jun || '-',
      jul: item.jul || '-',
      aug: item.aug || '-',
      sep: item.sep || '-',
      oct: item.oct || '-',
      nov: item.nov || '-',
      dec: item.dec || '-',
    }));

    data5.push(totalRow); // Add the total row to the end of the data array

    // Add the table to the PDF document
    //doc.autoTable(columns5, data5, options5);
    doc.autoTable({
      ...defaultOptions,
      startY: doc.lastAutoTable.finalY + 10,
      columns: columns5,
      body: data5,
    });

    // Save the PDF
    doc.save('RAPPORT PNL LIVE METRICS.pdf');
  };


  const handleButtonClick = (e) => { };

  const handleMenuClick = (e) => {
    message.info('Year has been changed to ' + e.key);

    setSelectedYear(e.key);
  };

  const currentYear = new Date().getFullYear();

  const menuItems = [];
  for (let i = currentYear; i >= 2020; i--) {
    menuItems.push({
      label: ` ${i}  `,
      key: `${i}`,
      icon: <CalendarOutlined />,
    });
  }

  const menuProps = {
    items: menuItems,
    onClick: handleMenuClick,
  };

  return (
    <div className="page-content">
      <MetaTags>
        <title>{props.t("Reports Profit & Loss")} | Live-Metrics - E-com SaaS</title>
      </MetaTags>
      <div className="container-fluid">
        <Breadcrumbs
          title={props.t("Reports")}
          breadcrumbItem={props.t("Profit & Loss")}
          pageHeading={props.t("Profit & Loss")}
        />
      </div>
     
      <Row>
        <Row>
          <Card>
            <CardHeader className='d-flex flex-md-row flex-column justify-content-md-between justify-content-start align-items-md-center align-items-start'>
              <CardTitle tag='h4'>{props.t("Total Sales Over Time")}</CardTitle>
              <Menu compact>
                <Dropdown.Button menu={menuProps} onClick={handleButtonClick} icon={<CalendarOutlined />}>
                  {selectedYear}
                </Dropdown.Button>
              </Menu>
            </CardHeader>
            {
              loadingState ? (
                <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '100vh', // Set to 100% of the viewport height
                }}
              >
                <Spinner color="primary" style={{ height: '3rem', width: '3rem' }} />
              </div>
              ):(
                <CardBody>
                <Chart options={optionsd} series={seriesData} type='bar' height={400} />
              </CardBody>
              )
            }
           
           
            
           </Card>
        </Row>
         {loadingState ? (
          <>
          </>
         ) : (
          <div>

         
          <Row>
          <Col xl={6}>
            <Card className="card-h-100">
              <CardBody>
                <div className="d-flex flex-wrap align-items-center mb-4">
                  <h5 className="card-title me-2">
                    {props.t('monthly Gross Profit')}
                  </h5>
                </div>
                <Row className="align-items-center">
                  <div className="col-sm">
                    <div id="wallet-balance" className="apex-charts">
                      <Pie options={options} data={grosssDatau} />
                    </div>
                  </div>

                </Row>
              </CardBody>
            </Card>
          </Col>
          <Col xl={6}>
            <Card className="card-h-100">
              <CardBody>
                <div className="d-flex flex-wrap align-items-center mb-4">
                  <h5 className="card-title me-2">
                    {props.t('Total monthly Orders/Units')}{' '}
                  </h5>
                </div>
                <Row className="align-items-center">
                  <div className="col-sm">
                    <div id="wallet-balance" className="apex-charts">
                      <Bar
                        data={ordersData}
                        options={{
                          legend: {
                            display: true,
                          },
                          scales: {
                            yAxes: [
                              {
                                ticks: {
                                  beginAtZero: true,
                                  callback: function (value) {
                                    if (Number.isInteger(value)) {
                                      return value;
                                    }
                                  }
                                },
                                scaleLabel: {
                                  display: true,
                                  labelString: 'Count',
                                },
                              },
                            ],
                          },
                        }}
                      />
                    </div>
                  </div>
                </Row>
              </CardBody>
            </Card>
          </Col>
          <hr />
        </Row>
        <Table
        style={{ border: '1px solid #e8e8e8', marginBottom: '40px' }}
        columns={columns2} // Update to use your modified columns array
        dataSource={data2} // Update to use your modified data array  
        pagination={false}
        scroll={{ y: 700 }}
      />
        <div>
      <Button
        onClick={handleAdd}
        type="primary"
        style={{
          marginBottom: 16,
        }}
      >
        Add a row
      </Button>
      <Table
        components={components}
        rowClassName={() => 'editable-row'}
        bordered
        dataSource={dataSource}
        columns={columns6}
      />
    </div>
        <Table
          style={{ border: '1px solid #e8e8e8', marginBottom: '40px' }}
          columns={columns5} // Update to use your modified columns array
          dataSource={data5} // Update to use your modified data array   
          pagination={false}
          scroll={{ y: 700 }}
        />
         <Table
          style={{ border: '1px solid #e8e8e8', marginBottom: '40px' }}
          columns={columns4} // Update to use your modified columns array
          dataSource={data4} // Update to use your modified data array 
          pagination={false}
          scroll={{ y: 700 }}
        />
       </div>
         )}
      </Row>
      <div className="mt-2-overview-dashboard">
        <button className="btn btn-primary btn-sm ms-2" onClick={generatePDF}>
          Generate PDF
        </button>
      </div>
      
    </div>
    
  );
}

NProfitAndLoss.propTypes = {
  t: PropTypes.any
};
export default withRouter(withTranslation()(NProfitAndLoss));
//export default NProfitAndLoss;