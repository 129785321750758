import {
    CHECK_INTEGRATION_REQUEST_KLAVIYO,
    CHECK_INTEGRATION_SUCCESS,
    CHECK_INTEGRATION_FAILURE,
    FETCH_DATA_KLAVIYO_REQUEST,
    FETCH_DATA_KLAVIYO_SUCCESS,
    FETCH_DATA_KLAVIYO_FAILURE,
    FETCH_METRICS_KLAVIYO_REQUEST,
    FETCH_METRICS_KLAVIYO_SUCCESS,
    FETCH_METRICS_KLAVIYO_FAILURE,
    INTEGRATION_KLAVIYO_REQUEST,
    INTEGRATION_KLAVIYO_SUCCESS,
    INTEGRATION_KLAVIYO_FAILURE,
    DELETE_INTEGRATION_KLAVIYO_REQUEST,
    DELETE_INTEGRATION_KLAVIYO_SUCCESS,
    DELETE_INTEGRATION_KLAVIYO_FAILURE,
    FETCH_DATA_FLOW_REQUEST,
    FETCH_DATA_FLOW_SUCCESS,
    FETCH_DATA_FLOW_FAILURE,
    } from './action';

    const initialState = {
        isLoading: false,
        isDisabled: false,
        data: [],
        error: null,
        compaigns:[],
        metrics:[],
        apiKey: '',
        flows:[]
        
      };

    export default function Klaviyo(state = initialState, action) {
        switch (action.type) {
            case CHECK_INTEGRATION_REQUEST_KLAVIYO:
            return {
                ...state,
                error: null,
            };
            case CHECK_INTEGRATION_SUCCESS:
            return {
                ...state,
                data: action.payload.checkIntegration,
                checkIntegrationdata: action.payload.checkIntegrationdata,
                apiKey: action.payload.apiKey,
                error: null,
            };
            case CHECK_INTEGRATION_FAILURE:
            return {
                ...state,
                error: action.payload,
            };
            case FETCH_DATA_KLAVIYO_REQUEST:
            return {
                ...state,
                error: null,
            };
            case FETCH_DATA_KLAVIYO_SUCCESS:
            return {
                ...state,
                compaigns: action.payload.compaigns,
                error: null,
            };
            case FETCH_DATA_KLAVIYO_FAILURE:
            return {
                ...state,
                error: action.payload,
            };
            case FETCH_METRICS_KLAVIYO_REQUEST:
            return {
                ...state,
                error: null,
            };
            case FETCH_METRICS_KLAVIYO_SUCCESS:
            return {
                ...state,
                metrics: action.payload.metrics,
                error: null,
            };
            case FETCH_METRICS_KLAVIYO_FAILURE:
            return {
                ...state,
                error: action.payload,
            };
            case INTEGRATION_KLAVIYO_REQUEST:
            return {
                ...state,
            };
            case INTEGRATION_KLAVIYO_SUCCESS:
            return {
                ...state,
                data: action.payload,
            }
            case INTEGRATION_KLAVIYO_FAILURE:
            return {
                ...state,
                error: action.payload,
            }  
            case DELETE_INTEGRATION_KLAVIYO_REQUEST:
            return {
                ...state,
            };
            case DELETE_INTEGRATION_KLAVIYO_SUCCESS:
            return {
                ...state,
                data: action.payload,
            };
            case DELETE_INTEGRATION_KLAVIYO_FAILURE:  
            return {
                ...state,
                error: action.payload,
            };
            case FETCH_DATA_FLOW_REQUEST:
            return {
                ...state,
                error: null,
            }   
            case FETCH_DATA_FLOW_SUCCESS:
            return {
                ...state,
                flows: action.payload.flows,
                error: null,
            }
            case FETCH_DATA_FLOW_FAILURE:
            return {
                ...state,
                error: action.payload,
            }
            default:
            return state;
        }
    } 