import React, { useState, useEffect } from 'react';
import {
  Card, CardBody, Col, Container, Row, Label, Input, Badge, Alert, Button,
} from "reactstrap";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import { Spin } from 'antd'; // Import Ant Design's Spin component
import { integrationswoRequest, deleteWoocommerceRequest, getSalesPlateformeRequest, checkThirdStepWoocommerceRequest } from '../../../store/woocommerce/actions';
import WoocommerceIcon from "../Woocommerce/woo2.png";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";

function WooCommerceCard(props) {
  const dispatch = useDispatch();
  const { step, info } = useSelector((state) => state.woocommerce);

  const [validation, setValidation] = useState({ unm: null, fnm: null, lnm: null });
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [key, setKey] = useState("");
  const [secret, setSecret] = useState("");
  const [url, setUrl] = useState("");
  const [loading, setLoading] = useState(true); // State to manage loading

  // useEffect to handle side-effects and API requests
  useEffect(() => {
    setLoading(true); // Start loading
    dispatch(checkThirdStepWoocommerceRequest())
    dispatch(getSalesPlateformeRequest());
    setLoading(false); // Set loading to false after the dispatches (use proper logic to stop it when necessary)

    if (info && info.connectionstate === false) {
      Swal.fire({
        title: 'Oops',
        text: props.t("Please check your WooCommerce credentials or contact our support team."),
        icon: 'warning',
        showCancelButton: false,
      });
    }
  }, [dispatch, info.connectionstate, props]);
  console.log("loading",loading)

  // Extract domain from URL
  const newdomain = info && info.url ? info.url.match(/^https?:\/\/([^/?#]+)(?:[/?#]|$)/i)[1] : '';

  // Integration function
  const integrate = async () => {
    if (!key || !secret || !url) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Please fill all the fields!',
      });
    } else {
      dispatch(integrationswoRequest(key, secret, url));
    }
  };

  // Delete connection function
  const deleteConnection = async () => {
    Swal.fire({
      title: props.t("Are you sure?"),
      text: props.t("You won't be able to revert this!"),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: props.t("Yes, delete it!"),
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(deleteWoocommerceRequest());
      }
    });
  };

  // Step 1 - Initial form to connect WooCommerce
  const firstStep = () => (
    <div style={{ marginTop: '15px' }}>
      <Container fluid>
        <Row>
          <Col>
            <Card className="border text-secondary">
              <CardBody>
                <div className="my-0 text-primary d-flex justify-content-between align-items-center">
                  <div className='text-left d-flex align-items-center'>
                    <img className="" src={WoocommerceIcon} style={{ height: '48px', width: '195px' }} alt="Woocommerce" />
                  </div>
                </div>
                <p style={{ marginTop: '20px', fontSize: '16px', fontWeight: '400' }}>
                  {props.t("Connect your WooCommerce Store within 30 seconds")}
                </p>
                <div className="d-flex flex-column align-items-start" style={{ marginTop: '40px' }}>
                  {['Allow LiveMetrics to access your WooCommerce', 'Just add your API key', 'You will get 7 days free trial'].map((text, idx) => (
                    <p className="mb-2" key={idx} style={{ fontSize: '17px' }}>
                      <i className="bx bx-check" style={{ width: '30px', height: '20px', fontSize: '25px', color: '#0080F9' }}></i>
                      {props.t(text)}
                    </p>
                  ))}
                </div>

                {/* Form to enter domain and keys */}
                <Row tag='form' className='g-1'>
      <Col lg="12">
        <div className="fw-bolder mt-5 mb-1">
        
          <div className="input-group" style={{ borderRadius: '8px', overflow: 'hidden', border: '1px solid #e5e5e5' }}>
            <span className="input-group-text" style={{ backgroundColor: '#F4F2FF', fontSize: '16px', fontWeight: '500', border: 'none', borderRight: '1px solid #e5e5e5' }}>
              <i className="bx bx-globe" style={{ marginRight: '8px', color: '#4A4A4A' }}></i>
            </span>
            <Input
              type="text"
              className="form-control"
              placeholder="Website URL"
              onChange={e => setUrl(e.target.value)}
              valid={validation["unm"] === true}
              invalid={validation["unm"] !== true && validation["unm"] !== null}
              style={{ fontSize: '17px', fontWeight: '400', border: 'none' }}
            />
          </div>
          {validation["unm"] === true ? (
            <div className="valid-tooltip">{props.t("Looks good!")}</div>
          ) : (
            <div className="invalid-tooltip">{props.t("Please enter a valid website URL")}</div>
          )}
        </div>
      </Col>

      <Col md="12">
        <div className="fw-bolder mb-1">
         
          <div className="input-group" style={{ borderRadius: '8px', overflow: 'hidden', border: '1px solid #e5e5e5' }}>
            <span className="input-group-text" style={{ backgroundColor: '#F4F2FF', fontSize: '16px', fontWeight: '500', border: 'none', borderRight: '1px solid #e5e5e5',}}>
              <i className="bx bx-key" style={{ marginRight: '8px', color: '#4A4A4A' }}></i>
            </span>
            <Input
              type="text"
              className="form-control"
              placeholder="Consumer key"
              onChange={e => setKey(e.target.value)}
              valid={validation["fnm"] === true}
              invalid={validation["fnm"] !== true && validation["fnm"] !== null}
              style={{ fontSize: '17px', fontWeight: '400', border: 'none' }}
              //placehoder color 
            
            />
          </div>
        </div>
      </Col>

      <Col md="12">
        <div className="fw-bolder mb-1">
        
          <div className="input-group" style={{ borderRadius: '8px', overflow: 'hidden', border: '1px solid #e5e5e5' }}>
            <span className="input-group-text" style={{ backgroundColor: '#F4F2FF', fontSize: '16px', fontWeight: '500', border: 'none', borderRight: '1px solid #e5e5e5' }}>
              <i className="bx bx-hide" style={{ marginRight: '8px', color: '#4A4A4A' }}></i>
            </span>
            <Input
              type="text"
              className="form-control"
              placeholder="Secret key"
              onChange={e => setSecret(e.target.value)}
              valid={validation["lnm"] === true}
              invalid={validation["lnm"] !== true && validation["lnm"] !== null}
              style={{ fontSize: '17px', fontWeight: '400', border: 'none' }}
            />
          </div>
        </div>
      </Col>
    </Row>

                <button
                   
                  onClick={integrate}
                  className="btn btn-primary"
                  style={{ width: '100%',backgroundColor:"#0080F9", borderRadius:'6px', fontSize:'17px', fontWeight:'500',border: 'none',marginTop:'10px'}}
                >
                  {props.t("Connect")}
                </button>

              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );

  // Step 2 - Status display after integration
  const secondStep = () => (
    <div style={{ marginTop: '15px' }}>
      <Container fluid>
        <Row>
          <Col>
            <Card className="border text-secondary">
              <CardBody>
                {info ? (
                  <div>
                    <div className="my-0 text-primary d-flex justify-content-between align-items-center">
                      <div className="text-left d-flex align-items-center">
                        <img style={{ height: '48px', width: '191px' }} src={WoocommerceIcon} alt="Woocommerce" />
                      </div>
                    </div>
                    <p style={{ marginTop: '20px', fontSize: '16px', fontWeight: '400' }}>
                      {props.t("Connect your WOOcommerce Store within 30 seconds")}
                    </p>

                    <Row tag='form' className='g-1'>
                      <Table className="table table-striped table-bordered" style={{ borderSpacing: '0 10px' }}>
                        <Thead style={{ backgroundColor: '#F4F2FF' }}>
                          <Tr>
                            <Th style={{ fontWeight: 'bold', color: '#6E6893', textTransform: 'uppercase' }}>
                              {props.t("Domaine Name")}
                            </Th>
                            <Th style={{ fontWeight: 'bold', color: '#6E6893', textTransform: 'uppercase' }}>
                              {props.t("Status")}
                            </Th>
                          </Tr>
                        </Thead>
                        <Tbody>
                          <Tr>
                            <Td style={{ fontWeight: '500', color: '#1E1B39' }}>
                              {newdomain}
                            </Td>
                            <Td>
                              {info.connectionstate ? (
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                  <span style={{ width: '10px', height: '10px', backgroundColor: '#04CE00', borderRadius: '50%', marginRight: '8px' }}></span>
                                  <Badge style={{ backgroundColor: '#DFF9E6', color: '#1E1B39', fontWeight: 'bold', borderRadius: '15px', padding: '5px 10px' }}>
                                    Online
                                  </Badge>
                                </div>
                              ) : (
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                  <span style={{ width: '10px', height: '10px', backgroundColor: '#FF4D4F', borderRadius: '50%', marginRight: '8px' }}></span>
                                  <Badge style={{ backgroundColor: '#FFE6E6', color: '#1E1B39', fontWeight: 'bold', borderRadius: '15px', padding: '5px 10px' }}>
                                    Offline
                                  </Badge>
                                </div>
                              )}
                            </Td>
                          </Tr>
                        </Tbody>
                      </Table>
                    </Row>
                  </div>
                ) : (
                  <div style={{ display: "grid" }}>
                    <h3>{props.t("There is no Store integrated")}</h3>
                    <small>{props.t("Try again")}</small>
                  </div>
                )}
                <div className='text-center'>
                  <button
                    className="btn btn-danger waves-effect waves-light"
                    onClick={deleteConnection}
                    style={{
                      width: '100%',
                      backgroundColor: "#F4F2FF",
                      borderRadius: '6px',
                      fontSize: '17px',
                      fontWeight: '500',
                      border: 'none',
                      marginTop: '10px',
                      color: "#6E6893"
                    }}
                  >
                    {props.t("Delete Connection")}
                  </button>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );

  // Step 3 - Warning if a store is already integrated
  const thirdStep = () => (
    <div style={{ marginTop: '15px', borderRadius: "6px", border: "1px solid #D9D5EC " }}>
      <Row>
        <Col>
          <Card style={{ border: "none" }}>
            <div className="card-header bg-transparent border-0 d-flex justify-content-start align-items-center" style={{ padding: '10px' }}>
              <img style={{ height: '48px', width: '191px' }} src={WoocommerceIcon} alt="Woocommerce" />
            </div>
            <CardBody>
              <div className="text-center">
                <Alert className="d-flex justify-content-between align-items-center" style={{ padding: '10px', backgroundColor: '#FEF9C3', border: '1px solid #E7B008' }}>
                  <span style={{ color: '#864E0E', fontSize: '14px' }}>
                    {props.t("You have already a store integrated")}
                  </span>
                  <button type="button" className="close" aria-label="Close" style={{ color: '#864E0E', fontSize: '16px' }}>
                    <span aria-hidden="true">&times;</span>
                  </button>
                </Alert>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );

  // Loading Section
  const loadingSection = () => (
    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
      <Spin size="large" />
    </div>
  );

  // Switch between steps
  const renderSections = () => {
     if (loading) return loadingSection(); // Show spinner if loading

    switch (step) {
      case 3:
        return thirdStep();
      case 1:
        return firstStep();
      case 2:
        return secondStep();
      default:
        return firstStep();
    }
  };

  return <div>{renderSections()}</div>;
}

WooCommerceCard.propTypes = {
  t: PropTypes.any,
};

export default withRouter(withTranslation()(WooCommerceCard));
