import gql from 'graphql-tag';

const getFromFacebookSocialMedia = gql`
  mutation ($code: String!) {
    integration(code: $code) {
      name
    }
  }
`;

const getFacebookSocialMedia = gql`
  query {
    one {
      id
      name
    }
  }
`;

const getFacebookSocialMediaAccounts = gql`
  query {
    SocialMediaAccounts {
      name
      fb_page_id
      active
    }
  }
`;

const deletePlatformFacebookSocialMedia = gql`
  mutation {
    delete {
      isDeleted
    }
  }
`;

const toggleFbSocialMediaAccount = gql`
  mutation ($platform_id: String!, $fb_page_id: String!) {
    toggle(platform_id: $platform_id, fb_page_id: $fb_page_id) {
      active
    }
  }
`;

const getfbPagePosts = gql`
  query {
    getfbPagePosts {
      post_id
      page_id
      createdAt
      full_picture
      post_clicks
      post_likes
      post_impressions
      postReactionsTotal
      permalink_url
      shares
      post_impressions_paid
      post_engagements
      post_negative_feedback
    }
  }
`;

const getfbPageData = gql`
  query getfbPageData($startDate: String, $endDate: String) {
    getfbPageData(startDate: $startDate, endDate: $endDate) {
      fb_page_id
      followers
      likes_numbre
      engageduser
      picture
      monthly_impressions
      post_impressions
      post_engagements
      page_impressions_paid
      page_posts_impressions_paid
      day
    }
  }
`;

const getfbPageAudience = gql`
  query {
    getfbPageAudience {
      fb_page_id
      fans_gender_age
      page_fans_city
      page_fans_country
    }
  }
`;

export {
  getFromFacebookSocialMedia,
  getFacebookSocialMedia,
  getFacebookSocialMediaAccounts,
  deletePlatformFacebookSocialMedia,
  toggleFbSocialMediaAccount,
  getfbPagePosts,
  getfbPageData,
  getfbPageAudience,
};
