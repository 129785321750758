import React, { useState, useEffect, useRef } from 'react';
import {
  Row,
  Col,
  CardBody,
  Card,
  Container,
  FormGroup,
  InputGroup,
} from 'reactstrap';
import { Button, Space, Table, Menu, Dropdown, Checkbox } from 'antd';
import ApolloClient from 'apollo-boost';
import urls from '../../routes/apiUrls';
import MetaTags from 'react-meta-tags';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { DateRange } from 'react-date-range';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import secureSection from '../../MangeSecurity/MemberSecurity';
import {
  getInstaPageData,
  getInstaPostData,
  getInstaAudienceData,
} from '../../store/instagram/actions';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import logo from '../../assets/images/Live_Metrics_Logo.png';
import { Pie, Bar, Doughnut } from 'react-chartjs-2';
import {
  datePattern,
  formatDate,
} from '../GoogleAnalytics/common/HelperFunctions/utils';
import InstagramSocialMediaTable from './InstagramSocialMediaTable';
import InstagramSocialMediaCards from './InstagramSocialMediaCards';
import InstagramSocialMediaGender from './InstagramSocialMediaGender';
import i18next from 'i18next';
import InstagramSocialMediaAge from './InstagramSocialMediaAge';

const ApolloCliGetInstagram = new ApolloClient({
  uri: urls.instagramSocialMedia,
});

const InstagramLogo = `${process.env.PUBLIC_URL}/images/integrations_images/Insta1.svg`;

const InstagramDashboard = (props) => {
  secureSection();
  const dispatch = useDispatch();
  const { instaPageData, instaPostData, instaAudienceData } = useSelector(
    (state) => state.Instagram
  );

  const [tableData, setTableData] = useState([]);
  const [instaPagePosts, setinstaPagePosts] = useState([]);
  const [reach, setreach] = useState('');
  const [impressions, setimpressions] = useState('');
  const [follower_count, setfollower_count] = useState('');
  const [profile_views, setprofile_views] = useState('');
  const [website_clicks, setwebsite_clicks] = useState('');
  const [isPeriodChecked, setPeriodChecked] = useState(false);
  const [isYearChecked, setYearChecked] = useState(false);
  const [genderdata, setgenderdata] = useState(null);
  const [fans_gender_age, setFans_gender_age] = useState([]);
  const [image, setImage] = useState(null);

  let newTotals = {};

  useEffect(() => {
    const fetchData = async () => {
      try {
        dispatch(getInstaAudienceData());
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);
  useEffect(() => {
    if (instaAudienceData.length > 0) {
      setFans_gender_age(instaAudienceData[0].fans_gender_age);
      setImage(instaAudienceData[0].profile_picture_url);

      // Calculate totals for each gender (F, M, U)
      newTotals = instaAudienceData[0].fans_gender_age.reduce(
        (acc, entry) => {
          const gender = entry.dimension_values[0];
          const value = entry.value;

          // Update the corresponding counter based on gender
          acc[gender] += value;

          return acc;
        },
        { F: 0, M: 0, U: 0 }
      );

      // Log the totals
      setgenderdata(newTotals);
    }
  }, [instaAudienceData]);

  let total = 0;
  let femalePercentage;
  let malePercentage;
  let underPercentage;
  // Calculate percentages
  if (
    genderdata?.F !== undefined &&
    genderdata?.M !== undefined &&
    genderdata?.U !== undefined
  ) {
    total = genderdata.F + genderdata.M + genderdata.U;

    femalePercentage = ((genderdata.F / total) * 100).toFixed(2);
    malePercentage = ((genderdata.M / total) * 100).toFixed(2);
    underPercentage = ((genderdata.U / total) * 100).toFixed(2);
  }

  // CREATE THE CHART DATA
  const dataChart = {
    labels: ['Female', 'Male', 'Unkown'],
    datasets: [
      {
        data: [
          genderdata && genderdata.F ? genderdata.F : 0,
          genderdata && genderdata.M ? genderdata.M : 0,
          genderdata && genderdata.U ? genderdata.U : 0,
        ],
        backgroundColor: [
          'rgb(42, 181, 125)',
          'rgb(59, 91, 152)',
          'rgb(75, 166, 239)',
        ],
        hoverBorderColor: '#fff',
      },
    ],
  };
  // CREATE THE AGE GROUP CHART DATA
  const groupedData = {};

  // Check if fans_gender_age is not null and is an array before iterating
  if (fans_gender_age && Array.isArray(fans_gender_age)) {
    fans_gender_age.forEach((entry) => {
      const ageGroup = entry.dimension_values[1]; // Assuming age is at index 1

      // Initialize the group if it doesn't exist
      if (!groupedData[ageGroup]) {
        groupedData[ageGroup] = { F: 0, M: 0, U: 0 };
      }

      // Update the counts for each gender
      const gender = entry.dimension_values[0];
      const value = entry.value;
      groupedData[ageGroup][gender] += value;
    });
  } else {
    console.log('fans_gender_age is null or not an array');
  }

  // Now groupedData contains the total counts for each age group and gender

  const ageGroups = Object.keys(groupedData);
  const chartData = {
    labels: ageGroups,
    datasets: [
      {
        label: i18next.t('Female'),
        data: ageGroups.map((ageGroup) => groupedData[ageGroup]?.F || 0),
        backgroundColor: 'rgb(42, 181, 125)', // Female
      },
      {
        label: i18next.t('Male'),
        data: ageGroups.map((ageGroup) => groupedData[ageGroup]?.M || 0),
        backgroundColor: 'rgb(59, 91, 152)', // Male
      },
      {
        label: i18next.t('Unknown'),
        data: ageGroups.map((ageGroup) => groupedData[ageGroup]?.U || 0),
        backgroundColor: 'rgb(75, 166, 239)', // Unknown
      },
    ],
  };
  // GET ALL PAGE POST DATA
  useEffect(() => {
    dispatch(getInstaPostData());
  }, []);

  const { dateRange } = useSelector((state) => state.dateRangeReducer);

  // GET PAGE DATA
  useEffect(() => {
    var startDate = formatDate(dateRange.selection1.startDate);
    var endDate = formatDate(dateRange.selection1.endDate);
    if (startDate && endDate) {
      dispatch(getInstaPageData(startDate, endDate));
    }
  }, [dateRange]);
  useEffect(() => {
    if (instaPageData) {
      let total_reach = 0; // Initialize total_reach before the map function
      let total_impressions = 0;
      let total_follower_count = 0;
      let total_profile_views = 0;
      let total_website_clicks = 0;

      instaPageData.forEach((item) => {
        total_reach += item.reach;
        total_impressions += item.impressions;
        total_follower_count += item.follower_count;
        total_profile_views += item.profile_views;
        total_website_clicks += item.website_clicks;
      });
      setreach(total_reach);
      setimpressions(total_impressions);
      setfollower_count(total_follower_count);
      setprofile_views(total_profile_views);
      setwebsite_clicks(total_website_clicks);
    }
  }, [instaPageData]);

  const exportToPDF = async () => {
    try {
      const capture = document.querySelector('.print');
      const canvas = await html2canvas(capture, { useCORS: true, scale: 3 }); // Adjust scale for higher resolution
      const imgData = canvas.toDataURL('image/png', 5.0); // Adjust quality
      const doc = new jsPDF('p', 'mm', 'a4');
      const componentWidth = doc.internal.pageSize.getWidth();
      const componentHeight = doc.internal.pageSize.getHeight();
      const padding = 10; // Adjust this value to set the padding in millimeters
      // Add padding to X and Y coordinates
      doc.addImage(
        imgData,
        'PNG',
        1 + padding,
        padding,
        componentWidth - 2 * padding,
        componentHeight - 2 * padding
      );
      // // Customize the appearance of the PDF
      // doc.setLineWidth(0.5); // Set line width for borders
      // doc.setDrawColor(103, 137, 242); // Set border color to black
      // doc.rect(1, 1, componentWidth - 1, componentHeight - 1, 'S'); // Add a border around the content
      doc.save('Rapport-Instagram-Ads.pdf');
    } catch (error) {
      console.error('Error exporting to PDF:', error);
    }
  };

  const [secCards, setsecCards] = useState([
    {
      id: 1,
      name: 'Reach',
      data: reach,
    },
    { id: 2, name: 'Impressions', data: impressions },
    { id: 3, name: 'New followers', data: follower_count },
    { id: 4, name: 'Profile views', data: profile_views },
    { id: 5, name: 'Website clicks', data: website_clicks },
  ]);
  useEffect(() => {
    let isSubscribed = true;
    setsecCards([
      {
        id: 1,
        name: 'Reach',
        data: reach,
      },
      { id: 2, name: 'Impressions', data: impressions },
      { id: 3, name: 'New followers', data: follower_count },
      { id: 4, name: 'Profile views', data: profile_views },
      { id: 5, name: 'Website clicks', data: website_clicks },
    ]);
    return () => (isSubscribed = false);
  }, [reach, impressions, follower_count, profile_views, website_clicks]);

  return (
    <Row>
      <div className="page-content">
        <MetaTags>
          <title>
            {i18next.t('Instagram Social Media Dashboard')} | Convergen-Metrics
            - E-com SaaS
          </title>
        </MetaTags>
        <Breadcrumbs
          title={i18next.t('Social Media Dashboard')}
          breadcrumbItem={i18next.t('Instagram Media Dashboard')}
        />
        <Container fluid className="print">
          <Card className="tamour">
            <CardBody className="">
              <div>
                <div className="invoice-title">
                  <div className="d-flex align-items-start">
                    <div className="flex-grow-1">
                      <div className="">
                        <img src={logo} alt="" height="27" />
                      </div>
                    </div>
                    <div className="flex-shrink-0">
                      <div className="">
                        <h4 className="float-end font-size-16">
                          {i18next.t('Instagram Social Media Dashboard')}
                        </h4>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="flex-grow-1">
                      {/* <div className="">
                        <address>
                          <br />
                          <span>
                            {i18next.t(
                              'Industrial Zone Khéreddine Zone North West,'
                            )}
                          </span>
                          <br />
                          <span>Tunis, Tunisia</span>
                        </address>
                      </div>*/}
                    </div>
                    <div className="flex-grow-1">
                      <img
                        src={image}
                        alt=""
                        height="90"
                        width="90"
                        style={{ borderRadius: '50%', marginRight: '' }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </CardBody>
          </Card>

          <Row>
            <Col lg="12">
              <InstagramSocialMediaCards secCards={secCards} />
            </Col>
          </Row>
          <Row>
            <InstagramSocialMediaTable instaPostData={instaPostData} />
          </Row>
          <Row>
            <Col xl={6}>
              <InstagramSocialMediaGender dataChart={dataChart} />
            </Col>
            <Col xl={6}>
              <InstagramSocialMediaAge chartData={chartData} />
            </Col>
            <hr />
            <div className="d-print-none mt-4">
              <div className="float-end">
                <button
                  type="button"
                  onClick={exportToPDF}
                  className="btn btn-primary waves-effect waves-light w-md"
                >
                  <div className="d-flex align-items-center">
                    {' '}
                    {/* Use Flexbox to align icon and text */}
                    <i
                      className="mdi mdi-download d-block font-size-16"
                      style={{ color: 'white' }}
                    ></i>
                    <span className="ms-2">{i18next.t('Download')}</span>{' '}
                    {/* Add margin for space between icon and text */}
                  </div>
                </button>
              </div>
            </div>
          </Row>
        </Container>
      </div>
    </Row>
  );
};
InstagramDashboard.propTypes = {
  t: PropTypes.any,
};

export default withRouter(withTranslation()(InstagramDashboard));
