import React, { useState, useEffect } from 'react';
import { Spin, Table, Switch, message, ConfigProvider, Input } from "antd"; // Ant Design components
import { Card, CardBody, Col, Row, CardHeader } from "reactstrap";
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import ApolloClient from 'apollo-boost';
import { getGooglePlatform, getAccounts, toggleGoogle, deleteGoogle, generateUrl } from '../../../queries/GoogleAnalytics/Queries';
import { integrateGoogleAnalyticsRequest } from '../../../store/googleAnalytics/actions';
import urls from '../../../routes/apiUrls';
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from "prop-types";
import GoogleAnalyticsIcon from '../../../assets/integrations_images/Logo_Google_Analytics.svg';

const ApploCLI = new ApolloClient({
  uri: urls.analytics
});

function GoogleAnalyticsCard({ t }) {
  const dispatch = useDispatch();
  const [gaData, setGaData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [activeAccounts, setActiveAccounts] = useState([]);
  const [step, setStep] = useState(1);
  const [searchText, setSearchText] = useState('');
  const [filteredData, setFilteredData] = useState([]);
  const [url, setUrl] = useState('');

  useEffect(() => {
    fetchPlatform();
  }, []);

  const fetchPlatform = async () => {
    setLoading(true);
    try {
      const { data } = await ApploCLI.query({
        query: getGooglePlatform,
        context: {
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem('token') || ''}`,
          },
        },
        errorPolicy: 'all',
        fetchPolicy: 'network-only',
      });
      if (data.get === null) {
        generateUrlFx();
        setStep(1);
      } else {
        setStep(2);
        fetchAccounts();
      }
    } catch (error) {
      console.error('Error fetching platform data:', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchAccounts = async () => {
    setLoading(true);
    try {
      const { data } = await ApploCLI.query({
        query: getAccounts,
        context: {
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem('token') || ''}`,
          },
        },
        errorPolicy: 'all',
        fetchPolicy: 'network-only',
      });
      if (data.accounts) {
        const active = data.accounts.filter(account => account.active);
        const inactive = data.accounts.filter(account => !account.active);
        setActiveAccounts(active);
        setGaData(inactive);  // Only store inactive accounts in gaData
        setFilteredData(inactive); // Initially show inactive accounts
        if (active.length > 0) {
          setStep(3);
        } else {
          setStep(2);
        }
      }
    } catch (error) {
      console.error('Error fetching accounts:', error);
    } finally {
      setLoading(false);
    }
  };

  const generateUrlFx = async () => {
    setLoading(true);
    try {
      const { data } = await ApploCLI.query({
        query: generateUrl,
        context: {
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem('token') || ''}`,
          },
        },
        errorPolicy: 'all',
        fetchPolicy: 'network-only',
      });
      if (data.url) {
        setUrl(data.url.url);
      }
    } catch (error) {
      console.error('Error generating URL:', error);
    } finally {
      setLoading(false);
    }
  };

  const integrateGoogle = async (google_code) => {
    dispatch(integrateGoogleAnalyticsRequest(google_code));
  };

  const openPopup = () => {
    setLoading(true);
    let windowObjectReference = null;
    let previousUrl = null;
    let interval = null;
    const strWindowFeatures = 'toolbar=no, menubar=no, width=600, height=700, top=100, left=100';
    if (windowObjectReference === null || windowObjectReference.closed) {
      windowObjectReference = window.open(url, '', strWindowFeatures);
      var timer = setInterval(function () {
        if (windowObjectReference.closed) {
          setLoading(false);
          clearInterval(timer);
        }
      }, 1000);
    } else if (previousUrl !== url) {
      windowObjectReference = window.open(url, '', strWindowFeatures);
      windowObjectReference.focus();
      var timer = setInterval(function () {
        if (windowObjectReference.closed) {
          setLoading(false);
          clearInterval(timer);
        }
      }, 1000);
    } else {
      windowObjectReference.focus();
      var timer = setInterval(function () {
        if (windowObjectReference.closed) {
          setLoading(false);
          clearInterval(timer);
        }
      }, 1000);
    }
    interval = window.setInterval(() => {
      try {
        let temp = windowObjectReference.location;
        if (temp.hostname === 'localhost' || temp.hostname === 'app.live-metrics.io') {
          window.clearInterval(interval);
          let code = temp.href.split('?')[1].split('&')[0].split('=')[1];
          integrateGoogle(code);
          windowObjectReference.close();
          setLoading(false);
          setStep(2);
        }
      } catch (error) {
        console.log(error);
      }
    }, 1000);
    previousUrl = url;
  };

  const handleToggle = async (adAccountId, isCurrentlyActive) => {
    setLoading(true);
    try {
      const { data } = await ApploCLI.mutate({
        mutation: toggleGoogle,
        variables: { adAccount: adAccountId },
        context: {
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem('token') || ''}`,
          },
        },
        errorPolicy: 'all',
      });

      if (data?.toggle) {
        const newState = data.toggle.active;

        message.success(newState ? 'Account activated successfully!' : 'Account deactivated successfully!');

        // Update accounts state immediately
        if (newState) {
          setActiveAccounts([data.toggle]); // Only one active account
        } else {
          setActiveAccounts([]);
        }
        setFilteredData(prev => prev.map(account => (account.id === adAccountId ? data.toggle : account)));
      } else {
        message.error('Failed to toggle the account.');
      }
    } catch (error) {
      message.error('Error toggling the account.');
      console.error('Error toggling the account:', error);
    } finally {
      setLoading(false);
    }
  };

  const deleteConnection = async () => {
    setLoading(true);
    try {
      const { data } = await ApploCLI.mutate({
        mutation: deleteGoogle,
        context: {
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem('token') || ''}`,
          },
        },
        errorPolicy: 'all',
      });
      if (data?.delete?.isDeleted) {
        message.success('Account deleted successfully.');
        setActiveAccounts([]);
        setFilteredData([]);
        setStep(1);
      } else {
        message.error('Failed to delete the account.');
      }
    } catch (error) {
      message.error('Error deleting the account.');
      console.error('Error deleting the account:', error);
    } finally {
      setLoading(false);
    }
  };

  const columns = [
    {
      title: 'ACCOUNT NAME',
      dataIndex: 'name',
      key: 'name',
      render: (text) => (
        <span className='table-cell-text'>{text}</span>
      ),
    },
    {
      title: 'ACTION',
      key: 'action',
      render: (text, record) => (
        <ConfigProvider
          theme={{
            token: {
              colorPrimary: '#5CCF5C',
              colorTextQuaternary: '#6E6893',
            },
          }}
        >
          <Switch
            checked={record.active}
            onChange={() => handleToggle(record.id, record.active)}
            loading={loading}
            disabled={!record.active && activeAccounts.length > 0}
            checkedChildren={<CheckOutlined />}
            unCheckedChildren={<CloseOutlined />}
          />
        </ConfigProvider>
      ),
    },
  ];

  const handleSearchInputChange = (e) => {
    const value = e.target.value.toLowerCase();
    setSearchText(value);
    const filtered = gaData.filter((account) => account.name.toLowerCase().includes(value));
    setFilteredData(filtered);
  };

  return (
    <div style={{ borderColor: "#D9D5EC", borderRadius: "3px", opacity: "1px" }}>
      <Row>
        <Col>
          <Card className="border text-secondary">
            <CardHeader style={{ borderBottom: "none" }}>
              <img src={GoogleAnalyticsIcon} alt="Google Analytics" />
            </CardHeader>
            <CardBody>
              {step === 1 && (
                <>
                  <p style={{ fontSize: '16px', fontWeight: '400' }}>
                    {t("Connect your Google Analytics Account within 30 seconds")}
                  </p>
                  <button onClick={openPopup} className="btn btn-primary" style={{ width: '100%', backgroundColor: "#0080F9", borderRadius: '6px', fontSize: '17px', fontWeight: '500', border: 'none' }}>
                    {t("Connect")}
                  </button>
                </>
              )}
              {step !== 1 && (
                <>
                  <h5 style={{ marginBottom: '20px', fontWeight: '600' }}>Choose Account</h5>
                  <div className="search-box" style={{ flex: 1, maxWidth: '523px', position: 'relative', backgroundColor: '#F4F2FF', borderRadius: '6px', padding: '10px 15px' }}>
                    <Input
                      placeholder={t('Search Account')}
                      value={searchText}
                      onChange={handleSearchInputChange}
                      className="custom-search-input"
                    />
                     <i className="bx bx-search-alt search-icon" style={{ position: 'absolute', left: '10px', top: '50%', transform: 'translateY(-50%)', color: '#7a7a7a' }} />
                  </div>

                  <Spin spinning={loading}>
                    {/* Active Accounts Table */}
                    {activeAccounts.length > 0 && (
                      <Table bordered
                        columns={columns}
                        dataSource={activeAccounts}
                        rowKey="id"
                        pagination={false}
                        style={{ marginTop: '20px' }}
                      />
                    )}

                    {/* Inactive Accounts Table */}
                    <Table bordered
                      columns={columns}
                      dataSource={filteredData}
                      rowKey="id"
                      pagination={{
                        pageSize: 5,
                        style: {
                          backgroundColor: '#F4F2FF',
                          padding: '10px 15px',
                          marginTop: '2px',
                          borderRadius: '6px',
                          fontsize: '14px',
                          fontweight: '700',
                          color: '#6E6893',
                        },
                      }}
                      style={{ marginTop: '20px' }}
                    />
                  </Spin>

                  {/* Conditionally render the delete button if there are no active accounts */}
                  {activeAccounts.length === 0 && (
                    <div className='text-center'>
                      <button type="button" className="btn btn-danger" style={{
                        width: '100%',
                        backgroundColor: "#F4F2FF",
                        borderRadius: '6px',
                        fontSize: '17px',
                        fontWeight: '500',
                        border: 'none',
                        marginTop: '10px',
                        color: "#6E6893",
                        fontFamily: "DM Sans"
                      }} onClick={deleteConnection}>
                        {t("Delete Connection")}
                      </button>
                    </div>
                  )}
                </>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

GoogleAnalyticsCard.propTypes = {
  t: PropTypes.any,
};

export default withRouter(withTranslation()(GoogleAnalyticsCard));
