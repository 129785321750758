import React, { useEffect, useState } from "react"
import MetaTags from 'react-meta-tags';
import {
  Table,
  Row,
  Col,
  Card,
  CardBody,
} from "reactstrap"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import ApolloClient from 'apollo-boost';
import { getGAPlatform } from "../../../queries/GoogleAnalytics/Queries";
import urls from "../../../routes/apiUrls";
import { withTranslation } from "react-i18next"
import { withRouter } from "react-router-dom"
const ApploCLI = new ApolloClient({
  uri: urls.analytics,
});
function GAOperatingSystem(props) {
  const [refetch, setRefetch] = useState(false);
  const [dataOperatingSystem,setDataOperatingSystem]= useState([]);
  useEffect(() => {
    ApploCLI.query({
      query: getGAPlatform,
      context: {
        headers: {
          Authorization: window.localStorage.getItem("token")
            ? `Bearer ${window.localStorage.getItem("token")}`
            : "",
        },
      },
      errorPolicy: "all",
      fetchPolicy: "network-only",
    }).then((data) => {
      if (data.data.gaPlatform) {
        setDataOperatingSystem(data.data.gaPlatform[0].operatingSystem);
      } else {
        setRefetch(!refetch);
      }
    });
  }, []);
  const filteredOperatingSystem = dataOperatingSystem.filter((e) =>
    e
  )
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Google Analytics | Live-Metrics - E-com SaaS</title>
        </MetaTags>
        <div className="container-fluid">
          <Breadcrumbs title="Google Analytics" breadcrumbItem="Operating System" pageHeading="Operating System" />
          <Row>
            <Col col={12}>
              <Card>
                <CardBody>
                  <div className="table-responsive">
                    <Table className="table table-striped table-bordered">
                      <thead className="table-light">
                        <tr>
                          <th>{props.t("Operating System Name")}</th>
                          <th>{props.t("Operating System count")}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {filteredOperatingSystem.map((element, index) => (
                          <tr key={index}>
                          {/* <th scope="row">{index}</th> */}
                          <td>{element[0]}</td>
                          <td>{element[1]}</td>
                          </tr>
                          ))
                        }
                      </tbody>
                    </Table>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

        </div>
      </div>
    </React.Fragment>
  )
}
export default withRouter(withTranslation() (GAOperatingSystem));
