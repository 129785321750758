import React from 'react'
import axios from "axios";
import { useState } from 'react';
import { Select, Space,ConfigProvider ,Dropdown,Button ,Menu,Checkbox} from 'antd';
import { SearchOutlined, CloseOutlined, DownOutlined } from '@ant-design/icons';
import   currencyIcon from "../../assets/integrations_images/currency.svg";

function CurrencyChanger() {

  const [selectedCurrency, setSelectedCurrency] = useState(null);

    const optionGroup = [
        {
          label: "USD - United States Dollar",
          value: "USD",
        },
        {
          label: "EUR - Euro",
          value: "EUR",
        },
    
        {
          label: "LYD - Libyan Dinar",
          value: "LYD",
        },
        {
          label: "MAD - Moroccan Dirham",
          value: "MAD",
        },
        {
          label: "TND - Tunisian Dinar",
          value: "TND",
        },
    
      ]
      const handleSelectGroup = (selectedOption) => {
        setSelectedCurrency(selectedOption); // Update the selected checkbox state
    
        // API request using the selected currency
        axios
          .get(`https://v6.exchangerate-api.com/v6/c0fd644ef51cdd9e2aed5b22/latest/USD`)
          .then((response) => {
            console.log(
              'Conversion Rate for selected currency:',
              response.data.conversion_rates[selectedOption]
            );
          })
          .catch((error) => {
            console.error('Error fetching conversion rate:', error);
          });
      };
  
      const menu = (
        <Menu>
        {optionGroup.map((column) => (
          <Menu.Item key={column.value}>
            <Checkbox
              checked={selectedCurrency === column.value} // Manage which checkbox is selected
              onChange={() => handleSelectGroup(column.value)}
            >
              {column.label}
            </Checkbox>
          </Menu.Item>
        ))}
      </Menu>
    );
  return (
    <Dropdown overlay={menu} trigger={['click']}>
    <Button  style={{ 
          backgroundColor: '#04044C', 
          borderColor: '#04044C', 
          margin: '0 15px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '40px',
          minHeight: '40px',
          marginTop: '15px',
          padding: '0 15px',
          color: 'white',
        }} >
    <img src={currencyIcon} style={{marginRight:"10px"}}/>  Choose currency  <DownOutlined />
    </Button>
  </Dropdown>
  )
}

export default CurrencyChanger