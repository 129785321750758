import React, { useEffect, useState } from "react";
import MetaTags from 'react-meta-tags';
import { useDispatch, useSelector } from 'react-redux';
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { Row, Col, Card } from "reactstrap";
import { fetchProductsRequest } from '../../../store/prestaShop/actions';
import { Table, Input } from 'antd';
import i18next from 'i18next';

const PrestaShopIcon = `${process.env.PUBLIC_URL}/images/integrations_images/PrestaShop.svg`;

function AllProducts() {
  const dispatch = useDispatch();
  const { products } = useSelector((state) => state.prestashop);
  const [filteredInfo, setFilteredInfo] = useState({});
  const [sortedInfo, setSortedInfo] = useState({});
  const [searchText, setSearchText] = useState('');
  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    const savedProducts = localStorage.getItem('products');
    if (savedProducts) {
      dispatch({ type: 'FETCH_PRODUCTS_SUCCESS', payload: JSON.parse(savedProducts) });
    } else {
      dispatch(fetchProductsRequest(50, 1));
    }
  }, [dispatch]);

  useEffect(() => {
    if (products && Array.isArray(products)) {
      const mappedProducts = products.map((item) => item.product);
      setFilteredData(mappedProducts); // Initialize filteredData with all the products
    }
  }, [products]);

  const handleSearchInputChange = (e) => {
    const value = e.target.value.toLowerCase();
    setSearchText(value);

    const filtered = products
      .map((item) => item.product)
      .filter((item) =>
        item.name.toLowerCase().includes(value)
      );

    setFilteredData(filtered); // Filter data based on search text
  };

  const columns = [
    {
      title: 'PRODUCT ID',
      dataIndex: 'product_id',
      key: 'product_id',
      sorter: (a, b) => a.product_id - b.product_id,
      sortOrder: sortedInfo.columnKey === 'product_id' && sortedInfo.order,
      render: (text) => (
        <span className='table-cell-text'>{text}</span>
      ),
    },
    {
      title: 'NAME',
      dataIndex: 'name',
      key: 'name',
      render: (text) => (
        <span className='table-cell-text'>{text}</span>
      ),
    },
    {
      title: 'PRICE',
      dataIndex: 'price',
      key: 'price',
      sorter: (a, b) => a.price - b.price,
      sortOrder: sortedInfo.columnKey === 'price' && sortedInfo.order,
      render: (text) => (
        <span className='table-cell-text'>{text}</span>
      ),
    },
    {
      title: 'QUANTITY',
      dataIndex: 'quantity',
      key: 'quantity',
      sorter: (a, b) => a.quantity - b.quantity,
      sortOrder: sortedInfo.columnKey === 'quantity' && sortedInfo.order,
      render: (text) => (
        <span className='table-cell-text'>{text}</span>
      ),
    }
  ];

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>All PrestaShop Products | LiveMetrics - E-com SaaS</title>
        </MetaTags>
        <div className="container-fluid">
          <Breadcrumbs
            title="All PrestaShop Products"
            breadcrumbItem=" All PrestaShop "
            pageHeading="Prestashop"
            style={{ fontSize: '15px', fontWeight: '700' }}
            image={PrestaShopIcon}
          />

          <Row>
            <Col className="col-12">
              <Card style={{ border: "none" }}>
                <div className="align-items-center">
                  <div
                    className="search-box"
                    style={{
                      flex: 1,
                      maxWidth: '523px',
                      position: 'relative',
                      backgroundColor: '#F4F2FF',
                      borderRadius: '6px',
                      padding: '10px 15px',
                      marginBottom: '20px',
                    }}
                  >
                    <Input
                      placeholder={i18next.t('Product Name')}
                      value={searchText}
                      onChange={handleSearchInputChange}
                      className="custom-search-input"
                    />
                    <i
                      className="bx bx-search-alt search-icon"
                      style={{
                        position: 'absolute',
                        left: '10px',
                        top: '50%',
                        transform: 'translateY(-50%)',
                        color: '#7a7a7a',
                      }}
                    />
                  </div>
                </div>
                <div className="table-border" style={{ marginBottom: "20px" }}>
                  <Table
                    columns={columns}
                    dataSource={filteredData} // Use filteredData for table
                    pagination={{
                      pageSize: 10,
                      showSizeChanger: true,
                      pageSizeOptions: ['5', '10', '20'],
                      style: {
                        backgroundColor: '#F4F2FF',
                        padding: '10px 15px',
                        marginTop: '2px',
                        borderRadius: '6px',
                        fontSize: '14px',
                        fontWeight: '700',
                        color: '#6E6893',
                      },
                    }}
                    rowKey="product_id"
                  />
                </div>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
}

export default AllProducts;
