import gql from 'graphql-tag';

const getPrestashopStore = gql`query{
    get{
        id,
        connectionstate,
        url,
    }
}
`
const getPrestashopStores = gql`query{
    getStores{
        id,
        shop_id,
        name,
        active
    }
}
`

const integratePresta = gql`mutation($api:String!, $domain:String!, $protocol:String!){
    integration(api:$api,domain:$domain,protocol:$protocol){
        name,
        createdAt
    }
}
`

const toggleShop = gql`mutation($shop_id:String!){
    toggle(shop_id:$shop_id){
        active
    }
}
`

const deleteIntegration = gql`mutation{
    delete{
        isDeleted
    }
}
`
export { getPrestashopStore, integratePresta, getPrestashopStores, toggleShop, deleteIntegration };

