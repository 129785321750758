import {
  FETCH_TOP_SELLERS_FAILURE, 
  FETCH_TOP_SELLERS_SUCCESS, 
  FETCH_TOP_SELLERS_REQUEST, 
  FETCH_COUPONS_CODES_FAILURE, 
  FETCH_COUPONS_CODES_SUCCESS, 
  FETCH_COUPONS_CODES_REQUEST,
  FETCH_ORDER_STATUS_REQUEST,
  FETCH_ORDER_STATUS_SUCCESS,
  FETCH_ORDER_STATUS_FAILURE,
  FETCH_PRODUCT_CATEGORY_REQUEST,
  FETCH_PRODUCT_CATEGORY_SUCCESS,
  FETCH_PRODUCT_CATEGORY_FAILURE,
  INTEGRATIONS_WO_REQUEST,
  INTEGRATIONS_WO_SUCCESS,
  INTEGRATIONS_WO_FAILURE,
  DELETE_WOOCOMMERCE_REQUEST,
  DELETE_WOOCOMMERCE_SUCCESS,
  DELETE_WOOCOMMERCE_FAILURE,
  GET_SALES_PLATEFORME_REQUEST,
  GET_SALES_PLATEFORME_SUCCESS,
  GET_SALES_PLATEFORME_FAILURE,
  CHECK_THIRD_STEP_WOOCOMMERCE_REQUEST,
  CHECK_THIRD_STEP_WOOCOMMERCE_SUCCESS,
  CHECK_THIRD_STEP_WOOCOMMERCE_FAILURE

} from "./actionsTypes"

// TOP SELLERS
export const fetchTopSellersRequest = (limit, page) => ({
  type: FETCH_TOP_SELLERS_REQUEST,
  payload: { limit, page },
});

export const fetchTopSellersSuccess = (data) => ({
  type: FETCH_TOP_SELLERS_SUCCESS,
  payload: data,
});

export const fetchTopSellersFailure = (error) => ({
  type: FETCH_TOP_SELLERS_FAILURE,
  payload: error,
});
// COUPONS CODES
export const fetchCouponsCodesRequest = (limit, page) => ({
  type: FETCH_COUPONS_CODES_REQUEST,
  payload: { limit, page },
});

export const fetchCouponsCodesSuccess = (data) => ({
  type: FETCH_COUPONS_CODES_SUCCESS,
  payload: data
});

export const fetchCouponsCodesFailure = (error) => ({
  type: FETCH_COUPONS_CODES_FAILURE,
  payload: error,
});

// ORDER STATUS 
export const fetchOrderStatusRequest = (limit, page) => ({
  type: FETCH_ORDER_STATUS_REQUEST,
  payload: { limit, page },
});

export const fetchOrderStatusSuccess = (data) => ({
  type: FETCH_ORDER_STATUS_SUCCESS,
  payload: data
});

export const fetchOrderStatusFailure = (error) => ({
  type: FETCH_ORDER_STATUS_FAILURE,
  payload: error,
});

//PRODUCT CATEGORY 
export const fetchProductCategoryRequest = (limit, page) => ({
  type: FETCH_PRODUCT_CATEGORY_REQUEST,
  payload: { limit, page },
});

export const fetchProductCategorySuccess = (data) => ({
  type: FETCH_PRODUCT_CATEGORY_SUCCESS,
  payload: data
});
export const fetchProductCategoryFailure = (error) => ({
  type: FETCH_PRODUCT_CATEGORY_FAILURE,
  payload: error,
});

// INTEGRATIONS
export const integrationswoRequest = (key, secret,url) => ({
  type: INTEGRATIONS_WO_REQUEST,
  payload: {key, secret,url },
})

export const integrationswoSuccess = (data) => ({
  type: INTEGRATIONS_WO_SUCCESS,
  payload: data
})

export const integrationswoFailure = (error) => ({
  type: INTEGRATIONS_WO_FAILURE,
  payload: error

})

// delete woocommerce
export const deleteWoocommerceRequest = () => ({
  type: DELETE_WOOCOMMERCE_REQUEST,
})

export const deleteWoocommerceSuccess = () => ({
  type: DELETE_WOOCOMMERCE_SUCCESS,
})
 export const deleteWoocommerceFailure = (error) => ({
  type: DELETE_WOOCOMMERCE_FAILURE,
  payload: error
 })
 


 // GET SALES PLATEFORME
  export const getSalesPlateformeRequest = () => ({
    type: GET_SALES_PLATEFORME_REQUEST,

  })
  export const getSalesPlateformeSuccess = (info,websiteName) => ({
    type: GET_SALES_PLATEFORME_SUCCESS,
    payload: {info,websiteName}
  }) 
  export const getSalesPlateformeFailure = (error) => ({
    type: GET_SALES_PLATEFORME_FAILURE,
    payload: error

  })


  // CHECK THIRD STEP WOOCOMMERCE
  export const checkThirdStepWoocommerceRequest = () => ({
    type: CHECK_THIRD_STEP_WOOCOMMERCE_REQUEST,
  })
  export const checkThirdStepWoocommerceSuccess = (data) => ({
    type: CHECK_THIRD_STEP_WOOCOMMERCE_SUCCESS,
    payload: data
  })
  export const checkThirdStepWoocommerceFailure = (error) => ({
    type: CHECK_THIRD_STEP_WOOCOMMERCE_FAILURE,
    payload: error
  })
  