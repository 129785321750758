import React, { useState, useEffect, useContext } from 'react';
import {
    Card, CardBody, Col, Container, Row, Modal, Input, Label, FormGroup, Alert, Button, ModalBody,Badge,Table
} from "reactstrap";
// import { Table, Thead, Tbody, Tr, Th,Td } from "react-super-responsive-table"
import urls from '../../../routes/apiUrls';
import ApolloClient from "apollo-boost";
import { deleteIntegrationPrestaShopRequest, integrationPrestaShopRequest, fetchStoresRequest, fetchStoreRequest, checkThirdStepPrestaShopRequest } from '../../../store/prestaShop/actions';

import { useDispatch, connect, useSelector } from 'react-redux';
import { deleteAlert, successAlert } from '../../Alerts/NotLinkedAccountAlert';
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import PrestaShopIcon from "../../../assets/images/PrestaShop.png"


function PrestaShopCard(props) {
    
    const dispatch = useDispatch();

    const { step, reachedFinalStep, stores,firststore } = useSelector((state) => state.prestashop);
    const options = [{ key: 1, text: 'HTTP', value: 'http' }, { key: 2, text: 'HTTPS', value: 'https' }];
    const [api, setApi] = useState("");
    const [domain, setDomain] = useState("");
    const [deleteLoading, setDeleteLoading] = useState(false);
    const [protocol, setProtocol] = useState(options[1]);

    const [validation, setValidation] = useState({
        unm: null,
        fnm: null,
        lnm: null,
    })
   

    useEffect(() => {
        dispatch(checkThirdStepPrestaShopRequest())
        dispatch(fetchStoresRequest())

    }, [])
    useEffect(() => {
        dispatch(fetchStoreRequest())
    }, [])


    const deleteConnection = () => {
        let name = ""
        if (stores && stores.length > 0 && stores[0] && stores[0].name) {
            name = stores[0].name
        } else {
            name = "prestashop"
        }


        deleteAlert(name).then(function (e) {
            if (e.isConfirmed) {
                dispatch(deleteIntegrationPrestaShopRequest())
            }
        })
    }


    let integrate = () => {
        dispatch(integrationPrestaShopRequest(api, domain, protocol.value))
    }
    // const filteredAccounts = stores.filter((e) =>
    //     e.name.toUpperCase().includes(filterStr.toUpperCase())
    // );
    const newdomain = firststore && firststore.url ? firststore.url.match(/^https?:\/\/([^/?#]+)(?:[/?#]|$)/i)[1] : '';
    const firstStep = () => {
        return (
            <React.Fragment>
                <div className=""style={{marginTop:'15px'}}>
                
                        <Row>
                            <Col>
                                <Card className="border text-secondary">
                                    <CardBody>
                                        <div className="my-0 text-primary d-flex justify-content-between align-items-center">
                                            <div className="text-left d-flex align-items-center">
                                                <img className="" src={PrestaShopIcon} style={{height:'48px',width:'191px'}}/>
                                            </div>
                                        </div>
                                        <p className=""style={{marginTop:'20px', fontSize:'16px',fontWeight:'400'}}>{props.t("Connect your PrestaShop Store within 30 seconds")}</p>
                                        <div className="d-flex flex-column align-items-start" style={{marginTop:'40px'}}>
  <p className="mb-2"style={{fontSize:'17px'}}>
  <i className="bx bx-check" style={{ width: '30px', height: '20px', fontSize: '25px', color: '#0080F9' }}></i>
    {props.t('Allow LiveMetrics to access to your PrestaShop')}
  </p>
  <p className="mb-2"style={{fontSize:'17px'}}>
    <i className="bx bx-check"style={{ width: '30px', height: '20px', fontSize: '25px', color: '#0080F9' }}></i>
    {props.t('Just add your api key')}
  </p>
  <p className="mb-0"style={{fontSize:'17px'}}>
    <i className="bx bx-check"style={{ width: '30px', height: '20px', fontSize: '25px', color: '#0080F9' }}></i>
    {props.t('You will get')} <strong>{props.t('7 days free trial')}</strong>
  </p>
</div>
<Row tag='form' className='g-1'>
                                                            <Col lg="12">
                                                             
                                                                 <div className="fw-bolder mt-5 mb-1">
        
        <div className="input-group" style={{ borderRadius: '8px', overflow: 'hidden', border: '1px solid #e5e5e5' }}>
          <span className="input-group-text" style={{ backgroundColor: '#F4F2FF', fontSize: '16px', fontWeight: '500', border: 'none', borderRight: '1px solid #e5e5e5' }}>
            <i className="bx bx-globe" style={{ marginRight: '8px', color: '#4A4A4A' }}></i>
          </span>
          <Input
            type="text"
            className="form-control"
            placeholder="Website Dmaine"
            onChange={e => setDomain(e.target.value)}
            valid={validation["unm"] === true}
            invalid={validation["unm"] !== true && validation["unm"] !== null}
            style={{ fontSize: '17px', fontWeight: '400', border: 'none' }}
          />
        </div>
        {validation["unm"] === true ? (
          <div className="valid-tooltip">{props.t("Looks good!")}</div>
        ) : (
          <div className="invalid-tooltip">{props.t("Please enter a valid website URL")}</div>
        )}
      </div>
                                                            </Col>
                                                            <Col md="12">
                                                                <div className="input-group" style={{ borderRadius: '8px', overflow: 'hidden', border: '1px solid #e5e5e5' }}>
                                                                <span className="input-group-text" style={{ backgroundColor: '#F4F2FF', fontSize: '16px', fontWeight: '500', border: 'none', borderRight: '1px solid #e5e5e5' }}>
                                                                <i className="bx bx-key" style={{ marginRight: '8px', color: '#4A4A4A' }}></i>
            </span>
                                                                    <Input
                                                                        type="text"
                                                                        className="form-control-apketg"
                                                                        id="validationTooltip01"
                                                                        placeholder={props.t("Api key")}
                                                                        onChange={e => setApi(e.target.value)}
                                                                        valid={validation["fnm"] === true}
                                                                        invalid={
                                                                            validation["fnm"] !== true &&
                                                                           
                                                                            validation["fnm"] !== null
                                                                        }
                                                                        style={{ fontSize: '17px', fontWeight: '400', border: 'none' }}
                                                                    />

                                                                    <div
                                                                        className={
                                                                            validation["fnm"] === true
                                                                                ? "valid-tooltip"
                                                                                : "invalid-tooltip"
                                                                        }
                                                                        name="validate"
                                                                        id="validate1"
                                                                    >
                                                                        {validation["fnm"] === true
                                                                            ? props.t("Looks good!")
                                                                            : props.t("Please Enter Valid Api key")}
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                        </Row>

                                                <button
                                                    onClick={() => { integrate() }}
                                                    className="btn btn-primary"
                                                    style={{ width: '100%',backgroundColor:"#0080F9", borderRadius:'6px', fontSize:'17px', fontWeight:'500',border: 'none',marginTop:'10px'}}
>
                                                    {props.t("Connect")}
                                                </button>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    
                </div>
            </React.Fragment>
        );
    }
    const secondStep = () => {
        return (
            <React.Fragment>
            <div className=""style={{marginTop:'15px'}}>
             
                <Row>
                  <Col>
                    <Card className="border text-secondary">
                    
                     
                  
                          <div style={{ textAlign: "center", padding:'10px'}}>
                            {firststore ? (
                              <div>
                              <div className="my-0 text-primary d-flex justify-content-between align-items-center">
                                <div className="text-left d-flex align-items-center">
                                  <img style={{ height: '48px', width: '191px' }} src={ PrestaShopIcon} alt="Prestashop" />
                                </div>
                              </div>
                              <p style={{ marginTop: '20px', fontSize: '16px', fontWeight: '400' }}>
                                {props.t("Connect your PrestaShop Store within 30 seconds")}
                              </p>
          
                             
                              <div style={{ borderRadius: '6px', overflow: 'hidden', border: '1px solid #D9D5EC' }}>
  <Table responsive style={{ borderSpacing: '0', marginBottom: '0', borderCollapse: 'separate' }}>
    <thead>
      <tr>
        <th style={{ 
          backgroundColor: '#F4F2FF', 
          color: '#6E6893', 
          fontWeight: 'bold', 
          textTransform: 'uppercase', 
          padding: '12px',
          borderBottom: '1px solid #D9D5EC',  // Add bottom border to the header cells
          borderRight: '1px solid #D9D5EC'   // Add right border to the header cells
        }}>
          {props.t("Domaine Name")}
        </th>
        <th style={{ 
          backgroundColor: '#F4F2FF', 
          color: '#6E6893', 
          fontWeight: 'bold', 
          textTransform: 'uppercase', 
          padding: '12px',
          borderBottom: '1px solid #D9D5EC',  // Add bottom border to the header cells
        }}>
          {props.t("Status")}
        </th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td style={{ 
          padding: '15px', 
          fontWeight: '500', 
          color: '#1E1B39', 
       
          borderRight: '1px solid #D9D5EC'    // Add right border to cells
        }}>
          {newdomain}
        </td>
        <td style={{ 
          padding: '15px', 
          textAlign: 'center', 
          // Add border between rows
        }}>
          {firststore.connectionstate ? (
            <div style={{ 
              display: 'flex', 
              justifyContent: 'center', 
              alignItems: 'center', 
              backgroundColor: '#DFF9E6', 
              borderRadius: '20px', 
              padding: '5px 12px' 
            }}>
              <span style={{ 
                width: '10px', 
                height: '10px', 
                backgroundColor: '#04CE00', 
                borderRadius: '50%', 
                marginRight: '8px' 
              }}></span>
              <span style={{ 
                color: '#04CE00', 
                fontWeight: 'bold', 
                fontSize: '14px' 
              }}>
                Online
              </span>
            </div>
          ) : (
            <div style={{ 
              display: 'flex', 
              justifyContent: 'center', 
              alignItems: 'center', 
              backgroundColor: '#FFE6E6', 
              borderRadius: '20px', 
              padding: '5px 12px' 
            }}>
              <span style={{ 
                width: '10px', 
                height: '10px', 
                backgroundColor: '#FF4D4F', 
                borderRadius: '50%', 
                marginRight: '8px' 
              }}></span>
              <span style={{ 
                color: '#FF4D4F', 
                fontWeight: 'bold', 
                fontSize: '14px' 
              }}>
                Offline
              </span>
            </div>
          )}
        </td>
      </tr>
    </tbody>
  </Table>
</div>

                              
                            </div>
                            ) : (
                              <div style={{ display: "grid" }}>
                                <div>
                                  <h3>{props.t("There is no Store integrated")}</h3>
                                  <small>{props.t("Try again")}</small>
                                </div>
                              </div>
                            )}
                            {deleteLoading ? (
                              <img style={{ float: 'left', margin: 10 }} alt="loading icon" />
                            ) : (
                              <div className='text-center'>
                                <button
                                  type="button"
                                  className="btn btn-danger waves-effect waves-light"
                                  style={{
                                    width: '100%',
                                    backgroundColor: "#F4F2FF",
                                    borderRadius: '6px',
                                    fontSize: '17px',
                                    fontWeight: '500',
                                    border: 'none',
                                    marginTop: '10px',
                                   
                                    color: "#6E6893"
                                  }}
                                  onClick={() => deleteConnection()}
                                >
                                  {props.t("Delete Connect ")}
                                </button>
                              </div>
                            )}
                          </div>
                     
                     
                    </Card>
                  </Col>
                </Row>
             
            </div>
          </React.Fragment>
          
        );
    };
    const thirdStep = () => {
        return (
          <div style={{ marginTop: '15px',borderRadius:"6px",border:"1px solid #D9D5EC " }}>
  
          <Row>
            <Col>
              <Card  style={{border:"none"}}>
                <div className="card-header bg-transparent border-0 d-flex justify-content-start align-items-center" style={{ padding: '10px' }}>
                <img style={{ height: '48px', width: '191px' }} src={ PrestaShopIcon} alt="Prestashop" />
                </div>
                <CardBody>
                  <div className="text-center">
                    <Alert  className="d-flex justify-content-between align-items-center" style={{ padding: '10px', backgroundColor: '#FEF9C3', border: '1px solid #E7B008' }}>
                      <span style={{ color: '#864E0E', fontSize: '14px' }}>
                       
                        {props.t("You have already a store integrated")}
                      </span>
                      <button type="button" className="close" aria-label="Close" style={{ color: '#864E0E', fontSize: '16px' }}>
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </Alert>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
      
      </div>
        )
    }
    const renderSections = () => {
        switch (step) {
            case 3:
                return thirdStep();
            case 1:
                return firstStep();
            case 2:
                return secondStep();
            default:
                return firstStep();
        }
    };
    return <div>{renderSections()}</div>;
}
PrestaShopCard.propTypes = {
    t: PropTypes.any
};

export default withRouter(withTranslation()(PrestaShopCard));
//export default PrestaShopCard;