// INTEGRATE GOOGLE ANALYTICS
export const INTEGRATE_GOOGLE_ANALYTICS_REQUEST =
  'INTEGRATE_GOOGLE_ANALYTICS_REQUEST';
export const INTEGRATE_GOOGLE_ANALYTICS_SUCCESS =
  'INTEGRATE_GOOGLE_ANALYTICS_SUCCESS';
export const INTEGRATE_GOOGLE_ANALYTICS_FAILURE =
  'INTEGRATE_GOOGLE_ANALYTICS_FAILURE';

export const GET_GOOGLE_ANALYTICS_ACCOUNTS_REQUEST =
  'GET_GOOGLE_ANALYTICS_ACCOUNTS_REQUEST';
export const GET_GOOGLE_ANALYTICS_ACCOUNTS_SUCCESS =
  'GET_GOOGLE_ANALYTICS_ACCOUNTS_SUCCESS';
export const GET_GOOGLE_ANALYTICS_ACCOUNTS_FAILURE =
  'GET_GOOGLE_ANALYTICS_ACCOUNTS_FAILURE';

export const GET_GOOGLE_ANALYTICS_PRODUCTS_REQUEST =
  'GET_GOOGLE_ANALYTICS_PRODUCTS_REQUEST';
export const GET_GOOGLE_ANALYTICS_PRODUCTS_SUCCESS =
  'GET_GOOGLE_ANALYTICS_PRODUCTS_SUCCESS';
export const GET_GOOGLE_ANALYTICS_PRODUCTS_FAILURE =
  'GET_GOOGLE_ANALYTICS_PRODUCTS_FAILURE';

export const GET_GOOGLE_ANALYTICS_GAPLATFORM_REQUEST =
  'GET_GOOGLE_ANALYTICS_GAPLATFORM_REQUEST';
export const GET_GOOGLE_ANALYTICS_GAPLATFORM_SUCCESS =
  'GET_GOOGLE_ANALYTICS_GAPLATFORM_SUCCESS';
export const GET_GOOGLE_ANALYTICS_GAPLATFORM_FAILURE =
  'GET_GOOGLE_ANALYTICS_GAPLATFORM_FAILURE';

export const GET_GOOGLE_ANALYTICS_USERS_REQUEST =
  'GET_GOOGLE_ANALYTICS_USERS_REQUEST';
export const GET_GOOGLE_ANALYTICS_USERS_SUCCESS =
  'GET_GOOGLE_ANALYTICS_USERS_SUCCESS';
export const GET_GOOGLE_ANALYTICS_USERS_FAILURE =
  'GET_GOOGLE_ANALYTICS_USERS_FAILURE';

export const GET_GOOGLE_ANALYTICS_AUDIENCE_REQUEST =
  'GET_GOOGLE_ANALYTICS_AUDIENCE_REQUEST';
export const GET_GOOGLE_ANALYTICS_AUDIENCE_SUCCESS =
  'GET_GOOGLE_ANALYTICS_AUDIENCE_SUCCESS';
export const GET_GOOGLE_ANALYTICS_AUDIENCE_FAILURE =
  'GET_GOOGLE_ANALYTICS_AUDIENCE_FAILURE';

  
export const GET_GOOGLE_ANALYTICS_AFFILIATIONS_REQUEST =
  'GET_GOOGLE_ANALYTICS_AFFILIATIONS_REQUEST';
export const GET_GOOGLE_ANALYTICS_AFFILIATIONS_SUCCESS =
  'GET_GOOGLE_ANALYTICS_AFFILIATIONS_SUCCESS';
export const GET_GOOGLE_ANALYTICS_AFFILIATIONS_FAILURE =
  'GET_GOOGLE_ANALYTICS_AFFILIATIONS_FAILURE';


export const GET_GOOGLE_ANALYTICS_TRAFFIC_ACQUISITION_REQUEST =
  'GET_GOOGLE_ANALYTICS_TRAFFIC_ACQUISITION_REQUEST';
export const GET_GOOGLE_ANALYTICS_TRAFFIC_ACQUISITION_SUCCESS =
  'GET_GOOGLE_ANALYTICS_TRAFFIC_ACQUISITION_SUCCESS';
export const GET_GOOGLE_ANALYTICS_TRAFFIC_ACQUISITION_FAILURE =
  'GET_GOOGLE_ANALYTICS_TRAFFIC_ACQUISITION_FAILURE';


export const GET_GOOGLE_ANALYTICS_PURCHASE_JOURNEY_REQUEST =
  'GET_GOOGLE_ANALYTICS_PURCHASE_JOURNEY_REQUEST';
export const GET_GOOGLE_ANALYTICS_PURCHASE_JOURNEY_SUCCESS =
  'GET_GOOGLE_ANALYTICS_PURCHASE_JOURNEY_SUCCESS';
export const GET_GOOGLE_ANALYTICS_PURCHASE_JOURNEY_FAILURE =
  'GET_GOOGLE_ANALYTICS_PURCHASE_JOURNEY_FAILURE';
