import React from 'react';
import { Card, CardBody, Col } from 'reactstrap';
const KlaviyoIcon = `${process.env.PUBLIC_URL}/images/integrations_images/Klaviyo.svg`;

const KlaviyoMetrics = (props) => {
  if (props.metrics != null) {
    props.metrics.map((metric) => {
      console.log("Metric Results", metric.results);
    })
  }
  return (
    <React.Fragment>
      {props.metrics ?
        props.metrics.filter(metric => metric.results > 0).map((metric) => (
          <Col xl={3} key={metric.id} >
            <Card className="card-h-100">
              <CardBody>
                <div className="d-flex flex-wrap align-items-center justify-content-between mb-4">
                  <h5 className="card-title me-2">{metric.name}</h5>
                  <img className="img-intgration-icon" src={KlaviyoIcon} />
                </div>
                {metric.results}
                <div className="mt-2-overview-dashboard">
                </div>
              </CardBody>
            </Card>
          </Col>
        ))
        : 'test'
      }
    </React.Fragment>
  );
}
export default KlaviyoMetrics;