import React from 'react';
import robotImg from '../../assets/images/robot.png'; // Assuming you have a robot image
import logoImg from '../../assets/images/Live Metrics White Logo.png';  // Assuming you have a logo image

const CarouselLogin = () => {
    return (
        <React.Fragment>
            <div className="col-xxl-6 col-lg-6 col-md-7">
                <div className="auth-bg-login d-flex align-items-end justify-content-center">
                    {/* Move the logo to the right */}
                    <div className="position-absolute top-0 end-0" style={{ marginRight: '110px', marginTop: '60px' }}>
                        <a
                            href="https://live-metrics.io/"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <img src={logoImg} alt="Logo" className="img-fluid" style={{ width: '220px', maxHeight: '36px', height: 'auto', objectFit: 'contain' }} />
                        </a>
                    </div>

                    <div className="left-side-content text-center text-white">
                        {/* Use viewport-based units for consistent size across browsers */}
                        <img
                            src={robotImg}
                            alt="Robot"
                            className="img-fluid"
                            style={{
                                width: '24vw',  // Use viewport width for proportional sizing
                                maxWidth: '600px',  // Set a maximum size to avoid it becoming too large
                                height: 'auto',  // Maintain aspect ratio
                                objectFit: 'contain',
                            }}
                        />
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default CarouselLogin;
