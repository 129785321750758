import React from 'react';
import BarChart from '../common/BarChart';

const createData = (array) => {
  const aggregatedData = array.reduce((acc, item) => {
    const { totalRevenue } = item;

    // Check if totalRevenue is an array and process it
    if (Array.isArray(totalRevenue)) {
      totalRevenue.forEach(({ sessionPrimaryChannelGroup, value }) => {
        if (!acc[sessionPrimaryChannelGroup]) {
          acc[sessionPrimaryChannelGroup] = {
            sessionPrimaryChannelGroup,
            totalRevenue: 0,
          };
        }
        acc[sessionPrimaryChannelGroup].totalRevenue += value;
      });
    }

    return acc;
  }, {});

  const dataSource = Object.values(aggregatedData).map((item) => ({
    sessionPrimaryChannelGroup: item.sessionPrimaryChannelGroup,
    totalRevenue: item.totalRevenue.toFixed(2), // Format to 2 decimal places
  }));

  const sessionPrimaryChannelGroupNames = dataSource.map(
    (item) => item.sessionPrimaryChannelGroup
  );
  const totalRevenueArray = dataSource.map((item) =>
    parseFloat(item.totalRevenue)
  );

  const finalData = sessionPrimaryChannelGroupNames.map((item, index) => [
    item,
    totalRevenueArray[index],
  ]);

  return finalData;
};

const GATrafficAcquisitionTable = ({ gaTrafficAcquisition }) => {
  console.log('gaTrafficAcquisition', gaTrafficAcquisition);
  let aggregatedData = [];
  let comparedAggregatedData = [];

  if (gaTrafficAcquisition[0] && gaTrafficAcquisition[0].length > 0) {
    aggregatedData = createData(gaTrafficAcquisition[0]);
  }

  if (gaTrafficAcquisition[1] && gaTrafficAcquisition[1].length > 0) {
    comparedAggregatedData = createData(gaTrafficAcquisition[1]);
  }

  return (
    <div className="d-flex align-items-center justify-content-center ">
      <BarChart
        data={aggregatedData}
        comparedData={comparedAggregatedData}
        vertical={false}
        width="1000"
        color="#F6B43B"
      />
    </div>
  );
};

export default GATrafficAcquisitionTable;
