import React, { useState, useEffect, useRef } from 'react';
import { Space, Button, Dropdown, Menu, Progress,Table, Alert, Input, Checkbox } from 'antd';
import {
  Row,
  Col,
  CardBody,
  Card,
  Container,
  FormGroup,
  InputGroup,
} from 'reactstrap';
import { format } from 'date-fns';
import FeatherIcon from 'feather-icons-react';

import { Link } from 'react-router-dom';
import Highlighter from 'react-highlight-words';
import secureSection from '../../MangeSecurity/MemberSecurity';
import { withTranslation } from "react-i18next"
import { withRouter } from "react-router-dom"
import PropTypes from 'prop-types';
import { DownOutlined, SearchOutlined } from '@ant-design/icons';
import MetaTags from 'react-meta-tags';
import { useDispatch, useSelector } from 'react-redux';
import { getFacebookCampaigns ,getFacebookCampaignsPerDate} from '../../store/facebookAds/actions';
import Breadcrumbs from '../../components/Common/Breadcrumb';
const FacebbokAdsIcon = `${process.env.PUBLIC_URL}/images/integrations_images/AD8.png`;


function Campaignstable(props) {
  secureSection();
  const dispatch = useDispatch();
  const { metrcisbyCompaign } = useSelector((state) => state.facebookAds);
  const {dateRange} = useSelector((state) => state.dateRangeReducer);


  const formattedDateRange = {
    selection1: {
      startDate: format(new Date(dateRange.selection1.startDate), 'yyyy-MM-dd'),
      endDate: format(new Date(dateRange.selection1.endDate), 'yyyy-MM-dd'),
      key: dateRange.selection1.key,
    },
  };

  useEffect(() => {
    
   
      dispatch(getFacebookCampaignsPerDate(formattedDateRange.selection1.startDate,formattedDateRange.selection1.endDate,  )   );
    
  }, [dateRange]);


 
  const [filteredInfo, setFilteredInfo] = useState({});
  const [sortedInfo, setSortedInfo] = useState({});
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [paginationOptions, setPaginationOptions] = useState({
    pageSize: 5, // Default page size
    pageSizeOptions: ['5', '10', '20'], // Available page size options
  });
  const searchInput = useRef(null);
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1677ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });
  const handleChange = (pagination, filters, sorter) => {
    setFilteredInfo(filters);
    setSortedInfo(sorter);
    if (pagination.pageSize !== paginationOptions.pageSize) {
      setPaginationOptions({
        ...paginationOptions,
        pageSize: pagination.pageSize,
      });
    }
  };
  const clearFilters = () => {
    setFilteredInfo({});
  };
  const clearAll = () => {
    setFilteredInfo({});
    setSortedInfo({});
    // make the columns  selected
    setSelectedColumns(columns.map((column) => column.key));
  };
  const setAgeSort = () => {
    setSortedInfo({
      order: 'descend',
      columnKey: 'impressions',
    });
  };
  // Utility function to format numbers with spaces
  const formatNumberWithSpaces = (value) => {
    if (value !== undefined && value !== null) {
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    }
    return ''; // or any default value you want to return for undefined/null cases
  };
  const columns = [
    {
      title: 'STATUS',
      dataIndex: 'status',
      key: 'status',
      align: 'center',
      width: 100,
      render: (status) => (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: status === 'ACTIVE' ? '#007F00' : 'grey',
            backgroundColor: status === 'ACTIVE' ? '#CDFFCD' : '#fff0f6',
            fontWeight: status === 'ACTIVE' ? 'bold' : 'normal',
            opacity: status === 'ACTIVE' ? 1 : 0.6,
            borderRadius: '12.67px',
          }}
        >
          <span
            style={{
              width: '10px',
              height: '10px',
              borderRadius: '50%',
              display: 'inline-block',
              backgroundColor: status === 'ACTIVE' ? 'green' : 'grey',
              marginRight: '8px',
            }}
          ></span>
          {status === 'ACTIVE' ? 'Active' : 'Inactive'}
        </div>
      ),
    },
    {
      title: props.t('NAME'),
      dataIndex: 'campaign_name',
      key: 'campaign_name',
      align: 'center',
      width: 200,
      render: (text) => (
        <span className='table-cell-text'>{text}</span>
  
    ),
    },
    {
      title: props.t('SPEND'),
      dataIndex: 'spend',
      align: 'center',
      key: 'spend',
      sorter: (a, b) => a.spend - b.spend,
      sortOrder: sortedInfo.columnKey === 'spend' ? sortedInfo.order : null,
      ellipsis: true,
      render: (text) => <strong className='table-cell-text'>{text.toFixed(2)}$</strong>,
    },
    {
      title: props.t('REVENUE'),
      dataIndex: 'action_values',
      key: 'action_values',
      sorter: (a, b) => a.action_values - b.action_values,
      sortOrder:
        sortedInfo.columnKey === 'action_values' ? sortedInfo.order : null,
      ellipsis: true,
      align: 'center',
      render: (text) => <strong className='table-cell-text'>{text.toFixed(2)}$</strong>,
    },
    {
      title: props.t('CONVERSIONS'),
      dataIndex: 'actions',
      align: 'center',
      key: 'actions',
      sorter: (a, b) => a.actions - b.actions,
      sortOrder: sortedInfo.columnKey === 'actions' ? sortedInfo.order : null,
      ellipsis: true,
      render: (text) => <strong className='table-cell-text'>{text}</strong>,
      width: 150,
    },
    {
      title: props.t('IMPRESSIONS'),
      dataIndex: 'impressions',
      key: 'impressions',
      sorter: (a, b) => a.impressions - b.impressions,
      sortOrder:
        sortedInfo.columnKey === 'impressions' ? sortedInfo.order : null,
      ellipsis: true,
      align: 'center',
      render: (text) => <strong className='table-cell-text'>{formatNumberWithSpaces(text)}</strong>,
      width: 150,
    },
    {
      title: props.t('CLICKS'),
      dataIndex: 'clicks',
      key: 'Clicks',
      sorter: (a, b) => a.clicks - b.clicks,
      sortOrder: sortedInfo.columnKey === 'clicks' ? sortedInfo.order : null,
      ellipsis: true,
      align: 'center',
      render: (text) => <strong className='table-cell-text'>{formatNumberWithSpaces(text)}</strong>,
      width: 100,
    },
    {
      title: props.t('REACH'),
      dataIndex: 'reach',
      key: 'reach',
      align: 'center',
      sorter: (a, b) => a.reach - b.reach,
      sortOrder: sortedInfo.columnKey === 'reach' ? sortedInfo.order : null,
      ellipsis: true,
      render: (text) => <strong className='table-cell-text'>{formatNumberWithSpaces(text)}</strong>,
    },
    {
      title: 'CTR',
      dataIndex: 'ctr',
      align: 'center',
      key: 'ctr',
      sorter: (a, b) => a.clicks / a.impressions - b.clicks / b.impressions,
      sortOrder: sortedInfo.columnKey === 'ctr' ? sortedInfo.order : null,
      ellipsis: true,
      render: (text, record) => {
        const ctrValue = (record.clicks / record.impressions) * 100;
        return <strong className='table-cell-text'>{isNaN(ctrValue) ? '0' : ctrValue.toFixed(2)}%</strong>;
      },
    },
    {
      title: 'CPA',
      dataIndex: 'cpa',
      align: 'center',
      key: 'cpa',
      sorter: (a, b) => a.cpa - b.cpa,
      sortOrder: sortedInfo.columnKey === 'cpa' ? sortedInfo.order : null,
      ellipsis: true,
      render: (text) => <strong className='table-cell-text'>{text.toFixed(2)}$</strong>,
    },
    {
      title: 'CPC',
      dataIndex: 'cpc',
      key: 'cpc',
      align: 'center',
      sorter: (a, b) => a.cpc - b.cpc,
      sortOrder: sortedInfo.columnKey === 'cpc' ? sortedInfo.order : null,
      ellipsis: true,
      render: (text, record) => {
        const floatValue = parseFloat(text);
        return (
          <strong className='table-cell-text'>
            {isNaN(floatValue) ? '-' : `$${floatValue.toFixed(2)}`}
          </strong>
        );
      },
    },
    // {
    //   title: props.t("PageView"),
    //   dataIndex: 'landingPageViewAction',
    //   align: 'center',
    //   key: 'landingPageViewAction',
    //   sorter: (a, b) => a.landingPageViewAction - b.landingPageViewAction,
    //   sortOrder:
    //     sortedInfo.columnKey === 'landingPageViewAction'
    //       ? sortedInfo.order
    //       : null,
    //   ellipsis: true,
    //   render: (text) => <strong>{text}</strong>,
    // },
  ];
  const [selectedColumns, setSelectedColumns] = useState(
    columns.map((column) => column.key)
  );

  const handleMenuClick = (clickedKey) => {
    const index = selectedColumns.indexOf(clickedKey);

    let updatedSelectedColumns = [];

    if (index === -1) {
      // Column was not selected before, add it to the selected columns
      updatedSelectedColumns = [...selectedColumns, clickedKey];
    } else {
      // Column was selected before, remove it from the selected columns
      updatedSelectedColumns = selectedColumns.filter(
        (key) => key !== clickedKey
      );
    }

    setSelectedColumns(updatedSelectedColumns);
  };



  const [statusFilter, setStatusFilter] = useState({
    ENABLED: false,
    PAUSED: false,
  });
  const [filteredData, setFilteredData] = useState(metrcisbyCompaign);

  const handleSearchInputChange = (e) => {
    const value = e.target.value.toLowerCase();
    setSearchText(value);

    const filtered = metrcisbyCompaign.filter((item) =>
      item.campaign_name.toLowerCase().includes(value)
    );

    setFilteredData(filtered);
  };

  // new filter
  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;

    const updatedFilter = {
      ...statusFilter,
      [name]: checked,
    };

    setStatusFilter(updatedFilter);

    const selectedStatuses = Object.keys(updatedFilter).filter(
      (key) => updatedFilter[key]
    );

    const filtered = metrcisbyCompaign.filter((item) =>
      selectedStatuses.length > 0
        ? selectedStatuses.includes(item.status)
        : true
    );

    setFilteredData(filtered);
  };

  const filteredColumns = columns.filter((column) =>
    selectedColumns.includes(column.key)
  );

  const menu = (
    <Menu>
      {columns.map((column) => (
        <Menu.Item key={column.key} onClick={() => handleMenuClick(column.key)}>
          <Checkbox
            checked={selectedColumns.includes(column.key)}
            style={{ marginRight: '8px' }}
          />
          <span
            style={
              selectedColumns.includes(column.key) ? { fontWeight: 'bold' } : {}
            }
          >
            {column.title}
          </span>
        </Menu.Item>
      ))}
    </Menu>
  );

  const menufilter = (
    <Menu>
      <Menu.Item key="ENABLED">
        <Checkbox
          name="ACTIVE"
          checked={statusFilter.ENABLED}
          onChange={handleCheckboxChange}
        >
          ENABLED
        </Checkbox>
      </Menu.Item>
      <Menu.Item key="PAUSED">
        <Checkbox
          name="PAUSED"
          checked={statusFilter.PAUSED}
          onChange={handleCheckboxChange}
        >
          PAUSED
        </Checkbox>
      </Menu.Item>
    </Menu>
  );

  return (
    <div className="page-content">
      <MetaTags>
        <title>{props.t(" Meta Campaigns Data")} | Convergen-Metrics - E-com SaaS</title>
      </MetaTags>
      
      <div className="container-fluid">
          <Breadcrumbs
              title={props.t("Meta Ads campaigns")}
              breadcrumbItem={props.t("Meta Ads Dashboard")}
              pageHeading={props.t("Ads Dashboard")}
              image={FacebbokAdsIcon}
            />
      
         
        
      <Row>
          <Card style={{ border: 'none' }}>
            <Space
              style={{
                marginBottom: 16,
              }}
            >
              <Dropdown overlay={menu} trigger={['click']}>
                <Button className="custom-button">
                  {props.t('Select metrics')} <DownOutlined />{' '}
                </Button>
              </Dropdown>
              <Button className="custom-button" onClick={setAgeSort}>
                {props.t('Sort Impressions')}
              </Button>
              <Button onClick={clearFilters} className="custom-button">
                {props.t('Clear filters')}
              </Button>
              <Button onClick={clearAll} className="custom-button">
                {props.t('Clear filters and sorters')}
              </Button>
            </Space>
            <div className="table-border">
              <div
                className="d-flex align-items-center"
                style={{ padding: '20px' }}
              >
                <Dropdown overlay={menufilter} trigger={['click']}>
                  <Button
                    className="custom-button"
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      padding: '22px',
                    }}
                  >
                    <FeatherIcon
                      icon="filter"
                      size="16"
                      style={{ marginRight: '8px' }}
                    />
                    {props.t('Filter')}
                  </Button>
                </Dropdown>

                <div
                  className="search-box"
                  style={{
                    flex: 1,
                    maxWidth: '523px',
                    position: 'relative',
                    backgroundColor: '#F4F2FF',
                    borderRadius: '6px',
                    padding: '10px 15px',
                    marginLeft: '16px',
                  }}
                >
                  <Input
                    placeholder={props.t('Campaign Name')}
                    value={searchText}
                    onChange={handleSearchInputChange}
                    className="custom-search-input"
                  />
                  <i
                    className="bx bx-search-alt search-icon"
                    style={{
                      position: 'absolute',
                      left: '10px',
                      top: '50%',
                      transform: 'translateY(-50%)',
                      color: '#7a7a7a',
                    }}
                  />
                </div>

              
              </div>

              <Table
                columns={filteredColumns}
                dataSource={filteredData} // Use filtered data here
                onChange={handleChange}
                pagination={{
                  ...paginationOptions,
                  onChange: handleChange,
                  showSizeChanger: true,
                  style: {
                    backgroundColor: '#F4F2FF',
                    padding: '10px 15px',
                    marginTop: '2px',
                    borderRadius: '6px',
                    fontsize: '14px',
                    fontweight: '700',
                    color: '#6E6893',
                  },
                }}
                headerCellStyle={{
                  backgroundColor: '#FF0000', // Change this to your desired color
                  color: '#333333', // Text color
                }}
              />
            </div>
          </Card>
        </Row>
       
      </div>
      <div className="mt-2-overview-dashboard">
        {/* <Link to="/fb-campaigns-details" className="btn btn-primary btn-sm">
          {props.t("View more")} <i className="overview-dashboard-icon mdi mdi-arrow-right ms-1"></i>
        </Link> */}
      </div>
    </div>
  );
}

Campaignstable.propTypes = {
  t: PropTypes.any,
};

export default withRouter(withTranslation()(Campaignstable));
