import gql from 'graphql-tag';

const getRefunds = gql`query($limit:Int!,$page:Int!){
    all(limit:$limit,page:$page){
        pages,
        type,
        refund{
            id,
            amount,
            order
           
        }
     
}}
`
export { getRefunds };