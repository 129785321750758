export const    GET_OPERATION_REPORTS_REQUEST = ' GET_OPERATION_REPORTS_REQUEST';
export const   GET_OPERATION_REPORTS_SUCCESS = 'GET_OPERATION_REPORTS_SUCCESS';
export const   GET_OPERATION_REPORTS_FAILURE = 'GET_OPERATION_REPORTS_FAILURE';


export const getOperationDataRequest = (startDate,endDate,channel) => ({
    type: GET_OPERATION_REPORTS_REQUEST,
    payload: {startDate,endDate,channel},
    
})

export const getOperationDataSuccess = ( ops,series, sales, avg, daily, avgGR,avgAdCost, avgFacebook, avgGoogle, avgCOG, avgSnh, avgRef,  avgDis, avgTax, avgOrd, avgUni, avgShip, avgMF,  avgNetIn,avgtotalreturn) => ({
    type: GET_OPERATION_REPORTS_SUCCESS,
    payload: {ops,series, sales, avg, daily, avgGR,avgAdCost, avgFacebook, avgGoogle, avgCOG, avgSnh, avgRef,  avgDis, avgTax, avgOrd, avgUni, avgShip, avgMF,  avgNetIn,avgtotalreturn}
})

export const getOperationDataFailure = (error) => ({
    type: GET_OPERATION_REPORTS_FAILURE,
    payload: error,
})
