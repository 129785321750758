import React, { useState,useEffect } from 'react';
import { Card, CardBody, Col, Row } from 'reactstrap';
import { withTranslation } from "react-i18next";
import PropTypes from 'prop-types';
import { withRouter } from "react-router-dom";
import { Link } from 'react-router-dom';
import { Button, Space, Table, Menu, Dropdown, Checkbox } from 'antd';
import FeatherIcon from 'feather-icons-react';
import {
  getFacebookPageDataPerDate,getFacebookPagePostData
} from '../../store/facebookPage/actions';
import { useSelector,useDispatch } from 'react-redux';
import { formatDate } from '../GoogleAnalytics/common/HelperFunctions/utils';
import Cardswithchart from './Cardswithchart';
import Breadcrumbs from '../../components/Common/Breadcrumb';

const FacebbokAdsIcon = `${process.env.PUBLIC_URL}/images/integrations_images/facebook.svg`;


const FacebookPageOverview = (props) => {

  const startDate = formatDate(props.dateRange.selection1.startDate);
  const endDate = formatDate(props.dateRange.selection1.endDate);
  const dispatch = useDispatch();
  const {facebookPageData,facebookPagePostData  } = useSelector(
    (state) => state.facebookPage
  );
  const [likes_numbre, setLikes_numbre] = useState('');
  const [post_impressions, setpost_impressions] = useState('');
  const [post_engagements, setpost_engagements] = useState('');
  const [followers, setFollowers] = useState('');

  
  

  const [filteredInfo, setFilteredInfo] = useState({});
  const [sortedInfo, setSortedInfo] = useState({});
  const [paginationOptions, setPaginationOptions] = useState({
    pageSize: 5,
    pageSizeOptions: ['5', '10', '20'],
  });


  

useEffect(() => {
dispatch(getFacebookPageDataPerDate(startDate,endDate))
if (facebookPageData && facebookPageData.length > 0) {

  let total_page_posts_impressions_paid = 0; // Initialize total_page_posts_impressions_paid before the map function
  let total_monthly_impressions = 0;
  let total_post_engagements = 0;
  let total_post_impressions = 0;
  let total_page_impressions_paid = 0;

  facebookPageData.forEach((item) => {
    total_monthly_impressions += item.monthly_impressions;
    total_post_engagements += item.post_engagements;
    total_post_impressions += item.post_impressions;
    total_page_impressions_paid += item.page_impressions_paid;
    total_page_posts_impressions_paid += item.page_posts_impressions_paid;
  });

  // setFacebookPageData(getfbPageData);
  setLikes_numbre(facebookPageData[0].likes_numbre);
  setFollowers(facebookPageData[0].followers);
  setpost_engagements(total_post_engagements);
  setpost_impressions(total_post_impressions);
}

}, [props.dateRange]);
useEffect(() => {
  dispatch(getFacebookPagePostData())
}, []);

const metrics = [
  {
    title: "Likes",
    number: likes_numbre,
    percentage: "23%", // Static data for testing
    trend: "up", // "up" or "down"
    graphData: (facebookPageData || []).map(item => ({
      name: formatDate(item.day),
      value: item.likes_numbre, // or any other metric you want to show in the graph
    }))
  },
  {
    title: "Followers",
    number: followers,
    percentage: "5098", // Static data for testing
    trend: "up",
    graphData: (facebookPageData || []).map(item => ({
      name: formatDate(item.day),
      value: item.followers, // or any other metric you want to show in the graph
    }))
  },
  {
    title: "Post Engagements",
    number: post_engagements,
    percentage: "-5%", // Static data for testing
    trend: "down",
    graphData: (facebookPageData || []).map(item => ({
      name: formatDate(item.day),
      value: item.post_engagements, // or any other metric you want to show in the graph
    }))
  },
  {
    title: "Post Impressions",
    number: post_impressions,
    percentage: "12%", // Static data for testing
    trend: "up",
    graphData: (facebookPageData || []).map(item => ({
      name: formatDate(item.day),
      value: item.post_impressions, // or any other metric you want to show in the graph
    }))
  }
];


  const formatNumberWithSpaces = (value) => {
    if (value !== undefined && value !== null) {
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    }
    return "";
  };

  const handleChange = (pagination, filters, sorter) => {
    setFilteredInfo(filters);
    setSortedInfo(sorter);
    if (pagination.pageSize !== paginationOptions.pageSize) {
      setPaginationOptions({
        ...paginationOptions,
        pageSize: pagination.pageSize,
      });
    }
  };

  const clearAll = () => {
    setSortedInfo({});
    setSelectedColumns(columns.map((column) => column.key));
  };

  const setAgeSort = () => {
    setSortedInfo({
      order: 'descend',
      columnKey: 'post_impressions',
    });
  };

  const columns = [
    {
      title: props.t('POST IMAGE'),
      dataIndex: 'full_picture',
      key: 'full_picture',
      align: 'center',
      ellipsis: true,
      render: (text, record) => {
        if (!text) {
          return (
            <a href={record.permalink_url} target="_blank" rel="noopener noreferrer">
              <span>{'There is Non-image'}</span>
            </a>
          );
        } else {
          return (
            <a href={record.permalink_url} target="_blank" rel="noopener noreferrer">
              <img
                src={text}
                alt={`Full Picture ${record.full_picture}`}
                style={{ maxWidth: '100px', maxHeight: '90px' }}
              />
            </a>
          );
        }
      }
    },
    {
      title: props.t('POST CLICKS'),
      dataIndex: 'post_clicks',
      align: 'center',
      key: 'post_clicks',
      sorter: (a, b) => a.post_clicks - b.post_clicks,
      sortOrder: sortedInfo.columnKey === 'post_clicks' ? sortedInfo.order : null,
      ellipsis: true,
      render: (text) => <strong className='table-cell-text'>{formatNumberWithSpaces(text)}</strong>,
    },
    {
      title: props.t('POST LIKES'),
      dataIndex: 'post_likes',
      key: 'post_likes',
      align: 'center',
      sorter: (a, b) => a.post_likes - b.post_likes,
      sortOrder:
        sortedInfo.columnKey === 'post_likes' ? sortedInfo.order : null,
      ellipsis: true,
      render: (text) => <strong className='table-cell-text'>{formatNumberWithSpaces(text)}</strong>,
    },
    {
      title: props.t('POST REACTIONS'),
      dataIndex: 'postReactionsTotal',
      key: 'postReactionsTotal',
      align: 'center',
      sorter: (a, b) => a.postReactionsTotal - b.postReactionsTotal,
      sortOrder:
        sortedInfo.columnKey === 'postReactionsTotal' ? sortedInfo.order : null,
      ellipsis: true,
      render: (text) => <strong className='table-cell-text'>{formatNumberWithSpaces(text)}</strong>,
    },
    {
      title: props.t('POST IMPRESSIONS'),
      dataIndex: 'post_impressions',
      align: 'center',
      key: 'post_impressions',
      onFilter: (value, record) => record.address.includes(value),
      sorter: (a, b) => a.post_impressions - b.post_impressions,
      sortOrder: sortedInfo.columnKey === 'post_impressions' ? sortedInfo.order : null,
      ellipsis: true,
      render: (text) => <strong className='table-cell-text'>{formatNumberWithSpaces(text)}</strong>,
    },
    {
      title: props.t('SHARES'),
      dataIndex: 'shares',
      key: 'shares',
      align: 'center',
      sorter: (a, b) => a.shares - b.shares,
      sortOrder: sortedInfo.columnKey === 'shares' ? sortedInfo.order : null,
      ellipsis: true,
      render: (text) => <strong className='table-cell-text'>{formatNumberWithSpaces(text)}</strong>,
    },
    {
      title: props.t('CREATED AT'),
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (text, record, index) => {
        const formattedDate = text ? new Date(text).toLocaleDateString() : '';
        return <strong className='table-cell-text'>{formattedDate}</strong>;
      },
      align: 'center',
    },
  ];

  const [selectedColumns, setSelectedColumns] = useState(
    columns.map((column) => column.key)
  );

  const handleMenuClick = (clickedKey) => {
    const index = selectedColumns.indexOf(clickedKey);

    let updatedSelectedColumns = [];

    if (index === -1) {
      updatedSelectedColumns = [...selectedColumns, clickedKey];
    } else {
      updatedSelectedColumns = selectedColumns.filter(
        (key) => key !== clickedKey
      );
    }

    setSelectedColumns(updatedSelectedColumns);
  };

  const filteredColumns = columns.filter((column) =>
    selectedColumns.includes(column.key)
  );
  const handleSelectAll = () => {
    if (selectedColumns.length === columns.length) {
      // Deselect all
      setSelectedColumns([]);
    } else {
      // Select all
      setSelectedColumns(columns.map((column) => column.key));
    }
  };
  const isAllSelected = selectedColumns.length === columns.length;
  const menu = (
    <Menu>
      <Menu.Item key="select-all" onClick={handleSelectAll}>
        <Checkbox
          checked={isAllSelected}
          style={{ marginRight: '8px' }}
        />
        <span style={{ color: 'black' }}>
           All
        </span>
      </Menu.Item>
      {columns.map((column) => (
        <Menu.Item
          key={column.key}
          onClick={() => handleMenuClick(column.key)}
        >
          <Checkbox
            checked={selectedColumns.includes(column.key)}
            style={{ marginRight: '8px' }}
          />
          <span
            style={
              selectedColumns.includes(column.key)
                ? { fontWeight: 'bold' }
                : {}
            }
          >
            {column.title}
          </span>
        </Menu.Item>
      ))}
    </Menu>
  );

  const colors ={
    lineColor: '#2D5BFF',
    offsetZERO: '#93AAFD',
     offsetONE: '#ffffff',
  }

  return (
    <div className='mt-5'>
      <Col xl={12}>
      
      <Breadcrumbs
              image={FacebbokAdsIcon}
              title="Facebook Social Page"
            />

        <Row>
        <Cardswithchart metrics={metrics} colors={colors} />
        </Row>

       

        <Row>
       
          <Card style={{ border: "none" ,marginTop:"50px"}}>
      
          <Breadcrumbs
              image={FacebbokAdsIcon}
              title="Facebook Page posts"
            />
              <Space style={{ marginBottom: 16 }}>
                <Dropdown overlay={menu} trigger={['click']}>
                  <Button className='custom-button' >{props.t("Select metrics")}</Button>
                </Dropdown>
                <Button className='custom-button'  onClick={setAgeSort}>{props.t("Sort Impressions")}</Button>
                <Button className='custom-button' onClick={clearAll}>{props.t("Clear filters and sorters")}</Button>
                
              </Space>
              <div className="table-border">
              <div
                className="d-flex align-items-center"
                style={{ padding: '20px' }}
              >
                <Dropdown overlay={menu} trigger={['click']}>
                  <Button
                    className="custom-button"
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      padding: '22px',
                    }}
                  >
                    <FeatherIcon
                      icon="filter"
                      size="16"
                      style={{ marginRight: '8px' }}
                    />
                    {props.t('Filter')}
                  </Button>
                </Dropdown>

                

                <Link
                  to="/ads-dashboard"
                  className="btn btn-primary btn-sm viewMetrics-button"
                >
                  {props.t('View full metrics')}
                </Link>
              </div>
              <Table
                columns={filteredColumns}
                dataSource={facebookPagePostData || []}
                onChange={handleChange}
                
                pagination={{
                  ...paginationOptions,
                  onChange: handleChange,
                  showSizeChanger: true,
                  style: {
                    backgroundColor: '#F4F2FF',
                    padding: '10px 15px',
                    marginTop: '2px',
                    borderRadius: '6px',
                    fontsize: '14px' ,
                fontweight: '700',
                color: '#6E6893',
                  },
                }}
                
                headerCellStyle={{
                  backgroundColor: '#FF0000', // Change this to your desired color
                  color: '#333333', // Text color
                }}

              
              />
         </div>
          </Card>
        </Row>
      </Col>
    </div>
  );
}

FacebookPageOverview.propTypes = {
  t: PropTypes.any
};

export default withRouter(withTranslation()(FacebookPageOverview));
