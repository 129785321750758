import { call, put, takeEvery } from 'redux-saga/effects';
import {
  fetchTopSellersFailure,
  fetchTopSellersSuccess,
  fetchCouponsCodesFailure,
  fetchCouponsCodesSuccess,
  fetchOrderStatusSuccess,
  fetchOrderStatusFailure,
  fetchProductCategorySuccess,
  fetchProductCategoryFailure,
  integrationswoSuccess,
  integrationswoFailure,
  deleteWoocommerceSuccess,
  deleteWoocommerceFailure,
  getSalesPlateformeSuccess,
  getSalesPlateformeFailure,
  checkThirdStepWoocommerceSuccess,
  checkThirdStepWoocommerceFailure,
} from './actions';
import {
  FETCH_TOP_SELLERS_REQUEST,
  FETCH_COUPONS_CODES_REQUEST,
  FETCH_ORDER_STATUS_REQUEST,
  FETCH_PRODUCT_CATEGORY_REQUEST,
  INTEGRATIONS_WO_REQUEST,
  DELETE_WOOCOMMERCE_REQUEST,
  GET_SALES_PLATEFORME_REQUEST,
  CHECK_THIRD_STEP_WOOCOMMERCE_REQUEST,
} from "./actionsTypes"
import { getWooTopSellers } from '../../queries/woo/wooTopSellersQueries';
import { getCoupons } from '../../queries/woo/wooCouponsQueries';
import { getOrdersStatus } from '../../queries/woo/wooOrdersStatusQueries';
import { getProductCategory } from '../../queries/woo/wooProductCategoryQueries';
import { getWooStore, integrateWoo, deleteIntegration,} from '../../queries/woo/queries';
import {  checkSales } from '../../queries/Shopify/queries';
import { deleteAlert, successAlert } from '../../pages/Alerts/NotLinkedAccountAlert';
import urls from '../../routes/apiUrls';
import ApolloClient from "apollo-boost";

const AppoloCliWoocommerce = new ApolloClient({
  uri: urls.shopify
})

const ApploCLI = new ApolloClient({
  uri: urls.woo,
});

const wooTopSellers = new ApolloClient({
  uri: urls.wootopsellers,
});

const wooCouponsCodes = new ApolloClient({
  uri: urls.woocoupons,
})

const wooOrderStatus = new ApolloClient({
  uri: urls.wooordersstatus,
})

const wooProductCategory = new ApolloClient({
  uri: urls.wooproductcategory,
})

function* fetchWooTopSellers(action) {
  try {
    const { limit, page } = action.payload;
    const { data } = yield call(wooTopSellers.query, {
      query: getWooTopSellers,
      variables: { limit, page },
      context: {
        headers: {
          Authorization: window.localStorage.getItem("token")
            ? `Bearer ${window.localStorage.getItem("token")}`
            : "",
        },
      },
      errorPolicy: "all",
      fetchPolicy: "no-cache", //allow me to refecth data
    });
    localStorage.setItem('wootopsellers', JSON.stringify(data.all));
    yield put(fetchTopSellersSuccess(data.all));

  } catch (error) {
    yield put(fetchTopSellersFailure(error.message));
  }
}
function* fetchWooCouponsCodes(action) {
  try {
    const { limit, page } = action.payload;
    const { data } = yield call(wooCouponsCodes.query, {
      query: getCoupons,
      variables: { limit, page },
      context: {
        headers: {
          Authorization: window.localStorage.getItem("token")
            ? `Bearer ${window.localStorage.getItem("token")}`
            : "",
        },
      },
      errorPolicy: "all",
      fetchPolicy: "no-cache", //allow me to refecth data
    });
    localStorage.setItem('woocoupons', JSON.stringify(data.all));
    yield put(fetchCouponsCodesSuccess(data.all));

  } catch (error) {
    yield put(fetchCouponsCodesFailure(error.message));
  }
}
function* fetchWooOrderStatus(action) {
  try {
    const { limit, page } = action.payload;
    const { data } = yield call(wooOrderStatus.query, {
      query: getOrdersStatus,
      variables: { limit, page },
      context: {
        headers: {
          Authorization: window.localStorage.getItem("token")
            ? `Bearer ${window.localStorage.getItem("token")}`
            : "",
        },
      },
      errorPolicy: "all",
      fetchPolicy: "no-cache", //allow me to refecth data
    });
    localStorage.setItem('wooorderstatus', JSON.stringify(data.all));
    yield put(fetchOrderStatusSuccess(data.all));

  } catch (error) {
    yield put(fetchOrderStatusFailure(error.message));
  }
}
function* fetchWooProductCategory(action) {
  try {
    const { limit, page } = action.payload;
    const { data } = yield call(wooProductCategory.query, {
      query: getProductCategory,
      variables: { limit, page },
      context: {
        headers: {
          Authorization: window.localStorage.getItem("token")
            ? `Bearer ${window.localStorage.getItem("token")}`
            : "",
        },
      },
      errorPolicy: "all",
      fetchPolicy: "no-cache", //allow me to refecth data
    });
    localStorage.setItem('wooProductCategory', JSON.stringify(data.all));
    yield put(fetchProductCategorySuccess(data.all));

  } catch (error) {
    yield put(fetchProductCategoryFailure(error.message));
  }
}
function* integratewo(action){
  try{
    const { data } = yield call(ApploCLI.mutate, {
      mutation: integrateWoo,
      variables: {
        key: action.payload.key,
        secret: action.payload.secret,
        url: action.payload.url,

      },
      context: {
        headers: {
          Authorization: window.localStorage.getItem("token")
            ? `Bearer ${window.localStorage.getItem("token")}`
            : "",
        },
      },
      errorPolicy: "all",
      fetchPolicy: "no-cache", //allow me to refecth data
    });
    console.log(data)
    if (data.integration) {
    yield put(integrationswoSuccess(data.integration));
    window.location.replace('/integrations')
    }

  }catch(error){
    yield put(integrationswoFailure(error.message));
 }
 }
  function* deleteWoocommerce(action){
  try{
    const { data } = yield call(ApploCLI.mutate, {
      mutation: deleteIntegration,
      context: {
        headers: {
          Authorization: window.localStorage.getItem("token")
            ? `Bearer ${window.localStorage.getItem("token")}`
            : "",
        },
      },
      errorPolicy: "all",
      fetchPolicy: "no-cache", //allow me to refecth data
    });
   
   
    yield put(deleteWoocommerceSuccess());
    successAlert().then(() => {
      window.location.replace("/integrations");
  });
    
  }
  catch(error){
    yield put(deleteWoocommerceFailure(error.message)); 
  }
}

function* fetchWooStore() {
  try{
    const { data } = yield call(ApploCLI.query, {
      query: getWooStore,
      context: {
        headers: {
          Authorization: window.localStorage.getItem("token")
            ? `Bearer ${window.localStorage.getItem("token")}`
            : "",
        },
      },
      errorPolicy: "all",
      fetchPolicy: "no-cache", //allow me to refecth data
    })
     if(data.get){
      console.log('*************eaezaeaz**************',data.get)
      var websiteName = JSON.stringify(data.get.url)
      var websiteName = websiteName.replace('/', '')
      localStorage.setItem('websiteName', websiteName);
       var info=data.get
      yield put(getSalesPlateformeSuccess(info, websiteName));
     
    }else{
      yield put(getSalesPlateformeFailure('data null'));
    }
   

  }catch(error){
    yield put(getSalesPlateformeFailure(error.message));
  }

}  

 function* checkThirdStepWoocommerce(){
  try{
    const {data} = yield call(AppoloCliWoocommerce.query, {
      query: checkSales,
      context: {
        headers: {
            Authorization: window.localStorage.getItem("token")
                ? `Bearer ${window.localStorage.getItem("token")}`
                : "",
        },
    },
    errorPolicy: "all",
    fetchPolicy: "network-only",
    })
     if( data.getIntegration){
      if(data.getIntegration.type != 7){
        yield put(checkThirdStepWoocommerceSuccess());
      }
     }
    
  }catch (error) {
    yield put(checkThirdStepWoocommerceFailure(error.message));
 }
}

export default function* rootSaga() {
  yield takeEvery(FETCH_TOP_SELLERS_REQUEST, fetchWooTopSellers);
  yield takeEvery(FETCH_COUPONS_CODES_REQUEST, fetchWooCouponsCodes);
  yield takeEvery(FETCH_ORDER_STATUS_REQUEST, fetchWooOrderStatus);
  yield takeEvery(FETCH_PRODUCT_CATEGORY_REQUEST, fetchWooProductCategory);
  yield takeEvery(INTEGRATIONS_WO_REQUEST, integratewo);
  yield takeEvery(DELETE_WOOCOMMERCE_REQUEST, deleteWoocommerce);
  yield takeEvery(GET_SALES_PLATEFORME_REQUEST, fetchWooStore);
  yield takeEvery(CHECK_THIRD_STEP_WOOCOMMERCE_REQUEST, checkThirdStepWoocommerce);
}