import React, { useEffect, useState } from 'react';
import MetaTags from 'react-meta-tags';
import { Card, CardBody, CardHeader, Col, Container, Row } from "reactstrap";
import { useLocation } from 'react-router-dom';
import urls from '../../routes/apiUrls';
import ApolloClient from 'apollo-boost';
import { Paiment, verifPaiment } from '../../queries/Users/userQuery';
import PlanDetaills from "./paiementComponents/PlanDetaills";
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import Swal from 'sweetalert2';

const ApploCLI = new ApolloClient({
    uri: urls.user
});

const useQuery = () => {
    return new URLSearchParams(useLocation().search);
};

const PagePricing = (props) => {
    const { PlanType } = props;

    const yearData = [
        {
            id: 1,
            title: "Essential",
            price: 299000,
            desc: props.t("Designed for small brands and growing businesses,"),
            integration: props.t("All Platforms"),
            users: 1,
            reporting: props.t("Basic"),
            Multicurrency: props.t("Yes"),
            CogsZones: props.t("Products Only"),
            HitoricalData: props.t("Yes"),
        },
        {
            id: 2,
            title: "Meduim",
            price: 499000,
            desc: props.t("For larger businesses or those with additional administration needs"),
            integration: props.t("All Platforms"),
            users: 2,
            reporting: props.t("Profit Reports"),
            Multicurrency: props.t("Yes"),
            CogsZones: props.t("Only One"),
            HitoricalData: props.t("Yes"),
        },
        {
            id: 3,
            title: "Full-Metrics",
            price: 999000,
            desc: props.t("For extra large businesses or those in regulated industries"),
            integration: props.t("All Platforms"),
            users: props.t("Unlimited"),
            reporting: props.t("Full Metrics Reports"),
            Multicurrency: props.t("Yes"),
            CogsZones: props.t("Unlimited"),
            HitoricalData: props.t("yes"),
        },
    ];

    // Set isFeatured based on PlanType
    const updatedYearData = yearData.map((year) => {
        if (year.title === PlanType) {
            return { ...year, isFeatured: true };
        } else {
            return { ...year, isFeatured: false };
        }
    });

    const [activeTab2, toggleTab2] = useState("");

    const verifpaiement = async (payment_id, Type, Amount) => {
        try {
            await ApploCLI.query({
                query: verifPaiment,
                variables: {
                    id_paiement: payment_id,
                    Type: Type,
                    Amount: Amount
                },
                context: {
                    headers: {
                        Authorization: window.localStorage.getItem('token') ?
                            `Bearer ${window.localStorage.getItem('token')}` :
                            '',
                    }
                },
                errorPolicy: "all",
            }).then(res => {
                console.log("verif", res);
                if (res.data.verif_paiement.success === "SUCCESS") {
                    Swal.fire({
                        title: props.t('Success!'),
                        text: props.t('Paiement effectué avec succès!'),
                        icon: 'success',
                        confirmButtonText: props.t('Done')
                    }).then((result) => {
                        if (result.isConfirmed) {
                            window.location.href = "/settings-profile";
                        }
                    });
                } else {
                    Swal.fire({
                        title: props.t('Error!'),
                        text: props.t('Erreur lors du paiement! please check your card informations'),
                        icon: 'error',
                        confirmButtonText: props.t('Done')
                    }).then((result) => {
                        if (result.isConfirmed) {
                            window.location.href = "/settings-profile";
                        }
                    });
                }
            }).catch(err => {
                console.log("veriiif error", err);
            });
        } catch (error) {
            console.log(error);
        }
    };

    const functionpaiement = async (amount, type) => {
        try {
            await ApploCLI.mutate({
                mutation: Paiment,
                variables: {
                    amount: amount,
                    Type: type
                },
                context: {
                    headers: {
                        Authorization: window.localStorage.getItem('token') ?
                            `Bearer ${window.localStorage.getItem('token')}` :
                            '',
                    }
                },
                errorPolicy: "all",
            }).then(res => {
                const queryParams = new URLSearchParams({ amount, type }).toString();
                const redirectUrl = `${res.data.paiement.link}?${queryParams}`;
                window.location.href = redirectUrl;
            }).catch(err => {
                console.log(err);
            });
        } catch (error) {
            console.log(error);
        }
    };

    const query = useQuery();
    const paymentId = query.get('payment_id');
    const Type = query.get('Type');
    const Amount = query.get('amount');

    const getButtonTextsecond = (PlanType) => {
        if (PlanType === "Premium") {
            return props.t('current plan');
        } else if (PlanType === "Standard") {
            return props.t('Upgrade');
        } else {
            return props.t('Choose Plan');
        }
    };

    const getButtonTextfirst = (PlanType) => {
        if (PlanType === "Standard") {
            return props.t('This is your plan');
        } else if (PlanType === "Premium") {
            return props.t('Downgrade your plan');
        } else {
            return props.t('Choose Plan');
        }
    };

    useEffect(() => {
        if (paymentId) {
            verifpaiement(paymentId, Type, Amount);
        }
    }, [paymentId]);

    return (
        <React.Fragment>
            <Container fluid>
                <Row>
                    <Col xl={12}>
                        <Card>
                            <CardHeader>
                                <h4 className="card-title">{props.t('Subscription')}</h4>
                                <p className="card-title-desc">{props.t('Choose the subscription you want to sign up for and turn your data into opportunities!')}</p>
                            </CardHeader>
                            <CardBody>
                                <Row>
                                    <PlanDetaills yearData={updatedYearData} onChoosePlan={functionpaiement} />
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </React.Fragment>
    );
};

export default withRouter(withTranslation()(PagePricing));
