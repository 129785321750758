import gql from 'graphql-tag';

const getData = gql`
  query (
    $startDate: String!
    $endDate: String!
    $compareStartDate: String
    $compareEndDate: String
  ) {
    get(
      startDate: $startDate
      endDate: $endDate
      compareStartDate: $compareStartDate
      compareEndDate: $compareEndDate
    ) {
      report {
        day
        totalCost
        Revenue
        totalConversions
        ROAS
        cpa
        cpc
        ctr
        conversionRate
        totalImpressions
        totalClicks
        AOV
      }
    }
  }
`;

export { getData };
