import React, { useState, useEffect } from 'react';

import  BarChart  from '../common/BarChart';
// Helper function to aggregate data
const aggregateData = (key, array) => {
  return array.reduce((acc, item) => {
    item[key].forEach(([value, count]) => {
      acc[value] = (acc[value] || 0) + parseInt(count, 10);
    });
    return acc;
  }, {});
};

const AudienceDataHandler = ({ gaaudiences, dataKey, color, title, width }) => {
  const [data, setData] = useState([]);
  const [comparedData, setComparedData] = useState([]);

  console.log('data', data);
  useEffect(() => {
    if (gaaudiences && gaaudiences.length > 0) {
      if (gaaudiences[0] && gaaudiences[0].length > 0) {
        const aggregatedData = aggregateData(dataKey, gaaudiences[0]);
        setData(Object.entries(aggregatedData));
        setComparedData([]);
      }

      if (gaaudiences[1] && gaaudiences[1].length > 0) {
        const aggregatedData = aggregateData(dataKey, gaaudiences[1]);
        setComparedData(Object.entries(aggregatedData));
      }
    }
  }, [gaaudiences, dataKey]);

  return (
    <BarChart
      data={data}
      comparedData={comparedData}
      dataKey={dataKey}
      width={width}
      title={title}
      color={color}
    />
  );
};

export default AudienceDataHandler;
