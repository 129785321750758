import React, { useState, useEffect, useRef } from 'react';
import { SearchOutlined } from '@ant-design/icons';
import {
  Row,
  Col,
  CardBody,
  Nav,
  TabContent,
  TabPane,
  Progress,
  Alert,
  Card,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import SimpleBar from 'simplebar-react';
import { Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import { useDispatch, useSelector } from 'react-redux';
import Highlighter from 'react-highlight-words';
import {
  getMetricsByCampaigns,
  getAdsRequest,
} from '../../store/googleAds/actions';
import MetaTags from 'react-meta-tags';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import secureSection from '../../MangeSecurity/MemberSecurity';
import PropTypes from 'prop-types';
import i18next from 'i18next';
import { Button, Space, Table, Menu, Dropdown, Input, Checkbox } from 'antd';
import GoogleAdsLogo from '../../assets/integrations_images/GoogleAdsLogo.svg';
import Spinner from '../GoogleAnalytics/common/Spinner';

function Adstable({
  title,
  columns,
  setFilteredData,
  filteredData,
  data,
  isLoading,
  sortedInfo,
  setSortedInfo,
  filters,
}) {
  const dispatch = useDispatch();
  secureSection();

  const [searchText1, setSearchText1] = useState('');
  const [searchText2, setSearchText2] = useState('');

  const handleChange = (pagination, filters, sorter) => {
    console.log('Various parameters', pagination, sorter);
    setSortedInfo(sorter);
    if (pagination.pageSize !== paginationOptions.pageSize) {
      setPaginationOptions({
        ...paginationOptions,
        pageSize: pagination.pageSize,
      });
    }
  };
  const clearAll = () => {
    clearFilters();
    clearSorters();
    setSelectedColumns(columns.map((column) => column.key));
  };

  const clearFilters = () => {
    setSearchText1('');
    setSearchText2('');
    setFilteredData(data);
  };
  const clearSorters = () => {
    setSortedInfo({});
  };
  const setAgeSort = () => {
    setSortedInfo({
      order: 'descend',
      columnKey: 'conversions',
    });
  };
  // columns option

  const [selectedColumns, setSelectedColumns] = useState(
    columns.map((column) => column.key)
  );
  const [paginationOptions, setPaginationOptions] = useState({
    pageSize: 5, // Default page size
    pageSizeOptions: ['5', '10', '20'], // Available page size options
  });
  const handleMenuClick = (clickedKey) => {
    const index = selectedColumns.indexOf(clickedKey);

    let updatedSelectedColumns = [];

    if (index === -1) {
      // Column was not selected before, add it to the selected columns
      updatedSelectedColumns = [...selectedColumns, clickedKey];
    } else {
      // Column was selected before, remove it from the selected columns
      updatedSelectedColumns = selectedColumns.filter(
        (key) => key !== clickedKey
      );
    }

    setSelectedColumns(updatedSelectedColumns);
  };

  const filteredColumns = columns.filter((column) =>
    selectedColumns.includes(column.key)
  );

  const menu = (
    <Menu>
      {columns.map((column) => (
        <Menu.Item key={column.key} onClick={() => handleMenuClick(column.key)}>
          <Checkbox
            checked={selectedColumns.includes(column.key)}
            style={{ marginRight: '8px' }}
          />
          <span
            style={
              selectedColumns.includes(column.key) ? { fontWeight: 'bold' } : {}
            }
          >
            {column.title}
          </span>
        </Menu.Item>
      ))}
    </Menu>
  );
  const handleSearchChange = (type, value) => {
    const lowerCaseValue = value.toLowerCase();

    if (type === filters[0].type) {
      setSearchText1(lowerCaseValue);
    } else if (type === filters[1].type) {
      setSearchText2(lowerCaseValue);
    }
    console.log('filters', filters[0]);
    const filtered = data.filter(
      (item) =>
        item[filters[0].value]
          .toLowerCase()
          .includes(type === filters[0].type ? lowerCaseValue : searchText1) &&
        item[filters[1].value]
          .toLowerCase()
          .includes(type === filters[1].type ? lowerCaseValue : searchText2)
    );

    setFilteredData(filtered);
  };

  const Header = (
    <div className="d-flex align-items-center">
      <span
        style={{ marginLeft: '80px', marginRight: '100px', fontWeight: 600 }}
      >
        SEARCH
      </span>

      <div
        className="search-box"
        style={{
          flex: 1,
          maxWidth: '250px', // Adjust width to fit better
          position: 'relative',
          backgroundColor: '#F4F2FF',
          borderRadius: '6px',
          padding: '5px 20px', // Reduce padding to make the input box smaller
          marginRight: '20px', // Add spacing between the two inputs
        }}
      >
        <input
          placeholder={i18next.t(filters[0].placeholder)}
          value={searchText1}
          onChange={(e) => handleSearchChange(filters[0].type, e.target.value)}
          className="custom-search-input"
          style={{
            paddingLeft: '30px', // Offset to make space for the icon
            height: '35px', // Adjust height for better alignment
            outline: 'none', // Remove the default focus style
          }}
        />
        <i
          className="bx bx-search-alt search-icon"
          style={{
            position: 'absolute',
            left: '10px',
            top: '50%',
            transform: 'translateY(-50%)',
            color: '#7a7a7a',
          }}
        />
      </div>

      <div
        className="search-box"
        style={{
          flex: 1,
          maxWidth: '250px',
          position: 'relative',
          backgroundColor: '#F4F2FF',
          borderRadius: '6px',
          padding: '5px 10px',
          marginRight: '20px',
        }}
      >
        <input
          placeholder={i18next.t(filters[1].placeholder)}
          value={searchText2}
          onChange={(e) => handleSearchChange(filters[1].type, e.target.value)}
          className="custom-search-input"
          style={{
            paddingLeft: '30px',
            height: '35px',
            outline: 'none',
          }}
        />
        <i
          className="bx bx-search-alt search-icon"
          style={{
            position: 'absolute',
            left: '10px',
            top: '50%',
            transform: 'translateY(-50%)',
            color: '#7a7a7a',
          }}
        />
      </div>
    </div>
  );

  return (
    <div className="page-content">
      <Spinner loading={isLoading} />
      <Card style={{ border: 'none' }}>
        <CardBody>
          <div className="d-flex align-items-center mb-4">
            <img
              src={GoogleAdsLogo}
              alt="Google Ads Icon"
              style={{ marginTop: '-20px', height: '20px' }}
            />
            <h5
              className="ms-2 font-weight-bold"
              style={{ marginTop: '-13px' }}
            >
              {i18next.t(title)}
            </h5>
          </div>

          <div>
            <Space style={{ marginBottom: 16 }}>
              <Dropdown overlay={menu} trigger={['click']}>
                <Button className="custom-button">{i18next.t('Select Columns')}</Button>
              </Dropdown>
              <Button className="custom-button" onClick={setAgeSort}>
                {i18next.t('Sort Conversions')}
              </Button>
              <Button className="custom-button" onClick={clearSorters}>
                {i18next.t('Clear sorters')}
              </Button>
              <Button className="custom-button" onClick={clearAll}>
                {i18next.t('Clear filters and sorters')}
              </Button>
            </Space>
          </div>

          <Table
            columns={filteredColumns}
            bordered
            dataSource={filteredData}
            onChange={handleChange}
            pagination={{
              ...paginationOptions,
              onChange: handleChange,
              showSizeChanger: true, // Enable the page size selector
              style: {
                backgroundColor: '#F4F2FF',
                padding: '10px 15px',
                marginTop: '2px',
                borderRadius: '6px',
                fontsize: '14px',
                fontweight: '700',
                color: '#6E6893',
              },
            }}
          
            title={() => Header}
          />
        </CardBody>
      </Card>
    </div>
  );
}
Adstable.propTypes = {
  t: PropTypes.any,
};
export default withRouter(withTranslation()(Adstable));
//export default Adstable;
