import React, { useState, useEffect, useRef } from 'react';
import { Card } from "semantic-ui-react";
import { getInformations } from "../../queries/Reports/PnLQuery";
import urls from "../../routes/apiUrls";
import CountUp from "react-countup";
import { withTranslation } from "react-i18next"
import PropTypes from 'prop-types';
import { withRouter } from "react-router-dom"
import { Row, Col, CardBody, Alert, Badge } from 'reactstrap';
import Breadcrumbs from '../../components/Common/Breadcrumb';


function StoreOverview({ data, handleClick, storecurrency }) {
  const formatNumberWithSpaces = (value) => {
    if (value !== undefined && value !== null) {
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    }
    return ""; // or any default value you want to return for undefined/null cases
  };

  const handleBadgeClick = () => {
    // This function gets triggered when the badge is clicked
    handleClick(true); // Pass the value to the parent component
  };
  //spend
  const googleCost = data.GoogleMetrics ? data.GoogleMetrics.totalCost : 0;
  const facebookCost = data.facebookMetrics ? data.facebookMetrics.totalCost : 0;
  const Globalspend = googleCost + facebookCost;
  // // Revenue
  const googleRevenue = data.GoogleMetrics ? data.GoogleMetrics.totalconversionValue : 0;
  const facebookRevenue = data.facebookMetrics ? data.facebookMetrics.totalconversionValue : 0;
  const globalRevenue = googleRevenue + facebookRevenue;

  const currency = storecurrency ? storecurrency.selectedOptionValue : "TND";
  const MER = data?.store?.total_sales / (Globalspend * storecurrency.conversion_rate);
  return (
    <div className="mt-5">
      
      <Breadcrumbs
            
              title="Overall Store Overview"
            />
    <Col>
  <Row className="justify-content-center">
  <Col xl={2} className="mb-4">
      <Card className="h-100" style={{  border: '1px solid #D3E3FD', borderRadius: '6px' }}>
        <CardBody className="p-3">
          <div className="d-flex flex-column align-items-start">
            <h6 className="text-primary" style={{ fontWeight: '600', fontSize: '14px', color: '#0044BD' }}>
              Total Sales
            </h6>
            <h3 style={{ fontWeight: '700', color: '#000000', fontSize: '24px' }}>
              {formatNumberWithSpaces(data?.store?.total_sales?.toFixed(2))}{currency}
            </h3>
            <div className="d-flex align-items-center mt-2">
              <h6 className="mb-0 px-2 py-1" style={{ backgroundColor: '#CDFFCD', borderRadius: '4px', color: '#007F00', fontWeight: '600', fontSize: '14px' }}>
                28.3% <i className="mdi mdi-arrow-up-bold" style={{ color: '#007F00' }}></i>
              </h6>
              <span style={{ color: '#B0B0B0', fontSize: '12px', marginLeft: '8px' }}>
                Than last month
              </span>
            </div>
          </div>
        </CardBody>
      </Card>
    </Col>
    <Col xl={2} className="mb-4">
      <Card className="h-100" style={{ border: '1px solid #D9D5EC', borderRadius: '6px' }}>
        <CardBody className="p-3">
          <div className="d-flex flex-column align-items-start">
            <h6 className="text-primary" style={{ fontWeight: '600', fontSize: '14px', color: '#0044BD' }}>
            Average Order Value
            </h6>
            <h3 style={{ fontWeight: '700', color: '#000000', fontSize: '24px' }}>
            {data?.store?.aov?.toFixed(2)}{currency}
            </h3>
            <div className="d-flex align-items-center mt-2">
              <h6 className="mb-0 px-2 py-1" style={{ backgroundColor: '#CDFFCD', borderRadius: '4px', color: '#007F00', fontWeight: '600', fontSize: '14px' }}>
                28.3% <i className="mdi mdi-arrow-up-bold" style={{ color: '#007F00' }}></i>
              </h6>
              <span style={{ color: '#B0B0B0', fontSize: '12px', marginLeft: '8px' }}>
                Than last month
              </span>
            </div>
          </div>
        </CardBody>
      </Card>
    </Col>

    <Col xl={2} className="mb-4">
      <Card className="h-100" style={{ border: '1px solid #D9D5EC', borderRadius: '6px' }}>
        <CardBody className="p-3">
          <div className="d-flex flex-column align-items-start">
            <h6 className="text-primary" style={{ fontWeight: '600', fontSize: '14px', color: '#0044BD' }}>
            Total Orders
            </h6>
            <h3 style={{ fontWeight: '700', color: '#000000', fontSize: '24px' }}>
            {data?.store?.total_orders}
            </h3>
            <div className="d-flex align-items-center mt-2">
              <h6 className="mb-0 px-2 py-1" style={{ backgroundColor: '#CDFFCD', borderRadius: '4px', color: '#007F00', fontWeight: '600', fontSize: '14px' }}>
                28.3% <i className="mdi mdi-arrow-up-bold" style={{ color: '#007F00' }}></i>
              </h6>
              <span style={{ color: '#B0B0B0', fontSize: '12px', marginLeft: '8px' }}>
                Than last month
              </span>
            </div>
          </div>
        </CardBody>
      </Card>
    </Col>

    <Col xl={2} className="mb-4">
      <Card className="h-100" style={{ border: '1px solid #D9D5EC', borderRadius: '6px' }}>
        <CardBody className="p-3">
          <div className="d-flex flex-column align-items-start">
            <h6 className="text-primary" style={{ fontWeight: '600', fontSize: '14px', color: '#0044BD' }}>
            MER
            </h6>
            <h3 style={{ fontWeight: '700', color: '#000000', fontSize: '24px' }}>
           10
            </h3>
            <div className="d-flex align-items-center mt-2">
              <h6 className="mb-0 px-2 py-1" style={{ backgroundColor: '#CDFFCD', borderRadius: '4px', color: '#007F00', fontWeight: '600', fontSize: '14px' }}>
                28.3% <i className="mdi mdi-arrow-up-bold" style={{ color: '#007F00' }}></i>
              </h6>
              <span style={{ color: '#B0B0B0', fontSize: '12px', marginLeft: '8px' }}>
                Than last month
              </span>
            </div>
          </div>
        </CardBody>
      </Card>
    </Col>

    <Col xl={2} className="mb-4">
      <Card className="h-100" style={{ border: '1px solid #D9D5EC', borderRadius: '6px' }}>
        <CardBody className="p-3">
          <div className="d-flex flex-column align-items-start">
            <h6 className="text-primary" style={{ fontWeight: '600', fontSize: '14px', color: '#0044BD' }}>
            Total Ads Spend
            </h6>
            <h3 style={{ fontWeight: '700', color: '#000000', fontSize: '24px' }}>
            {formatNumberWithSpaces(Globalspend.toFixed(2))}$
            </h3>
            <div className="d-flex align-items-center mt-2">
              <h6 className="mb-0 px-2 py-1" style={{ backgroundColor: '#CDFFCD', borderRadius: '4px', color: '#007F00', fontWeight: '600', fontSize: '14px' }}>
                28.3% <i className="mdi mdi-arrow-up-bold" style={{ color: '#007F00' }}></i>
              </h6>
              <span style={{ color: '#B0B0B0', fontSize: '12px', marginLeft: '8px' }}>
                Than last month
              </span>
            </div>
          </div>
        </CardBody>
      </Card>
    </Col>
    <Col xl={2} className="mb-4">
      <Card className="h-100" style={{ border: '1px solid #D9D5EC', borderRadius: '6px' }}>
        <CardBody className="p-3">
          <div className="d-flex flex-column align-items-start">
            <h6 className="text-primary" style={{ fontWeight: '600', fontSize: '14px', color: '#0044BD' }}>
            Total Ads Revenue
            </h6>
            <h3 style={{ fontWeight: '700', color: '#000000', fontSize: '24px' }}>
            {formatNumberWithSpaces(globalRevenue.toFixed(2))}$
            </h3>
            <div className="d-flex align-items-center mt-2">
              <h6 className="mb-0 px-2 py-1" style={{ backgroundColor: '#CDFFCD', borderRadius: '4px', color: '#007F00', fontWeight: '600', fontSize: '14px' }}>
                28.3% <i className="mdi mdi-arrow-up-bold" style={{ color: '#007F00' }}></i>
              </h6>
              <span style={{ color: '#B0B0B0', fontSize: '12px', marginLeft: '8px' }}>
                Than last month
              </span>
            </div>
          </div>
        </CardBody>
      </Card>
    </Col>
  </Row>
</Col>     
    </div>
  )
}
StoreOverview.propTypes = {
  t: PropTypes.any
};
export default withRouter(withTranslation()(StoreOverview));

