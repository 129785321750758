import gql from 'graphql-tag';

const LOGIN = gql`
  query ($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      accessToken
    }
  }
`;
const LOGINWITHGOOGLE = gql`
  query ($token: String!) {
    loginWithGoogle(token: $token) {
      accessToken
    }
  }
`;
const AUTHENTIFICATIONSTATE = gql`
  query {
    AuthentificationState {
      isAuth
    }
  }
`;

const LOGOUT = gql`
  query {
    logout {
      isLoggedOut
    }
  }
`;
const REFRESH_TOKEN = gql`
  query ($token: String!) {
    refreshToken(accessToken: $token) {
      accessToken
    }
  }
`;

const VERIFYACCOUNT = gql`
  mutation ($id: String!, $verifCode: String!, $realCode: String!) {
    verifyAccount(id: $id, verifCode: $verifCode, realCode: $realCode) {
      accessToken
      
    }
  }
`;

const ISVERIFIED = gql`
  query ($id: String!) {
    isVerified(id: $id) {
      isVerified
    }
  }
`;

export {
  LOGIN,
  AUTHENTIFICATIONSTATE,
  LOGINWITHGOOGLE,
  VERIFYACCOUNT,
  ISVERIFIED,
  REFRESH_TOKEN,
  LOGOUT,
};
