import PropTypes from 'prop-types';
import MetaTags from 'react-meta-tags';
import React, { useEffect, useState } from 'react';
import { Row, Col, Container } from 'reactstrap';
import { Link } from 'react-router-dom';
import { Form } from 'react-final-form';
import { useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import CarouselLogin from '../AuthenticationInner/CarouselLogin';
import {
  loginRequest,
  isConnectedRequest,
  loginWithGoogleRequest,
} from '../../store/auth/actions';
import { GoogleLogin, useGoogleLogin } from '@react-oauth/google';
import Gooogleicon from '../../assets/images/google.png';
import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';

const Login = () => {
  const dispatch = useDispatch();
  const { isConnected } = useSelector((state) => state.editProfile);
  const { register, handleSubmit } = useForm();

  // State to toggle the password visibility
  const [showPassword, setShowPassword] = useState(false);

  const onSuccess = (response) => {
    if (response && response.access_token) {
      const tokenString = response.access_token;
      console.log('Google login response:', tokenString);
      dispatch(loginWithGoogleRequest({ token: tokenString }));
    } else {
      console.error(
        'Google login response is missing necessary data:',
        response
      );
    }
  };

  const onError = (error) => {
    if (error.error === 'popup_closed_by_user') {
      console.log('User closed the Google login popup.');
    } else {
      console.error('Google login failed:', error);
    }
  };

  const loginWithGoogle = useGoogleLogin({
    onSuccess,
    onError,
  });

  const onSubmit = (formData) => {
    if (formData) {
      dispatch(loginRequest(formData.email, formData.password));
    }
  };

  useEffect(() => {
    if (isConnected) window.location.replace('/integrations');
  }, [isConnected]);

  useEffect(() => {
    dispatch(isConnectedRequest());
  }, []);

  return (
    <React.Fragment>
      <MetaTags>
        <title>Login | LiveMetrics - E-com SaaS</title>
      </MetaTags>
      <div className="auth-page">
        <Container fluid className="p-0">
          <Row className="g-0">
            <Col lg={4} md={5} className="col-xxl-6 d-flex justify-content-center align-items-center" style={{width:'50%'}}>
              <div className="auth-full-page-content align-items-center d-flex" style={{ maxWidth: '560px', width: '100%', padding: '20px' }}>
                <div className="w-100">
                  <div className="d-flex flex-column h-100">
                    <div className="auth-content my-auto">
                      <div className="text-left" style={{ marginBottom: '30px' }}>
                        <h5 style={{ fontSize: '1.75rem', fontWeight: '800', color: 'black' }}>Welcome Back !</h5>
                        <span style={{ fontSize: '1rem', fontWeight: '400' }}>
                          Sign in to continue to LiveMetrics.
                        </span>
                      </div>
                      <Form onSubmit={handleSubmit(onSubmit)}>
                        {({ handleSubmit }) => (
                          <form onSubmit={handleSubmit}>
                            <label className="form-label" style={{ fontSize: '1rem' }}>Email</label>
                            <div className="mb-3">
                              <input
                                type="email"
                                name="email"
                                required
                                id="email"
                                className="form-control"
                                placeholder="Enter email"
                                {...register('email', { required: true })}
                                style={{ height: '60px', fontSize: '1rem', borderRadius: '8px', width: '100%' }}
                              />
                            </div>
                            <div className="mb-3">
                              <div className="d-flex align-items-start">
                                <div className="flex-grow-1">
                                  <label className="form-label" style={{ fontSize: '1rem' }}>Password</label>
                                </div>
                                <div className="flex-shrink-0">
                                  <div className="">
                                    <Link
                                      to="/forgot-password"
                                      className="text-muted"
                                      style={{ fontSize: '1rem' }}
                                    >
                                      Forgot password?
                                    </Link>
                                  </div>
                                </div>
                              </div>
                              <div className="mb-3 position-relative">
                                <input
                                  name="password"
                                  type={showPassword ? 'text' : 'password'} // Toggle password visibility
                                  id="password"
                                  className="form-control"
                                  {...register('password', { required: true })}
                                  required
                                  placeholder="Enter Password"
                                  style={{ height: '60px', fontSize: '1rem', borderRadius: '8px', width: '100%', paddingRight: '45px' }}
                                />
                                {/* Eye Icon */}
                                <span
                                  onClick={() => setShowPassword(!showPassword)}
                                  style={{
                                    position: 'absolute',
                                    right: '16px',
                                    top: '14px',
                                    cursor: 'pointer',
                                    fontSize: '20px',
                                    color: '#999'
                                  }}
                                >
                                  {showPassword ? (
                                    <EyeOutlined />
                                  ) : (
                                    <EyeInvisibleOutlined />
                                  )}
                                </span>
                              </div>
                            </div>
                            <div className="row mb-4">
                              <div className="col">
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="remember-check"
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="remember-check"
                                  >
                                    Remember me
                                  </label>
                                </div>
                              </div>
                            </div>
                            <div style={{ marginBottom: '12px', marginTop: '32px' }}>
                              <button
                                className="btn btn-auth w-100"
                                type="submit"
                                style={{ fontSize: '1rem', padding: '14px 0' }}
                              >
                                Log In
                              </button>
                            </div>
                          </form>
                        )}
                      </Form>

                      <button
                        onClick={loginWithGoogle}
                        className="btn btn-light w-100 waves-effect mb-2 d-flex align-items-center justify-content-center"
                        type="submit"
                        style={{
                          height: '60px',
                          color: '#4285F4',
                          backgroundColor: '#d1e9ff',
                          fontSize: '1rem',
                          fontWeight: '700',
                          borderRadius: '8px',
                        }}
                      >
                        <img
                          src={Gooogleicon}
                          alt="Google Icon"
                          height="20"
                          style={{ marginRight: '15px' }}
                        />
                        <span>Continue with Google</span>
                      </button>
                      <div className="mt-5 text-center">
                        <p className="mb-0" style={{ fontSize: '1rem', color: 'gray' }}>
                          Don't have an account ?{' '}
                          <Link
                            to="/register"
                            className="fw-semibold"
                            style={{ fontSize: '1rem', color: '#4285F4' }}
                          >
                            {' '}
                            Sign Up {' '}
                          </Link>{' '}
                        </p>
                      </div>
                    </div>
                    <div className="text-center" style={{ position: 'absolute', bottom: '30px', left: '50%', transform: 'translateX(-50%)', color: 'gray' }}>
                      <p className="mb-0" style={{fontSize:'1rem'}}>
                        © {new Date().getFullYear()} LiveMetrics. Crafted by
                        Convergen
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <CarouselLogin />
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};
export default Login;

Login.propTypes = {
  history: PropTypes.object,
};
