export const FETCH_DATA_KLAVIYO_REQUEST = 'FETCH_DATA_KLAVIYO_REQUEST';
export const FETCH_DATA_KLAVIYO_SUCCESS = 'FETCH_DATA_KLAVIYO_SUCCESS';
export const FETCH_DATA_KLAVIYO_FAILURE = 'FETCH_DATA_KLAVIYO_FAILURE';


export const CHECK_INTEGRATION_REQUEST_KLAVIYO = 'CHECK_INTEGRATION_REQUEST_KLAVIYO';
export const CHECK_INTEGRATION_SUCCESS = 'CHECK_INTEGRATION_SUCCESS';
export const CHECK_INTEGRATION_FAILURE = 'CHECK_INTEGRATION_FAILURE';

export const FETCH_METRICS_KLAVIYO_REQUEST = 'FETCH_METRICS_KLAVIYO_REQUEST';
export const FETCH_METRICS_KLAVIYO_SUCCESS = 'FETCH_METRICS_KLAVIYO_SUCCESS';
export const FETCH_METRICS_KLAVIYO_FAILURE = 'FETCH_METRICS_KLAVIYO_FAILURE';

//integration request
export const INTEGRATION_KLAVIYO_REQUEST = 'INTEGRATION_KLAVIYO_REQUEST';
export const INTEGRATION_KLAVIYO_SUCCESS = 'INTEGRATION_KLAVIYO_SUCCESS';
export const INTEGRATION_KLAVIYO_FAILURE = 'INTEGRATION_KLAVIYO_FAILURE';


// delete  integration  request
export const DELETE_INTEGRATION_KLAVIYO_REQUEST = 'DELETE_INTEGRATION_KLAVIYO_REQUEST';
export const DELETE_INTEGRATION_KLAVIYO_SUCCESS = 'DELETE_INTEGRATION_KLAVIYO_SUCCESS';
export const DELETE_INTEGRATION_KLAVIYO_FAILURE = 'DELETE_INTEGRATION_KLAVIYO_FAILURE';

//get flows
 export const FETCH_DATA_FLOW_REQUEST = 'FETCH_DATA_FLOW_REQUEST';
  export const FETCH_DATA_FLOW_SUCCESS = 'FETCH_DATA_FLOW_SUCCESS';
  export const FETCH_DATA_FLOW_FAILURE = 'FETCH_DATA_FLOW_FAILURE';




  /*display datacompaines*/ 

  export const fetchDataklaviyoRequest = (Apikey) => ({
    type: FETCH_DATA_KLAVIYO_REQUEST,
    payload:{ Apikey },

  });
  export const fetchDataklaviyoSuccess = (compaigns) => ({
    type: FETCH_DATA_KLAVIYO_SUCCESS,
    payload: {
      compaigns
    },
  })

  export const fetchDataklaviyoFailure = (error) => ({
    type: FETCH_DATA_KLAVIYO_FAILURE,
    payload: error,

  })

  /*display metrics*/
  export const fetchMetricsklaviyoRequest = (Apikey) => ({  
    type: FETCH_METRICS_KLAVIYO_REQUEST,
    payload:{ Apikey },
  });
   
  export const fetchMetricsklaviyoSuccess = (metrics) => ({
    type: FETCH_METRICS_KLAVIYO_SUCCESS,
    payload: {
      metrics
    },
  });
 export const fetchMetricsklaviyoFailure = (error) => ({
    type: FETCH_METRICS_KLAVIYO_FAILURE,
    payload: error,
 })

  /* check integration request  */
 export const checkIntegrationRequestKlaviyo = () => ({ 
  type: CHECK_INTEGRATION_REQUEST_KLAVIYO ,
});

export const checkIntegrationSuccessKlaviyo = (checkIntegration,checkIntegrationdata,apiKey) => ({ 
  type: CHECK_INTEGRATION_SUCCESS,
  payload: {checkIntegration,checkIntegrationdata,apiKey}
});
export const checkIntegrationFailureKlaviyo = (error) => ({
  type: CHECK_INTEGRATION_FAILURE,
  payload: error,
});


// integration klaviyo

export const integrationklaviyoRequest = (apiKey) => ({
  type: INTEGRATION_KLAVIYO_REQUEST,
  payload: {apiKey},
});

export const integrationklaviyoSuccess = (data) => ({
  type: INTEGRATION_KLAVIYO_SUCCESS,
  payload: {data},
})

export const integrationklaviyoFailure = (error) => ({
  type: INTEGRATION_KLAVIYO_FAILURE,
  payload: error,
})


// delete integration klaviyo

export const deleteIntegrationklaviyoRequest = () => ({
  type: DELETE_INTEGRATION_KLAVIYO_REQUEST,
})

export const deleteIntegrationklaviyoSuccess = (data) => ({
  type: DELETE_INTEGRATION_KLAVIYO_SUCCESS,
  payload: {data},
})


export const deleteIntegrationklaviyoFailure = (error) => ({
  type: DELETE_INTEGRATION_KLAVIYO_FAILURE,
  payload: error,
})
//get flows
export const fetchDataFlowRequest = (Apikey) => ({
  type: FETCH_DATA_FLOW_REQUEST,
  payload:{ Apikey },
});
export const fetchDataFlowSuccess = (flows) => ({
  type: FETCH_DATA_FLOW_SUCCESS,
  payload: {
    flows
  }
})
export const fetchDataFlowFailure = (error) => ({ 
  type: FETCH_DATA_FLOW_FAILURE,
  payload: error,
})