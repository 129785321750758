import React from 'react';

const CouponSummary = ({ coupons, t }) => {
  console.log('Coupons in summary:', coupons);

  // If there are no coupons, show a message
  if (!coupons || coupons.length === 0) {
    return <p>No coupons available</p>;
  }

  // Extract the coupon data from each item
  const extractedCoupons = coupons.map(couponWrapper => couponWrapper.coupon);

  // Sort the coupons by usage count to get the top 6
  const topCoupons = extractedCoupons
    .sort((a, b) => b.usage_count - a.usage_count) // Sort by usage_count in descending order
    .slice(0, 6); // Get the top 6

  return (
    <div className="row coupon-summary justify-content-center">
  {topCoupons.map((coupon, index) => (
    <div key={index} className="col-2 coupon-card">
      <div className="card" style={{ border: '1px solid #D9D5EC', borderRadius: '3px', textAlign: 'left',width:'200px', height:'100px', marginLeft:'20px' }}>
        <div className="card-body">
          <h5 style={{ fontSize: '13px', color: '#6F6F6F' }}>{t('Code')} : <span style={{ fontWeight: 'bold', color: '#000' }}>{coupon.code}</span></h5>
          <h5 style={{ fontSize: '13px', color: '#6F6F6F' }}>{t('Amount')} : <span style={{ fontWeight: 'bold', color: '#000' }}>{coupon.amount}</span></h5>
          <h5 style={{ fontSize: '13px', color: '#6F6F6F' }}>{t('Usage')} : <span style={{ fontWeight: 'bold', color: '#000' }}>{coupon.usage_count}</span></h5>
        </div>
      </div>
    </div>
  ))}
</div>

  );
};

export default CouponSummary;
