import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getGAUsersCompraisonRequest,
  getGAUsersRequest,
} from '../../../store/actions';
import { formatDate } from '../common/HelperFunctions/utils';

import GACardnew from '../common/GACardnew';
import { Row } from 'reactstrap';
const GAUsersOverview = () => {
  const { dateRange } = useSelector((state) => state.dateRangeReducer);

  const [usersDataDashboard, setUsersDataDashboard] = useState([]);

  const [compareUsersDataDashboard, setCompareUsersDataDashboard] = useState(
    []
  );
  const [loadingState, setLoadingState] = useState(false);
  const [userEngagement, setUserEngagement] = useState([]);
  const [users, setUsers] = useState([]);
  const [newUsers, setNewUsers] = useState([]);
  const [comparedUsers, setComparedUsers] = useState([]);
  const [comparedNewUsers, setComparedNewUsers] = useState([]);
  const [comparedUserEngagement, setComparedUserEngagement] = useState([]);
  const dispatch = useDispatch();
  const { gaUsers } = useSelector((state) => state.googleAnalytics);
  useEffect(() => {
    const newStartDate = formatDate(dateRange.selection1?.startDate);
    const newEndDate = formatDate(dateRange.selection1?.endDate);
    if (dateRange.selection2?.startDate && dateRange.selection2?.endDate) {
      const newStartDate2 = formatDate(dateRange.selection2?.startDate);
      const newEndDate2 = formatDate(dateRange.selection2?.endDate);
      dispatch(
        getGAUsersCompraisonRequest(
          newStartDate,
          newEndDate,
          newStartDate2,
          newEndDate2
        )
      );
    } else dispatch(getGAUsersRequest(newStartDate, newEndDate));
  }, [dateRange]);
  useEffect(() => {
    if (gaUsers.length > 0) {
      const sortedData = gaUsers[0].sort(
        (a, b) => new Date(a.day) - new Date(b.day)
      );
      setUsersDataDashboard(sortedData);
      setCompareUsersDataDashboard([]);
      if (gaUsers.length > 1) {
        const sortedData2 = gaUsers[1].sort(
          (a, b) => new Date(a.day) - new Date(b.day)
        );
        setCompareUsersDataDashboard(sortedData2);
      }
      setLoadingState(true);
    }
  }, [gaUsers]);

  useEffect(() => {
    let isSubscribed = true;
    var TUserEngagement = [];
    var Tusers = [];
    var TnewUsers = [];
    if (usersDataDashboard.length !== 0) {
      usersDataDashboard.forEach((elem) => {
        // Calculate user engagement per user
        const users = elem.users ? elem.users : 0;
        const userEngagement = elem.userEngagementDuration
          ? elem.userEngagementDuration
          : 0;
        const engagementPerUser = users > 0 ? userEngagement / users : 0;

        TUserEngagement.push(engagementPerUser);

        Tusers.push(users);
        TnewUsers.push(elem.newUsers ? elem.newUsers : 0);
      });
    }
    setUserEngagement(TUserEngagement);
    setUsers(Tusers);
    setNewUsers(TnewUsers);

    return () => (isSubscribed = false);
  }, [usersDataDashboard]);

  useEffect(() => {
    var TComparedUserEngagement = [];
    var TComparedusers = [];
    var TComparednewUsers = [];
    if (compareUsersDataDashboard.length !== 0) {
      compareUsersDataDashboard.forEach((elem) => {
        // Calculate user engagement per user
        const users = elem.users ? elem.users : 0;
        const userEngagement = elem.userEngagementDuration
          ? elem.userEngagementDuration
          : 0;
        const engagementPerUser = users > 0 ? userEngagement / users : 0;

        TComparedUserEngagement.push(engagementPerUser);

        TComparedusers.push(users);
        TComparednewUsers.push(elem.newUsers ? elem.newUsers : 0);
      });
    }
    setComparedUserEngagement(TComparedUserEngagement);
    setComparedUsers(TComparedusers);
    setComparedNewUsers(TComparednewUsers);
  }, [compareUsersDataDashboard]);

  const [myCards, setMyCards] = useState([
    { id: 1, name: 'Users', data: users, compareData: comparedUsers },
    {
      id: 2,
      name: 'New Users',
      data: newUsers,
      compareData: comparedNewUsers,
    },
    {
      id: 3,
      name: 'User Engagement',
      data: userEngagement,compareData: comparedUserEngagement,
    },
  ]);

  useEffect(() => {
    setMyCards([
      { id: 1, name: 'Users', data: users, compareData: comparedUsers },
      {
        id: 2,
        name: 'New Users',
        data: newUsers,
        compareData: comparedNewUsers,
      },
      {
        id: 3,
        name: 'User Engagement',
        data: userEngagement,
        compareData: comparedUserEngagement,
      },
    ]);
  }, [
    users,
    newUsers,
    userEngagement,
    comparedUsers,
    comparedNewUsers,
    comparedUserEngagement,
  ]);
  

  return (
    <Row className=" justify-content-center my-5">
      <GACardnew data={myCards}  />
    </Row>
  );
};

export default GAUsersOverview;
