import React, { useState } from 'react'
import ApolloClient from 'apollo-boost';
import urls from '../../../routes/apiUrls';
import { toggleShop } from '../../../queries/PrestaShop/queries';
import { Tr } from "react-super-responsive-table"

const ApploCLI = new ApolloClient({
    uri: urls.shopify
});
function ShopifyStore(props){
    const [isActive, setIsActive] = useState(props.element.active ? true : false);
    const toggleStore = (shopId) => {
        ApploCLI.mutate({
            mutation: toggleShop,
            variables: {
                shop_id: shopId
            },
            context: {
                headers: {
                    Authorization: window.localStorage.getItem("token") ? `Bearer ${window.localStorage.getItem("token")}` : ""
                }
            },
            errorPolicy: 'all',
        }).then((data) => {
            if (data.data.toggle) {
                window.location.replace('/integrations')
            }
        })
    }
    return (
        <Tr className="">
            <td className="">{props.element.shop_id}</td>
            <td className="">{props.element.name}</td>
            <td>
            <div className="form-check form-switch mb-3" dir="ltr">
                                            <input type="checkbox" className="form-check-input" id="customSwitch1" defaultChecked />
                                            <label className="form-check-label" htmlFor="customSwitch1" checked={isActive} onChange={(e, data) => { toggleStore(props.element.id);setIsActive(!isActive) }} ></label>
                                        </div>
            </td> 
        </Tr>
    )
}
export default ShopifyStore;