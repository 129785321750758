import React from 'react';

const Spinner = ({ loading }) => {
  return (
    loading && (
      <div
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundColor: 'rgba(255, 255, 255, 0.5)',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          zIndex: 1000,
          pointerEvents: 'none',
        }}
      >
        <div className="spinner-border" role="status">
        </div>
      </div>
    )
  );
};

export default Spinner;
