export const GET_ALL_TRACKING_STATUS_REQUEST = 'GET_ALL_TRACKING_STATUS_REQUEST';
export const GET_ALL_TRACKING_STATUS_SUCCESS = 'GET_ALL_TRACKING_STATUS_SUCCESS';
export const GET_ALL_TRACKING_STATUS_FAILURE = 'GET_ALL_TRACKING_STATUS_FAILURE';


export const FETCH_DATA_REQUEST = 'FETCH_DATA_REQUEST';
export const FETCH_DATA_SUCCESS = 'FETCH_DATA_SUCCESS';
export const FETCH_DATA_FAILURE = 'FETCH_DATA_FAILURE';


export const CHECK_INTEGRATION_REQUEST = 'CHECK_INTEGRATION_REQUEST';
export const CHECK_INTEGRATION_SUCCESS = 'CHECK_INTEGRATION_SUCCESS';
export const CHECK_INTEGRATION_FAILURE = 'CHECK_INTEGRATION_FAILURE';

// delete integration amarex
export const DELETE_INTEGRATION_ARAMEX_REQUEST = 'DELETE_INTEGRATION_ARAMEX_REQUEST';
export const DELETE_INTEGRATION_ARAMEX_SUCCESS = 'DELETE_INTEGRATION_ARAMEX_SUCCESS';
export const DELETE_INTEGRATION_ARAMEX_FAILURE = 'DELETE_INTEGRATION_ARAMEX_FAILURE';

// integration amarex
export const INTEGRATION_ARAMEX_REQUEST = 'INTEGRATION_ARAMEX_REQUEST';
export const INTEGRATION_ARAMEX_SUCCESS = 'INTEGRATION_ARAMEX_SUCCESS';
export const INTEGRATION_ARAMEX_FAILURE = 'INTEGRATION_ARAMEX_FAILURE';



/*display data*/ 
export const fetchDataRequest = (limit, page) => ({
  type: FETCH_DATA_REQUEST,
  payload:{ limit, page },
});

export const fetchDataSuccess = (trackingNStatus, shipmentChargesPaid, supportingDocumentReturnedtoShipper, returnedtoShipper, delivered,emailofreturnedshipper) => ({
  type: FETCH_DATA_SUCCESS,
  payload: {
    trackingNStatus,
    ShipmentChargesPaid: shipmentChargesPaid,
    SupportingDocumentReturnedtoShipper: supportingDocumentReturnedtoShipper,
    ReturnedtoShipper: returnedtoShipper,
    Delivered: delivered,
    emailofreturnedshipper: emailofreturnedshipper,
  },
});

export const fetchDataFailure = (error) => ({
  type: FETCH_DATA_FAILURE,
  payload: error,
});

/* get all tracking status request  */

export const getAllTrackingStatusRequest = () => ({
  type: GET_ALL_TRACKING_STATUS_REQUEST,
});

export const getAllTrackingStatusSuccess = (data) => ({
  type: GET_ALL_TRACKING_STATUS_SUCCESS,
  payload: data,
});

export const getAllTrackingStatusFailure = (error) => ({
  type: GET_ALL_TRACKING_STATUS_FAILURE,
  payload: error,
});

 /* check integration request  */

export const checkIntegrationRequest = () => ({ 
  type: CHECK_INTEGRATION_REQUEST ,
});

export const checkIntegrationSuccess = (checkIntegration,checkIntegrationdata) => ({ 
  type: CHECK_INTEGRATION_SUCCESS,
  payload: {checkIntegration,checkIntegrationdata}
});
export const checkIntegrationFailure = (error) => ({
  type: CHECK_INTEGRATION_FAILURE,
  payload: error,
});


/* delete integration amarex request  */

export const deleteIntegrationAramexRequest = () => ({
  type: DELETE_INTEGRATION_ARAMEX_REQUEST,

});

export const deleteIntegrationAramexSuccess = (data) => ({
  type: DELETE_INTEGRATION_ARAMEX_SUCCESS,
  payload: data,
});

export const deleteIntegrationAramexFailure = (error) => ({
  type: DELETE_INTEGRATION_ARAMEX_FAILURE,
})

/* integration amarex request  */

export const integrationAramexRequest = (UserName,Password,AccountNumber,AccountPin,AccountEntity,AccountCountryCode) => ({
  type: INTEGRATION_ARAMEX_REQUEST,
  payload: {UserName,Password,AccountNumber,AccountPin,AccountEntity,AccountCountryCode},
});

export const integrationAramexSuccess = (data) => ({

  type: INTEGRATION_ARAMEX_SUCCESS,
  payload: data,
})

export const integrationAramexFailure = (error) => ({
  type: INTEGRATION_ARAMEX_FAILURE,
  payload: error,
})


