import React, { useEffect, useState, useRef } from 'react';
import { FontSizeOutlined, SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import { Card, CardBody, Col, Row } from 'reactstrap';
import { format } from 'date-fns';
import { withTranslation } from 'react-i18next';
import { DownOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { getFacebookCampaignsPerDate } from '../../store/facebookAds/actions';
import {
  Button,
  Space,
  Table,
  Menu,
  Dropdown,
  Input,
  Checkbox,
  Select,
  Tooltip,
} from 'antd';
import FeatherIcon from 'feather-icons-react';
import { CloseOutlined } from '@ant-design/icons';
import Breadcrumbs from '../../components/Common/Breadcrumb';

const FacebbokAdsIcon = `${process.env.PUBLIC_URL}/images/integrations_images/AD8.png`;

const FacebookAdsOverview = (props) => {
  const dispatch = useDispatch();
  const { metrcisbyCompaign } = useSelector((state) => state.facebookAds);
  let CPA, AOV, CR, ROAS, CPC, CTR;
  if (props.facebook) {
    CPA = props.facebook.totalCost / props.facebook.totalConversions;
    AOV = props.facebook.totalconversionValue / props.facebook.totalConversions;
    CR =
      (props.facebook.totalConversions / props.facebook.totalLandingPageViews) *
      100;
    ROAS = props.facebook.totalconversionValue / props.facebook.totalCost;
    CPC = props.facebook.totalCost / props.facebook.totalClicks;
    CTR = (props.facebook.totalClicks / props.facebook.totalImpressions) * 100;
  }
  const formattedDateRange = {
    selection1: {
      startDate: format(
        new Date(props.dateRange.selection1.startDate),
        'yyyy-MM-dd'
      ),
      endDate: format(
        new Date(props.dateRange.selection1.endDate),
        'yyyy-MM-dd'
      ),
      key: props.dateRange.selection1.key,
    },
  };
  // get facebook campaign data
  useEffect(() => {
    dispatch(
      getFacebookCampaignsPerDate(
        formattedDateRange.selection1.startDate,
        formattedDateRange.selection1.endDate
      )
    );
  }, [props.dateRange]);

  const [filteredInfo, setFilteredInfo] = useState({});
  const [sortedInfo, setSortedInfo] = useState({});
  const [searchText, setSearchText] = useState('');
  const [paginationOptions, setPaginationOptions] = useState({
    pageSize: 5, // Default page size
    pageSizeOptions: ['5', '10', '20'], // Available page size options
  });
  const handleChange = (pagination, filters, sorter) => {
    setFilteredInfo(filters);
    setSortedInfo(sorter);
    if (pagination.pageSize !== paginationOptions.pageSize) {
      setPaginationOptions({
        ...paginationOptions,
        pageSize: pagination.pageSize,
      });
    }
  };
  const clearFilters = () => {
    setFilteredInfo({});
  };
  const clearAll = () => {
    setFilteredInfo({});
    setSortedInfo({});
    // make the columns selected
    setSelectedColumns(columns.map((column) => column.key));
  };
  const setAgeSort = () => {
    setSortedInfo({
      order: 'descend',
      columnKey: 'impressions',
    });
  };
  // Utility function to format numbers with spaces
  const formatNumberWithSpaces = (value) => {
    if (value !== undefined && value !== null) {
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    }
    return ''; // or any default value you want to return for undefined/null cases
  };
  const columns = [
    {
      title: 'STATUS',
      dataIndex: 'status',
      key: 'status',
      align: 'center',
      width: 100,
      render: (status) => (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: status === 'ACTIVE' ? '#007F00' : 'grey',
            backgroundColor: status === 'ACTIVE' ? '#CDFFCD' : '#fff0f6',
            fontWeight: status === 'ACTIVE' ? 'bold' : 'normal',
            opacity: status === 'ACTIVE' ? 1 : 0.6,
            borderRadius: '12.67px',
          }}
        >
          <span
            style={{
              width: '10px',
              height: '10px',
              borderRadius: '50%',
              display: 'inline-block',
              backgroundColor: status === 'ACTIVE' ? 'green' : 'grey',
              marginRight: '8px',
            }}
          ></span>
          {status === 'ACTIVE' ? 'Active' : 'Inactive'}
        </div>
      ),
    },
    {
      title: props.t('NAME'),
      dataIndex: 'campaign_name',
      key: 'campaign_name',
      align: 'center',
      width: 200,
      render: (text) => (
        <span className='table-cell-text'>{text}</span>
  
    ),
    },
    {
      title: props.t('SPEND'),
      dataIndex: 'spend',
      align: 'center',
      key: 'spend',
      sorter: (a, b) => a.spend - b.spend,
      sortOrder: sortedInfo.columnKey === 'spend' ? sortedInfo.order : null,
      ellipsis: true,
      render: (text) => <strong className='table-cell-text'>{text.toFixed(2)}$</strong>,
    },
    {
      title: props.t('REVENUE'),
      dataIndex: 'action_values',
      key: 'action_values',
      sorter: (a, b) => a.action_values - b.action_values,
      sortOrder:
        sortedInfo.columnKey === 'action_values' ? sortedInfo.order : null,
      ellipsis: true,
      align: 'center',
      render: (text) => <strong className='table-cell-text'>{text.toFixed(2)}$</strong>,
    },
    {
      title: props.t('CONVERSIONS'),
      dataIndex: 'actions',
      align: 'center',
      key: 'actions',
      sorter: (a, b) => a.actions - b.actions,
      sortOrder: sortedInfo.columnKey === 'actions' ? sortedInfo.order : null,
      ellipsis: true,
      render: (text) => <strong className='table-cell-text'>{text}</strong>,
      width: 150,
    },
    {
      title: props.t('IMPRESSIONS'),
      dataIndex: 'impressions',
      key: 'impressions',
      sorter: (a, b) => a.impressions - b.impressions,
      sortOrder:
        sortedInfo.columnKey === 'impressions' ? sortedInfo.order : null,
      ellipsis: true,
      align: 'center',
      render: (text) => <strong className='table-cell-text'>{formatNumberWithSpaces(text)}</strong>,
      width: 150,
    },
    {
      title: props.t('CLICKS'),
      dataIndex: 'clicks',
      key: 'Clicks',
      sorter: (a, b) => a.clicks - b.clicks,
      sortOrder: sortedInfo.columnKey === 'clicks' ? sortedInfo.order : null,
      ellipsis: true,
      align: 'center',
      render: (text) => <strong className='table-cell-text'>{formatNumberWithSpaces(text)}</strong>,
      width: 100,
    },
    {
      title: props.t('REACH'),
      dataIndex: 'reach',
      key: 'reach',
      align: 'center',
      sorter: (a, b) => a.reach - b.reach,
      sortOrder: sortedInfo.columnKey === 'reach' ? sortedInfo.order : null,
      ellipsis: true,
      render: (text) => <strong className='table-cell-text'>{formatNumberWithSpaces(text)}</strong>,
    },
    {
      title: 'CTR',
      dataIndex: 'ctr',
      align: 'center',
      key: 'ctr',
      sorter: (a, b) => a.clicks / a.impressions - b.clicks / b.impressions,
      sortOrder: sortedInfo.columnKey === 'ctr' ? sortedInfo.order : null,
      ellipsis: true,
      render: (text, record) => {
        const ctrValue = (record.clicks / record.impressions) * 100;
        return <strong className='table-cell-text'>{isNaN(ctrValue) ? '0' : ctrValue.toFixed(2)}%</strong>;
      },
    },
    {
      title: 'CPA',
      dataIndex: 'cpa',
      align: 'center',
      key: 'cpa',
      sorter: (a, b) => a.cpa - b.cpa,
      sortOrder: sortedInfo.columnKey === 'cpa' ? sortedInfo.order : null,
      ellipsis: true,
      render: (text) => <strong className='table-cell-text'>{text.toFixed(2)}$</strong>,
    },
    {
      title: 'CPC',
      dataIndex: 'cpc',
      key: 'cpc',
      align: 'center',
      sorter: (a, b) => a.cpc - b.cpc,
      sortOrder: sortedInfo.columnKey === 'cpc' ? sortedInfo.order : null,
      ellipsis: true,
      render: (text, record) => {
        const floatValue = parseFloat(text);
        return (
          <strong className='table-cell-text'>
            {isNaN(floatValue) ? '-' : `$${floatValue.toFixed(2)}`}
          </strong>
        );
      },
    },
    // {
    //   title: props.t("PageView"),
    //   dataIndex: 'landingPageViewAction',
    //   align: 'center',
    //   key: 'landingPageViewAction',
    //   sorter: (a, b) => a.landingPageViewAction - b.landingPageViewAction,
    //   sortOrder:
    //     sortedInfo.columnKey === 'landingPageViewAction'
    //       ? sortedInfo.order
    //       : null,
    //   ellipsis: true,
    //   render: (text) => <strong>{text}</strong>,
    // },
  ];
  const [selectedColumns, setSelectedColumns] = useState(
    columns.map((column) => column.key)
  );

  const handleMenuClick = (clickedKey) => {
    const index = selectedColumns.indexOf(clickedKey);

    let updatedSelectedColumns = [];

    if (index === -1) {
      // Column was not selected before, add it to the selected columns
      updatedSelectedColumns = [...selectedColumns, clickedKey];
    } else {
      // Column was selected before, remove it from the selected columns
      updatedSelectedColumns = selectedColumns.filter(
        (key) => key !== clickedKey
      );
    }

    setSelectedColumns(updatedSelectedColumns);
  };

  const filteredColumns = columns.filter((column) =>
    selectedColumns.includes(column.key)
  );
  const handleSelectAll = () => {
    if (selectedColumns.length === columns.length) {
      // Deselect all
      setSelectedColumns([]);
    } else {
      // Select all
      setSelectedColumns(columns.map((column) => column.key));
    }
  };

  const isAllSelected = selectedColumns.length === columns.length;
  const menu = (
    <Menu>
      <Menu.Item key="select-all" onClick={handleSelectAll}>
        <Checkbox checked={isAllSelected} style={{ marginRight: '8px' }} />
        <span style={{ color: 'black' }}>All</span>
      </Menu.Item>
      {columns.map((column) => (
        <Menu.Item key={column.key} onClick={() => handleMenuClick(column.key)}>
          <Checkbox
            checked={selectedColumns.includes(column.key)}
            style={{ marginRight: '8px' }}
          />
          <span style={{ color: 'black' }}>{column.title}</span>
        </Menu.Item>
      ))}
    </Menu>
  );

  const [statusFilter, setStatusFilter] = useState({
    ENABLED: false,
    PAUSED: false,
  });

  const [filteredData, setFilteredData] = useState(metrcisbyCompaign);

  const handleSearchInputChange = (e) => {
    const value = e.target.value.toLowerCase();
    setSearchText(value);

    const filtered = metrcisbyCompaign.filter((item) =>
      item.campaign_name.toLowerCase().includes(value)
    );

    setFilteredData(filtered);
  };

  // new filter
  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;

    const updatedFilter = {
      ...statusFilter,
      [name]: checked,
    };

    setStatusFilter(updatedFilter);

    const selectedStatuses = Object.keys(updatedFilter).filter(
      (key) => updatedFilter[key]
    );

    const filtered = metrcisbyCompaign.filter((item) =>
      selectedStatuses.length > 0
        ? selectedStatuses.includes(item.status)
        : true
    );

    setFilteredData(filtered);
  };

  // Menu for the dropdown with checkboxes
  const menufilter = (
    <Menu>
      <Menu.Item key="ENABLED">
        <Checkbox
          name="ACTIVE"
          checked={statusFilter.ENABLED}
          onChange={handleCheckboxChange}
        >
          ENABLED
        </Checkbox>
      </Menu.Item>
      <Menu.Item key="PAUSED">
        <Checkbox
          name="PAUSED"
          checked={statusFilter.PAUSED}
          onChange={handleCheckboxChange}
        >
          PAUSED
        </Checkbox>
      </Menu.Item>
    </Menu>
  );

  // Cards
  const [selectedGroups, setSelectedGroups] = useState([]);
  const [hiddenCards, setHiddenCards] = useState([]);

  const handleHideCard = (cardIndex) => {
    setHiddenCards((prevHidden) => [...prevHidden, cardIndex]);
  };

  const handleSelectGroup = (selectedKey) => {
    const selectedOption = optionGroup.find(
      (option) => option.key === selectedKey
    );
    if (
      selectedOption &&
      !selectedGroups.find((group) => group.key === selectedKey)
    ) {
      setSelectedGroups((prevGroups) => [...prevGroups, selectedOption]);
    }
  };

  const MetricCard = ({ selectedGroup, cardIndex, handleHideCard }) => {
    return (
      <Col xl={2} md={4} key={selectedGroup.key} className="mb-4">
        <Card className="text-center">
          <CardBody style={{ padding: '20px' }}>
            <div className="my-0 text-primary d-flex justify-content-between align-items-center">
              <h5 style={{ color: '#0044BD', fontSize: '16px', margin: 0 }}>
                {selectedGroup.label}
              </h5>
              <Tooltip title="Delete Metric">
                <CloseOutlined
                  onClick={() => handleHideCard(cardIndex)}
                  style={{ cursor: 'pointer', fontSize: '12px' }}
                />
              </Tooltip>
            </div>
            <Row className="justify-content-center">
              <Col xs={12} className="d-flex justify-content-center">
                <div className="mt-3">
                  <h4
                    style={{ fontSize: '24px', fontWeight: 'bold', margin: 0 }}
                  >
                    {selectedGroup.value}
                  </h4>
                </div>
              </Col>
            </Row>
            <Row className="justify-content-center">
              <Col xs={12} className="d-flex justify-content-center">
                <div className="mt-3">
                  <span
                    style={{
                      display: 'inline-block',
                      backgroundColor: '#E6F7E6',
                      color: '#52C41A',
                      padding: '2px 6px',
                      borderRadius: '4px',
                      marginRight: '6px',
                    }}
                  >
                    28.3% ↑
                  </span>
                  <span style={{ color: '#BFBFBF', fontSize: '14px' }}>
                    Than last month
                  </span>
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>
    );
  };

  const optionGroup = [
    {
      key: 'spend',
      label: 'Spend',
      value: props.facebook
        ? `$${formatNumberWithSpaces(props.facebook.totalCost.toFixed(2) || 0)}`
        : '$0',
    },
    {
      key: 'Revenue',
      label: 'Revenue',
      value: props.facebook
        ? `$${formatNumberWithSpaces(
            props.facebook.totalconversionValue.toFixed(2) || 0
          )}`
        : '0',
    },
    {
      key: 'Clicks',
      label: 'Clicks',
      value: props.facebook
        ? formatNumberWithSpaces(props.facebook.totalClicks || 0)
        : '0',
    },
    {
      key: 'Conversions',
      label: 'Conversions',
      value: props.facebook
        ? formatNumberWithSpaces(props.facebook.totalConversions || 0)
        : '0',
    },
    {
      key: 'Impressions',
      label: 'Impressions',
      value: props.facebook
        ? formatNumberWithSpaces(props.facebook.totalImpressions || 0)
        : '0',
    },
    {
      key: 'Reach',
      label: 'Reach',
      value: props.facebook
        ? formatNumberWithSpaces(props.facebook.totalReach || 0)
        : '0',
    },
    {
      key: 'CPA',
      label: 'CPA',
      value: props.facebook && isFinite(CPA) ? `$${CPA.toFixed(2)}` : '$0',
    },
    {
      key: 'CPC',
      label: 'CPC',
      value:
        props.facebook && isNaN(CPC) === false ? `$${CPC.toFixed(2)}` : '$0',
    },
    {
      key: 'AOV',
      label: 'AOV',
      value:
        props.facebook && isNaN(AOV) === false ? `$${AOV.toFixed(2)}` : '$0',
    },
    {
      key: 'CR',
      label: 'CR%',
      value: props.facebook && isNaN(CR) === false ? `${CR.toFixed(2)}%` : '0',
    },
    {
      key: 'ROAS',
      label: 'ROAS',
      value: props.facebook ? `${ROAS.toFixed(2)}` : '0',
    },
  ];

  return (
    <div className="mt-5">
      <Col xl={12}>
      
         {/* <div className="row mb-4">
          <div className="col">
            <h5 className="card-title d-flex align-items-center">
            <img
                className="img-overview-dashboard ms-2"
                src={FacebbokAdsIcon}
              />
              <h6 className="ms-2"  style={{ fontWeight: '700', color: '#000000' }}> {props.t(' Facebook Ads performance')}</h6>
            </h5>
          </div>
        </div> */} 
            <Breadcrumbs
              image={FacebbokAdsIcon}
              title="Facebook Ads performance"
            />

        <Row className="justify-content-center">
          {selectedGroups.map(
            (selectedGroup, index) =>
              !hiddenCards.includes(index) && (
                <MetricCard
                  key={index}
                  selectedGroup={selectedGroup}
                  cardIndex={index}
                  handleHideCard={handleHideCard}
                />
              )
          )}

          {/* Customize your metrics card */}
          <Col xl={2} md={4}>
            <Card
              className="text-center"
              style={{
                margin: '7px',
                border: '2px dashed #D3D3D3', // Dashed border like in the image
                borderRadius: '8px', // Rounded corners
                padding: '20px',
              }}
            >
              <div className="my-0 d-flex flex-column align-items-center">
                {/* Title styling */}
                <h5
                  className="mb-2"
                  style={{
                    color: '#0044BD',
                    fontWeight: '700',
                    fontSize: '13px',
                  }}
                >
                  {props.t('Customize your metrics')}
                </h5>

                {/* Dropdown Select */}
                <Select
                  placeholder={
                    <span>
                      {/* Plus sign icon */}
                      {props.t('Select metrics')}
                    </span>
                  }
                  onChange={(selectedKey) => handleSelectGroup(selectedKey)}
                  style={{
                    width: '100%',
                    borderColor: 'initial',
                    backgroundColor: '#E9E9EF', // Light gray background for dropdown
                    fontWeight: 'bold',
                    //  borderRadius: "8px", // Rounded corners for the dropdown
                  }}
                  dropdownStyle={{ zIndex: 9999 }}
                >
                  {optionGroup.map((option, index) => (
                    <Select.Option key={index} value={option.key}>
                      {option.label}
                    </Select.Option>
                  ))}
                </Select>
              </div>
            </Card>
          </Col>
        </Row>

        <Row>
          <Card style={{ border: 'none' }}>
         
            {/* <div className="row mb-4">
          <div className="col">
            <h5 className="card-title d-flex align-items-center">
            <img
                className="img-overview-dashboard ms-2"
                src={FacebbokAdsIcon}
              />
              <h6 className="ms-2"  style={{ fontWeight: '700', color: '#000000' }}>  {props.t('Facebook Ads campaigns')}</h6>
            </h5>
          </div>
        </div> */}
         <Breadcrumbs
              image={FacebbokAdsIcon}
              title="Facebook Ads campaigns"
            />
            <Space
              style={{
                marginBottom: 16,
              }}
            >
              <Dropdown overlay={menu} trigger={['click']}>
                <Button className="custom-button">
                  {props.t('Select metrics')} <DownOutlined />{' '}
                </Button>
              </Dropdown>
              <Button className="custom-button" onClick={setAgeSort}>
                {props.t('Sort Impressions')}
              </Button>
              <Button onClick={clearFilters} className="custom-button">
                {props.t('Clear filters')}
              </Button>
              <Button onClick={clearAll} className="custom-button">
                {props.t('Clear filters and sorters')}
              </Button>
            </Space>
            <div className="table-border">
              <div
                className="d-flex align-items-center"
                style={{ padding: '20px' }}
              >
                <Dropdown overlay={menufilter} trigger={['click']}>
                  <Button
                    className="custom-button"
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      padding: '22px',
                    }}
                  >
                    <FeatherIcon
                      icon="filter"
                      size="16"
                      style={{ marginRight: '8px' }}
                    />
                    {props.t('Filter')}
                  </Button>
                </Dropdown>

                <div
                  className="search-box"
                  style={{
                    flex: 1,
                    maxWidth: '523px',
                    position: 'relative',
                    backgroundColor: '#F4F2FF',
                    borderRadius: '6px',
                    padding: '10px 15px',
                    marginLeft: '16px',
                  }}
                >
                  <Input
                    placeholder={props.t('Campaign Name')}
                    value={searchText}
                    onChange={handleSearchInputChange}
                    className="custom-search-input"
                  />
                  <i
                    className="bx bx-search-alt search-icon"
                    style={{
                      position: 'absolute',
                      left: '10px',
                      top: '50%',
                      transform: 'translateY(-50%)',
                      color: '#7a7a7a',
                    }}
                  />
                </div>

                <Link
                  to="/ads-dashboard"
                  className="btn btn-primary btn-sm viewMetrics-button"
                >
                  {props.t('View full metrics')}
                </Link>
              </div>

              <Table
                columns={filteredColumns}
                dataSource={filteredData} // Use filtered data here
                onChange={handleChange}
                pagination={{
                  ...paginationOptions,
                  onChange: handleChange,
                  showSizeChanger: true,
                  style: {
                    backgroundColor: '#F4F2FF',
                    padding: '10px 15px',
                    marginTop: '2px',
                    borderRadius: '6px',
                    fontsize: '14px',
                    fontweight: '700',
                    color: '#6E6893',
                  },
                }}
                headerCellStyle={{
                  backgroundColor: '#FF0000', // Change this to your desired color
                  color: '#333333', // Text color
                }}
              />
            </div>
          </Card>
        </Row>
      </Col>
    </div>
  );
};

FacebookAdsOverview.propTypes = {
  t: PropTypes.any,
};

export default withRouter(withTranslation()(FacebookAdsOverview));
