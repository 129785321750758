import { from } from "apollo-boost";
import {
    GET_FACEBOOKPAGE_DATA_PER_DATE,
    GET_FACEBOOKPAGE_DATA_PER_DATE_SUCCESS,
    GET_FACEBOOKPAGE_DATA_PER_DATE_FAILURE,
    GET_FACEBOOKPAGE_POST_DATA,
    GET_FACEBOOKPAGE_POST_DATA_SUCCESS,
    GET_FACEBOOKPAGE_POST_DATA_FAILURE,
} from './actions';

const initialState = {

    facebookPageData: [],
    loading: false,
    error: null,
    facebookPagePostData: [],

};


export default function facebookPage(state = initialState, action) {
    switch(action.type){
        case GET_FACEBOOKPAGE_DATA_PER_DATE:
            return {
                ...state,
                loading: true,
            }
        case GET_FACEBOOKPAGE_DATA_PER_DATE_SUCCESS:
            return {
                ...state,
                facebookPageData: action.payload.facebookPageData,
                loading: false,
            }
        case GET_FACEBOOKPAGE_DATA_PER_DATE_FAILURE:
            return {
                ...state,
                error: action.payload,
                loading: false,
            }

        case GET_FACEBOOKPAGE_POST_DATA:
            return {
                ...state,
                loading: true,
            }
        case GET_FACEBOOKPAGE_POST_DATA_SUCCESS:
            return {
                ...state,
                facebookPagePostData: action.payload.facebookPagePostData,
                loading: false,
            }
        case GET_FACEBOOKPAGE_POST_DATA_FAILURE:
            return {
                ...state,
                error: action.payload,
                loading: false,
            }
            
        default:
            return state;
    }
}