import React, { useEffect, useState } from 'react';
import MetaTags from 'react-meta-tags';
import CountUp from 'react-countup';
import { Link, withRouter } from 'react-router-dom';
import FeatherIcon from 'feather-icons-react';
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Table,
  CardHeader,
  CardTitle,
  CardSubtitle,
} from 'reactstrap';
import { isEmpty, map } from 'lodash';
import SplineArea from './Rapport-charts/ChartRapport';
import Revnuechart from './Rapport-charts/chart2Rapport';
import secureSection from '../../MangeSecurity/MemberSecurity';
import Conversionchart from './Rapport-charts/conversionchart';
//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
//Import Image
import logo from '../../assets/images/Live_Metrics_Logo.png';
//import { getInvoiceDetail as onGetInvoiceDetail } from "../../store/invoices/actions"
//redux
import { useSelector, useDispatch } from 'react-redux';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
const GoogleAdsIcon = `${process.env.PUBLIC_URL}/images/integrations_images/GoogleADS.svg`;
const InvoiceDetail = (props) => {
  secureSection();
  const [selectedImage, setSelectedImage] = useState(null);
  const currencyrevenue = localStorage.getItem('google_revenue_currency');
  // Function to handle image selection

  useEffect(() => {
    // Check localStorage for a previously selected image
    const storedImage = localStorage.getItem('selectedImage');
    if (storedImage) {
      setSelectedImage(storedImage);
    }
  }, []);
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (event) => {
        const imageDataURL = event.target.result;

        // Save the selected image URL to localStorage
        localStorage.setItem('selectedImage', imageDataURL);

        setSelectedImage(imageDataURL);
      };
      reader.readAsDataURL(file);
    }
  };

  const dispatch = useDispatch();
  const { DataDashboard, gaKeywords } = useSelector((state) => state.GoogleAds);

  const valuecurrency = JSON.parse(
    localStorage.getItem('google_currency_revenue_rate')
  );

  const exportToPDF = async () => {
    const capture = document.querySelector('.print');

    html2canvas(capture).then((canvas) => {
      const imgData = canvas.toDataURL('img/png');
      const doc = new jsPDF('p', 'mm', 'a4');
      const componentWidth = doc.internal.pageSize.getWidth();
      const componentHeight = doc.internal.pageSize.getHeight();

      const padding = 10; // Adjust this value to set the padding in millimeters

      // Add padding to X and Y coordinates
      doc.addImage(
        imgData,
        'PNG',
        1 + padding,
        padding,
        210 - 2 * padding,
        componentHeight - 2 * padding
      );

      doc.save('Rapport-Googl-Ads.pdf');
    });
  };
  const invoiceDate = new Date();

  const {
    match: { params },
  } = props;
  const filteredGaKeywords = gaKeywords?.gaKeywords?.filter(
    (gaKeyword) => !isNaN(gaKeyword.conversions / gaKeyword.cost)
  );

  const sortedGaKeywords = filteredGaKeywords?.sort(
    (a, b) => b.conversions / b.cost - a.conversions / a.cost
  );

  //Print the Invoice
  const printInvoice = () => {
    window.print();
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>
            {props.t('Invoice Detail')} | Convergen-Metrics - E-com SaaS
          </title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs
            title={props.t('Invoices')}
            breadcrumbItem={props.t('Google Ads Report')}
          />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <div className="print">
                    <div className="invoice-title">
                      <div className="d-flex align-items-start">
                        <div className="flex-grow-1">
                          <div className="">
                            <div
                              style={{ marginRight: '-10px' }}
                              className="flex-grow-1"
                            >
                              {/* Input element for uploading an image */}
                              <input
                                type="file"
                                id="imageUpload"
                                accept="image/*"
                                style={{ display: 'none' }}
                                onChange={handleImageChange}
                              />
                              {selectedImage ? (
                                <img
                                  src={selectedImage}
                                  alt="Selected Image"
                                  style={{
                                    maxWidth: '200px',
                                    maxHeight: '200px',
                                    cursor: 'pointer',
                                  }}
                                  onClick={() =>
                                    document
                                      .getElementById('imageUpload')
                                      .click()
                                  }
                                />
                              ) : (
                                <label
                                  htmlFor="imageUpload"
                                  className="btn btn-primary waves-effect waves-light w-sm"
                                >
                                  <i className="mdi mdi-upload d-block font-size-16 text-white"></i>
                                  {props.t('Upload Brand Image')}
                                </label>
                              )}
                            </div>
                          </div>
                        </div>

                        <div className="flex-shrink-0">
                          <div className="">
                            <h4 className="float-end font-size-16">
                              {props.t('Google ADS Performence Report')}
                            </h4>
                          </div>
                        </div>
                      </div>
                      <div className="d-flex align-items-center justify-content-between">
                        <div className="flex-grow-1">
                          <div className=""></div>
                        </div>

                        <div className="flex-shrink-0">
                          <div>
                            <div>
                              <FeatherIcon icon="calendar" />
                              Start Date
                              <input
                                name="date"
                                type="date"
                                value={DataDashboard.DataDashboard.reports[0].date.substring(
                                  0,
                                  10
                                )}
                                autoComplete="off"
                                id="date"
                                disabled
                                className="form-control"
                                placeholder="date"
                              />
                              {/* Static shipping address */}
                            </div>
                            <FeatherIcon icon="calendar" />
                            End Date:
                            <input
                              name="date"
                              type="date"
                              value={DataDashboard.DataDashboard.reports[
                                DataDashboard.DataDashboard.reports.length - 1
                              ].date.substring(0, 10)}
                              autoComplete="off"
                              id="email"
                              disabled
                              className="form-control"
                              placeholder="Enter Email"
                            />
                            <span></span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <hr className="my-4" />
                    <Row>
                      <Col xl={3} md={4}>
                        <Card className="card-h-100 tamour">
                          <CardBody>
                            <Row className="align-items-center">
                              <Col>
                                <div className="d-flex justify-content-between align-items-center">
                                  <div>
                                    <span className="text-primary mb-3 lh-1 d-block text-truncate">
                                      {props.t('Ad Spent')}
                                    </span>
                                    <h4 className="mb-3">
                                      <span className="counter-value">
                                        {DataDashboard.DataDashboard.reports
                                          .reduce(
                                            (sum, report) => sum + report.cps,
                                            0
                                          )
                                          .toFixed(2)}
                                        $
                                      </span>
                                    </h4>
                                  </div>
                                  <img src={GoogleAdsIcon} alt="" height="50" />
                                </div>
                              </Col>
                            </Row>
                          </CardBody>
                        </Card>
                      </Col>
                      <Col xl={3} md={4}>
                        <Card className="card-h-100 tamour">
                          <CardBody>
                            <Row className="align-items-center">
                              <Col>
                                <div className="d-flex justify-content-between align-items-center">
                                  <div>
                                    <span className="text-primary mb-3 lh-1 d-block text-truncate">
                                      {props.t('Revenue')}
                                    </span>
                                    <h4 className="mb-3">
                                      <span className="counter-value">
                                        {DataDashboard.DataDashboard.reports
                                          .reduce(
                                            (sum, report) =>
                                              sum + report.conversions_value,
                                            0
                                          )
                                          .toFixed(2)}{' '}
                                        {currencyrevenue}
                                      </span>
                                    </h4>
                                  </div>
                                  <img src={GoogleAdsIcon} alt="" height="50" />
                                </div>
                              </Col>
                            </Row>
                          </CardBody>
                        </Card>
                      </Col>
                      <Col xl={3} md={4}>
                        <Card className="card-h-100 tamour">
                          <CardBody>
                            <Row className="align-items-center">
                              <Col>
                                <div className="d-flex justify-content-between align-items-center">
                                  <div>
                                    <span className="text-primary mb-3 lh-1 d-block text-truncate">
                                      {props.t('Conversions')}
                                    </span>
                                    <h4 className="mb-3">
                                      <span className="counter-value">
                                        {DataDashboard.DataDashboard.reports
                                          .reduce(
                                            (sum, report) =>
                                              sum + report.conversions,
                                            0
                                          )
                                          .toFixed(2)}
                                      </span>
                                    </h4>
                                  </div>
                                  <img src={GoogleAdsIcon} alt="" height="50" />
                                </div>
                              </Col>
                            </Row>
                          </CardBody>
                        </Card>
                      </Col>
                      <Col xl={3} md={4}>
                        <Card className="card-h-100 tamour">
                          <CardBody>
                            <Row className="align-items-center">
                              <Col>
                                <div className="d-flex justify-content-between align-items-center">
                                  <div>
                                    <span className="text-primary mb-3 lh-1 d-block text-truncate">
                                      ROAs
                                    </span>
                                    <h4 className="mb-3">
                                      <span className="counter-value">
                                        {(
                                          DataDashboard.DataDashboard.reports.reduce(
                                            (valueSum, report) =>
                                              valueSum +
                                              report.conversions_value /
                                                valuecurrency,
                                            0
                                          ) /
                                          DataDashboard.DataDashboard.reports.reduce(
                                            (conversionsSum, report) =>
                                              conversionsSum + report.cps,
                                            0
                                          )
                                        ).toFixed(2)}
                                        x
                                      </span>
                                    </h4>
                                  </div>
                                  <img src={GoogleAdsIcon} alt="" height="50" />
                                </div>
                              </Col>
                            </Row>
                          </CardBody>
                        </Card>
                      </Col>
                      <Col xl={3} md={4}>
                        <Card className="card-h-100 tamour">
                          <CardBody>
                            <Row className="align-items-center">
                              <Col>
                                <div className="d-flex justify-content-between align-items-center">
                                  <div>
                                    <span className="text-primary mb-3 lh-1 d-block text-truncate">
                                      CPA
                                    </span>
                                    <h4 className="mb-3">
                                      <span className="counter-value">
                                        {(
                                          DataDashboard.DataDashboard.reports.reduce(
                                            (valueSum, report) =>
                                              valueSum + report.cps,
                                            0
                                          ) /
                                          DataDashboard.DataDashboard.reports.reduce(
                                            (conversionsSum, report) =>
                                              conversionsSum +
                                              report.conversions,
                                            0
                                          )
                                        ).toFixed(2)}
                                        $
                                      </span>
                                    </h4>
                                  </div>
                                  <img src={GoogleAdsIcon} alt="" height="50" />
                                </div>
                              </Col>
                            </Row>
                          </CardBody>
                        </Card>
                      </Col>
                      <Col xl={3} md={4}>
                        <Card className="card-h-100 tamour">
                          <CardBody>
                            <Row className="align-items-center">
                              <Col>
                                <div className="d-flex justify-content-between align-items-center">
                                  <div>
                                    <span className="text-primary mb-3 lh-1 d-block text-truncate">
                                      CPC
                                    </span>
                                    <h4 className="mb-3">
                                      <span className="counter-value">
                                        {(
                                          DataDashboard.DataDashboard.reports.reduce(
                                            (valueSum, report) =>
                                              valueSum + report.cps,
                                            0
                                          ) /
                                          DataDashboard.DataDashboard.reports.reduce(
                                            (conversionsSum, report) =>
                                              conversionsSum + report.clicks,
                                            0
                                          )
                                        ).toFixed(2)}
                                        $
                                      </span>
                                    </h4>
                                  </div>
                                  <img src={GoogleAdsIcon} alt="" height="50" />
                                </div>
                              </Col>
                            </Row>
                          </CardBody>
                        </Card>
                      </Col>
                      <Col xl={3} md={4}>
                        <Card className="card-h-100 tamour">
                          <CardBody>
                            <Row className="align-items-center">
                              <Col>
                                <div className="d-flex justify-content-between align-items-center">
                                  <div>
                                    <span className="text-primary mb-3 lh-1 d-block text-truncate">
                                      CTR
                                    </span>
                                    <h4 className="mb-3">
                                      <span className="counter-value">
                                        {(
                                          (DataDashboard.DataDashboard.reports.reduce(
                                            (clicksSum, report) =>
                                              clicksSum + report.clicks,
                                            0
                                          ) /
                                            DataDashboard.DataDashboard.reports.reduce(
                                              (impressionsSum, report) =>
                                                impressionsSum +
                                                report.impressions,
                                              0
                                            )) *
                                          100
                                        ).toFixed(2)}
                                        %{' '}
                                        {/* Format the CTR with two decimal places */}
                                      </span>
                                    </h4>
                                  </div>
                                  <img src={GoogleAdsIcon} alt="" height="50" />
                                </div>
                              </Col>
                            </Row>
                          </CardBody>
                        </Card>
                      </Col>
                      <Col xl={3} md={4}>
                        <Card className="card-h-100 tamour">
                          <CardBody>
                            <Row className="align-items-center">
                              <Col>
                                <div className="d-flex justify-content-between align-items-center">
                                  <div>
                                    <span className="text-primary mb-3 lh-1 d-block text-truncate">
                                      {props.t('Conversion Rate')}
                                    </span>
                                    <h4 className="mb-3">
                                      <span className="counter-value">
                                        {(
                                          (DataDashboard.DataDashboard.reports.reduce(
                                            (clicksSum, report) =>
                                              clicksSum + report.conversions,
                                            0
                                          ) /
                                            DataDashboard.DataDashboard.reports.reduce(
                                              (impressionsSum, report) =>
                                                impressionsSum + report.clicks,
                                              0
                                            )) *
                                          100
                                        ).toFixed(2)}
                                        %{' '}
                                        {/* Format the CTR with two decimal places */}
                                      </span>
                                    </h4>
                                  </div>
                                  <img src={GoogleAdsIcon} alt="" height="50" />
                                </div>
                              </Col>
                            </Row>
                          </CardBody>
                        </Card>
                      </Col>

                      <Col xl={3} md={4}>
                        <Card className="card-h-100 tamour">
                          <CardBody>
                            <Row className="align-items-center">
                              <Col>
                                <div className="d-flex justify-content-between align-items-center">
                                  <div>
                                    <span className="text-primary mb-3 lh-1 d-block text-truncate">
                                      {props.t('Impressions')}
                                    </span>
                                    <h4 className="mb-3">
                                      <span className="counter-value">
                                        {DataDashboard.DataDashboard.reports.reduce(
                                          (sum, report) =>
                                            sum + report.impressions,
                                          0
                                        )}
                                      </span>
                                    </h4>
                                  </div>
                                  <img src={GoogleAdsIcon} alt="" height="50" />
                                </div>
                              </Col>
                            </Row>
                          </CardBody>
                        </Card>
                      </Col>
                      <Col xl={3} md={4}>
                        <Card className="card-h-100 tamour">
                          <CardBody>
                            <Row className="align-items-center">
                              <Col>
                                <div className="d-flex justify-content-between align-items-center">
                                  <div>
                                    <span className="text-primary mb-3 lh-1 d-block text-truncate">
                                      {props.t('Clicks')}
                                    </span>
                                    <h4 className="mb-3">
                                      <span className="counter-value">
                                        {DataDashboard.DataDashboard.reports.reduce(
                                          (sum, report) => sum + report.clicks,
                                          0
                                        )}
                                      </span>
                                    </h4>
                                  </div>
                                  <img src={GoogleAdsIcon} alt="" height="50" />
                                </div>
                              </Col>
                            </Row>
                          </CardBody>
                        </Card>
                      </Col>
                      <Col xl={3} md={4}>
                        <Card className="card-h-100 tamour">
                          <CardBody>
                            <Row className="align-items-center">
                              <Col>
                                <div className="d-flex justify-content-between align-items-center">
                                  <div>
                                    <span className="text-primary mb-3 lh-1 d-block text-truncate">
                                      AOV
                                    </span>
                                    <h4 className="mb-3">
                                      <span className="counter-value">
                                        {(
                                          DataDashboard.DataDashboard.reports.reduce(
                                            (valueSum, report) =>
                                              valueSum +
                                              report.conversions_value,
                                            0
                                          ) /
                                          DataDashboard.DataDashboard.reports.reduce(
                                            (conversionsSum, report) =>
                                              conversionsSum +
                                              report.conversions,
                                            0
                                          )
                                        ).toFixed(2)}
                                        $
                                      </span>
                                    </h4>
                                  </div>
                                  <img src={GoogleAdsIcon} alt="" height="50" />
                                </div>
                              </Col>
                            </Row>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                    <hr className="my-4" />
                    <Row>
                      <Col xl={12}>
                        <Card className="tamour">
                          <CardHeader
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'space-between',
                            }}
                          >
                            <div>
                              <CardTitle>
                                {props.t('Spent vs Revenue Google Ads')}
                              </CardTitle>
                            </div>
                            <div>
                              <img src={GoogleAdsIcon} alt="" height="30" />
                            </div>
                          </CardHeader>
                          <CardBody>
                            <div dir="ltr">
                              <SplineArea />
                            </div>
                          </CardBody>
                        </Card>
                      </Col>
                      <Col xl={12}>
                        <Card className="tamour">
                          <CardHeader
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'space-between',
                            }}
                          >
                            <div>
                              <CardTitle>
                                {props.t('Click & CPC Over Time Google Ads')}
                              </CardTitle>
                            </div>
                            <div>
                              <img src={GoogleAdsIcon} alt="" height="35" />
                            </div>
                          </CardHeader>
                          <CardBody>
                            <div dir="ltr">
                              <Revnuechart />
                            </div>
                          </CardBody>
                        </Card>
                      </Col>

                      <Col xl={12}>
                        <Card className="tamour">
                          <CardHeader
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'space-between',
                            }}
                          >
                            <div>
                              <CardTitle>
                                {props.t('Conversion Google Ads')}
                              </CardTitle>
                            </div>
                            <div>
                              <img src={GoogleAdsIcon} alt="" height="30" />
                            </div>
                          </CardHeader>
                          <CardBody>
                            <div dir="ltr">
                              <Conversionchart />
                            </div>
                          </CardBody>
                        </Card>
                      </Col>
                      <hr className="my-4" />
                      <Col md={12}>
                        <Card className="tamour">
                          <CardHeader
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'space-between',
                            }}
                          >
                            <div>
                              <CardTitle>
                                {props.t('Keyword Performence')}
                              </CardTitle>
                            </div>
                            <div>
                              <img src={GoogleAdsIcon} alt="" height="30" />
                            </div>
                          </CardHeader>
                          <CardBody>
                            <div className="table-responsive ">
                              <Table className="table mb-0">
                                <thead>
                                  <tr>
                                    <th></th>
                                    <th>{props.t('Keyword Text')}</th>
                                    <th>{props.t('Campaign name')}</th>
                                    <th>{props.t('Cost')}</th>
                                    <th>{props.t('Clicks')}</th>
                                    <th>{props.t('Conversions')}</th>
                                    <th>{props.t('conversions_value')}</th>
                                    <th>{props.t('impressions')}</th>
                                    <th>ROAs</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {sortedGaKeywords
                                    ?.slice(0, 5)
                                    .map((gaKeyword, key) => (
                                      <tr key={key}>
                                        <td>{key + 1}</td>
                                        <td>{gaKeyword.keyword_text}</td>
                                        <td>{gaKeyword.campaign_name}</td>
                                        <td>{gaKeyword.cost}</td>
                                        <td>{gaKeyword.clicks}</td>
                                        <td>{gaKeyword.conversions}</td>
                                        <td>
                                          {gaKeyword.conversions_value.toFixed(
                                            2
                                          )}
                                        </td>
                                        <td>{gaKeyword.impressions}</td>
                                        <td>
                                          {(
                                            gaKeyword.conversions_value /
                                            valuecurrency /
                                            gaKeyword.cost
                                          ).toFixed(2)}
                                        </td>
                                      </tr>
                                    ))}
                                </tbody>
                              </Table>
                            </div>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                    <div className="d-print-none mt-4">
                      <div className="float-end">
                        <Link
                          to="#"
                          onClick={printInvoice}
                          className="btn btn-success me-2"
                        >
                          <i className="fa fa-print" />
                        </Link>
                        <button
                          onClick={exportToPDF}
                          className="btn btn-primary w-md "
                        >
                          {props.t('Download')}
                        </button>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};
InvoiceDetail.propTypes = {
  t: PropTypes.any,
};
export default withRouter(withTranslation()(InvoiceDetail));
//export default withRouter(InvoiceDetail);
