import React from 'react';
import "../../../assets/scss/custom/pages/_operations.scss";
import { Table, Popup, Grid, Header } from 'semantic-ui-react'
import { default as NumberFormat } from 'react-number-format';
function StrategicRow(props) {
  const currency = window.localStorage.getItem("currency")
  function isValidDate(d) {
    return d instanceof Date && !isNaN(d);
  }
  const d = isValidDate(new Date(props.singleStrat.created)) ? new Date(props.singleStrat.created).toUTCString() : new Date(parseInt(props.singleStrat.created)).toUTCString();
  const day = d.substring(11, 5);
  const final = day.split(" ").reverse().join(" ")
  const adCost = parseFloat(props.singleStrat.facebook) + parseFloat(props.singleStrat.google);
  return (
    <Table.Row>
      <Table.Cell textAlign="center" style={{ fontSize: '12px' }}>{final}</Table.Cell>
      <Table.Cell textAlign="center" style={{ fontSize: '11px' }}><span style={props.singleStrat.sales && props.singleStrat.sales.toFixed(2) < 0 ? { color: "red" } : null}>{props.singleStrat.sales && props.singleStrat.sales === 0 ? '-' : <NumberFormat value={props.singleStrat.sales ? props.singleStrat.sales : "-"} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={"TND"} renderText={value => <div>{value}</div>} />}</span>
      </Table.Cell>
      <Table.Cell textAlign="center" style={{ fontSize: '11px' }}><span style={props.singleStrat.netProfit && props.singleStrat.netProfit.toFixed(2) < 0 ? { color: "red" } : null}>{props.singleStrat.netProfit && props.singleStrat.netProfit === 0 ? '-' : <NumberFormat value={props.singleStrat.netProfit ? props.singleStrat.netProfit : "-"} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={"TND"} renderText={value => <div>{value}</div>} />}</span>
      </Table.Cell>
      {(parseFloat(props.singleStrat.facebook) !== 0 || parseFloat(props.singleStrat.google) !== 0) ? (
        <Popup size='mini'
          trigger={<Table.Cell textAlign="center" style={{ fontSize: '11px' }}><span style={adCost < 0 ? { color: "red" } : null}>{adCost === 0 ? '-' : <NumberFormat value={adCost.toFixed(2)} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={currency === 'CAD' ? 'C$' : currency === 'AUD' ? 'A$' : '$'} renderText={value => <div>{value}</div>} />}</span>
          </Table.Cell>
          } flowing hoverable position={"right center"}
          style={{
            border: '1px solid #ccc',
            borderRadius: '4px',
            padding: '10px',
            background: '#f9f9f9',
            zIndex:'999',
            width:'110px'
          }}
          >
          <Grid centered divided columns={2}>
            <Grid.Column textAlign='center'>
              <Header as='h6' style={{color:"#556ee6" }}>Facebook</Header>
              <p><b><span style={{ marginBottom: '10px' }}>{((props.singleStrat.facebook / adCost) * 100).toFixed(1)}%</span></b><br></br><br></br>
                {props.singleStrat.facebook === 0 ? '-' : <NumberFormat value={props.singleStrat.facebook} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={"TND"} renderText={value => <div>{value}</div>} />}</p>
            </Grid.Column>
            <hr></hr>
            <Grid.Column textAlign='center'>
              <Header as='h6'>Google</Header>
              <p><b><span   style={{color:"#556ee6" }}>{((props.singleStrat.google / adCost) * 100).toFixed(1)}%</span></b><br></br><br></br>
                {props.singleStrat.google === 0 ? '-' : <NumberFormat value={props.singleStrat.google} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={"TND"} renderText={value => <div>{value}</div>} />}
              </p>
            </Grid.Column>
          </Grid>
        </Popup>) : <Table.Cell textAlign="center" style={{ fontSize: '11px' }}><span style={adCost < 0 ? { color: "red" } : null}>{adCost === 0 ? '-' : <NumberFormat value={adCost.toFixed(2)} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={"TND"} renderText={value => <div>{value}</div>} />}</span>
        </Table.Cell>}
      <Table.Cell textAlign="center" style={{ fontSize: '11px' }}><span style={props.singleStrat.aov && props.singleStrat.aov.toFixed(2) < 0 ? { color: "red" } : null}>{props.singleStrat.aov && props.singleStrat.aov === 0 ? '-' : <NumberFormat value={props.singleStrat.aov ? props.singleStrat.aov : '-'} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={"TND"} renderText={value => <div>{value}</div>} />}</span>
      </Table.Cell>
      <Table.Cell textAlign="center" style={{ fontSize: '11px' }}><span style={props.singleStrat.cps && props.singleStrat.cps.toFixed(2) < 0 ? { color: "red" } : null}>{props.singleStrat.cps && props.singleStrat.cps === 0 ? '-' : <NumberFormat value={props.singleStrat.cps ? props.singleStrat.cps : '-'} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={"TND"} renderText={value => <div>{value}</div>} />}</span>
      </Table.Cell>
      <Table.Cell textAlign="center" style={{ fontSize: '11px' }}><span style={props.singleStrat.roa && props.singleStrat.roa.toFixed(2) < 0 ? { color: "red" } : null}>{props.singleStrat.roa && props.singleStrat.roa === 0 ? '-' : <NumberFormat value={props.singleStrat.roa ? props.singleStrat.roa : "-"} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={'%'} renderText={value => <div>{value}</div>} />}</span>
      </Table.Cell>
     
      
      <Table.Cell textAlign="center" style={{ fontSize: '11px' }}><span style={props.singleStrat.profitMargin && props.singleStrat.profitMargin.toFixed(2) < 0 ? { color: "red" } : null}>{props.singleStrat.profitMargin && props.singleStrat.profitMargin === 0 ? '-' : <NumberFormat value={props.singleStrat.profitMargin ? (props.singleStrat.profitMargin * 100) : "-"} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={'%'} renderText={value => <div>{value}</div>} />}</span>
      </Table.Cell>
      <Table.Cell textAlign="center" style={{ fontSize: '11px' }}><span style={props.singleStrat.bRoas && props.singleStrat.bRoas.toFixed(2) < 0 ? { color: "red" } : null}>{props.singleStrat.bRoas && props.singleStrat.bRoas === 0 ? '-' : <NumberFormat value={props.singleStrat.bRoas ? props.singleStrat.bRoas : "-"} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={'%'} renderText={value => <div>{value}</div>} />}</span>
      </Table.Cell>
    </Table.Row>
  )
}
export default StrategicRow;