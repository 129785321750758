import React, { useMemo } from 'react';
import ReactEcharts from 'echarts-for-react';
import * as echarts from 'echarts'; // Import echarts to access graphic

const SmallLineChart = ({ data,colors }) => {


  
  // Memoize the chart options for performance optimization
  const options = useMemo(() => ({
    xAxis: {
      type: 'category',
      data: data.map(d => d.name),
      axisLine: {
        show: false, // Hide the axis line
      },
      axisTick: {
        show: false, // Hide the ticks
      },
      axisLabel: {
        show: false, // Hide the x-axis labels
      },
    },
    yAxis: {
      type: 'value',
      show: false, // Hide the y-axis entirely
    },
    series: [
      {
        data: data.map(d => d.value),
        type: 'line',
        smooth: true, // Smooth curve line
        lineStyle: {
          color: colors.lineColor, // Line color
          width: 2,
        },
        areaStyle: {
          opacity: 0.4, // Adjust opacity for the gradient area
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            {
              offset: 0.4433, // Adjusted to match the 44.33% point from Figma
              color: colors.offsetZERO, // Top gradient color
            },
            {
              offset: 1,
              color: colors.offsetONE, // Bottom gradient color
            },
          ]),
        },
        showSymbol: false, // Disable the points on the line
      },
    ],
    tooltip: {
      //show  default tooltip
      // trigger: 'axis', // Display tooltip on axis hover
      // formatter: '{b}: {c}', // Display category and value
      // backgroundColor: 'rgba(255, 255, 255, 0.8)', // White background with transparency
      // borderColor: '#FF6241', // Border color matching the line
      // borderWidth: 1, // Thin border
      // textStyle: {
      //   color: '#333', // Dark text color
      //   fontWeight: 'bold',
      // },
      // axisPointer: {
      //   type: 'line', // Highlight the line for the tooltip
      //   lineStyle: {
      //     color: '#FF6241',
      //     width: 2,
      //   },
      // },
    },
    grid: {
      left: '0%',
      right: '0%',
      bottom: '0%',
      top: '10%', // Adjust top margin if needed
      containLabel: false,
    },
  }), [data]);


  return (
    <div id="chart">
      <ReactEcharts option={options} style={{ height: 150, width: '100%' }} />
    </div>
  );
};

export default React.memo(SmallLineChart);