import { takeLatest, put, call,takeEvery} from 'redux-saga/effects';
import urls from '../../routes/apiUrls';
import ApolloClient from "apollo-boost";
import { getMembers,addMember,editMember,deleteSingleMember } from "../../queries/Members/MembersQuery";
import {
    GET_MEMBER_REQUEST,
    getMemberSuccess,
    getMemberFailure,
    REST_GET_MEMBER_REQUEST,
    restGetMemberSuccess,
    restGetMemberFailure,
    ADD_MEMBER_REQUEST,
    addMemberSuccess,
    addMemberFailure,
    EDIT_MEMBER_REQUEST,
    editMemberSuccess,
    editMemberFailure,
    DELETE_MEMBER_REQUEST,
    deleteMemberSuccess,
    deleteMemberFailure,
} from './action';
const ApploCLI = new ApolloClient({
    uri: urls.member
  });

function* getMember({  }) {
    try{

        const {data}= yield call(ApploCLI.query, {
            query: getMembers,
            context: {
              headers: {
                Authorization: window.localStorage.getItem('token')
                  ? `Bearer ${window.localStorage.getItem('token')}`
                  : '',
              }
            },
            errorPolicy: "all",
                fetchPolicy: "no-cache",
          })
          if(data.all ){
            console.log("data",data.all)
            yield put(getMemberSuccess(data.all));
          }else{
            yield put(getMemberFailure('error'));
          }
    }catch(error){
        yield put(getMemberFailure(error));
    }


}

function* restGetMember({ action }) {

    try{

        const {data}= yield call(ApploCLI.query, {
            query: getMembers,
            variables: {
                name: action.payload.name,
            },
            context: {
              headers: {
                Authorization: window.localStorage.getItem('token')
                  ? `Bearer ${window.localStorage.getItem('token')}`
                  : '',
              }
            },
            errorPolicy: "all",
                fetchPolicy: "no-cache",
          })
          if(data.all ){
            yield put(restGetMemberSuccess(data.all));
          }else{
            yield put(restGetMemberFailure('error'));
          }
    }catch(error){
        yield put(restGetMemberFailure(error));
    }
}

function* addMemberRequest(action) {
    try {
      const { response } = yield call(ApploCLI.mutate, {
        mutation: addMember,
        variables: {
          email: action.payload.email,
          storeName: action.payload.storeName,
          sections: action.payload.sections,
        },
        context: {
          headers: {
            Authorization: window.localStorage.getItem('token')
              ? `Bearer ${window.localStorage.getItem('token')}`
              : '',
          },
        },
      });
      yield put(addMemberSuccess(response)); // Dispatch success action
    } catch (error) {
      yield put(addMemberFailure(error)); // Dispatch failure action
    }
  }
  

function* editMemberRequest(action) {
    try {
        const { response } = yield call(ApploCLI.mutate, {
            mutation: editMember,
            variables: {
                email: action.payload.email,
                sections: action.payload.sections,
            },
            context: {
                headers: {
                    Authorization: window.localStorage.getItem('token')
                        ? `Bearer ${window.localStorage.getItem('token')}`
                        : '',
                }
            }
          });
        yield put(editMemberSuccess(response));
    } catch (error) {
        yield put(editMemberFailure(error));
    }
}
function * deleteMemberRequest(action){

    try {
        const { response } = yield call(ApploCLI.mutate, {
            mutation: deleteSingleMember,
            variables: {
                email: action.payload.email,
            },
            context: {
                headers: {
                    Authorization: window.localStorage.getItem('token')
                        ? `Bearer ${window.localStorage.getItem('token')}`
                        : '',
                }
            }
          });
        yield put(deleteMemberSuccess(response));
       
    } catch (error) {
        yield put(deleteMemberFailure(error));
    }
}

export default function* memberSaga() {
    yield takeLatest(GET_MEMBER_REQUEST, getMember);
    yield takeLatest(REST_GET_MEMBER_REQUEST, restGetMember);
    yield takeLatest(ADD_MEMBER_REQUEST, addMemberRequest);
    yield takeLatest(EDIT_MEMBER_REQUEST, editMemberRequest);
    yield takeLatest(DELETE_MEMBER_REQUEST, deleteMemberRequest);

}