import React, { useState } from "react";
import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
  MDBSpinner,
} from "mdb-react-ui-kit";
import  {useSelector} from "react-redux";
import {  validateEmail } from "./lib";
import { emailbuilder } from "../../queries/EmailBuilder/queries";
import urls from "../../routes/apiUrls"
import ApolloClient from 'apollo-boost';

const ApolooCLIEmailBuilder = new ApolloClient({
  uri : urls.EmailBuilder
});


const verifyEmails = (emails) => {
  const _emails = emails.split(",");
  _emails.forEach((e) => {
    if (!validateEmail(e)) return false;
  });
  return true;
};

const SendMailModal = ({ isOpenMailModal, toggleMailModal, mailContent }) => {
  const { emailofreturnedshipper } = useSelector((state) => state.aramex)
  const [loading, setLoading] = useState(false);
  const [subject, setSubject] = useState("");
  const [selectedEmail, setSelectedEmail] = useState("");
  const handleSubjectInput = (e) => {
    setSubject(e.target.value);
  };
  const handleEmailInput = (e) => {
    setSelectedEmail(e.target.value);
  };

  // SEND EMAILS 
   const sendNewsletter = async (payload_data) => {
    ApolooCLIEmailBuilder.mutate({
      mutation : emailbuilder,
      context : {
        headers: {
          Authorization: window.localStorage.getItem("token")
            ? `Bearer ${window.localStorage.getItem("token")}`
            : "",
        },
        variables : {
          payload : payload_data
        }
      },
      errorPolicy: 'all',
    }).then((data)=>{
      if (data && data.length > 0 )
      console.log("success");
  })
    }
  


  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (subject.trim().length < 10) {
        return alert("Enter Email Subject");
      }
      if (selectedEmail.trim().length < 10) {
        return alert("Enter a valid email or emails separated by comma");
      }
      // if (!verifyEmails(emails)) {
      //   return alert("Please, enter only valid email addresses");
      // }
      // const data = localStorage.getItem('newsletter')
      if (!mailContent) {
        return alert("You can't send empty content");
      }
      setLoading(true);

      // const res = await sendNewsletter({ subject, mailContent, emails });
      sendNewsletter()
      setSubject("");
      setSelectedEmail("");
      setLoading(false);
      //alert(res.message);
    } catch (error) {}
  };
  return (
    <>
      <MDBModal staticBackdrop={true} show={isOpenMailModal} tabIndex="-1">
        <MDBModalDialog size="md">
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle>Send Mail</MDBModalTitle>
              <MDBBtn
                className="btn-close"
                color="none"
                onClick={() => toggleMailModal()}
              ></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>
              <form>
                <label htmlFor="formFileSm" className="form-label">
                  Enter Mail Subject
                </label>
                <input
                  className="form-control form-control-sm"
                  id="formFileSm"
                  placeholder="Enter mail subject"
                  onChange={(e) => handleSubjectInput(e)}
                />
                <div className="mt-2 mb-2">
                <label htmlFor="formEmail" className="form-label">
                  Select Email Address
                </label>
                <br />
                <small>
                  You can select an email address from the list
                </small>
                <select
                  id="formEmail"
                  className="form-select"
                  value={selectedEmail}
                  onChange={(e) => handleEmailInput(e)}
                >
                  <option value="">Select an Email Address</option>
                  {emailofreturnedshipper.map((email) => (
                    <option key={email} value={email}>
                      {email}
                    </option>
                  ))}
                </select>
                </div>

                <div className="d-block text-center mt-3">
                  {loading && (
                    <MDBSpinner className="mx-2" color="info">
                      <span className="visually-hidden">Loading...</span>
                    </MDBSpinner>
                  )}
                  <MDBBtn
                    disabled={loading}
                    color="info"
                    onClick={(e) => handleSubmit(e)}
                  >
                    Send Mail
                  </MDBBtn>
                </div>
              </form>
              <hr />
            </MDBModalBody>
            <MDBModalFooter>
              <MDBBtn color="danger" onClick={() => toggleMailModal()}>
                Close
              </MDBBtn>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </>
  );
};

export default SendMailModal;
