import React from 'react'
import { formatNumberWithSpaces } from '../GoogleAnalytics/common/HelperFunctions/utils'
import { Card, CardBody, Col, Row } from 'reactstrap';
import i18next from 'i18next';

const InstagramLogo = `${process.env.PUBLIC_URL}/images/integrations_images/Insta1.svg`;

const InstagramSocialMediaCards = ({ secCards}) => {
  return (
    <Row className="">
      {secCards.map((item) => (
        <div className="dashboardMinorCardItem" key={item.id}>
          <Card className="card-h-100">
            <CardBody>
              <div className="my-0 text-primary d-flex justify-content-between align-items-center">
                {i18next.t(item.name)}
                <img
                  className="img-intgration-icon"
                  src={InstagramLogo}
                  alt="Instagram Logo"
                />
              </div>
              <Row className="align-items-center">
                <Col xs={6}>
                  <div className="mb-3">
                    <h4>{formatNumberWithSpaces(item.data)}</h4>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </div>
      ))}
    </Row>
  );
};

export default InstagramSocialMediaCards
