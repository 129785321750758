import React, { useEffect } from 'react';
import MetaTags from 'react-meta-tags';
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Row, Col, CardBody, Nav } from "reactstrap";
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
  PaginationProvider, PaginationListStandalone,
  SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import "../../pages/GoogleAnalytics/gaAudience/datatables.scss"
import { checkIntegrationRequestKlaviyo, fetchDataklaviyoRequest, fetchMetricsklaviyoRequest,fetchDataFlowRequest } from '../../store/Klaviyo/action';
import { useDispatch, useSelector } from 'react-redux';
import KlaviyoMetrics from './KlaviyoMetrics';
import FlowsTable from './FlowsTable';
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
const KlaviyoIcon = `${process.env.PUBLIC_URL}/images/integrations_images/Klaviyo.svg`;

function KlaviyoDashboard(props) {

  const dispatch = useDispatch();
  const { apiKey, compaigns, metrics,flows } = useSelector((state) => state.klaviyo)
  const fetchData = async (apiKey) => {
    await dispatch(checkIntegrationRequestKlaviyo());

    console.log("data.apiKey", apiKey);
    dispatch(fetchMetricsklaviyoRequest(apiKey));
    dispatch(fetchDataklaviyoRequest(apiKey));
    dispatch(fetchDataFlowRequest(apiKey));
    
  };
  useEffect(() => {
    fetchData(apiKey)
    

  }, [dispatch, apiKey]);
  const columns = [
    {
      dataField: 'name',
      text: props.t("Compaign Name"),
      sort: true
    },
    {
      dataField: 'campaign_type',
      text: props.t("Campaign Type"),
      sort: true
    },
    {
      dataField: 'message_type',
      text: props.t("Message Type"),
      sort: true
    },
    {
      dataField: 'num_recipients',
      text: props.t("Number Recipients"),
      sort: true
    },
    {
      dataField: 'template_id',
      text: props.t("Compaign Template"),
      sort: false
    },
    {
      dataField: 'status',
      text: props.t("Compaign Status"),
      sort: false
    },
    {
      dataField: 'send_time',
      text: props.t("Compaign Send Time"),
      sort: false
    },
  ];
  const defaultSorted = [{
    dataField: 'id',
    order: 'asc'
  }];
  const pageOptions = {
    sizePerPage: 10,
    totalSize: compaigns ? compaigns.length : 0,
    custom: true,
  }
  const selectRow = {
    mode: 'checkbox'
  }
  const { SearchBar } = Search;
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title> {props.t("Klaviyo Dashboard")} | Convergen Metrics - E-COM SASS</title>
        </MetaTags>
        <div className="container-fluid">
          {<Breadcrumbs title={props.t("Dashboards")} breadcrumbItem={props.t("Klaviyo Dashboard")} />}
          <Row>
            <div className="card">
              <div className="card-header align-items-center d-flex">
                <h4 className="card-title mb-0 flex-grow-1">
                  {props.t("Metrics")}{" "}
                  <i class="fa fa-info-circle text-primary" aria-hidden="true" data-tooltip={props.t("This is last 30 days' data")}></i>
                </h4>
                <div className="flex-shrink-0">
                  <Nav className="justify-content-end nav-tabs-custom rounded card-header-tabs" role="tablist">
                    <img className="img-intgration-icon" src={KlaviyoIcon} />
                  </Nav>
                </div>
              </div>
              <CardBody>
                <Row>
                  <KlaviyoMetrics metrics={metrics} />
                </Row>
              </CardBody>
            </div>
          </Row>
          <Row>
            <div className="card">
              <div className="card-header align-items-center d-flex">
                <h4 className="card-title mb-0 flex-grow-1">{props.t("Campaigns")}{" "}
                  <i class="fa fa-info-circle text-primary" aria-hidden="true" data-tooltip={props.t("This is last 30 days' data")}></i>
                </h4>
                <div className="flex-shrink-0">
                  <Nav className="justify-content-end nav-tabs-custom rounded card-header-tabs" role="tablist">
                    <img className="img-intgration-icon" src={KlaviyoIcon} />
                  </Nav>
                </div>
              </div>
              <CardBody>
                <PaginationProvider
                  pagination={paginationFactory(pageOptions)}
                  keyField='id'
                  columns={columns}
                  data={compaigns ? compaigns : []}
                >
                  {({ paginationProps, paginationTableProps }) => (
                    <ToolkitProvider
                      keyField='id'
                      columns={columns}
                      data={compaigns ? compaigns : []}
                      search
                    >
                      {toolkitProps => (
                        <React.Fragment>
                          <Row className="mb-2">
                            <Col md="4">
                              <div className="search-box me-2 mb-2 d-inline-block">
                                <div className="position-relative">
                                  <SearchBar
                                    {...toolkitProps.searchProps}
                                  />
                                  <i className="bx bx-search-alt search-icon" />
                                </div>
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col xl="12">
                              <div className="table-responsive">
                                <BootstrapTable
                                  keyField={"id"}
                                  key={"id"}
                                  responsive
                                  bordered={false}
                                  striped={false}
                                  defaultSorted={defaultSorted}
                                  selectRow={selectRow}
                                  classes={
                                    "table align-middle table-nowrap"
                                  }
                                  headerWrapperClasses={"thead-light"}
                                  {...toolkitProps.baseProps}
                                  {...paginationTableProps}
                                />
                              </div>
                            </Col>
                          </Row>
                          <Row className="align-items-md-center mt-30">
                            <Col className="inner-custom-pagination d-flex">
                              <div className="d-inline">
                                <SizePerPageDropdownStandalone
                                  {...paginationProps}
                                />
                              </div>
                              <div className="text-md-right ms-auto">
                                <PaginationListStandalone
                                  {...paginationProps}
                                />
                              </div>
                            </Col>
                          </Row>
                        </React.Fragment>
                      )
                      }
                    </ToolkitProvider>
                  )
                  }</PaginationProvider>
              </CardBody>
            </div>
          </Row>
          <FlowsTable flows={flows}/>
        </div>

      </div>
    </React.Fragment >
  );
}
KlaviyoDashboard.propTypes = {
  t: PropTypes.any
};

export default withRouter(withTranslation()(KlaviyoDashboard));
//export default KlaviyoDashboard;