import React, { useEffect } from 'react';

import StackedLineChart from '../common/StakedLineChart';
import { getGoogleAnalyticsProductsRequest } from '../../../store/actions';
import { useDispatch, useSelector } from 'react-redux';
import { datePattern, formatDate } from '../common/HelperFunctions/utils';
import GASoldproductsTable from './GASoldproductsTable';

const GASoldProductsOverview = ({ showPagination }) => {
  const dispatch = useDispatch();
  const { dateRange } = useSelector((state) => state.dateRangeReducer);

  const { soldProducts } = useSelector((state) => state.googleAnalytics);
  const { startDate, endDate } = dateRange.selection1;

  useEffect(() => {
    if (startDate && endDate) {
      const formattedStartDate = formatDate(startDate);
      const formattedEndDate = formatDate(endDate);
      console.log('hamyda ya hamyda ', formattedEndDate);

      dispatch(
        getGoogleAnalyticsProductsRequest(formattedStartDate, formattedEndDate)
      );
    }
  }, [dateRange]);

  const groupProductsByName = (soldProducts) => {
    return soldProducts.reduce((acc, product) => {
      product.products.forEach((item) => {
        const productName = item.itemName;
        if (!acc[productName]) {
          acc[productName] = {
            itemCategory: item.itemCategory,
            itemName: item.itemName,
            itemRevenue: parseFloat(item.itemRevenue),
            itemsPurchased: parseInt(item.itemsPurchased, 10),
            key: productName,
          };
        } else {
          acc[productName].itemRevenue += parseFloat(item.itemRevenue);
          acc[productName].itemsPurchased += parseInt(item.itemsPurchased, 10);
        }
      });
      return acc;
    }, {});
  };

  const dataSource = Object.values(groupProductsByName(soldProducts)).sort(
    (a, b) => b.itemsPurchased - a.itemsPurchased
  );

  const sortData = soldProducts.sort(
    (a, b) => new Date(a.day).getTime() - new Date(b.day).getTime()
  );
  const top5 = dataSource.slice(0, 5);

  const seriesData = top5.map((product) => ({
    name: product.itemName,
    data: sortData.map((dayData) => {
      const dayProduct = dayData.products.find(
        (p) => p.itemName === product.itemName
      );
      return dayProduct ? dayProduct.itemsPurchased : 0;
    }),
  }));

  return (
    <div>
      <StackedLineChart
        dataSeries={seriesData}
        chosedDate={dateRange.selection1}
      />
      <GASoldproductsTable
        dataSource={dataSource}
        showPagination={showPagination}
      />
    </div>
  );
};

export default GASoldProductsOverview;
