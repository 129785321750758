import React, { useState, useEffect, useRef } from 'react';
import {
  Row,
  Col,
  CardBody,
  Card,
  Container,
  FormGroup,
  InputGroup,
} from 'reactstrap';
import ApolloClient from 'apollo-boost';
import urls from '../../routes/apiUrls';
import MetaTags from 'react-meta-tags';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import FeatherIcon from 'feather-icons-react';
import {
  getfbPagePosts,
  getfbPageData,
  getfbPageAudience,
} from '../../queries/FacebookSocialMedia/queries';
import {
  getFacebookPageDataPerDate,getFacebookPagePostData
} from '../../store/facebookPage/actions';
import secureSection from '../../MangeSecurity/MemberSecurity';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import logo from '../../assets/images/Live_Metrics_Logo.png';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import FbSocialMediaCards from './fbSocialMediaCards';
import FbSocialMediaPostsTable from './fbSocialMediaPostsTable';
import FbSocialMediaGender from './fbSocialMediaGender';
import FbSocialMediaAge from './fbSocialMediaAge';
import { DateRange } from 'react-date-range';
import { formatDate } from '../GoogleAnalytics/common/HelperFunctions/utils';
import { useSelector,useDispatch } from 'react-redux';

const ApolloCliGetfbSocialMedia = new ApolloClient({
  uri: urls.facebookSocialMedia,
});

function FbSocialMediaDashboard(props) {
  secureSection();
  const dispatch = useDispatch();
  const {facebookPageData ,facebookPagePostData } = useSelector(
    (state) => state.facebookPage
  );

  const exportToPDF = async () => { 
    try {
      const capture = document.querySelector('.print');
      const canvas = await html2canvas(capture, { useCORS: true, scale: 3 }); // Adjust scale for higher resolution
      const imgData = canvas.toDataURL('image/png', 5.0); // Adjust quality
      const doc = new jsPDF('p', 'mm', 'a4');
      const componentWidth = doc.internal.pageSize.getWidth();
      const componentHeight = doc.internal.pageSize.getHeight();
      const padding = 10; // Adjust this value to set the padding in millimeters
      // Add padding to X and Y coordinates
      doc.addImage(
        imgData,
        'PNG',
        1 + padding,
        padding,
        componentWidth - 2 * padding,
        componentHeight - 2 * padding
      );
      // // Customize the appearance of the PDF
      // doc.setLineWidth(0.5); // Set line width for borders
      // doc.setDrawColor(103, 137, 242); // Set border color to black
      // doc.rect(1, 1, componentWidth - 1, componentHeight - 1, 'S'); // Add a border around the content
      doc.save('Rapport-Instagram-Ads.pdf');
    } catch (error) {
      console.error('Error exporting to PDF:', error);
    }
  };
  const [loadingState, setLoadingState] = useState(false);

  const [facebookAudience, setFacebookAudience] = useState([]);
  const [facebookPagePost, setFacebookPagePost] = useState([]);
  // const [facebookPageData, setFacebookPageData] = useState([]);
  const [engageduser, setEngageduser] = useState('');
  const [likes_numbre, setLikes_numbre] = useState('');
  const [post_impressions, setpost_impressions] = useState('');
  const [post_engagements, setpost_engagements] = useState('');
  const [followers, setFollowers] = useState('');
  const [monthly_impressions, setMonthly_impressions] = useState('');
  const [page_fans_country, setPage_fans_country] = useState([]);
  const [page_fans_city, setPage_fans_city] = useState([]);
  const [fans_gender_age, setFans_gender_age] = useState([]);
  const [page_impressions_paid, setpage_impressions_paid] = useState([]);
  const [page_posts_impressions_paid, setpage_posts_impressions_paid] =
    useState([]);

  const { dateRange } = useSelector((state) => state.dateRangeReducer);
  console.log('im in facebeook', dateRange.selection1);
  const [selectedImage, setSelectedImage] = useState(null);
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      // You can use FileReader to display the selected image
      const reader = new FileReader();
      reader.onload = (event) => {
        setSelectedImage(event.target.result);
      };
      reader.readAsDataURL(file);
    }
  };
  // GET FACEBOOK SOCIAL MEDIA AUDIENCE
  useEffect(() => {
    ApolloCliGetfbSocialMedia.query({
      query: getfbPageAudience,

      context: {
        headers: {
          Authorization: window.localStorage.getItem('token')
            ? `Bearer ${window.localStorage.getItem('token')}`
            : '',
        },
      },
      errorPolicy: 'all',
      fetchPolicy: 'no-cache', //allow me to refecth data
    }).then((res) => {
      if (res.data.getfbPageAudience && res.data.getfbPageAudience.length > 0) {
        setFacebookAudience(res.data.getfbPageAudience);
        const firstAudience = res.data.getfbPageAudience[0];

        if (firstAudience.page_fans_country) {
          setPage_fans_country(firstAudience.page_fans_country);
        }
        if (firstAudience.page_fans_city) {
          setPage_fans_city(firstAudience.page_fans_city);
        }
        if (firstAudience.fans_gender_age) {
          setFans_gender_age(firstAudience.fans_gender_age);
        }
      }
    });
  }, []);
  // Calculate sums by gender
  const sums = {
    Female: 0,
    Male: 0,
    Under: 0,
  };
  let data = null;
  // console.log("fans_gender_age", fans_gender_age);
  if (
    fans_gender_age &&
    Array.isArray(fans_gender_age) &&
    fans_gender_age.length > 0
  ) {
    data = fans_gender_age[0];

    for (const key in data) {
      if (key.startsWith('F.')) {
        sums.Female += data[key];
      } else if (key.startsWith('M.')) {
        sums.Male += data[key];
      } else if (key.startsWith('U.')) {
        sums.Under += data[key];
      }
    }
  } else {
    // Handle the case when fans_gender_age is null, not an array, or empty
    console.log('The fans_gender_age array is empty, null, or not an array.');
  }

  // CREATE THE CHART DATA
  const dataChart = {
    labels: ['Female', 'Male', 'Unkown'],
    datasets: [
      {
        data: [sums.Female, sums.Male, sums.Under],
        backgroundColor: [
          'rgb(42, 181, 125)',
          'rgb(59, 91, 152)',
          'rgb(75, 166, 239)',
        ],
        hoverBorderColor: '#fff',
      },
    ],
  };

  // Extract unique age groups from the keys
  let ageGroups = [];

  // Checking if fans_gender_age is an array and has elements
  if (
    Array.isArray(fans_gender_age) &&
    fans_gender_age.length > 0 &&
    fans_gender_age[0]
  ) {
    ageGroups = Array.from(
      new Set(
        Object.keys(fans_gender_age[0])
          .filter(
            (key) =>
              key.startsWith('F.') ||
              key.startsWith('M.') ||
              key.startsWith('U.')
          )
          .map((key) => key.split('.')[1].trim())
      )
    );
  } else {
    console.log(
      'fans_gender_age is null, an empty array, or does not contain valid elements.'
    );
  }

  // Calculate sums by age and gender
  const sumsByAgeAndGender = ageGroups.map((ageGroup) => {
    const ageKey = `F.${ageGroup}`;
    return {
      ageGroup,
      Female: fans_gender_age[0][ageKey] || 0,
      Male: fans_gender_age[0][`M.${ageGroup}`] || 0,
      Under: fans_gender_age[0][`U.${ageGroup}`] || 0,
    };
  });
  for (const key in data) {
    const gender = key[0]; // First character represents gender (F, M, U)
    const ageGroup = key.replace(/[A-Z]\./, '').trim();

    const entry = sumsByAgeAndGender.find((item) => item.ageGroup === ageGroup);
    if (entry) {
      entry[gender] += data[key];
    }
  }

  // Create the chart data
  const chartData = {
    labels: ageGroups,
    datasets: [
      {
        label: props.t('Female'),
        data: sumsByAgeAndGender.map((entry) => entry.Female),
        backgroundColor: 'rgb(42, 181, 125)', // Female
      },
      {
        label: props.t('Male'),
        data: sumsByAgeAndGender.map((entry) => entry.Male),
        backgroundColor: 'rgb(59, 91, 152)', // Male
      },
      {
        label: props.t('Unkown'),
        data: sumsByAgeAndGender.map((entry) => entry.Under),
        backgroundColor: 'rgb(75, 166, 239)', // Under
      },
    ],
  };

  // GET ALL PAGE POST DATA
  useEffect(() => {
    // ApolloCliGetfbSocialMedia.query({
    //   query: getfbPagePosts,

    //   context: {
    //     headers: {
    //       Authorization: window.localStorage.getItem('token')
    //         ? `Bearer ${window.localStorage.getItem('token')}`
    //         : '',
    //     },
    //   },
    //   errorPolicy: 'all',
    //   fetchPolicy: 'no-cache', //allow me to refecth data
    // }).then((res) => {
    //   setFacebookPagePost(res.data.getfbPagePosts);
    // });
    dispatch(getFacebookPagePostData())
  }, []);

  const startDate = formatDate(dateRange.selection1.startDate);
  const endDate = formatDate(dateRange.selection1.endDate);

  // GET PAGE DATA
  useEffect(() => {
  
      if (facebookPageData && facebookPageData.length > 0) {
        let total_page_posts_impressions_paid = 0; // Initialize total_page_posts_impressions_paid before the map function
        let total_monthly_impressions = 0;
        let total_post_engagements = 0;
        let total_post_impressions = 0;
        let total_page_impressions_paid = 0;

        facebookPageData.forEach((item) => {
          total_monthly_impressions += item.monthly_impressions;
          total_post_engagements += item.post_engagements;
          total_post_impressions += item.post_impressions;
          total_page_impressions_paid += item.page_impressions_paid;
          total_page_posts_impressions_paid += item.page_posts_impressions_paid;
        });

        // setFacebookPageData(getfbPageData);
        setEngageduser(facebookPageData[0].engageduser);
        setLikes_numbre(facebookPageData[0].likes_numbre);
        setFollowers(facebookPageData[0].followers);
        setpost_engagements(total_post_engagements);
        setpost_impressions(total_post_impressions);
        //setShares(res.data.getfbPageData[0].Shares);
        setMonthly_impressions(total_monthly_impressions);
        setpage_impressions_paid(total_page_impressions_paid);
        setpage_posts_impressions_paid(total_page_posts_impressions_paid);
      }
    } , [facebookPageData]);
    

  useEffect(() => {
dispatch(getFacebookPageDataPerDate(startDate,endDate))

  }, [dateRange]);

  const [secCards, setsecCards] = useState([
    { id: 1, name: 'Engaged User', data: engageduser },
    { id: 2, name: 'Likes', data: likes_numbre },
    { id: 3, name: 'Followers', data: followers },

    { id: 4, name: 'Monthly Impressions', data: monthly_impressions },
    { id: 5, name: 'Post Engagements', data: post_engagements },
    { id: 6, name: 'Post Impressions', data: post_impressions },
    { id: 7, name: 'Page Impressions Paid', data: page_impressions_paid },
    {
      id: 8,
      name: 'Page Posts Impressions Paid',
      data: page_posts_impressions_paid,
    },
  ]);
  useEffect(() => {
    let isSubscribed = true;
    setsecCards([
      { id: 1, name: 'Engaged User', data: engageduser },
      { id: 2, name: 'Likes', data: likes_numbre },
      { id: 3, name: 'Followers', data: followers },

      { id: 4, name: 'Monthly Impressions', data: monthly_impressions },
      { id: 5, name: 'Post Engagements', data: post_engagements },
      { id: 6, name: 'Post Impressions', data: post_impressions },
      { id: 7, name: 'Page Impressions Paid', data: page_impressions_paid },
      {
        id: 8,
        name: 'Page Posts Impressions Paid',
        data: page_posts_impressions_paid,
      },
    ]);
    return () => (isSubscribed = false);
  }, [
    engageduser,
    likes_numbre,
    followers,
    monthly_impressions,
    post_impressions,
    post_engagements,
    page_impressions_paid,
    page_posts_impressions_paid,
  ]);
  return (
    <Row>
      <div className="page-content">
        <MetaTags>
          <title>
            {props.t('Facebook Social Media Dashboard')} | Convergen-Metrics -
            E-com SaaS
          </title>
        </MetaTags>

        <Breadcrumbs
          title={props.t('Social Media Dashboard')}
          breadcrumbItem={props.t('Facebook Social Media Dashboard')}
          // pageHeading={props.t("Facebook Social Media Dashboard")}
        />
        <Container fluid className="print">
          <Card className="tamour">
            <CardBody className="print">
              <div>
                <div className="invoice-title">
                  <div className="d-flex align-items-start">
                    <div className="flex-grow-1">
                      <div className="">
                        <img src={logo} alt="" height="27" />
                      </div>
                    </div>

                    <div className="flex-shrink-0">
                      <div className="">
                        <h4 className="float-end font-size-16">
                          {props.t('Facebook Social Media Report')}
                        </h4>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="flex-grow-1">
                      <div className=""></div>
                    </div>
                    <div className="flex-grow-1">
                      <img
                        src={facebookPageData[0]?.picture}
                        alt=""
                        height="90"
                        width="90"
                        style={{ borderRadius: '50%' }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </CardBody>
          </Card>

          <Row>
            <Col lg="12">
              <FbSocialMediaCards secCards={secCards} />

              <FbSocialMediaPostsTable dataSource={facebookPagePostData} />
            </Col>
          </Row>
          <Row>
            {/* <Col xl={6}>
              <FbSocialMediaGender dataChart={dataChart} />
            </Col>
            <Col xl={6}>
              <FbSocialMediaAge chartData={chartData} />
            </Col> */}
            <hr />
            <div className="d-print-none mt-4">
              <div className="float-end">
                {/* <button
                  type="button"
                  onClick={exportToPDF('Rapport-Instagram-Ads.pdf')}
                  className="btn btn-primary waves-effect waves-light w-md"
                >
                  <div className="d-flex align-items-center">
                    {' '}
                    {/* Use Flexbox to align icon and text */}
                {/* <i
                      className="mdi mdi-download d-block font-size-16"
                      style={{ color: 'white' }}
                    ></i>
                    <span className="ms-2">{props.t('Download')}</span>{' '}  */}
                {/* Add margin for space between icon and text */}
                {/* </div>
                </button> */}
              </div>
            </div>
          </Row>
        </Container>
      </div>
    </Row>
  );
}
FbSocialMediaDashboard.propTypes = {
  t: PropTypes.any,
};
export default withRouter(withTranslation()(FbSocialMediaDashboard));
//export default FbSocialMediaDashboard
