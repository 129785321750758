import React, { useEffect, useState } from 'react';
import { Col, Row } from 'reactstrap';

import GoogleAdsChart from '../../GoogleDashboard/Google Ads Overview/GoogleAdsChart/GoogleAdsChart';
import GACardnew from '../common/GACardnew';

const GAEcommerceAffilitions = ({
  EcommerceAffiliationDashboard,
  comparedEcommerceAffiliationDashboard,
  loadingState,
}) => {

  const [ecommercePurchases, setEcommercePurchases] = useState([]);
  const [purchaserRate, setPurchaserRate] = useState([]);
  const [purchaseRevenue, setPurchaseRevenue] = useState([]);
  const [averagePurchaseRevenue, setAveragePurchaseRevenue] = useState([]);
  const [
    averagePurchaseRevenuePerPayingUser,
    setAveragePurchaseRevenuePerPayingUser,
  ] = useState([]);
  const [comparedEcommercePurchases, setComparedEcommercePurchases] = useState(
    []
  );
  const [comparedPurchaserRate, setComparedPurchaserRate] = useState([]);
  const [comparedPurchaseRevenue, setComparedPurchaseRevenue] = useState([]);
  const [comparedAveragePurchaseRevenue, setComparedAveragePurchaseRevenue] =
    useState([]);
  const [
    comparedAveragePurchaseRevenuePerPayingUser,
    setComparedAveragePurchaseRevenuePerPayingUser,
  ] = useState([]);

  useEffect(() => {
    let isSubscribed = true;
    var TecommercePurchases = [];
    var TpurchaserRate = [];
    var TpurchaseRevenue = [];
    var TaveragePurchaseRevenue = [];
    var TaveragePurchaseRevenuePerPayingUser = [];
    if (EcommerceAffiliationDashboard.length !== 0) {
      EcommerceAffiliationDashboard.map((elem) => {
        TecommercePurchases.push(
          elem.ecommercePurchases ? elem.ecommercePurchases : 0
        );
        TpurchaserRate.push(elem.purchaserRate ? elem.purchaserRate : 0);
        TpurchaseRevenue.push(elem.purchaseRevenue ? elem.purchaseRevenue : 0);
        TaveragePurchaseRevenue.push(
          elem.averagePurchaseRevenue ? elem.averagePurchaseRevenue : 0
        );
        TaveragePurchaseRevenuePerPayingUser.push(
          elem.averagePurchaseRevenuePerPayingUser
            ? elem.averagePurchaseRevenuePerPayingUser
            : 0
        );
      });
    }
    setEcommercePurchases(TecommercePurchases);
    setPurchaserRate(TpurchaserRate);
    setPurchaseRevenue(TpurchaseRevenue);
    setAveragePurchaseRevenue(TaveragePurchaseRevenue);
    setAveragePurchaseRevenuePerPayingUser(
      TaveragePurchaseRevenuePerPayingUser
    );
    return () => (isSubscribed = false);
  }, [EcommerceAffiliationDashboard]);

  useEffect(() => {
    let isSubscribed = true;
    var TecommercePurchases = [];
    var TpurchaserRate = [];
    var TpurchaseRevenue = [];
    var TaveragePurchaseRevenue = [];
    var TaveragePurchaseRevenuePerPayingUser = [];
    if (comparedEcommerceAffiliationDashboard.length !== 0) {
      comparedEcommerceAffiliationDashboard.map((elem) => {
        TecommercePurchases.push(
          elem.ecommercePurchases ? elem.ecommercePurchases : 0
        );
        TpurchaserRate.push(elem.purchaserRate ? elem.purchaserRate : 0);
        TpurchaseRevenue.push(elem.purchaseRevenue ? elem.purchaseRevenue : 0);
        TaveragePurchaseRevenue.push(
          elem.averagePurchaseRevenue ? elem.averagePurchaseRevenue : 0
        );
        TaveragePurchaseRevenuePerPayingUser.push(
          elem.averagePurchaseRevenuePerPayingUser
            ? elem.averagePurchaseRevenuePerPayingUser
            : 0
        );
      });
    }
    setComparedEcommercePurchases(TecommercePurchases);
    setComparedPurchaserRate(TpurchaserRate);
    setComparedPurchaseRevenue(TpurchaseRevenue);
    setComparedAveragePurchaseRevenue(TaveragePurchaseRevenue);
    setComparedAveragePurchaseRevenuePerPayingUser(
      TaveragePurchaseRevenuePerPayingUser
    );
    return () => (isSubscribed = false);
  }, [comparedEcommerceAffiliationDashboard]);

 

  const [myCards, setMyCards] = useState([
    {
      id: 1,
      name: 'Ecommerce Purchases',
      data: ecommercePurchases,
      compareData: comparedEcommercePurchases,
    },
    {
      id: 2,
      name: 'Purchaser Rate',
      data: purchaserRate,
      compareData: comparedPurchaserRate,
    },
    {
      id: 3,
      name: 'Purchase Revenue',
      data: purchaseRevenue,
      compareData: comparedPurchaseRevenue,
    },
    {
      id: 4,
      name: 'Average Purchase Revenue',
      data: averagePurchaseRevenue,
      compareData: comparedAveragePurchaseRevenue,
    },
    {
      id: 5,
      name: 'Average Purchase Revenue Per Paying User',
      data: averagePurchaseRevenuePerPayingUser,
      compareData: comparedAveragePurchaseRevenuePerPayingUser,
    },
  ]);
  useEffect(() => {
    let isSubscribed = true;
    setMyCards([
      {
        id: 1,
        name: 'Ecommerce Purchases',
        data: ecommercePurchases,
        compareData: comparedEcommercePurchases,
      },
      {
        id: 2,
        name: 'Purchaser Rate',
        data: purchaserRate,
        compareData: comparedPurchaserRate,
      },
      {
        id: 3,
        name: 'Purchase Revenue',
        data: purchaseRevenue,
        compareData: comparedPurchaseRevenue,
      },
      {
        id: 4,
        name: 'Average Purchase Revenue',
        data: averagePurchaseRevenue,
        compareData: comparedAveragePurchaseRevenue,
      },
      {
        id: 5,
        name: 'Average Purchase Revenue Per Paying User',
        data: averagePurchaseRevenuePerPayingUser,
        compareData: comparedAveragePurchaseRevenuePerPayingUser,
      },
    ]);
    return () => (isSubscribed = false);
  }, [
    ecommercePurchases,
    purchaserRate,
    purchaseRevenue,
    averagePurchaseRevenue,
    averagePurchaseRevenuePerPayingUser,
    comparedEcommercePurchases,
    comparedPurchaserRate,
    comparedPurchaseRevenue,
    comparedAveragePurchaseRevenue,
    comparedAveragePurchaseRevenuePerPayingUser,
  ]);

  const seriesData = [
    {
      name: 'ecommerce Purchases',
      data: ecommercePurchases,
      compareData: comparedEcommercePurchases,
      value: 'Ecommerce Purchases',
    },
    {
      name: 'purchaser Rate',
      data: purchaserRate,
      compareData: comparedPurchaserRate,
      value: 'Purchaser Rate',
    },
    {
      name: 'purchase Revenue',
      data: purchaseRevenue,
      compareData: comparedPurchaseRevenue,
      value: 'Purchase Revenue',
    },
    {
      name: 'average Purchase Revenue',
      data: averagePurchaseRevenue,
      compareData: comparedAveragePurchaseRevenue,
      value: 'Average Purchase Revenue',
    },
    {
      name: 'average Purchase Revenue Per PayingUser',
      data: averagePurchaseRevenuePerPayingUser,
      compareData: comparedAveragePurchaseRevenuePerPayingUser,
      value: 'Average Purchase Revenue Per PayingUser',
    },
  ];

  const colors = [
    {
      lineColor: '#FE915B',
      fillColor: [
        {
          offset: 0,
          color: '#FE915B',
        },
        {
          offset: 0.47,
          color: 'rgba(254, 145, 91, 0.7)',
        },
        {
          offset: 1,
          color: 'rgba(254, 145, 91, 0.1)',
        },
      ],
    },
    {
      lineColor: '#F6B43B',
      fillColor: [
        {
          offset: 0,
          color: ' #F6B43B',
        },

        {
          offset: 1,
          color: '#D9D9D9',
        },
      ],
    },
  ];
  return (
    <React.Fragment>
      <Row>
        <Col xl={12}>
          <GoogleAdsChart series={seriesData} colors={colors} />
        </Col>
      </Row>
      <Row className=" justify-content-center my-5">
        <GACardnew data={myCards} />
      </Row>
    </React.Fragment>
  );
};

export default GAEcommerceAffilitions;
