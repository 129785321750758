
import React, { useState, useEffect } from 'react';
import ApolloClient from 'apollo-boost';
import {  Row } from 'reactstrap';
import { addCost } from '../../queries/Costs/Costs';
import urls from '../../routes/apiUrls';
import Select from "react-select"
import "@vtaits/react-color-picker/dist/index.css"
import "react-datepicker/dist/react-datepicker.css"
import './costs.css';
import './viewCosts.css'
import Flatpickr from "react-flatpickr"
import { withTranslation } from "react-i18next"
import { withRouter } from "react-router-dom"
import PropTypes from 'prop-types';
const ApploCLI = new ApolloClient({
  uri: urls.cost
});
function AddNewCost(props) {
  const [title, setTitle] = useState('');
  const [price, setPrice] = useState('');
  const [activeDate, setActiveDate] = useState('dd-MM-yyyy');
  const [endDate, setEndDate] = useState('dd-MM-yyyy');
  const [costForm, setCostForm] = useState({ title: '', category: '', frequency: '', startedDate: new Date().toISOString().split('T')[0], endedDate: new Date().toISOString().split('T')[0], price: '' });
  const [dropDownV, setDropDownV] = useState({});
  const [dropDownVM, setDropDownVM] = useState({});
  const [isEmpty, setIsEmpty] = useState({ titleState: true, categoryState: true, frequencyState: true, dateState: true, endedDateState: true, priceState: true });
  const changeCostForm = (name, value) => {
    setCostForm(prevState => ({ ...prevState, [name]: value }));
  }
  const handleChangeD = (e) => {
    setDropDownV(e.value);
    changeCostForm('frequency', e.value)
    setIsEmpty({ ...isEmpty, frequencyState: false })
  }
  const handleChangeDM = (e) => {
    setDropDownVM(e.value);
    changeCostForm('category', e.value)
    setIsEmpty({ ...isEmpty, categoryState: false })
  }
  const MiscOptions = [
    { label: 'Marketing', value: 'Marketing' },
    { label: 'Advertising', value: 'Advertising' },
    { label: 'Salaries', value: 'Salaries' },
    { label: 'Outsourcing', value: 'Outsourcing' },
    { label: 'Subscription', value: 'Subscription' },
  ]
  const options = [
    { label: 'Daily', value: "Daily" },
    { label: 'Weekly', value: "Weekly" },
    { label: 'Monthly', value: "Monthly" },
    { label: 'Yearly', value: "Yearly" },
    { label: 'Bi-weekly', value: "Bi-weekly" },
  ]
  const save = () => {
    console.log("testtt",costForm)
    ApploCLI.mutate({
      mutation: addCost,
      variables: {
        title: costForm.title,
        category: costForm.category,
        frequency: costForm.frequency,
        startedDate: activeDate,
        endedDate: endDate,
        price: parseFloat(costForm.price)
      },
      context: {
        headers: {
          Authorization: window.localStorage.getItem("token") ? `Bearer ${window.localStorage.getItem("token")}` : ""
        }
      },
      errorPolicy: 'all',
    }).then(data => {
      props.addCost(false)
    }
    )
  }
  useEffect(() => {
    if (title.length !== 0)
      setIsEmpty({ ...isEmpty, titleState: false });
    if (price.length !== 0)
      setIsEmpty({ ...isEmpty, priceState: false });
    if (activeDate.length !== 0)
      setIsEmpty({ ...isEmpty, dateState: false });
    if (endDate.length !== 0)
      setIsEmpty({ ...isEmpty, endedDateState: false });
    if (dropDownV.length !== 0)
      setIsEmpty({ ...isEmpty, frequencyState: false });
    if (dropDownVM.length !== 0)
      setIsEmpty({ ...isEmpty, categoryState: false });
  }, [title,price,activeDate,endDate, dropDownV, dropDownVM])


  console.log("************activeDate**************[startedDate]",activeDate);
  function handleChangeName(e) {
    const value = e.target.value;
    setIsEmpty(prevState => ({ ...prevState, titleState: value.length === 0 }));
    setTitle(value);
    changeCostForm('title', value);
  }
  function handleChangePrice(e) {
    const value = e.target.value;
    setIsEmpty(prevState => ({ ...prevState, priceState: value.length === 0 }));
    setPrice(value);
    changeCostForm('price', value);
  }
  function handleChangeDate(e) {
    let dateT = new Date(e[0]);
    let newdate = dateT.getFullYear() + '-' + (dateT.getMonth() + 1) + '-' + dateT.getDate();
    setActiveDate(newdate);
    setIsEmpty({ ...isEmpty, dateState: false })
    changeCostForm('date', newdate);
    console.log('handleChangeDate', costForm)
    console.log('dateT', dateT)
    console.log('newdate', newdate)
  }
  function handleChangeEndDate(e) {
    let dateT = new Date(e[0]);
    let newdate = dateT.getFullYear() + '-' + (dateT.getMonth() + 1) + '-' + dateT.getDate();
    setEndDate(newdate);
    costForm.endedDate = newdate;
    setIsEmpty({ ...isEmpty, endedDateState: false });
    changeCostForm('endedDate', newdate);
  }
  return (
  
    <tr  >
      <td >
        <input type="text" className="form-control"
            required
            defaultValue={props.t("title")}
            value={title}
            onChange={(e) => handleChangeName(e)}
          />      </td>
      <td >
        <Row>
          <Select
            onChange={(e) => handleChangeDM(e)}
            options={MiscOptions}
            classNamePrefix="select2-selection"
          />
        </Row>
      </td>
      <td>
        <Row>
          <Select
       
            size="mini"
            onChange={(e) => handleChangeD(e)}
            options={options}
            classNamePrefix="select2-selection"
          />
        </Row>
      </td>
      <td>
        <Flatpickr
          name="date"
          className="form-control d-block"
          placeholder="dd-MM-yyyy"
          value={activeDate}
          onChange={(e) => handleChangeDate(e)}
          id="change_handler_example" />
      </td>
      <td>
        <Flatpickr
          name="endedDate"
          className="form-control d-block"
          placeholder="dd-MM-yyyy"
          value={endDate}
          onChange={(e) => handleChangeEndDate(e)}
          id="change_handler_example2" />
      </td>
      <td>
        <input type="number" className="form-control" error={isEmpty.priceState}
            required
            defaultValue={price}
            value={price}
            name="price"
            min="1"
            max="10000"
            onChange={(e) => handleChangePrice(e)}
          />
      </td>
      <td>
        <div className="btnTab">
          <button disabled={isEmpty.priceState || isEmpty.categoryState || isEmpty.titleState || isEmpty.frequencyState} className="btn btn-success" onClick={() => { save();  }}>{props.t("save")}</button>
          <button className="btn btn-danger ms-2" onClick={() => { props.addCost(false); }}>{props.t("Cancel")}</button>
        </div>
      </td>
    </tr>
 
  )
}
AddNewCost.propTypes = {
  t: PropTypes.any
};
export default withRouter(withTranslation() (AddNewCost));
