import {
  INTEGRATE_GOOGLE_ANALYTICS_FAILURE,
  INTEGRATE_GOOGLE_ANALYTICS_SUCCESS,
  INTEGRATE_GOOGLE_ANALYTICS_REQUEST,
  GET_GOOGLE_ANALYTICS_PRODUCTS_REQUEST,
  GET_GOOGLE_ANALYTICS_PRODUCTS_SUCCESS,
  GET_GOOGLE_ANALYTICS_PRODUCTS_FAILURE,
  GET_GOOGLE_ANALYTICS_GAPLATFORM_REQUEST,
  GET_GOOGLE_ANALYTICS_GAPLATFORM_SUCCESS,
  GET_GOOGLE_ANALYTICS_GAPLATFORM_FAILURE,
  GET_GOOGLE_ANALYTICS_USERS_REQUEST,
  GET_GOOGLE_ANALYTICS_USERS_SUCCESS,
  GET_GOOGLE_ANALYTICS_USERS_FAILURE,
  GET_GOOGLE_ANALYTICS_ACCOUNTS_REQUEST,
  GET_GOOGLE_ANALYTICS_ACCOUNTS_SUCCESS,
  GET_GOOGLE_ANALYTICS_ACCOUNTS_FAILURE,
  GET_GOOGLE_ANALYTICS_AUDIENCE_REQUEST,
  GET_GOOGLE_ANALYTICS_AUDIENCE_SUCCESS,
  GET_GOOGLE_ANALYTICS_AUDIENCE_FAILURE,
  GET_GOOGLE_ANALYTICS_AFFILIATIONS_SUCCESS,
  GET_GOOGLE_ANALYTICS_AFFILIATIONS_REQUEST,
  GET_GOOGLE_ANALYTICS_AFFILIATIONS_FAILURE,
  GET_GOOGLE_ANALYTICS_TRAFFIC_ACQUISITION_FAILURE,
  GET_GOOGLE_ANALYTICS_TRAFFIC_ACQUISITION_REQUEST,
  GET_GOOGLE_ANALYTICS_TRAFFIC_ACQUISITION_SUCCESS,
  GET_GOOGLE_ANALYTICS_PURCHASE_JOURNEY_REQUEST,
  GET_GOOGLE_ANALYTICS_PURCHASE_JOURNEY_SUCCESS,
  GET_GOOGLE_ANALYTICS_PURCHASE_JOURNEY_FAILURE,
} from './actionsTypes';

const initialState = {
  loading: false,
  loadingIntegration: false,
  error: null,
  Step: 1,
  accounts: [],
  data: [],
  ReachedFinalStep: false,
  soldProducts: [],
  gaUsers: [],
  gaPlatform: [],
  gaAudience: [],
  gaAffiliations: [],
  gaTrafficAcquisition: [],
  gaPurchaseJourney: [],
};

const googleAnalyticsReducer = (state = initialState, action) => {
  switch (action.type) {
    case INTEGRATE_GOOGLE_ANALYTICS_REQUEST:
      return {
        ...state,
        loadingIntegration: true,
        error: null,
      };
    case INTEGRATE_GOOGLE_ANALYTICS_SUCCESS:
      return {
        ...state,
        Step: 2,
        data: action.payload,
        ReachedFinalStep: true,
        loadingIntegration: false,
      };
    case INTEGRATE_GOOGLE_ANALYTICS_FAILURE:
      return {
        ...state,
        loadingIntegration: false,
        error: action.payload,
      };
    case GET_GOOGLE_ANALYTICS_ACCOUNTS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GET_GOOGLE_ANALYTICS_ACCOUNTS_SUCCESS:
      return {
        ...state,
        loading: false,
        accounts: action.payload,
      };
    case GET_GOOGLE_ANALYTICS_ACCOUNTS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case GET_GOOGLE_ANALYTICS_PRODUCTS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GET_GOOGLE_ANALYTICS_PRODUCTS_SUCCESS:
      return {
        ...state,
        loading: false,
        soldProducts: action.payload,
      };
    case GET_GOOGLE_ANALYTICS_PRODUCTS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case GET_GOOGLE_ANALYTICS_USERS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GET_GOOGLE_ANALYTICS_USERS_SUCCESS:
      return {
        ...state,
        loading: false,
        gaUsers: action.payload,
      };
    case GET_GOOGLE_ANALYTICS_USERS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case GET_GOOGLE_ANALYTICS_GAPLATFORM_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GET_GOOGLE_ANALYTICS_GAPLATFORM_SUCCESS:
      return {
        ...state,
        loading: false,
        gaPlatform: action.payload,
      };
    case GET_GOOGLE_ANALYTICS_GAPLATFORM_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case GET_GOOGLE_ANALYTICS_AUDIENCE_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GET_GOOGLE_ANALYTICS_AUDIENCE_SUCCESS:
      return {
        ...state,
        loading: false,
        gaAudience: action.payload,
      };
    case GET_GOOGLE_ANALYTICS_AUDIENCE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case GET_GOOGLE_ANALYTICS_AFFILIATIONS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GET_GOOGLE_ANALYTICS_AFFILIATIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        gaAffiliations: action.payload,
      };
    case GET_GOOGLE_ANALYTICS_AFFILIATIONS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case GET_GOOGLE_ANALYTICS_TRAFFIC_ACQUISITION_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GET_GOOGLE_ANALYTICS_TRAFFIC_ACQUISITION_SUCCESS:
      return {
        ...state,
        loading: false,
        gaTrafficAcquisition: action.payload,
      };
    case GET_GOOGLE_ANALYTICS_TRAFFIC_ACQUISITION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case GET_GOOGLE_ANALYTICS_PURCHASE_JOURNEY_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GET_GOOGLE_ANALYTICS_PURCHASE_JOURNEY_SUCCESS:
      return {
        ...state,
        loading: false,
        gaPurchaseJourney: action.payload,
      };
    case GET_GOOGLE_ANALYTICS_PURCHASE_JOURNEY_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default googleAnalyticsReducer;
