import React, { useEffect, useState } from 'react';
import { Card, CardBody, Col, Row } from 'reactstrap';
import { format } from 'date-fns';
import { Button, Space, Table, Menu, Dropdown, Checkbox } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';
import ReactApexChart from 'react-apexcharts';
import FeatherIcon from 'feather-icons-react';
import Breadcrumbs from '../../components/Common/Breadcrumb';

import { getInstaPageData, getInstaPostData, getInstaAudienceData } from '../../store/instagram/actions';
import { useDispatch, useSelector } from 'react-redux';
 import Cardswithchart from './Cardswithchart';

 import instagramIcon  from "../../assets/integrations_images/insta.svg"

const InstagramOverview = (props) => {
  const dispatch = useDispatch();
  const { instaPageData, instaPostData } = useSelector((state) => state.Instagram);

  const [filteredInfo, setFilteredInfo] = useState({});
  const [sortedInfo, setSortedInfo] = useState({});
  const [reach, setreach] = useState('');
  const [impressions, setimpressions] = useState('');
  const [follower_count, setfollower_count] = useState('');
  const [profile_views, setprofile_views] = useState('');
  const [website_clicks, setwebsite_clicks] = useState('');

  
  const [paginationOptions, setPaginationOptions] = useState({
    pageSize: 5, // Default page size
    pageSizeOptions: ['5', '10', '20'], // Available page size options
  });
  
 

  const formatDate = (date) => {
    const d = new Date(date);
    const year = d.getFullYear();
    const month = String(d.getMonth() + 1).padStart(2, '0');
    const day = String(d.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  const formattedDateRange = {
    selection1: {
      startDate: format(new Date(props.dateRange.selection1.startDate), 'yyyy-MM-dd'),
      endDate: format(new Date(props.dateRange.selection1.endDate), 'yyyy-MM-dd'),
      key: props.dateRange.selection1.key,
    },
  };

  useEffect(() => {
  
      dispatch(getInstaPageData(formattedDateRange.selection1.startDate, formattedDateRange.selection1.endDate));
    

  }, [props.dateRange]);
  

  useEffect(() => {
    if (instaPageData) {
      let total_reach = 0; // Initialize total_reach before the map function
      let total_impressions = 0;
      let total_follower_count = 0;
      let total_profile_views = 0;
      let total_website_clicks = 0;

      instaPageData.forEach((item) => {
        total_reach += item.reach;
        total_impressions += item.impressions;
        total_follower_count += item.follower_count;
        total_profile_views += item.profile_views;
        total_website_clicks += item.website_clicks;
      });
      setreach(total_reach);
      setimpressions(total_impressions);
      setfollower_count(total_follower_count);
      setprofile_views(total_profile_views);
      setwebsite_clicks(total_website_clicks);

      





    }
  }, [instaPageData]);

  
  useEffect(() => {
    dispatch(getInstaPostData());
  }, []);
  const metrics = [
    {
      title: "Impressions",
      number: impressions,
      percentage: "23%", // Static data for testing
      trend: "up",
      graphData: instaPageData.map(item => ({
        name: formatDate(item.day),
        value: item.impressions,
      })),
    },
    {
      title: "New Followers",
      number: follower_count,
      percentage: "5098", // Static data for testing
      trend: "up",
      graphData: instaPageData.map(item => ({
        name: formatDate(item.day),
        value: item.follower_count,
      })),
    },
    {
      title: "Reach",
      number: reach,
      percentage: "-5%", // Static data for testing
      trend: "down",
      graphData: instaPageData.map(item => ({
        name: formatDate(item.day),
        value: item.reach,
      })),
    },
    {
      title: "Profile views",
      number: profile_views,
      percentage: "12%", // Static data for testing
      trend: "up",
      graphData: instaPageData.map(item => ({
        name: formatDate(item.day),
        value: item.profile_views,
      })),
    },
  ];
  const columns = [

    {
      title: props.t('POST IMAGE'),
      dataIndex: 'media_url',
      key: 'media_url',
      align: 'center',
      render: (text, record) => {
        if (!text) {
          return (
            <a href={record.permalink} target="_blank" rel="noopener noreferrer">
              <span>{' There is Non-image '}</span>
            </a>
          );
        } else {
          return (
            <a href={record.permalink} target="_blank" rel="noopener noreferrer">
              <img
                src={text}
                alt={`Full Picture ${record.full_picture}`}
                style={{ maxWidth: '100px', maxHeight: '90px' }}
              />
            </a>
          );
        }
      }
    },
    {
      title: props.t('INTERACTIONS'),
      dataIndex: 'total_interactions',
      key: 'total_interactions',
      sorter: (a, b) => a.total_interactions - b.total_interactions,
      sortOrder:
        sortedInfo.columnKey === 'total_interactions' ? sortedInfo.order : null,
      ellipsis: true,
      align: 'center',
      render: (text) => <strong className='table-cell-text' >{text}</strong>,
    },
    {
      title: props.t('SAVES'),
      dataIndex: 'saved',
      align: 'center',
      key: 'saved',
      sorter: (a, b) => a.saved - b.saved,
      sortOrder: sortedInfo.columnKey === 'saved' ? sortedInfo.order : null,
      ellipsis: true,
      render: (text) => <strong className='table-cell-text'>{text}</strong>,
    },
    {
      title: props.t('IMPRESSIONS'),
      dataIndex: 'impressions',
      key: 'impressions',
      align: 'center',
      sorter: (a, b) => a.impressions - b.impressions,
      sortOrder: sortedInfo.columnKey === 'impressions' ? sortedInfo.order : null,
      ellipsis: true,
      render: (text) => <strong className='table-cell-text'>{text || '-'}</strong>, // Render '-' if text is falsy
    },
    {
      title: props.t('REACH'),
      dataIndex: 'reach',
      align: 'center',
      key: 'reach',
      onFilter: (value, record) => record.address.includes(value),
      sorter: (a, b) => a.reach - b.reach,
      sortOrder: sortedInfo.columnKey === 'reach' ? sortedInfo.order : null,
      ellipsis: true,
      render: (text) => <strong className='table-cell-text'>{text}</strong>,
    },
    {
      title: props.t('COMMENTS'),
      dataIndex: 'comments',
      key: 'comments',
      align: 'center',
      sorter: (a, b) => a.comments - b.comments,
      sortOrder: sortedInfo.columnKey === 'comments' ? sortedInfo.order : null,
      ellipsis: true,
      render: (text) => <strong className='table-cell-text'>{text}</strong>,

    },
    {
      title: props.t('LIKES'),
      dataIndex: 'likes',
      key: 'likes',
      sorter: (a, b) => a.likes - b.likes,
      sortOrder: sortedInfo.columnKey === 'likes' ? sortedInfo.order : null,
      ellipsis: true,
      align: 'center',
      render: (text) => <strong className='table-cell-text'>{text}</strong>,
    },
    {
      title: props.t('PLAYS'),
      dataIndex: 'plays',
      align: 'center',
      key: 'plays',
      sorter: (a, b) => a.plays - b.plays,
      sortOrder: sortedInfo.columnKey === 'plays' ? sortedInfo.order : null,
      ellipsis: true,
      render: (text) => <strong className='table-cell-text'>{text || '-'}</strong>,
    },
    {
      title: props.t('SHARES'),
      dataIndex: 'shares',
      align: 'center',
      key: 'shares',
      sorter: (a, b) => a.shares - b.shares,
      sortOrder: sortedInfo.columnKey === 'shares' ? sortedInfo.order : null,
      ellipsis: true,
      render: (text) => <strong className='table-cell-text'>{text}</strong>,
    },
    {
      title: props.t('CREATED AT'),
      dataIndex: 'timestamp',
      align: 'center',
      key: 'timestamp',
      sorter: (a, b) => new Date(a.timestamp) - new Date(b.timestamp),
      ellipsis: true,
      render: (text) => {
        const date = new Date(text);
        const formattedDate = date.toISOString().split('T')[0]; // Extracts date portion
        return <strong className='table-cell-text'>{formattedDate}</strong>;
      },
    },

  ];
  const [selectedColumns, setSelectedColumns] = useState(
    columns.map((column) => column.key)
  );
  const handleChange = (pagination, filters, sorter) => {
    setFilteredInfo(filters);
    setSortedInfo(sorter);
    if (pagination.pageSize !== paginationOptions.pageSize) {
      setPaginationOptions({
        ...paginationOptions,
        pageSize: pagination.pageSize,
      });
    }
  };

  const clearAll = () => {
    setFilteredInfo({});
    setSortedInfo({});
    // make the columns  selected
    setSelectedColumns(columns.map((column) => column.key));
  };

  const setimpressionsSort = () => {
    setSortedInfo({
      order: 'descend',
      columnKey: 'impressions',
    });
  };
  // const clearFilters = () => {
  //     setFilteredInfo({});
  //   };
  const handleMenuClick = (clickedKey) => {
    const index = selectedColumns.indexOf(clickedKey);

    let updatedSelectedColumns = [];

    if (index === -1) {
      // Column was not selected before, add it to the selected columns
      updatedSelectedColumns = [...selectedColumns, clickedKey];
    } else {
      // Column was selected before, remove it from the selected columns
      updatedSelectedColumns = selectedColumns.filter(
        (key) => key !== clickedKey
      );
    }

    setSelectedColumns(updatedSelectedColumns);
  };
  const handleSelectAll = () => {
    if (selectedColumns.length === columns.length) {
      // Deselect all
      setSelectedColumns([]);
    } else {
      // Select all
      setSelectedColumns(columns.map((column) => column.key));
    }
  };
  const isAllSelected = selectedColumns.length === columns.length;
  const menu = (
    <Menu>
      <Menu.Item key="select-all" onClick={handleSelectAll}>
        <Checkbox
          checked={isAllSelected}
          style={{ marginRight: '8px' }}
        />
        <span style={{ color: 'black' }}>
           All
        </span>
      </Menu.Item>
      {columns.map((column) => (
        <Menu.Item
          key={column.key}
          onClick={() => handleMenuClick(column.key)}
        >
          <Checkbox
            checked={selectedColumns.includes(column.key)}
            style={{ marginRight: '8px' }}
          />
          <span
            style={
              selectedColumns.includes(column.key)
                ? { fontWeight: 'bold' }
                : {}
            }
          >
            {column.title}
          </span>
        </Menu.Item>
      ))}
    </Menu>
  );

  // new filter 


  const [statusFilter, setStatusFilter] = useState({
    IMAGE: false,
    VIDEO: false,
    CAROUSEL_ALBUM: false,
  });
  const [filteredData, setFilteredData] = useState(instaPostData);




// new filter 
const handleCheckboxChange = (e) => {
  const { name, checked } = e.target;

  const updatedFilter = {
    ...statusFilter,
    [name]: checked,
  };

  setStatusFilter(updatedFilter);

  const selectedStatuses = Object.keys(updatedFilter).filter(
    (key) => updatedFilter[key]
  );

  const filtered = instaPostData.filter((item) =>
    selectedStatuses.length > 0
      ? selectedStatuses.includes(item.media_type)
      : true
  );

  setFilteredData(filtered);
};

  const menufilter = (
    <Menu>
      <Menu.Item key='IMAGE'>
        <Checkbox
            name="IMAGE"
            checked={statusFilter.IMAGE}
            onChange={handleCheckboxChange}
        >
          IMAGE
        </Checkbox>
      </Menu.Item>
      <Menu.Item key="VIDEO">
        <Checkbox
          name="VIDEO"
          checked={statusFilter.VIDEO}
          onChange={handleCheckboxChange}
        >
          VIDEO
        </Checkbox>
      </Menu.Item>
      <Menu.Item key="CAROUSEL_ALBUM">
        <Checkbox
          name="CAROUSEL_ALBUM"
          checked={statusFilter.CAROUSEL_ALBUM}
          onChange={handleCheckboxChange}
        >
           CAROUSEL ALBUM
        </Checkbox>
      </Menu.Item>
     
    </Menu>
  );

  const filteredColumns = columns.filter((column) =>
    selectedColumns.includes(column.key)
  );

 

  const colors ={
    lineColor: '#FF5B41',
    offsetZERO: '#FF6241',
     offsetONE: '#FFD354',
     offsetTWO: '#ffffff',
  }


  return (
    <div className='mt-5'>
      <Col xl={12}>
      <Breadcrumbs
              image={instagramIcon}
              title="Instagram Social Page"
            />


        <Row>
        <Cardswithchart metrics={metrics} colors={colors} />
</Row>

       
        <Row>
          <Card style={{ border: "none"  ,marginTop:"60px"}}>
          
          <Breadcrumbs 
              image={instagramIcon}
              title="Instagram Posts"
            />
              <Space
                style={{
                  marginBottom: 16,
                }}
              >
                <Dropdown overlay={menu} trigger={['click']}>
                <Button className='custom-button'  >{props.t("Select metrics")} < DownOutlined/></Button>
                </Dropdown>
                {/* Add any additional buttons or controls specific to Instagram here */}
                <Button className='custom-button'  onClick={setimpressionsSort}>{props.t("Sort Impressions")}</Button>
                {/* <Button onClick={clearFilters}>Clear filters</Button> */}
                <Button className='custom-button'  onClick={clearAll}>{props.t("Clear filters and sorters")}</Button>
                

              </Space>
              <div className='table-border'>
              <div className="d-flex align-items-center" style={{padding:"20px "}}>
              <Dropdown overlay={menufilter} trigger={['click']}>
    <Button  className='custom-button' style={{ display: 'flex', alignItems: 'center', padding:'22px' }}>
      <FeatherIcon icon="filter" size="16" style={{ marginRight: '8px' }} />
      {props.t("Filter")}
    </Button>
  </Dropdown>
                
                <Link to="/InstagramDashboard" className="btn btn-primary btn-sm viewMetrics-button">
                  {props.t('View full metrics')}
                </Link>
              </div>
              <Table
                // style={{ border: "1px solid #e8e8e8" }}
                columns={filteredColumns}
                dataSource={filteredData}
                onChange={handleChange}
                pagination={{
                  ...paginationOptions,
                  onChange: handleChange,
                  showSizeChanger: true, // Enable the page size selector
                  style: {
                    backgroundColor: '#F4F2FF',
                    padding: '10px 15px',
                    marginTop: '2px',
                    borderRadius: '6px',
                    fontsize: '14px' ,
                fontweight: '700',
                color: '#6E6893',
                  },
                }}
                headerCellStyle={{
                  backgroundColor: '#FF0000', // Change this to your desired color
                  color: '#333333', // Text color
                }}
              />
              </div>
          
          </Card>
        </Row>
        
      </Col>
    </div>
  );

};

InstagramOverview.propTypes = {
  t: PropTypes.any,
};

export default withRouter(withTranslation()(InstagramOverview));
