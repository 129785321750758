import React, { useRef, useState } from 'react';
import {
  Space,
  Button,
  Dropdown,
  Menu,
  Input,
  Checkbox,
  Select,
  Tooltip,
  Table,
} from 'antd';
import { Card, CardBody } from 'reactstrap';
import FeatherIcon from 'feather-icons-react';
import { useDispatch, useSelector } from 'react-redux';
import { SearchOutlined, CloseOutlined, DownOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import { formatNumberWithSpaces } from '../GoogleAnalytics/common/HelperFunctions/utils';
import { Link } from 'react-router-dom';
import i18next from 'i18next';
import Breadcrumbs from '../../components/Common/Breadcrumb';

const GoogleAdsIcon = `${process.env.PUBLIC_URL}/images/integrations_images/GoogleADS.svg`;

const GoogleAdsCompaignTable = ({ overview }) => {
  const [filteredInfo, setFilteredInfo] = useState({});
  const [sortedInfo, setSortedInfo] = useState({});
  const [searchText, setSearchText] = useState('');
  const [paginationOptions, setPaginationOptions] = useState({
    pageSize: 5, // Default page size
    pageSizeOptions: ['5', '10', '20'], // Available page size options
  });

  const { metrcisbyCompaign } = useSelector((state) => state.GoogleAds);

  const handleChange = (pagination, filters, sorter) => {
    setFilteredInfo(filters);
    setSortedInfo(sorter);
    if (pagination.pageSize !== paginationOptions.pageSize) {
      setPaginationOptions({
        ...paginationOptions,
        pageSize: pagination.pageSize,
      });
    }
  };

  const clearFilters = () => {
    setFilteredInfo({});
  };

  const clearAll = () => {
    setFilteredInfo({});
    setSortedInfo({});
    setSelectedColumns(columns.map((column) => column.key));
  };

  const setAgeSort = () => {
    setSortedInfo({
      order: 'descend',
      columnKey: 'impressions',
    });
  };

  const columns = [
    {
      title: 'STATUS',
      dataIndex: 'status',
      key: 'status',
      align: 'center',
      width: 100,
      render: (status) => (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: status == '2' ? '#007F00' : 'grey',
            backgroundColor: status == '2' ? '#CDFFCD' : '#fff0f6',
            fontWeight: status == '2' ? 'bold' : 'normal',
            opacity: status == '2' ? '1' : 0.6,
            borderRadius: '12.67px',
          }}
        >
          <span
            style={{
              width: '10px',
              height: '10px',
              borderRadius: '50%',
              display: 'inline-block',
              backgroundColor: status == '2' ? 'green' : 'grey',
              marginRight: '8px',
            }}
          ></span>
          {status == '2' ? 'Active' : 'Inactive'}
        </div>
      ),
    },
    {
      title: i18next.t('NAME'),
      dataIndex: 'campaign_name',
      key: 'campaign_name',
      align: 'center',
      width: 200,
      filteredValue: filteredInfo.campaign_name || null,
      // onFilter: (value, record) => record.campaign_name.includes(value),
      // ...getColumnSearchProps('campaign_name'),
      // ellipsis: true,
      render: (text) => (
        <span className='table-cell-text'>{text}</span>
  
    ),
    },
    {
      title: i18next.t('SPEND'),
      dataIndex: 'metrics.totalCost',
      align: 'center',
      key: 'spend',
      sorter: (a, b) => a.metrics.totalCost - b.metrics.totalCost,
      sortOrder:
        sortedInfo && sortedInfo.columnKey === 'spend'
          ? sortedInfo.order
          : null,
      ellipsis: true,
      render: (text, record) => (
        <strong className='table-cell-text'>{record.metrics.totalCost.toFixed(2)}$</strong>
      ),
    },
    {
      title: i18next.t('REVENUE'),
      dataIndex: 'totalConversionsValue',
      key: 'totalConversionsValue',
      sorter: (a, b) =>
        a.metrics.totalConversionsValue - b.metrics.totalConversionsValue,
      sortOrder:
        sortedInfo.columnKey === 'totalConversionsValue'
          ? sortedInfo.order
          : null,
      ellipsis: true,
      align: 'center',
      render: (text, record) => (
        <strong className='table-cell-text'>{record.metrics.totalConversionsValue.toFixed(2)}$</strong>
      ),
    },
    {
      title: i18next.t('CONVERSIONS'),
      dataIndex: 'totalConversions',
      key: 'totalConversions',
      align: 'center',
      width: 150,
      sorter: (a, b) => a.metrics.totalConversions - b.metrics.totalConversions,
      sortOrder:
        sortedInfo.columnKey === 'totalConversions' ? sortedInfo.order : null,
      ellipsis: true,
      render: (text, record) => (
        <strong className='table-cell-text'>{record.metrics.totalConversions.toFixed(2)}</strong>
      ),
    },
    {
      title: i18next.t('IMPRESSIONS'),
      dataIndex: 'metrics.totalImpressions',
      key: 'impressions',
      sorter: (a, b) => a.metrics.totalImpressions - b.metrics.totalImpressions,
      sortOrder:
        sortedInfo.columnKey === 'impressions' ? sortedInfo.order : null,
      ellipsis: true,
      width: 150,
      align: 'center',
      render: (text, record) => (
        <strong className='table-cell-text'>
          {formatNumberWithSpaces(record.metrics.totalImpressions)}
        </strong>
      ),
    },
    {
      title: i18next.t('INTERACTIONS'),
      dataIndex: 'metrics.totalInteractions',
      key: 'totalInteractions',
      align: 'center',
      width: 150,
      sorter: (a, b) =>
        a.metrics.totalInteractions - b.metrics.totalInteractions,
      sortOrder:
        sortedInfo.columnKey === 'totalInteractions' ? sortedInfo.order : null,
      ellipsis: true,
      render: (text, record) => (
        <strong className='table-cell-text'>
          {formatNumberWithSpaces(record.metrics.totalInteractions)}
        </strong>
      ),
    },
    {
      title: i18next.t('CLICKS'),
      dataIndex: 'metrics.totalClicks',
      align: 'center',
      key: 'totalClicks',
      sorter: (a, b) => a.metrics.totalClicks - b.metrics.totalClicks,
      sortOrder:
        sortedInfo.columnKey === 'totalClicks' ? sortedInfo.order : null,
      ellipsis: true,
      render: (text, record) => (
        <strong className='table-cell-text'>{formatNumberWithSpaces(record.metrics.totalClicks)}</strong>
      ),
    },
    {
      title: 'CTR',
      dataIndex: 'metrics.ctr',
      align: 'center',
      key: 'ctr',
      sorter: (a, b) => a.metrics.ctr - b.metrics.ctr,
      sortOrder: sortedInfo.columnKey === 'ctr' ? sortedInfo.order : null,
      ellipsis: true,
      render: (text, record) => (
        <strong className='table-cell-text'>{record.metrics?.ctr?.toFixed(2)}%</strong>
      ),
    },
    {
      title: 'CPC',
      dataIndex: 'metrics.cpc',
      align: 'center',
      key: 'cpc',
      sorter: (a, b) => a.metrics.cpc - b.metrics.cpc,
      sortOrder: sortedInfo.columnKey === 'cpc' ? sortedInfo.order : null,
      ellipsis: true,
      render: (text, record) => (
        <strong className='table-cell-text'>{record.metrics?.cpc?.toFixed(2)}$</strong>
      ),
    },
    {
      title: 'CPA',
      dataIndex: 'metrics.cpa',
      align: 'center',
      key: 'CPA',
      sorter: (a, b) => a.metrics.cpa - b.metrics.cpa,
      sortOrder: sortedInfo.columnKey === 'CPA' ? sortedInfo.order : null,
      ellipsis: true,
      render: (text, record) => (
        <strong className='table-cell-text'>{record.metrics?.cpa?.toFixed(2)}$</strong>
      ),
    },
  ];
  const [statusFilter, setStatusFilter] = useState({
    ENABLED: false,
    PAUSED: false,
  });
  const [filteredData, setFilteredData] = useState(metrcisbyCompaign);

  const handleSearchInputChange = (e) => {
    const value = e.target.value.toLowerCase();
    setSearchText(value);

    const filtered = metrcisbyCompaign.filter((item) =>
      item.campaign_name.toLowerCase().includes(value)
    );

    setFilteredData(filtered);
  };

  // new filter
  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;

    const updatedFilter = {
      ...statusFilter,
      [name]: checked,
    };

    setStatusFilter(updatedFilter);
    const statusMapping = {
      ENABLED: 2,
      PAUSED: 3,
    };
    const selectedStatuses = Object.keys(updatedFilter)
      .filter((key) => updatedFilter[key])
      .map((key) => statusMapping[key]);
    const filtered = metrcisbyCompaign.filter((item) =>
      selectedStatuses.length > 0
        ? selectedStatuses.includes(item.status)
        : true
    );

    setFilteredData(filtered);
  };

  const menufilter = (
    <Menu>
      <Menu.Item key="ENABLED">
        <Checkbox
          name="ENABLED"
          checked={statusFilter.ENABLED}
          onChange={handleCheckboxChange}
        >
          ENABLED
        </Checkbox>
      </Menu.Item>
      <Menu.Item key="PAUSED">
        <Checkbox
          name="PAUSED"
          checked={statusFilter.PAUSED}
          onChange={handleCheckboxChange}
        >
          PAUSED
        </Checkbox>
      </Menu.Item>
    </Menu>
  );

  const [selectedColumns, setSelectedColumns] = useState(
    columns.map((column) => column.key)
  );

  const handleMenuClick = (clickedKey) => {
    const index = selectedColumns.indexOf(clickedKey);

    let updatedSelectedColumns = [];

    if (index === -1) {
      updatedSelectedColumns = [...selectedColumns, clickedKey];
    } else {
      updatedSelectedColumns = selectedColumns.filter(
        (key) => key !== clickedKey
      );
    }

    setSelectedColumns(updatedSelectedColumns);
  };

  const filteredColumns = columns.filter((column) =>
    selectedColumns.includes(column.key)
  );

  const menu = (
    <Menu>
      {columns.map((column) => (
        <Menu.Item key={column.key} onClick={() => handleMenuClick(column.key)}>
          <Checkbox
            checked={selectedColumns.includes(column.key)}
            style={{ marginRight: '8px' }}
          />
          <span
            style={
              selectedColumns.includes(column.key) ? { fontWeight: 'bold' } : {}
            }
          >
            {column.title}
          </span>
        </Menu.Item>
      ))}
    </Menu>
  );

  return (
    <Card style={{ border: 'none' }}>
    
    <Breadcrumbs
              image={GoogleAdsIcon}
              title="Google Ads compaigns
"
            />
        <Space
          style={{
            marginBottom: 16,
          }}
        >
          <Dropdown overlay={menu} trigger={['click']}>
            <Button className="custom-button">
              {i18next.t('Select Metrcis ')} <DownOutlined />
            </Button>
          </Dropdown>
          <Button className="custom-button" onClick={setAgeSort}>
            {i18next.t('Sort Impressions')}
          </Button>
          <Button className="custom-button" onClick={clearFilters}>
            {i18next.t('Clear filters')}
          </Button>
          <Button className="custom-button" onClick={clearAll}>
            {i18next.t('Clear filters and sorters')}
          </Button>
        </Space>
        <div className="table-border">
          <div
            className="d-flex align-items-center"
            style={{ padding: '20px' }}
          >
            <Dropdown overlay={menufilter} trigger={['click']}>
              <Button
                className="custom-button"
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  padding: '22px',
                }}
              >
                <FeatherIcon
                  icon="filter"
                  size="16"
                  style={{ marginRight: '8px' }}
                />
                {i18next.t('Filter')}
              </Button>
            </Dropdown>

            <div
              className="search-box"
              style={{
                flex: 1,
                maxWidth: '523px',
                position: 'relative',
                backgroundColor: '#F4F2FF',
                borderRadius: '6px',
                padding: '10px 15px',
              }}
            >
              <Input
                placeholder={i18next.t('Campaign Name')}
                value={searchText}
                onChange={handleSearchInputChange}
                className="custom-search-input"
              />
              <i
                className="bx bx-search-alt search-icon"
                style={{
                  position: 'absolute',
                  left: '10px',
                  top: '50%',
                  transform: 'translateY(-50%)',
                  color: '#7a7a7a',
                }}
              />
            </div>
            {overview ? (
              <Link
                to="/google-dashboard"
                className="btn btn-primary btn-sm viewMetrics-button"
              >
                {i18next.t('View full metrics')}
              </Link>
            ) : (
              <></>
            )}
          </div>

          <Table
            columns={filteredColumns}
            dataSource={filteredData}
            onChange={handleChange}
            pagination={{
              ...paginationOptions,
              onChange: handleChange,
              showSizeChanger: true, // Enable the page size selector
              style: {
                backgroundColor: '#F4F2FF',
                padding: '10px 15px',
                marginTop: '2px',
                borderRadius: '6px',
                fontsize: '14px',
                fontweight: '700',
                color: '#6E6893',
              },
            }}
            headerCellStyle={{
              backgroundColor: '#FF0000', // Change this to your desired color
              color: '#333333', // Text color
            }}
          />
        </div>
   
    </Card>
  );
};

export default GoogleAdsCompaignTable;
