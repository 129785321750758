import React, { useState } from 'react';
import { Card } from 'reactstrap';
import { Doughnut } from "react-chartjs-2";

// Utility function to determine if the background color is dark or light
const isColorDark = (color) => {
  // Remove the "#" if it's a hex color
  if (color.startsWith("#")) {
    color = color.slice(1);
  }

  // Convert hex to RGB
  const r = parseInt(color.substr(0, 2), 16);
  const g = parseInt(color.substr(2, 2), 16);
  const b = parseInt(color.substr(4, 2), 16);

  // Calculate brightness (HSP equation)
  const brightness = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b));

  // Return true if the color is dark, false if it's light
  return brightness < 127.5;
};

const OrdersStatusChartCard = ({ data, chartOptions, orderStatus, totalOrders }) => {
  // Define a visibility state for each dataset (initialize all as visible)
  const [visibility, setVisibility] = useState(new Array(orderStatus.length).fill(true));

  // Update the chart data based on visibility state
  const updatedData = {
    ...data,
    datasets: data.datasets.map((dataset, datasetIndex) => ({
      ...dataset,
      data: dataset.data.map((value, dataIndex) => visibility[dataIndex] ? value : 0), // Set to 0 if hidden
    }))
  };

  const toggleVisibility = (index) => {
    setVisibility(prevVisibility => {
      const newVisibility = [...prevVisibility];
      newVisibility[index] = !newVisibility[index]; // Toggle visibility
      return newVisibility;
    });
  };

  // Custom legend with percentages
  const generateLegend = () => {
    return (
      <div className="custom-legend" style={{ display: 'flex', flexWrap: 'wrap', marginTop: '20px' }}>
        {orderStatus.map((item, index) => {
          const percentage = ((item.orderStatus.total / totalOrders) * 100).toFixed(2);
          const isHidden = !visibility[index];  // Check if hidden
          const backgroundColor = isHidden ? '#d3d3d3' : data.datasets[0].backgroundColor[index];
          const textColor = isColorDark(backgroundColor) ? '#FFFFFF' : '#000000'; // Set text color based on background

          return (
            <div
              key={index}
              className="legend-item"
              style={{
                display: 'flex',
                alignItems: 'center',
                marginBottom: '8px',
                flexBasis: '50%',
                cursor: 'pointer',  // Make it clickable
                opacity: isHidden ? 0.5 : 1  // Fade out if hidden
              }}
              onClick={() => toggleVisibility(index)}  // Toggle visibility on click
            >
              <div style={{
                width: '55px',
                height: '27px',
                backgroundColor: backgroundColor,  // Use calculated background color
                marginRight: '8px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}>
                <span style={{ fontSize: '15px', fontWeight: '600', color: textColor }}>{percentage}%</span> {/* Adjust text color */}
              </div>
              <span style={{ marginLeft: '8px' }}>{item.orderStatus.name}</span>
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <Card>
      <div style={{ display: 'flex', justifyContent: 'flex-start' }}> {/* Create a flexbox for layout */}
        <div style={{ width: '50%', marginLeft: '30px', marginTop: '50px' }}> {/* Left side for the chart */}
          <Doughnut width={200} height={200} data={updatedData} options={chartOptions} />
        </div>
      </div>
      <div style={{ marginLeft: '30px', marginTop: '20px' }}> {/* Right side for the legend */}
        {generateLegend()} {/* Custom legend displayed beside the chart */}
      </div>
    </Card>
  );
};

export default OrdersStatusChartCard;
