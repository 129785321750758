import React, { useState, useEffect, useRef } from 'react';
import { Card } from "semantic-ui-react";
import MetaTags from 'react-meta-tags'
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import CountUp from "react-countup";
import {
  Row, Col, CardBody, Nav, TabContent, TabPane, Progress, Alert, FormGroup,
  InputGroup,
} from 'reactstrap';
import { DateRange } from "react-date-range";
import { Pie } from "react-chartjs-2"
import { getcmetrcisbyCompaign, getAds, getcmetrcisbyCompaignperspecifcdate } from '../../../queries/Google/googleQueries';
import ApolloClient from 'apollo-boost';
import urls from '../../../routes/apiUrls';
import { getMetricsByCampaigns, getAdsRequest } from '../../../store/googleAds/actions';
import { useDispatch, useSelector } from 'react-redux';
const GoogleAdsIcon = `${process.env.PUBLIC_URL}/images/integrations_images/GoogleADS.svg`;
const ApploCLI2 = new ApolloClient({
  uri: urls.google,
});

export default function GoogleadsDetaills(props) {
  const dispatch = useDispatch();
  const { metrcisbyCompaign, ads } = useSelector((state) => state.GoogleAds)
  const wrapperRef = useRef(null);
  const [toggleDate, setToggleDate] = useState(false);
  // const [metrcisbyCompaign, setMetrcisbyCompaign] = useState([])

  console.log("ads", ads)
  const [activeTab, setActiveTab] = useState('1');
  const [selectedCampaign, setSelectedCampaign] = useState([]);
  const [selectedAd, setSelectedAd] = useState(null);

  const handleCampaignChange = (event) => {
    const selectedCampaignId = event.target.value;
    const campaign = ads.find((element) => element.campaign_id === selectedCampaignId);
    setSelectedCampaign([campaign]); // Wrap campaign object in an array
    setSelectedAd(null); // Reset selected ad when a new campaign is selected
  };

  const handleAdChange = (event) => {
    const selectedAdId = event.target.value;
    const ad = selectedCampaign.find((element) => element.ad_id === selectedAdId);
    setSelectedAd(ad);
  };
  console.log("selectedAd", selectedAd)

  //   const dataMapProductCategoryName=metrcisbyCompaign.map((item) => { return item.campaign_name})
  //   const dataMapProductCategoryCount=metrcisbyCompaign.map((item) => { return item.metrics.totalConversions})
  //   console.log("dataMapProductCategoryName",dataMapProductCategoryName)
  //   console.log("dataMapProductCategoryCount",dataMapProductCategoryCount)

  //   const data = {
  //     labels: dataMapProductCategoryName,
  //     datasets: [
  //       {
  //         data: dataMapProductCategoryCount,
  //         backgroundColor: ["#34c38f", "#ebeff2", "#EC1A74", "#F6B43B", "#EA2427", "#3B5B98", "	#000000", "#FF5733", "#76448A", "#0E6655 "],
  //         hoverBackgroundColor: ["#34c38f", "#ebeff2", "#EC1A74", "#F6B43B", "#EA2427", "#3B5B98", "	#000000", "#FF5733", "#76448A", "#0E6655"],
  //         hoverBorderColor: "#fff",
  //       },
  //     ],
  //   }
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>GoogleAds Dashbaord | Convergen-Metrics - E-com SaaS</title>
        </MetaTags>

        <div className="container-fluid">
          <Breadcrumbs
            title="Google Dashboard"
            breadcrumbItem=" Ads Details"
            pageHeading="Google Dashboard"
          />

          <select className="btn btn-primary mx-4" onChange={handleCampaignChange}>
            <option value="">Select campaign</option>
            {ads.map((campaign) => (
              <option key={campaign.campaign_id} value={campaign.campaign_id}>
                {campaign.campaign_name}
              </option>
            ))}
          </select>

          <select className="btn btn-primary" onChange={handleAdChange} disabled={!selectedCampaign.length}>
            <option value="">Select Ad</option>
            {selectedCampaign.length > 0 &&
              selectedCampaign.map((ad) => (
                <option key={ad.ad_id} value={ad.ad_id}>
                  {ad.ad_id}
                </option>
              ))}
          </select>

          <Row>
            <div className="mt-5">
              <Col xl={11} md={4}>
                <Row>
                  <Col>
                    <Card className="card-h-100">
                      <CardBody>
                        <div className="my-0 text-primary d-flex justify-content-between align-items-center">
                          <span className="my-0 text-primary">
                            <i className="fa fa-info-circle text-primary" aria-hidden="true" data-tooltip="Conversion Rate"></i>{" "}
                            Cost
                          </span>
                          <img className="img-intgration-icon" src={GoogleAdsIcon} />
                        </div>
                        <Row className="align-items-center">
                          <Col xs={6}>
                            <div className="  mb-3">
                              <h4>
                                <CountUp
                                  start={0}
                                  end={selectedAd && selectedAd.cost
                                    ? selectedAd.cost
                                    : '-'}
                                  duration={10}


                                  prefix="$"

                                />
                              </h4>
                            </div>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col>
                    <Card className="card-h-100">
                      <CardBody>
                        <div className="my-0 text-primary d-flex justify-content-between align-items-center">
                          <span className="my-0 text-primary">
                            <i className="fa fa-info-circle text-primary" aria-hidden="true" data-tooltip="Conversion Rate"></i>{" "}
                            Clicks
                          </span>
                          <img className="img-intgration-icon" src={GoogleAdsIcon} />
                        </div>
                        <Row className="align-items-center">
                          <Col xs={6}>
                            <div className="mb-3">
                              <h4>
                                <CountUp
                                  start={0}
                                  end={selectedAd && selectedAd.clicks
                                    ? selectedAd.clicks
                                    : '-'}
                                  duration={10}

                                />
                              </h4>

                            </div>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col>
                    <Card className="card-h-100">
                      <CardBody>
                        <div className="my-0 text-primary d-flex justify-content-between align-items-center">
                          <span className="my-0 text-primary">
                            <i className="fa fa-info-circle text-primary" aria-hidden="true" data-tooltip="Conversion Rate"></i>{" "}
                            Conversions
                          </span>
                          <img className="img-intgration-icon" src={GoogleAdsIcon} />
                        </div>
                        <Row className="align-items-center">
                          <Col xs={6}>
                            <div className="mb-3">
                              <h4>
                                <CountUp
                                  start={0}
                                  end={selectedAd && selectedAd.conversions

                                    ? selectedAd.conversions
                                    : '-'}
                                  duration={10}

                                />
                              </h4>

                            </div>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col>
                    <Card className="card-h-100">
                      <CardBody>
                        <div className="my-0 text-primary d-flex justify-content-between align-items-center">
                          <span className="my-0 text-primary">
                            <i className="fa fa-info-circle text-primary" aria-hidden="true" data-tooltip="Conversion Rate"></i>{" "}
                            Impressions
                          </span>
                          <img className="img-intgration-icon" src={GoogleAdsIcon} />
                        </div>
                        <Row className="align-items-center">
                          <Col xs={6}>
                            <div className="mb-3">
                              <h4>
                                <CountUp
                                  start={0}
                                  end={selectedAd && selectedAd.impressions
                                    ? selectedAd.impressions
                                    : '-'}
                                  duration={10}
                                />
                              </h4>
                            </div>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Col>
              <Col xl={11} md={4}>
                <Row>

                  <Col>
                    <Card className="card-h-100">
                      <CardBody>
                        <div className="my-0 text-primary d-flex justify-content-between align-items-center">
                          <span className="my-0 text-primary">
                            <i className="fa fa-info-circle text-primary" aria-hidden="true" data-tooltip="Conversion Rate"></i>{" "}
                            Avg.Cost
                          </span>
                          <img className="img-intgration-icon" src={GoogleAdsIcon} />
                        </div>
                        <Row className="align-items-center">
                          <Col xs={6}>
                            <div className="mb-3">
                              <h4>
                                <CountUp
                                  start={0}
                                  end={selectedAd && selectedAd.average_cost
                                    ? selectedAd.average_cost
                                    : '-'}
                                  duration={10}


                                  prefix="$"

                                />
                              </h4>
                            </div>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col>
                    <Card className="card-h-100">
                      <CardBody>
                        <div className="my-0 text-primary d-flex justify-content-between align-items-center">
                          <span className="my-0 text-primary">
                            <i className="fa fa-info-circle text-primary" aria-hidden="true" data-tooltip="Conversion Rate"></i>{" "}
                            Avg.Cpc
                          </span>
                          <img className="img-intgration-icon" src={GoogleAdsIcon} />
                        </div>
                        <Row className="align-items-center">
                          <Col xs={6}>
                            <div className="mb-3">
                              <h4>
                                <CountUp
                                  start={0}
                                  end={selectedAd && selectedAd.average_cpc
                                    ? selectedAd.average_cpc
                                    : '-'}
                                  duration={10}


                                  prefix="$"

                                />
                              </h4>
                            </div>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col>
                    <Card className="card-h-100">
                      <CardBody>
                        <div className="my-0 text-primary d-flex justify-content-between align-items-center">
                          <span className="my-0 text-primary">
                            <i className="fa fa-info-circle text-primary" aria-hidden="true" data-tooltip="Conversion Rate"></i>{" "}
                            Ctr
                          </span>
                          <img className="img-intgration-icon" src={GoogleAdsIcon} />
                        </div>
                        <Row className="align-items-center">
                          <Col xs={6}>
                            <div className="mb-3">
                              <h4>
                                <CountUp
                                  start={0}
                                  end={selectedAd && selectedAd.ctr
                                    ? selectedAd.ctr.toFixed(2)
                                    : '-'}
                                  duration={10}
                                  decimals={2}

                                  prefix="%"

                                />
                              </h4>
                            </div>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>

                </Row>
              </Col>
              <Col xl={11} md={4}>
                <Row>
                  <Col>
                    <Card className="card-h-100">
                      <CardBody>
                        <div className="my-0 text-primary d-flex justify-content-between align-items-center">
                          <span className="my-0 text-primary">
                            <i className="fa fa-info-circle text-primary" aria-hidden="true" data-tooltip="Conversion Rate"></i>{" "}
                            interactions
                          </span>
                          <img className="img-intgration-icon" src={GoogleAdsIcon} />
                        </div>
                        <Row className="align-items-center">
                          <Col xs={6}>
                            <div className="mb-3">
                              <h4>
                                <CountUp
                                  start={0}
                                  end={selectedAd && selectedAd.interactions
                                    ? selectedAd.interactions
                                    : '-'}
                                  duration={10}


                                />
                              </h4>
                            </div>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>

                  <Col>
                    <Card className="card-h-100">
                      <CardBody>
                        <div className="my-0 text-primary d-flex justify-content-between align-items-center">
                          <span className="my-0 text-primary">
                            <i className="fa fa-info-circle text-primary" aria-hidden="true" data-tooltip="Conversion Rate"></i>{" "}
                            conversions value
                          </span>
                          <img className="img-intgration-icon" src={GoogleAdsIcon} />
                        </div>
                        <Row className="align-items-center">
                          <Col xs={6}>
                            <div className="mb-3">
                              <h4>
                                <CountUp
                                  start={0}
                                  end={selectedAd && selectedAd.conversions_value
                                    ? selectedAd.conversions_value.toFixed(2)
                                    : '-'}
                                  duration={10}

                                  prefix="$"
                                />
                              </h4>
                            </div>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>


                </Row>
              </Col>
              <Col xl={11} md={4}>
                <Row>


                </Row>
              </Col>
              <Col xl={10} md={4}>
                <Row>
                  <CardBody>
                    {/* <Pie   width={474} height={260} data={data} /> */}
                  </CardBody>
                </Row>
              </Col>



            </div>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}
