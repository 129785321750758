import React, { useEffect, useState } from "react";
import { Row, Col, CardBody } from "reactstrap";
import { Table, Input } from "antd"; // Importing Ant Design components
import { useDispatch, useSelector } from 'react-redux';
import { fetchOrderStatusRequest } from '../../../store/woocommerce/actions';
import "../../PrestaDashbaord/datatables.scss";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";

function WooOrdersStatusTable(props) {
  const dispatch = useDispatch();
  const { orderStatus } = useSelector((state) => state.woocommerce);

  const [filteredData, setFilteredData] = useState([]); // State to hold filtered data
  const [searchTerm, setSearchTerm] = useState(""); // State to track the search term

  useEffect(() => {
    const savedOrderStatus = localStorage.getItem('wooorderstatus');
    if (savedOrderStatus) {
      dispatch({ type: 'FETCH_ORDER_STATUS_SUCCESS', payload: JSON.parse(savedOrderStatus) });
    } else {
      dispatch(fetchOrderStatusRequest(15, 1));
    }
  }, [dispatch]);

  useEffect(() => {
    if (orderStatus) {
      setFilteredData(orderStatus); // Initialize filtered data with all orders
    }
  }, [orderStatus]);

  // Define the columns for Ant Design Table
  const columns = [
    {
      title: props.t("NAME"),
      dataIndex: ['orderStatus', 'name'],
      key: 'name',
      sorter: (a, b) => a.orderStatus.name.localeCompare(b.orderStatus.name), // Sorting by name
    },
    {
      title: props.t("TOTAL"),
      dataIndex: ['orderStatus', 'total'],
      key: 'total',
      sorter: (a, b) => a.orderStatus.total - b.orderStatus.total, // Sorting by total
    }
  ];

  // Data mapping for the table
  const dataMapOrders = filteredData ? filteredData.map((item, index) => ({
    key: index,
    ...item,
  })) : [];

  const paginationOptions = {
    pageSize: 8,
    showSizeChanger: true,
    pageSizeOptions: ['10', '20', '50'],
  };

  // Ant Design Search Component
  const onSearch = (e) => {
    const value = e.target.value.toLowerCase();
    setSearchTerm(value);
    
    const filtered = orderStatus.filter(item => 
      item.orderStatus.name.toLowerCase().includes(value) || 
      item.orderStatus.total.toString().includes(value)
    );

    setFilteredData(filtered); // Update filtered data based on search
  };

 

  return (
    <React.Fragment>
      <div className="container-fluid">
        <h5 style={{ color: '#0044BD', fontSize: '20px', marginBottom: '20px' }}>
          {props.t("Orders Status Table")}
        </h5>
        <CardBody>
          <Row className="mb-2">
            <Col>
              <div
                className="search-box"
                style={{
                  flex: 1,
                  position: 'relative',
                  backgroundColor: '#F4F2FF',
                  borderRadius: '6px',
                  padding: '10px 15px',
                }}
              >
                <Input
                  placeholder={props.t("Search Orders Status")}
                  onChange={onSearch} // Trigger search on input change
                  value={searchTerm}
                  className="custom-search-input"
                  style={{ width: '100%' }}
                />
                <i
                  className="bx bx-search-alt search-icon"
                  style={{
                    position: 'absolute',
                    left: '10px',
                    top: '50%',
                    transform: 'translateY(-50%)',
                    color: '#7A7A7A',
                  }}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col xl="12">
              <Table
                columns={columns}
                bordered={true}
                dataSource={dataMapOrders}
                pagination={{
                  ...paginationOptions,
                  showSizeChanger: true,
                  style: {
                    backgroundColor: '#F4F2FF',
                    padding: '10px 15px',
                    marginTop: '2px',
                    borderRadius: '6px',
                    fontSize: '14px',
                    fontWeight: '700',
                    color: '#6E6893',
                  },
                }}
                
              />
            </Col>
          </Row>
        </CardBody>
      </div>
    </React.Fragment>
  );
}

WooOrdersStatusTable.propTypes = {
  t: PropTypes.any
};

export default withRouter(withTranslation()(WooOrdersStatusTable));
