import React, { useEffect, useState } from "react"
import MetaTags from 'react-meta-tags';

import {
  Row
} from "reactstrap"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import ApolloClient from 'apollo-boost';
import { getGATrackingPage } from "../../../queries/GoogleAnalytics/Queries";
import urls from "../../../routes/apiUrls";
import GATrackingPageDataCard from "./GATrackingPageDataCard";

const ApploCLI = new ApolloClient({
  uri: urls.analytics,
});
function GaTrackingPage() {
  const [trackingPageDataDashboard, setTrackingPageDataDashboard] = useState([]);
  const [loadingState, setLoadingState] = useState(false);
  const [entranceRate, setEntranceRate] = useState([]);
  const [pageviews, setPageviews] = useState([]);
  const [pageValue, setPageValue] = useState([]);
  const [pageviewsPerSession, setPageviewsPerSession] = useState([]);
  const [timeOnPage, setTimeOnPage] = useState([]);
  const [exits, setExits] = useState([]);
  const [exitRate, setExitRate] = useState([]);
  useEffect(() => {
    let isSubscribed = true;
    ApploCLI.query({
      query: getGATrackingPage,
      context: {
        headers: {
          Authorization: window.localStorage.getItem("token")
            ? `Bearer ${window.localStorage.getItem("token")}`
            : "",
        },
      },
      errorPolicy: "all",
      fetchPolicy: "network-only", //allow me to refecth data
    }).then((data) => {
      if (isSubscribed) {
        if (data.data.trackingpage) {
          setTrackingPageDataDashboard(data.data.trackingpage);
          setLoadingState(true);
        } else {
          setLoadingState(false);
        }
      }
    });
    return () => (isSubscribed = false);
  }, []);
  useEffect(() => {
    let isSubscribed = true;
    var TentranceRate = [];
    var Tpageviews = [];
    var TpageValue = [];
    var TpageviewsPerSession = [];
    var TtimeOnPage = [];
    var Texits = [];
    var TexitRate = [];
    if (trackingPageDataDashboard.length !== 0) {
      trackingPageDataDashboard.map((elem) => {
        TentranceRate.push(elem.entranceRate ? (elem.entranceRate) : 0)
        Tpageviews.push(elem.pageviews ? (elem.pageviews) : 0)
        TpageValue.push(elem.pageValue ? (elem.pageValue) : 0)
        TpageviewsPerSession.push(elem.pageviewsPerSession ? (elem.pageviewsPerSession) : 0)
        TtimeOnPage.push(elem.timeOnPage ? (elem.timeOnPage) : 0)
        Texits.push(elem.exits ? (elem.exits) : 0)
        TexitRate.push(elem.exitRate ? (elem.exitRate) : 0)
      })
    }
    setEntranceRate(TentranceRate);
    setPageviews(Tpageviews);
    setPageValue(TpageValue);
    setPageviewsPerSession(TpageviewsPerSession);
    setTimeOnPage(TtimeOnPage);
    setExits(Texits);
    setExitRate(TexitRate);
    return () => (isSubscribed = false);
  }, [trackingPageDataDashboard]);
  var op1 = {
    chart: {
      toolbar: {
        followCursor: true,
        show: false,
        tools: {
          download: true,
          selection: true,
          zoom: true,
          zoomin: true,
          zoomout: true,
          pan: true,
          reset: true,
        },
        tooltip: {
          shared: true,
          intersect: true,
          followCursor: true,
          x: {
            show: false,
          },
        },
        autoSelected: "zoom",
      },
      type: "area",
      height: 120,
      sparkline: {
        enabled: true,
      },
    },
    markers: {
      size: 0,
    },
    stroke: {
      curve: "straight",
      width: 2.5,
    },
    fill: {
      opacity: 1,
    },
    series: [{ name: "Entrance Rate", data: entranceRate }],
    xaxis: {
      crosshairs: {
        width: 1,
      },
    },
    yaxis: {
      show: false,
    },

    colors: ["#F6B43B", "#EC1A74"],
  };
  var op2 = {
    chart: {
      toolbar: {
        followCursor: true,
        show: false,
        tools: {
          download: true,
          selection: true,
          zoom: true,
          zoomin: true,
          zoomout: true,
          pan: true,
          reset: true,
        },
        tooltip: {
          shared: true,
          intersect: true,
          followCursor: true,
          x: {
            show: false,
          },
        },
        autoSelected: "zoom",
      },
      type: "area",
      height: 120,
      sparkline: {
        enabled: true,
      },
    },
    markers: {
      size: 0,
    },
    stroke: {
      curve: "straight",
      width: 2.5,
    },
    fill: {
      opacity: 1,
    },
    series: [{ name: "Page Views", data: pageviews }],
    xaxis: {
      crosshairs: {
        width: 1,
      },
    },
    yaxis: {
      show: false,
    },

    colors: ["#F6B43B", "#EC1A74"],
  };
  var op3 = {
    chart: {
      toolbar: {
        followCursor: true,
        show: false,
        tools: {
          download: true,
          selection: true,
          zoom: true,
          zoomin: true,
          zoomout: true,
          pan: true,
          reset: true,
        },
        tooltip: {
          shared: true,
          intersect: true,
          followCursor: true,
          x: {
            show: false,
          },
        },
        autoSelected: "zoom",
      },
      type: "area",
      height: 120,
      sparkline: {
        enabled: true,
      },
    },
    markers: {
      size: 0,
    },
    stroke: {
      curve: "straight",
      width: 2.5,
    },
    fill: {
      opacity: 1,
    },
    series: [{ name: "Page Value", data: pageValue }],
    xaxis: {
      crosshairs: {
        width: 1,
      },
    },
    yaxis: {
      show: false,
    },

    colors: ["#F6B43B", "#EC1A74"],
  };
  var op4 = {
    chart: {
      toolbar: {
        followCursor: true,
        show: false,
        tools: {
          download: true,
          selection: true,
          zoom: true,
          zoomin: true,
          zoomout: true,
          pan: true,
          reset: true,
        },
        tooltip: {
          shared: true,
          intersect: true,
          followCursor: true,
          x: {
            show: false,
          },
        },
        autoSelected: "zoom",
      },
      type: "area",
      height: 120,
      sparkline: {
        enabled: true,
      },
    },
    markers: {
      size: 0,
    },
    stroke: {
      curve: "straight",
      width: 2.5,
    },
    fill: {
      opacity: 1,
    },
    series: [{ name: "Page Views Per Session", data: pageviewsPerSession }],
    xaxis: {
      crosshairs: {
        width: 1,
      },
    },
    yaxis: {
      show: false,
    },

    colors: ["#F6B43B", "#EC1A74"],
  };
  var op5 = {
    chart: {
      toolbar: {
        followCursor: true,
        show: false,
        tools: {
          download: true,
          selection: true,
          zoom: true,
          zoomin: true,
          zoomout: true,
          pan: true,
          reset: true,
        },
        tooltip: {
          shared: true,
          intersect: true,
          followCursor: true,
          x: {
            show: false,
          },
        },
        autoSelected: "zoom",
      },
      type: "area",
      height: 120,
      sparkline: {
        enabled: true,
      },
    },
    markers: {
      size: 0,
    },
    stroke: {
      curve: "straight",
      width: 2.5,
    },
    fill: {
      opacity: 1,
    },
    series: [{ name: "Time On Page", data: timeOnPage }],
    xaxis: {
      crosshairs: {
        width: 1,
      },
    },
    yaxis: {
      show: false,
    },

    colors: ["#F6B43B", "#EC1A74"],
  };
  var op6 = {
    chart: {
      toolbar: {
        followCursor: true,
        show: false,
        tools: {
          download: true,
          selection: true,
          zoom: true,
          zoomin: true,
          zoomout: true,
          pan: true,
          reset: true,
        },
        tooltip: {
          shared: true,
          intersect: true,
          followCursor: true,
          x: {
            show: false,
          },
        },
        autoSelected: "zoom",
      },
      type: "area",
      height: 120,
      sparkline: {
        enabled: true,
      },
    },
    markers: {
      size: 0,
    },
    stroke: {
      curve: "straight",
      width: 2.5,
    },
    fill: {
      opacity: 1,
    },
    series: [{ name: "Exits", data: exits }],
    xaxis: {
      crosshairs: {
        width: 1,
      },
    },
    yaxis: {
      show: false,
    },

    colors: ["#F6B43B", "#EC1A74"],
  };
  var op7 = {
    chart: {
      toolbar: {
        followCursor: true,
        show: false,
        tools: {
          download: true,
          selection: true,
          zoom: true,
          zoomin: true,
          zoomout: true,
          pan: true,
          reset: true,
        },
        tooltip: {
          shared: true,
          intersect: true,
          followCursor: true,
          x: {
            show: false,
          },
        },
        autoSelected: "zoom",
      },
      type: "area",
      height: 120,
      sparkline: {
        enabled: true,
      },
    },
    markers: {
      size: 0,
    },
    stroke: {
      curve: "straight",
      width: 2.5,
    },
    fill: {
      opacity: 1,
    },
    series: [{ name: "Exit Rate", data: exitRate }],
    xaxis: {
      crosshairs: {
        width: 1,
      },
    },
    yaxis: {
      show: false,
    },

    colors: ["#F6B43B", "#EC1A74"],
  };
  const [myCards, setMyCards] = useState([
    { id: 1, name: "Entrance Rate", options: op1, series: op1.series },
    { id: 2, name: "Page Views", options: op2, series: op2.series },
    { id: 3, name: "Page Value", options: op3, series: op3.series },
    { id: 4, name: "Page Views Per Session", options: op4, series: op4.series },
    { id: 5, name: "Time On Page", options: op5, series: op5.series },
    { id: 6, name: "Exits", options: op6, series: op6.series },
    { id: 7, name: "Exit Rate", options: op7, series: op7.series },
  ]);
  useEffect(() => {
    let isSubscribed = true;
    setMyCards([
      { id: 1, name: "Entrance Rate", options: op1, series: op1.series },
      { id: 2, name: "Page Views", options: op2, series: op2.series },
      { id: 3, name: "Page Value", options: op3, series: op3.series },
      { id: 4, name: "Page Views Per Session", options: op4, series: op4.series },
      { id: 5, name: "Time On Page", options: op5, series: op5.series },
      { id: 6, name: "Exits", options: op6, series: op6.series },
      { id: 7, name: "Exit Rate", options: op7, series: op7.series },
    ]);
    return () => (isSubscribed = false);
  }, [entranceRate,pageviews,pageValue,pageviewsPerSession,timeOnPage,exits,exitRate]);
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Tracking Page | Live-Metrics - E-com SaaS</title>
        </MetaTags>
        <div className="container-fluid">
          <Breadcrumbs title="Google Analytics" breadcrumbItem="Tracking Page" pageHeading="Tracking Page" />
          <Row>
          {
              myCards.map(item => (
                <GATrackingPageDataCard loading={loadingState} item={item} />
              ))}
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}
export default GaTrackingPage;
