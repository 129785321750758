import React, { useState, useEffect, useRef } from 'react';
import MetaTags from 'react-meta-tags';
import "../../assets/scss/custom/pages/_daterange.scss";
import WooDataCards from './WooDataCards';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { format } from 'date-fns';
import { formatDate } from '../GoogleAnalytics/common/HelperFunctions/utils';

import Breadcrumbs from "../../components/Common/Breadcrumb";
import {
  Col,
  Container,
  Row,
  FormGroup,
  InputGroup,
  Card,
  CardHeader,
  CardTitle,
  CardBody
} from "reactstrap";
import "flatpickr/dist/themes/material_blue.css";
import { useDispatch, useSelector } from 'react-redux';

import urls from '../../routes/apiUrls';
import { getData } from '../../queries/Dashboard/WooDashboardQ';
import { getSalesPlatform } from '../../queries/Shopify/queries';
import { alert } from '../Alerts/NotLinkedAccountAlert';
import ApolloClient from 'apollo-boost';
import { DateRange } from "react-date-range";
import { useHistory } from "react-router-dom";
import secureSection from '../../MangeSecurity/MemberSecurity';
import ReactEcharts from "echarts-for-react"
import { withRouter } from "react-router-dom"
import { withTranslation } from "react-i18next"
import PropTypes from "prop-types";
import "../../assets/scss/theme.scss";
import ChartFilter from './ChartFilter';
import { color } from 'echarts';
const WooCommercepIcon = `${process.env.PUBLIC_URL}/images/integrations_images/WooCommerce.svg`;
const daysBetween = function (date1, date2) {
  var one_day = 1000 * 60 * 60 * 24;
  var date1_ms = date1.getTime();
  var date2_ms = date2.getTime();
  var difference_ms = date2_ms - date1_ms;
  return Math.round(difference_ms / one_day);
}

const ApploCLI = new ApolloClient({
  uri: urls.woodashboard,
});
const ApploShopifyCLI = new ApolloClient({
  uri: urls.shopify,
});
const generateDateRange = (startDate, endDate) => {
  const dates = [];
  let currentDate = new Date(startDate);

  while (currentDate <= new Date(endDate)) {
    dates.push(new Date(currentDate)); // Push a new date to the array
    currentDate.setDate(currentDate.getDate() + 1); // Increment the date by one day
  }

  return dates;
};

function WooDashboard(props) {
  secureSection();
  const wrapperRef = useRef(null);
  const {dateRange} = useSelector((state) => state.dateRangeReducer);

 
  const history = useHistory();
 
  const [metrics, setMetrics] = useState({
    sales: [],
    compareSales: [],
    GrossProfit: [],
    compareGrossProfit: [],
    NumberOrders: [],
    compareNumberOrders: [],
    AOV: [],
    compareAOV: []
  });

  
  const [dataDashboard, setDataDashboard] = useState([]);  
  const [loadingState, setLoadingState] = useState(false);

  const newStartDate = formatDate(dateRange.selection1.startDate);
        const newEndDate = formatDate(dateRange.selection1.endDate);

  useEffect(() => {   
      if (dateRange?.selection1?.startDate && dateRange?.selection1?.endDate) {
      
  
        setLoadingState(true);
        if (dateRange.selection2?.startDate && dateRange.selection2?.endDate) {
          const newCompareStartDate = formatDate(dateRange.selection2.startDate);
          const newCompareEndDate = formatDate(dateRange.selection2.endDate);
          ApploCLI.query({
            query: getData,
            variables: {
              startDate:newStartDate,
              endDate: newEndDate,
              compareStartDate: newCompareStartDate,
              compareEndDate: newCompareEndDate,
              
            },
            context: {
              headers: {
                Authorization: window.localStorage.getItem("token")
                  ? `Bearer ${window.localStorage.getItem("token")}`
                  : "",
              },
            },
            errorPolicy: "all",
            fetchPolicy: "no-cache",
          }).then((data) => {
            setDataDashboard(data.data.get);
          });



        } else {
          ApploCLI.query({
            query: getData,
            variables: {
              startDate:newStartDate,
              endDate: newEndDate,
              
            },
            context: {
              headers: {
                Authorization: window.localStorage.getItem("token")
                  ? `Bearer ${window.localStorage.getItem("token")}`
                  : "",
              },
            },
            errorPolicy: "all",
            fetchPolicy: "no-cache",
          }).then((data) => {
            setDataDashboard(data.data.get);
          });
        }
        setLoadingState(false);
      }


  }, [dateRange]);
  useEffect(() => {
    if (dataDashboard?.report?.length > 0) {
      const updatedMetrics = { sales: [], GrossProfit: [], NumberOrders: [], AOV: [] };
      const updatedCompareMetrics = { compareSales: [], compareGrossProfit: [], compareNumberOrders: [], compareAOV: [] };

      // First Report (Current Data)
      if (dataDashboard.report[0]?.length > 0) {
        dataDashboard.report[0].forEach((item) => {
          updatedMetrics.sales.push(item.sales || 0);
          updatedMetrics.GrossProfit.push(item.GrossProfit || 0);
          updatedMetrics.NumberOrders.push(item.NumberOrders || 0);
          updatedMetrics.AOV.push(item.AOV || 0);
        });
      }

      // Second Report (Comparison Data)
      if (dataDashboard.report.length > 1 && dataDashboard.report[1]?.length > 0) {
        dataDashboard.report[1].forEach((item) => {
          updatedCompareMetrics.compareSales.push(item.sales || 0);
          updatedCompareMetrics.compareGrossProfit.push(item.GrossProfit || 0);
          updatedCompareMetrics.compareNumberOrders.push(item.NumberOrders || 0);
          updatedCompareMetrics.compareAOV.push(item.AOV || 0);
        });
      }

      setMetrics((prevMetrics) => ({
        ...prevMetrics,
        ...updatedMetrics,
        ...updatedCompareMetrics
      }));
    }
  }, [dataDashboard]);
  // Helper function to create chart options



  
  // const [myCards, setMyCards] = useState([
  //   { id: "1", name: "sales", data:metrics.sales  , compareData: metrics.compareSales },
  //   // { id: "2", name: "GrossProfit"},
  //   // {
  //   //   id: "3", name: "AOV", data:metrics.AOV,},
  //   // { id: "4", name: "NumberofOrders",data:metrics.NumberOrders },
  // ]);
  // useEffect(() => {
  //   setMyCards([
  //     { id: "1", name: "sales", data:metrics.sales ,compareData: metrics.compareSales },
  //     // { id: "2", name: "GrossProfit"  ,data:metrics.sales},
  //     // {
  //     //   id: "3", name: "AOV", data:metrics.AOV,},
  //     // { id: "4", name: "NumberofOrders",data:metrics.NumberOrders },
  //   ]);
    
  // }, [metrics]);

  // const onDragEnd = (result) => {
  //   const newItems = Array.from(myCards);
  //   const [removed] = newItems.splice(result.source.index, 1);
  //   newItems.splice(result.destination.index, 0, removed);
  //   // setMyCards(newItems);
  // };


  useEffect(() => {
    let isSubscribed = true;
    ApploShopifyCLI.query({
      query: getSalesPlatform,
      context: {
        headers: {
          Authorization: window.localStorage.getItem("token")
            ? `Bearer ${window.localStorage.getItem("token")}`
            : "",
        },
      },
      errorPolicy: "all",
      fetchPolicy: "network-only",
    }).then((data) => {
      if (data.data && data.data.get && data.data.get.url === null) {
        console.log("data.data.get.url", data.data.get.url);
        alert().then(() => {
          history.push("/integrations");
        });
      }
    });
  
    return () => (isSubscribed = false);
  }, []);



  const getListStyle = (isDraggingOver, itemsLength) => ({
    background: "#fffff",
    display: "flex",
    padding: "10px",
    width: itemsLength * 68.44 + 16
  });

  // REACT E-CHARTS
  const getOption = () => {
    return {
      tooltip: {
        trigger: 'axis',
        backgroundColor: '#fff', // White background
        borderColor: '#0044BD', // Blue border
        borderWidth: 1,
        textStyle: {
          color: '#000', // Black text
        },
      },
      grid: {
        zlevel: 0,
        x: 50,
        x2: 50,
        y: 30,
        y2: 30,
        borderWidth: 0,
      },
      xAxis: {
        type: 'category',
        boundaryGap: false,
        data: generateDateRange(
          dateRange.selection1.startDate,
          dateRange.selection1.endDate
        ).map((item) =>
          item.toLocaleDateString('en-US', {
            day: '2-digit',
            month: 'short', // Change format to "Sep"
          })
        ) || [],
        axisLabel: {
          color: '#74788d',
          align: 'left',
        },
        axisLine: {
          lineStyle: {
            color: '#74788d', // Gray line
          },
        },
      },
      yAxis: {
        type: 'value',
        axisLabel: {
          color: '#74788d', // Gray labels
          align: 'right',
        },
        axisLine: {
          lineStyle: {
            color: '#74788d', // Gray line
          },
        },
      },
      series: [
        {
          name: selectedSeries,
          data: getSelectedSeriesData(selectedSeries), // Fetch the data for the selected series
          type: 'line',
          smooth: true, // Smoother line curve
          symbol: 'circle', // Circle symbol on data points
          symbolSize: 8, // Symbol size
          itemStyle: {
            color: '#9B5C8F', // Blue color for symbols
            borderColor: '#FFFFFF', // White border around symbols
            borderWidth: 2,
          },
          lineStyle: {
            width: 2,
            color: '#9B5C8F', // Line color
          },
          areaStyle: {
            color: {
              type: 'linear',
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [
                { offset: 0, color: '#D740BA' }, // Top gradient color
                { offset: 0.5, color: '#FFB7F1' }, // Middle gradient color
                { offset: 1, color: 'rgba(252, 229, 229, 0.31)' }, // Bottom color
              ],
            },
          },
        },
      ],
      color: ['#556ee6'], // Main color of the chart elements
      textStyle: {
        color: ['#74788d'], // Default text color
      },
    };
  };
  

  // options 
  const [selectedSeries, setSelectedSeries] = useState('sales'); // Default to 'sales_tab'

  // Define the toggleSeries function to update the selected series state
  const handleRadioChange = (e) => {
    setSelectedSeries(e.target.value);
  };

  // Get the selected filter label for display
  const getFilterLabel = () => {
    switch (selectedSeries) {
      case 'sales':
        return props.t("Sales");
      case 'grossProfit':
        return props.t("Gross Profit");
      case 'nmbrOfOrders':
        return props.t("Number Of Orders");
      default:
        return '';
    }
  };


  //Function to get the data array for the selected series
  const getSelectedSeriesData = (selectedSeries) => {
    switch (selectedSeries) {
      case "sales":
        return metrics.sales;
      case "grossProfit":
        return metrics.GrossProfit;
      case "nmbrOfOrders":
        return metrics.NumberOrders;
    }
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title> {props.t("WooCommerce Dashboard")} | LiveMetrics - E-COM SASS</title>
        </MetaTags>
        <Container fluid>
  
 
          {<Breadcrumbs 
          title={props.t("Dashboards")} 
          breadcrumbItem={props.t("WooCommerce Dashboard")} 
          pageHeading={props.t("Woocommerce Charts")}
        
          image={WooCommercepIcon}
          />}
          <Row>
            <Col xl={12}>
              <CardBody>
                  <div dir="ltr">
                  <div>
      <div className="d-flex align-items-center" style={{ marginBottom: '20px', marginLeft:'35px' }}>
        {/* Label for Filters */}
        <span style={{ marginRight: '30px', fontWeight: 'regular', fontSize:'15px',color:'#9291A5' }}>{props.t("Filters")}</span>
        
                  {/* Radio Buttons */}
                  <ChartFilter
                    selectedSeries={selectedSeries}
                    handleRadioChange={handleRadioChange}
                    props={props}
                  />
        <div style={{ marginLeft: 'auto', fontWeight: 'bold', fontSize: '20px', marginRight:'60px' }}>
         {getFilterLabel()} Chart
      </div>
      </div> 
    </div>
                    <div className="charts">
                      <ReactEcharts
                        option={getOption()}
                        style={{ height: "300px", width: "90%" }}
                      />
                    </div>
                  </div>
                </CardBody>
            </Col>
          </Row>
          <Row >
            <div>  {
              dateRange !== '' &&
              <div style={{ display: 'flex', marginTop: '50px', borderRadius: '5px', width: 'max-content', height: '30px', padding: "15px" }}>
                <section style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginRight:'15px' }}>
                  <div style={{ width: '26px', height: '26px', background: '#F6B43B', borderRadius: '4px', marginRight: '5px', marginTop: '-2px' }}></div>
                  <span style={{ color: '#0C1426', fontSize: '15px', fontWeight: 'bold' }}>
                    {/* {`${chosedDate[0]?.startDate?.toLocaleString("en", {
                      month: "short", day: "2-digit"
                    })}-${chosedDate[0]?.endDate?.toLocaleString("en", {
                      month: "short", day: "2-digit"
                    })} ${chosedDate[0]?.endDate?.getFullYear()}`} */}
                    {newStartDate}  {newEndDate}
                  </span>
                </section>
                <section style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginLeft: '15px' }}>
                  <div style={{ width: '26px', height: '26px', background: '#EC1A74', borderRadius: '4px', marginRight: '5px', marginTop: '-2px' }}></div>
                  <span style={{ color: '#0C1426', fontSize: '15px', fontWeight: 'bold' }}>
                    {/* {lastPeriod.split(':')[1]} */}
                  </span>
                </section>
              </div>
            }
              <br></br>
            </div>
          </Row>
          <Row style={{ justifyContent: 'center'}}>
           {/* <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="droppable" direction="horizontal">
                {(provided, snapshot) => (
                  <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    style={{
                      ...getListStyle(snapshot.isDraggingOver),
                      display: 'flex',
                      justifyContent: 'center',  // Center the content horizontally
                      alignItems: 'center',       // Align the items vertically (optional)
                    }}
                  >
                    {myCards.map((item, index) => (
                      <Draggable key={item.id} draggableId={item.id} index={index}>
                        {(provided, snapshot) => (
                          <><WooDataCard
                            provided={provided}
                            snapshot={snapshot}
                            item={item}
                            
                           />
                           </>
                        )}
                      </Draggable>
                    ))}
                  </div>
                )}
              </Droppable>
            </DragDropContext> */}
            <WooDataCards
              metrics={metrics} />
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
}
WooDashboard.propTypes = {
  children: PropTypes.element.isRequired,
};
export default withRouter(withTranslation()(React.memo(WooDashboard)));
//export default React.memo(WooDashboard);