import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAdsRequest } from '../../store/googleAds/actions';
import GoogleAdsTable from './GoogleAdsTable';

const AdsTables = () => {
  const dispatch = useDispatch();

  const { ads, isLoading } = useSelector((state) => state.GoogleAds);

  const [filteredData, setFilteredData] = useState(ads);
  const [sortedInfo, setSortedInfo] = useState({});
  useEffect(() => {
    dispatch(getAdsRequest());
  }, []);

  useEffect(() => {
    setFilteredData(ads);
  }, [ads]);

  const columns = [
    {
      title: 'AD ID',
      dataIndex: 'ad_id',
      key: 'ad_id',
      sorter: (a, b) => a.ad_id - b.ad_id,
      sortOrder: sortedInfo.columnKey === 'ad_id' ? sortedInfo.order : null,
      ellipsis: true,
      align: 'center',
      render: (text) => <strong className='table-cell-text'> {text}</strong>,
    },
    {
      title: 'AD GROUP NAME',
      dataIndex: 'ad_group_name',
      align: 'center',
      key: 'ad_group_name',
      
      render: (text) => <strong className='table-cell-text'>{text}</strong>,
    },
    {
      title: 'CAMPAIGN NAME',
      dataIndex: 'campaign_name',
      key: 'campaign_name',
      align: 'center',
      render: (text) => <strong className='table-cell-text'>{text}</strong>,
    },
    {
      title: 'CLICKS',
      dataIndex: 'clicks',
      align: 'center',
      key: 'clicks',
      sorter: (a, b) => a.clicks - b.clicks,
      sortOrder: sortedInfo.columnKey === 'clicks' ? sortedInfo.order : null,
      ellipsis: true,
      render: (text, record) => (
        <strong className='table-cell-text'>{record.clicks && record.clicks ? record.clicks : '-'}</strong>
      ),
    },
    {
      title: 'CONVERSIONS',
      dataIndex: 'conversions',
      key: 'conversions',
      align: 'center',
      sorter: (a, b) => a.conversions - b.conversions,
      sortOrder:
        sortedInfo.columnKey === 'conversions' ? sortedInfo.order : null,
      ellipsis: true,
      render: (text, record) => (
        <strong className='table-cell-text'>
          {record.conversions && record.conversions
            ? record.conversions.toFixed(2)
            : '-'}
        </strong>
      ),
    },
    {
      title: 'COST',
      dataIndex: 'cost',
      key: 'cost',
      align: 'center',
      sorter: (a, b) => a.cost - b.cost,
      sortOrder: sortedInfo.columnKey === 'cost' ? sortedInfo.order : null,
      ellipsis: true,
      render: (text, record) => (
        <strong className='table-cell-text'>{record.cost && record.cost ? record.cost : '-'}</strong>
      ),
    },
  ];

  const filters = [
    { type: 'adGroup', value: 'ad_group_name', placeholder: 'Ad Group Name ' },
    { type: 'campaign', value: 'campaign_name', placeholder: 'Campaign Name' },
  ];
  return (
    <GoogleAdsTable
      title={'Ads Table'}
      columns={columns}
      setFilteredData={setFilteredData}
      filteredData={filteredData}
      data={ads}
      sortedInfo={sortedInfo}
      setSortedInfo={setSortedInfo}
      isLoading={isLoading}
      filters={filters}
    />
  );
};

export default AdsTables;
