import PropTypes from 'prop-types';
import MetaTags from 'react-meta-tags';
import React from 'react';
import { Row, Col, Container } from 'reactstrap';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { forgetPasswordRequest } from '../../store/auth/actions';
import logoImg from '../../assets/images/Live Metrics White Logo.png';  // Assuming you have a logo image


const ForgetPasswordPage = (props) => {
  const dispatch = useDispatch();

  function handleSubmit(event) {
    event.preventDefault();
    const email = event.currentTarget.elements.email.value;
    dispatch(forgetPasswordRequest(email));
  }

  return (
    <React.Fragment>
      <MetaTags>
        <title>Forget Password | LiveMetrics - E-com SaaS</title>
      </MetaTags>
      <div className="auth-page bg-resetpassword" style={{ minHeight: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <div className="text-center position-absolute" style={{ top: '50px' }}>
          <a href="https://live-metrics.io/" target="_blank" rel="noopener noreferrer">
            <img src={logoImg} alt="LiveMetrics Logo" className="img-fluid" style={{ width: '220px', height: '36px' }} />
          </a>
        </div>
        <Container>
          <Row className="justify-content-center">
            <Col md={6} lg={6} className="d-flex justify-content-center">
              <div className="auth-box bg-white d-flex flex-column align-items-center justify-content-center" style={{ borderRadius: '20px', width: '540px', height: '387px' }}>
                <div className="text-center mb-4">
                  <h5 style={{ fontSize: '28px', fontWeight: '600', color: '#2d2f3a' }}>Reset Password</h5>
                  <span style={{fontSize:'16px', fontWeight:'400'}}>Sign In To Continue To LiveMetrics.</span>
                </div>
                <form onSubmit={handleSubmit} className="custom-form">
                  <div className="mb-3" style={{width:'396px'}}>
                    <label htmlFor="email" className="form-label" style={{fontSize:'16px'}}>Email</label>
                    <input
                      type="email"
                      name="email"
                      id="email"
                      className="form-control"
                      placeholder="Enter email"
                      style={{ height: '60px', fontSize: '16px', borderRadius: '8px', textAlign: 'center' }}
                      required
                    />
                  </div>
                  <div className="mb-3">
                    <button
                      className="btn btn-primary w-100"
                      type="submit"
                      style={{ height: '60px', fontSize: '16px', borderRadius: '8px', backgroundColor: '#4285F4', border: 'none', fontWeight: '600' }}
                    >
                      Reset password
                    </button>
                  </div>
                </form>
                <div className="text-center">
                        <p className="mb-0" style={{fontSize:'16px',color:'gray'}}>
                        Remember your password ?{' '}
                          <Link
                            to="/register"
                            className="fw-semibold"
                            style={{fontSize:'16px',color:'#4285F4'}}
                          >
                            {' '}
                            Sign Up{' '}
                          </Link>{' '}
                        </p>
                      </div>
              </div>
            </Col>
          </Row>
          <div className="text-center" style={{ position: 'absolute', bottom: '30px', left: '50%', transform: 'translateX(-50%)',color:'white' }}>
  <span className="mb-0" style={{fontSize:'16px'}}>© {new Date().getFullYear()} LiveMetrics. Crafted by Convergen</span>
</div>
        </Container>
      </div>
    </React.Fragment>
  );
};

ForgetPasswordPage.propTypes = {
  history: PropTypes.object,
};

export default ForgetPasswordPage;
