import React, { useEffect } from 'react';
import { Col, Row } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { fetchOrderStatusRequest } from '../../../store/woocommerce/actions';
import MetaTags from 'react-meta-tags';
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import WooOrdersStatusTable from './WooOrdersStatusTable';
import OrdersStatusChartCard from './OrdersStatusChartCard'; // Import the doughnut chart component
import OrdersStatusBarChartCard from './OrdersStatusBarChartCard'; // Import the new bar chart component
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import secureSection from '../../../MangeSecurity/MemberSecurity';
const WooCommercepIcon = `${process.env.PUBLIC_URL}/images/integrations_images/WooCommerce.svg`;

function WooOrdersStatus(props) {
  secureSection();
  const dispatch = useDispatch();
  const { orderStatus } = useSelector((state) => state.woocommerce);

  useEffect(() => {
    const savedOrderStatus = localStorage.getItem('wooorderstatus');
    if (savedOrderStatus) {
      dispatch({ type: 'FETCH_ORDER_STATUS_SUCCESS', payload: JSON.parse(savedOrderStatus) });
    } else {
      dispatch(fetchOrderStatusRequest(15, 1));
    }
  }, [dispatch]);

  const dataMapProductCategoryName = orderStatus?.map((item) => item.orderStatus.name);
  const dataMapProductCategoryCount = orderStatus?.map((item) => item.orderStatus.total);

  const totalOrders = dataMapProductCategoryCount.reduce((acc, count) => acc + count, 0);

  const percentageData = totalOrders > 0 
  ? dataMapProductCategoryCount.map(count => ((count / totalOrders) * 100)) 
  : Array(dataMapProductCategoryCount.length).fill(0);

  const doughnutData = {
    labels: dataMapProductCategoryName,
    datasets: [
      {
        data: dataMapProductCategoryCount,
        backgroundColor: ["#EA2427", "#FF5733", "#EC1A74", "#76448A", "#34c38f", "#0E6655", "#000000", "#3B5B98", "#F6B43B", "#ebeff2", "#E5E5E5", "#E5E5E5", "#E5E5E5"],
        hoverBackgroundColor: ["#EA2427", "#FF5733", "#EC1A74", "#76448A", "#34c38f", "#0E6655", "#000000", "#3B5B98", "#F6B43B", "#ebeff2", "#E5E5E5", "#E5E5E5", "#E5E5E5"],
        hoverBorderColor: "#fff",
      },
    ],
  };

  const barData = {
    labels: [
      ['En attente', 'de traitement'],
      ['Edit', 'CEGID Order'],
      ['Blocked', 'Order'],
      ['En cours', 'de preparation'],
      ['En cours', 'd\'expedition'],
      ['Attente', 'Paiement'],
      ['Payé', 'par carte'],
      ['Livrée'],
      ['Annulée'],
      ['Editée'],
      ['Échouée'],
      ['Remboursée'],
      ['Brouillon']
    ],
    datasets: [
      {
        label: 'Percentage of Total Orders',
        data: percentageData, // Use the percentage data
        backgroundColor: ["#EA2427", "#FF5733", "#EC1A74", "#76448A", "#34c38f", "#0E6655", "#000000", "#3B5B98", "#F6B43B", "#ebeff2", "#E5E5E5", "#E5E5E5", "#E5E5E5"],
        hoverBackgroundColor: ["#EA2427", "#FF5733", "#EC1A74", "#76448A", "#34c38f", "#0E6655", "#000000", "#3B5B98", "#F6B43B", "#ebeff2", "#E5E5E5", "#E5E5E5", "#E5E5E5"],
      },
    ],
  };
  

  const doughnutChartOptions = {
    cutoutPercentage: 40,
    legend: {
      display: false,
    },
    tooltips: {
      enabled: true,
    },
  };

  const barChartOptions = {
    responsive: true,
    legend: {
      display: false,
    },
    scales: {
      xAxes: [{
        barPercentage: 0.6,
        gridLines: {
          display: false,
        },
        ticks: {
          autoSkip: false,  // Ensure all labels are shown
          maxRotation: 0,   // Keep labels horizontal
          minRotation: 0,
          callback: function(value) {
            if (value.length > 10) {
              return value.slice(0, 10) + '\n' + value.slice(10);
            }
            return value; // For shorter labels
          }
        },
      }],
      yAxes: [{
        ticks: {
          beginAtZero: true,  // Start the y-axis at 0
          max: 100,           // Set y-axis max to 100 for percentage display
          callback: function(value) {
            return `${value}%`; // Append percentage symbol to y-axis tick values
          },
        },
        gridLines: {
          display: true,
        },
      }],
    },
    tooltips: {
      callbacks: {
        label: function(tooltipItem, data) {
          const value = data.datasets[0].data[tooltipItem.index].toFixed(2); // Ensure 2 decimal points in tooltip
          return `${value}% of Total Orders`; // Tooltip showing percentage
        },
      },
    },
  };
  
  
  

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>{props.t("woo orders status")} | Live-Metrics - E-com SaaS</title>
        </MetaTags>
        <div className="container-fluid">
        
           
            <Breadcrumbs
              title={props.t("WooCommerce")}
              breadcrumbItem={props.t("Orders Status")}
              pageHeading={props.t("WooCommerce")}
              style={{ fontSize: '15px', fontWeight: '700' }}
              image={WooCommercepIcon}
            />
        
          <Row style={{ marginTop: '40px' }}>
            <Col xl={6}>
              <h5 style={{ color: '#0044BD', fontSize: '20px', marginBottom: '20px' }}> {props.t("Orders Status")}</h5>
              <OrdersStatusChartCard
                data={doughnutData}
                chartOptions={doughnutChartOptions}
                orderStatus={orderStatus}
                totalOrders={totalOrders}
              />
            </Col>
            <Col xl={6}>
              <WooOrdersStatusTable />
            </Col>
          </Row>

          {/* Add new row for bar chart */}
          <Row style={{ marginTop: '40px', marginBottom:'40px' }}>
            <Col xl={12}>
              <OrdersStatusBarChartCard
                data={barData}
                chartOptions={barChartOptions}
              />
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
}

WooOrdersStatus.propTypes = {
  t: PropTypes.any,
};

export default withRouter(withTranslation()(WooOrdersStatus));
