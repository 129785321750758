import React, { useState, useEffect } from 'react';
import { CardBody, Progress, Alert } from 'reactstrap';
import { Table } from 'antd'; // Using Ant Design for the table
import MetaTags from 'react-meta-tags';
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { useDispatch, useSelector } from 'react-redux';
import { fetchTopSellersRequest } from '../../../store/woocommerce/actions';
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import secureSection from '../../../MangeSecurity/MemberSecurity';

const WooCommercepIcon = `${process.env.PUBLIC_URL}/images/integrations_images/WooCommerce.svg`;

function WooTopSellers(props) {
  secureSection();
  const dispatch = useDispatch();
  const { topSellers } = useSelector((state) => state.woocommerce);

  useEffect(() => {
    const savedTopSellers = localStorage.getItem('wootopsellers');
    if (savedTopSellers) {
      dispatch({ type: 'FETCH_TOP_SELLERS_SUCCESS', payload: JSON.parse(savedTopSellers) });
    } else {
      dispatch(fetchTopSellersRequest(50, 1));
    }
  }, [dispatch]);

  // Define columns for Ant Design table
  const columns = [
    {
      title: props.t("PRODUCT ID"),
      dataIndex: 'product_id', // Make sure this matches the data field name
      key: 'product_id',
      sorter: (a, b) => a.product_id - b.product_id, // Sort by numerical ID
    },
    {
      title: props.t("NAME"),
      dataIndex: 'name', // Make sure this matches the data field name
      key: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name), // Sort alphabetically
    },
    {
      title: props.t("QUANTITY"),
      dataIndex: 'quantity', // Make sure this matches the data field name
      key: 'quantity',
      sorter: (a, b) => a.quantity - b.quantity, // Sort numerically by quantity
    }
  ];

  // Pagination options
  const paginationOptions = {
    pageSize: 10,
    showSizeChanger: true,
    pageSizeOptions: ['10', '20', '50'],
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>{props.t("woo top sellers")} | Live-Metrics - E-com SaaS</title>
        </MetaTags>
        <div className="container-fluid">
          
           
            <Breadcrumbs
              title={props.t("WooCommerce")}
              breadcrumbItem={props.t("Top Sellers")}
              pageHeading={props.t("WooCommerce")}
              style={{ fontSize: '15px', fontWeight: '700' }}
              image={WooCommercepIcon}
            />
        

          <div style={{ marginTop: '40px' }}>
            <CardBody className="px-0">
              {topSellers && topSellers.length > 0 ? (
                <Table
                  columns={columns}
                  bordered={true} // Disable the default borders
                  dataSource={topSellers.map((element, index) => ({
                    key: index, // Ensure each row has a unique key
                    product_id: element.TopSeller.product_id,
                    name: element.TopSeller.name,
                    quantity: element.TopSeller.quantity,
                  }))}
                  pagination={{
                    ...paginationOptions,
                    showSizeChanger: true,
                    style: {
                      backgroundColor: '#F4F2FF',
                      padding: '10px 15px',
                      marginTop: '2px',
                      borderRadius: '6px',
                      fontsize: '14px',
                      fontweight: '700',
                      color: '#6E6893',
                    },
                  }}
                  headerCellStyle={{
                    backgroundColor: '#FF0000', // Change this to your desired color
                    color: '#333333', // Text color
                  }}
                  onChange={(pagination, filters, sorter) => {
                    console.log("Table changed", sorter);
                  }}
                  className="custom-table" // Custom class for removing column borders
                />
              ) : (
                <div className='text-center'>
                  <CardBody>
                    <Progress value={80} color="success" style={{ width: '75%' }} animated />
                    <Alert color="success" className="mt-3">
                      {props.t("This might take a few minutes!")}
                    </Alert>
                  </CardBody>
                </div>
              )}
            </CardBody>
          </div>
        </div>
      </div>
      <style jsx>{`
        /* Remove borders between columns */
        .custom-table .ant-table-cell {
          border-right: none !important; /* Remove right border */
        }

        .custom-table .ant-table-cell:last-child {
          border-right: none !important; /* Ensure no right border for the last cell */
        }

        .custom-table .ant-table-thead > tr > th {
          border-bottom: none !important; /* Remove bottom border from the header */
        }
      `}</style>
    </React.Fragment>
  );
}

WooTopSellers.propTypes = {
  t: PropTypes.any
};

export default withRouter(withTranslation()(WooTopSellers));
