import gql from 'graphql-tag';

const getCoupons = gql`query($limit:Int!,$page:Int!){
    all(limit:$limit,page:$page){
        pages,
        type,
        coupon{
            id,
            code,
            amount,
            usage_count,
            individual_use,
            free_shipping
        },
}}
`


export { getCoupons};