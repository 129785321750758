import React from 'react';
import GoogleAdsCard from './GoogleAdsCard/GoogleAdsCard';
import { getTotal } from '../../GoogleAnalytics/common/HelperFunctions/utils';

const GoogleAdsCards = ({ data , colors}) => {
  // console.log('here is our data', data);
  const currency = localStorage.getItem('googleads_customer_currency_code');

  const findDataByName = (name, value) => {
    const metric = data.find((item) => item.name === name);
    return metric ? metric[value] : [];
  };

  const generateTotalValue = (item, data) => {
    // Extract relevant data from data dynamically
    const totalClicks = getTotal(findDataByName('Clicks', data));
    const totalCost = getTotal(findDataByName('Total Ad Cost', data));
    const totalConversions = getTotal(findDataByName('Conversions', data));
    const totalImpressions = getTotal(findDataByName('impressions', data));
    const totalConversionsValue = getTotal(findDataByName('Revenue', data));
    const Tsales= getTotal(findDataByName('Sales', data));
    const TgrossProfit= getTotal(findDataByName('GrossProfit', data));
    const TNumberOrders= getTotal(findDataByName('NumberOrders', data));
    const TAOV= getTotal(findDataByName('AOV', data));
const TladingPage = getTotal(findDataByName('Total Landing Page View', data));
const TReach = getTotal(findDataByName('Total Reach', data));
    // Calculate the values based on the metric
    if (item.name === 'Total Ad Cost')
      return { data: totalCost, display: `${currency}${totalCost.toFixed(2)}` };
    if (item.name === 'Revenue')
      return {
        data: totalConversionsValue,
        display: `${totalConversionsValue.toFixed(2)}`, // Fixed the extra curly brace here
      };
    if (item.name === 'Sales')
      return {
        data: Tsales,
        display: `${Tsales.toFixed(2)}`, // Fixed the extra curly brace here
      };
    if (item.name === 'GrossProfit')
      return {
        
        data: TgrossProfit,
        display: `${TgrossProfit.toFixed(2)}`, // Fixed the extra curly brace here
      };
    if (item.name === 'NumberOrders')
      return {
        data: TNumberOrders,
        display: TNumberOrders, // Fixed the extra curly brace here
      };
    if (item.name === 'AOV')
      return {
        data: TAOV,
        display: `${TAOV.toFixed(2)}`, // Fixed the extra curly brace here
      };
    if (item.name === 'Conversions')
      return {
        data: totalConversions,
        display: `${totalConversions.toFixed(2)}`,
      };
    if (item.name === 'impressions')
      return { data: totalImpressions, display: `${totalImpressions}` };
    if (item.name === 'Clicks')
      return { data: totalClicks, display: `${totalClicks}` };
    if (item.name === 'ROAS') {
      const roas = totalCost !== 0 ? totalConversionsValue / totalCost : 0;
      return { data: roas, display: roas.toFixed(2) };
    }
    if (item.name === 'CPA') {
      const cpa = totalConversions !== 0 ? totalCost / totalConversions : 0;
      return { data: cpa, display: `$${cpa.toFixed(2)}` };
    }
    if (item.name === 'CPC') {
      const cpc = totalClicks !== 0 ? totalCost / totalClicks : 0;
      return { data: cpc, display: `$${cpc.toFixed(2)}` };
    }
    if (item.name === 'CTR') {
      const ctr =
        totalImpressions !== 0 ? (totalClicks / totalImpressions) * 100 : 0;
      return { data: ctr, display: '%' + ctr.toFixed(2) };
    }
    if (item.name === 'Conversion Rate') {
      const conversionRate =
        totalClicks !== 0 ? (totalConversions / totalClicks) * 100 : 0;
      return { data: conversionRate, display: '%' + conversionRate.toFixed(2) };
    }
    if (item.name === 'AOV') {
      const aov =
        totalConversions !== 0 ? totalConversionsValue / totalConversions : 0;
      return { data: aov, display: aov.toFixed(2) };
    }
    if( item.name === 'Total Landing Page View'){
      return { data: TladingPage, display: `${TladingPage}` };
    }
    if( item.name === 'Total Reach'){
      return { data: TReach, display: `${TReach}` };
    }

    // Default fallback
    return 'N/A';
  };

  const finalData = data.map((item) => {
    const TotalValue = generateTotalValue(item, 'data');

    if (item?.compareData.length > 0) {
      const OldTotalValue = generateTotalValue(item, 'compareData');
      return {
        name: item.name,
        totalValue: TotalValue.display,
        percentage: (
          (TotalValue.data - OldTotalValue.data) /
          OldTotalValue.data
        ).toFixed(2),
        series: [
          { name: item.name, data: item.data },
          { name: `compare ${item.name}`, data: item.compareData },
        ],
      };
    } else
      return {
        name: item.name,
        totalValue: TotalValue.display,
        series: [{ name: item.name, data: item.data }],
      };
  });

  
  return (
    <>
      {finalData.map((item, index) => (
        <GoogleAdsCard item={item} key={index} colors={colors} />
      ))}
    </>
  );
};

export default GoogleAdsCards;
