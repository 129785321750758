import React, { useState, useEffect, useRef } from 'react';
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import GADeviceCategory from './GADeviceCategory';
import GAPurchaseJourney from './GAPurchaseJourney';
import GAUsersOverview from './GAUsersOverview';
import GAAffiliationOverview from './GAAffiliationOverview';
import GASoldProductsOverview from '../GAsoldProducts/GASoldProductsOverview';
import Spinner from '../common/Spinner';

import logoAnalytics from '../../../assets/integrations_images/googleAnalytics.svg';
import { useSelector } from 'react-redux';
import GAActiveAccountBar from '../common/GAActiveAccountBar';
const GAOverview = (props) => {
  const { loading } = useSelector((state) => state.googleAnalytics);

  return (
    <div className="page-content">
      <div className="container-fluid">
        <div>
          <div>
            {/* <Col md={4}>
                <Link
                  to="/GoogleAnalyticsReportDetail"
                  className="btn btn-light"
                  style={{ marginRight: '10px' }}
                >
                  <i className="bx bx-plus me-1"> </i>Generate a Google
                  Analytics report
                </Link>
              </Col> */}
            <GAActiveAccountBar />
            <Breadcrumbs
              title="Google Analytics"
              breadcrumbItem="Products"
              pageHeading="Google Analytics Overview"
              image={logoAnalytics}
            />
            <Spinner loading={loading} />
            <GAUsersOverview />
            <Breadcrumbs
              image={logoAnalytics}
              title="Active Users Purchase Journey"
            />
            <GAPurchaseJourney />
            <Breadcrumbs image={logoAnalytics} title="Affiliation Overview" />

            <GAAffiliationOverview />
            <Breadcrumbs image={logoAnalytics} title="Top 5 best sellers" />
            <GASoldProductsOverview />
            <Breadcrumbs image={logoAnalytics} title="Device Category" />

            <GADeviceCategory />
          </div>
        </div>
      </div>
    </div>
  );
};

export default GAOverview;
