import React from "react"
import MetaTags from 'react-meta-tags';

import {
  Table,
  Row,
  Col,
  Card,
  CardBody,
  CardSubtitle,
  CardHeader,
  Button,
  CardText
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { withTranslation } from "react-i18next"
import { withRouter } from "react-router-dom"
// import  react-hook-form 
import { useForm } from 'react-hook-form';

const Stores = () => {

// from controller
const { register, handleSubmit, watch, errors } = useForm();

const onSubmit= handleSubmit((dataForm) => {
    console.log(dataForm);
  })
const handleAddFrom = (bool,e) => {
    
    var el = document.getElementById('form-add');
    var btn = document.getElementById('add-buttton');
    if(bool){
        el.classList.remove('d-none');
        e.target.disabled = true;
    }
    else {
        el.classList.add('d-none');
        console.log(btn)
        btn.disabled = false;
        console.log("e.target.disabled", e.target.disabled)
    }
}
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Stores | Live-Metrics - E-com SaaS</title>
        </MetaTags>
        <div className="container-fluid">
          <Breadcrumbs title="Settings" breadcrumbItem="Stores" pageHeading="Manage Stores" />

          <Row>
            <Col col={12}>
              <Card>
                <CardHeader>
                <h4 className="card-title mb-4">Stores details</h4>
                <Row>
                    <Col col={12} >
                        <CardText>You can a store</CardText>
                    </Col>
                    <Col col={12} className="text-end">
                        <Button color="primary" id="add-buttton" className="ms-1" onClick={(e) => handleAddFrom(true,e)}>
                            Add Store
                        </Button>
                    </Col>
                </Row>
                  
                  <CardSubtitle>
                    <form onSubmit={onSubmit} className="d-none" id="form-add">
                        <div className="mb-3">
                        <div className="d-flex align-items-start">
                            <div className="flex-grow-1">
                            <label className="form-label">Store Name</label>
                            </div>

                        </div>

                        <div className="mb-3">
                            <input
                            name="store"
                            //value="123456"
                            type="text"
                            id="store"
                            className="form-control"
                            {...register('store',{ required: true })} 
                            required
                            placeholder="Enter Store Name"
                            />
                        </div>
                        </div>

                        <div className="mb-3">
                        <Button type="submit" color="primary" className="ms-1">
                            Submit
                        </Button>{" "}
                        <Button type="reset" color="secondary" onClick={(e) => handleAddFrom(false,e)}>
                            Cancel
                        </Button>
                        </div>
                    </form>
                  </CardSubtitle>
                </CardHeader>
                <CardBody>
                  <div className="table-responsive">
                    <Table className="table mb-0">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Store Name</th>
                          <th>Store Owner</th>
                          <th>Store Type</th>
                          <th>Create At</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <th scope="row">1</th>
                          <td>AlriqaSport</td>
                          <td>Karim</td>
                          <td>Shopify</td>
                          <td>2022/05/20</td>
                        </tr>
                        <tr>
                          <th scope="row">2</th>
                          <td>TuttoBeauty</td>
                          <td>Karim</td>
                          <td>Woocommerce</td>
                          <td>2022/09/01</td>
                        </tr>
                        <tr>
                          <th scope="row">3</th>
                          <td>TuttoSport</td>
                          <td>Amine</td>
                          <td>Prestashop</td>
                          <td>2022/11/28</td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

        </div>
      </div>
    </React.Fragment>
  )
}

export default withRouter(withTranslation() (Stores));
