import gql from 'graphql-tag';



const emailbuilder = gql` mutation ($subject: String!, $mailContent: String!, $emails: String!) {
  sendNewsletter(subject: $subject, mailContent: $mailContent, emails: $emails) {
      subject,
      emails,
      mailContent,
    }
  }
`;

const UPLOAD_PHOTO_FILES = gql`
  mutation UploadPhotoFiles($body: BodyInput) {
    uploadFiles(body: $body) {
      message
      error
      data
    }
  }
`;

const GET_PHOTO_FILES = gql`
  query GetPhotoFiles {
    getFiles {
      message
      error
      data
    }
  }
`;


export  {emailbuilder }

