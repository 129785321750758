import React from 'react';
import { Pie } from 'react-chartjs-2';
import { Card, CardBody, Row } from 'reactstrap';
import i18next from 'i18next';

const InstagramSocialMediaGender = ({ dataChart }) => {
  let total = 0;
  let femalePercentage;
  let malePercentage;
  let underPercentage;
  // Calculate percentages
  if (
    dataChart.length > 0 &&
    dataChart.data[0] !== undefined &&
    dataChart.data[1] !== undefined &&
    dataChart.data[2] !== undefined
  ) {
    total = dataChart.data[0] + dataChart.data[1] + dataChart.data[2];

    femalePercentage = ((dataChart.data[0] / total) * 100).toFixed(2);
    malePercentage = ((dataChart.data[1] / total) * 100).toFixed(2);
    underPercentage = ((dataChart.data[2] / total) * 100).toFixed(2);
  }
  return (
    <Card className="card-h-100">
      <CardBody>
        <div className="d-flex flex-wrap align-items-center mb-4">
          <h5 className="card-title me-2">
            {i18next.t('Social Media Audience ( Gender )')}{' '}
          </h5>
        </div>
        <Row className="align-items-center">
          <div className="col-sm">
            <div id="wallet-balance" className="apex-charts">
              <Pie data={dataChart} />
            </div>
          </div>
          <div className="col-sm align-self-center">
            <div className="mt-4 mt-sm-0">
              <div>
                <p className="mb-2">
                  <i className="mdi mdi-circle align-middle font-size-10 me-2 text-success"></i>{' '}
                  {i18next.t('Female')}
                </p>
                <h6>
                  {femalePercentage} % ={' '}
                  <span className="text-muted font-size-14 fw-normal">
                    {dataChart && dataChart.length > 0 && dataChart.data[0]
                      ? dataChart.data[0]
                      : 0}
                  </span>
                </h6>
              </div>

              <div className="mt-4 pt-2">
                <p className="mb-2">
                  <i className="mdi mdi-circle align-middle font-size-10 me-2 text-primary"></i>{' '}
                  {i18next.t('Male')}
                </p>
                <h6>
                  {malePercentage} % ={' '}
                  <span className="text-muted font-size-14 fw-normal">
                    {dataChart && dataChart.length > 0 && dataChart.data[1]
                      ? dataChart.data[1]
                      : 1}{' '}
                  </span>
                </h6>
              </div>

              <div className="mt-4 pt-2">
                <p className="mb-2">
                  <i className="mdi mdi-circle align-middle font-size-10 me-2 text-info"></i>{' '}
                  {i18next.t('Unknown')}
                </p>
                <h6>
                  {underPercentage} % ={' '}
                  <span className="text-muted font-size-14 fw-normal">
                    {dataChart && dataChart.length > 0 && dataChart.data[2]
                      ? dataChart.data[2]
                      : 2}{' '}
                  </span>
                </h6>
              </div>
            </div>
          </div>
        </Row>
      </CardBody>
    </Card>
  );
};

export default InstagramSocialMediaGender;
