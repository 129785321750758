import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import Select from "react-select"
import FeatherIcon from "feather-icons-react";
import MetaTags from 'react-meta-tags';
import axios from "axios";
import "../../assets/scss/custom/pages/_daterange.scss";
import {
  Row,
  Col,
  FormGroup,
  InputGroup,
  Card,
  Modal,
  CardHeader,
  CardTitle,
  CardBody,
} from "reactstrap"
import Button from 'react-bootstrap/Button';
import Offcanvas from 'react-bootstrap/Offcanvas';
import OffcanvasHeader from 'react-bootstrap/OffcanvasHeader';
import OffcanvasBody from 'react-bootstrap/OffcanvasBody';
import {getFacebookPixels } from '../../queries/FacebookAds/queries';
import urls from '../../routes/apiUrls';
import { getData } from '../../queries/Dashboard/FacebookDashboardQ';
import Breadcrumbs from "../../components/Common/Breadcrumb"
import ApolloClient from 'apollo-boost';
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { getFbCampaignMetrics ,getFbCampaignMetricsCompare} from '../../store/facebookAds/actions';
import { useDispatch, useSelector } from 'react-redux';
import "flatpickr/dist/themes/material_blue.css"
import { formatDate } from '../GoogleAnalytics/common/HelperFunctions/utils';
// import GoogleAdsCards from '../GoogleDashboard/Google Ads Overview/GoogleAdsCards';
import GoogleAdsChart from '../GoogleDashboard/Google Ads Overview/GoogleAdsChart/GoogleAdsChart';
import GoogleAdsCards from '../GoogleDashboard/Google Ads Overview/GoogleAdsCards'


import secureSection from '../../MangeSecurity/MemberSecurity';
const ApploCLI = new ApolloClient({
  uri: urls.facebook
})
const FacebbokAdsIcon = `${process.env.PUBLIC_URL}/images/integrations_images/AD8.png`;



function Socials(props) {
  const dispatch = useDispatch();


  const colors = [
    {
      lineColor: '#4A3AFF',
      fillColor: [
        {
          offset: 0,
          color: '#93AAFD',
        },
        {
          offset: 0.47,
          color: '#C6D2FD',
        },
        {
          offset: 1,
          color: '#E5EAFC4F',
        },
      ],
    },
    {
      lineColor: '#F6B43B',
      fillColor: [
        {
          offset: 0,
          color: ' #F6B43B',
        },

        {
          offset: 1,
          color: '#D9D9D9',
        },
      ],
    },
  ];



  secureSection();
  const { dataDashboard } = useSelector((state) => state.facebookAds)
  const { dateRange } = useSelector((state) => state.dateRangeReducer);

  const [cost, setCost] = useState([]);
  const [compareCost, setCompareCost] = useState([]);

  const [revenue, setRevenue] = useState([]);
  const [compareRevenue, setCompareRevenue] = useState([]);

  const [conversions, setConversions] = useState([]);
  const [compareConversions, setCompareConversions] = useState([]);

  const [impressions, setImpressions] = useState([]);
  const [compareImpressions, setCompareImpressions] = useState([]);

  const [clicks, setClicks] = useState([]);
  const [compareClicks, setCompareClicks] = useState([]);

  const [ROAS, setROAS] = useState([]);
  const [compareROAS, setCompareROAS] = useState([]);

  const [cpa, setCPA] = useState([]);
  const [compareCPA, setCompareCPA] = useState([]);

  const [cpc, setCPC] = useState([]);
  const [compareCPC, setCompareCPC] = useState([]);

  const [ctr, setCTR] = useState([]);
  const [compareCTR, setCompareCTR] = useState([]);

  const [conversionRate, setConversionRate] = useState([]);
  const [compareConversionRate, setCompareConversionRate] = useState([]);

  const [AOV, setAOV] = useState([]);
  const [compareAOV, setCompareAOV] = useState([]);

  const [totalLandingPageView, setTotalLandingPageView] = useState([]);
  const [compareTotalLandingPageView, setCompareTotalLandingPageView] = useState([]);
  
  const [totalreach, setTotalreach] = useState([]);
  const [compareTotalreach, setCompareTotalreach] = useState([]);
 
  const [loadingState, setLoadingState] = useState(false);
 



  useEffect(() => {
   ApploCLI.query({
        query: getFacebookPixels,
        context: {
          headers: {
            Authorization: window.localStorage.getItem("token") ? `Bearer ${window.localStorage.getItem("token")}` : ""
          },
        },
        errorPolicy: "all",
        fetchPolicy: "network-only",
      }).then((data) => {
console.log("data",data)
       if(data.data.adAccounts){
        let dataActiveAccount = data.data.adAccounts;
        let x = dataActiveAccount.filter((account) => {
          return (account.active === true);
        })
        if(x.length){
          localStorage.setItem("metaads_customer_currency_code", data.data.adAccounts[0].currency_code);
        }
       }
      });
    
    
  }, [])



  useEffect(() => {
    if (dateRange?.selection1?.startDate && dateRange?.selection1?.endDate) {
      const newStartDate = formatDate(dateRange.selection1.startDate);
      const newEndDate = formatDate(dateRange.selection1.endDate);

      if (dateRange.selection2.startDate && dateRange.selection2.endDate) {
        const newCompareStartDate = formatDate(dateRange.selection2.startDate);
        const newCompareEndDate = formatDate(dateRange.selection2.endDate);
        dispatch(
          getFbCampaignMetricsCompare(
            newStartDate,
            newEndDate,
            newCompareStartDate,
            newCompareEndDate
          )
        );
      } else {
        dispatch(getFbCampaignMetrics(newStartDate, newEndDate));
      }
    } 
  }, [dateRange]);


  useEffect(() => {

    if (dataDashboard?.report && dataDashboard.report.length > 0) {    
      let TtotalCost = [];
      let Trevenue = [];
      let Tconversions = [];
      let Timpressions = [];
      let Tclicks = [];
      let TROAS = [];
      let Tcpa = [];
      let Tcpc = [];
      let Tctr = [];
      let Tconversionrate = [];
      let TAOV = [];
      let TtotalLandingPageView = [];
      let Ttotalreach = [];

      dataDashboard.report[0].forEach((item) => {
        TtotalCost.push(item.totalCost || 0);
        Trevenue.push(item.Revenue || 0);
        Tconversions.push(item.totalConversions);
        Timpressions.push(item.totalImpressions);
        Tclicks.push(item.totalClicks);
        TROAS.push(item.ROAS);
        Tcpa.push(item.cpa);
        Tcpc.push(item.totalcpc);
        Tctr.push(item.totalctr);
        Tconversionrate.push(item.conversionRate);
        TAOV.push(item.AOV);
        TtotalLandingPageView.push(item.totalLandingPageView);
        Ttotalreach.push(item.totalreach);
      });
      setCost(TtotalCost);
      setRevenue(Trevenue);
      setConversions(Tconversions);
      setImpressions(Timpressions);
      setClicks(Tclicks);
      setROAS(TROAS);
      setCPA(Tcpa);
      setCPC(Tcpc);
      setCTR(Tctr);
      setConversionRate(Tconversionrate);
      setAOV(TAOV);
      setTotalLandingPageView(TtotalLandingPageView);
      setTotalreach(Ttotalreach);

      // Check if there is a second report for comparison
      let TcompareCost = [];
      let TcompareRevenue = [];
      let TcompareConversions = [];
      let TcompareImpressions = [];
      let TcompareClicks = [];
      let TcompareROAS = [];
      let TcompareCPA = [];
      let TcompareCPC = [];
      let TcompareCTR = [];
      let TcompareConversionRate = [];
      let TcompareAOV = [];
      let TcompareTotalLandingPageView = [];
      let TcompareTotalreach = [];
      if (dataDashboard?.report.length > 1) {
       dataDashboard.report[1].forEach((item) => {
          TcompareCost.push(item.totalCost || 0);
          TcompareRevenue.push(item.Revenue || 0);
          TcompareConversions.push(item.totalConversions);
          TcompareImpressions.push(item.totalImpressions);
          TcompareClicks.push(item.totalClicks);
          TcompareROAS.push(item.ROAS);
          TcompareCPA.push(item.cpa);
          TcompareCPC.push(item.cpc);
          TcompareCTR.push(item.ctr);
          TcompareConversionRate.push(item.conversionRate);
          TcompareAOV.push(item.AOV);
          TcompareTotalLandingPageView.push(item.totalLandingPageView);
          TcompareTotalreach.push(item.totalreach);
        });
      }
      setCompareCost(TcompareCost);
      setCompareRevenue(TcompareRevenue);
      setCompareConversions(TcompareConversions);
      setCompareImpressions(TcompareImpressions);
      setCompareClicks(TcompareClicks);
      setCompareROAS(TcompareROAS);
      setCompareCPA(TcompareCPA);
      setCompareCPC(TcompareCPC);
      setCompareCTR(TcompareCTR);
      setCompareConversionRate(TcompareConversionRate);
      setCompareAOV(TcompareAOV);
      setCompareTotalLandingPageView(TcompareTotalLandingPageView);
      setCompareTotalreach(TcompareTotalreach);
    }
  }, [dataDashboard]);

 console.log("datashaboard",dataDashboard)
  const cardSeries = [
    { name: 'Total Ad Cost', data: cost, compareData: compareCost },
    { name: 'Revenue', data: revenue, compareData: compareRevenue },
    { name: 'Conversions', data: conversions, compareData: compareConversions },
    { name: 'ROAS', data: ROAS, compareData: compareROAS },
    { name: 'CPA', data: cpa, compareData: compareCPA },
    { name: 'CPC', data: cpc, compareData: compareCPC },
    { name: 'CTR', data: ctr, compareData: compareCTR },
    {
      name: 'Conversion Rate',
      data: conversionRate,
      compareData: compareConversionRate,
    },
    { name: 'impressions', data: impressions, compareData: compareImpressions },
    { name: 'Clicks', data: clicks, compareData: compareClicks },
    { name: 'AOV', data: AOV, compareData: compareAOV },
    { name: 'Total Landing Page View', data: totalLandingPageView, compareData: compareTotalLandingPageView },
    { name: 'Total Reach', data: totalreach, compareData: compareTotalreach },
  ];

  const series = [
    { name: 'spend', data: cost, value: 'Spend', compareData: compareCost },
    {
      name: 'revenue',
      data: revenue,
      value: 'Revenue',
      compareData: compareRevenue,
    },
    {
      name: 'conversions',
      data: conversions,
      value: 'Conversions',
      compareData: compareConversions,
    },
    {
      name: 'impressions',
      data: impressions,
      value: 'Impressions',
      compareData: compareImpressions,
    },
    {
      name: 'clicks',
      data: clicks,
      value: 'Clicks',
      compareData: compareClicks,
    },
  ]; //needed for the main chart

  const [selectedGroup, setselectedGroup] = useState(null);
  const optionGroup = [
    {
      label: "USD - United States Dollar",
      value: "USD",
    },
    {
      label: "EUR - Euro",
      value: "EUR",
    },

    {
      label: "LYD - Libyan Dinar",
      value: "LYD",
    },
    {
      label: "MAD - Moroccan Dirham",
      value: "MAD",
    },
    {
      label: "TND - Tunisian Dinar",
      value: "TND",
    },

  ]

  const checkcurrency = localStorage.getItem("metaads_currency_revenue_rate")
 


  const currencyspend = localStorage.getItem("metaads_customer_currency_code")
  function handleSelectGroup(selectedOption) {

    setselectedGroup(selectedOption)
    axios.get(`https://v6.exchangerate-api.com/v6/c0fd644ef51cdd9e2aed5b22/latest/${currencyspend}`)
      .then(response => {
        localStorage.setItem("meta_revenue_currency", selectedOption.value);
        const revenue_currency = response.data.conversion_rates[selectedOption.value]
        localStorage.setItem("metaads_currency_revenue_rate", revenue_currency);
        // Handle the response data here
      })
  }
  const currencyrevenuerate = localStorage.getItem("metaads_currency_revenue_rate")
  
 
 
 

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>{props.t("Meta Ads Dashboard")} | LiveMetrics - E-com SaaS</title>
        </MetaTags>
        <div className="container-fluid">
          <Breadcrumbs
              title={props.t("Meta Ads Dashboard")}
              breadcrumbItem={props.t("Meta Ads Dashboard")}
              pageHeading={props.t("Ads Dashboard")}
              image={FacebbokAdsIcon}
            />
          <Row>
          <Col xl={12}>
                    <GoogleAdsChart series={series} colors={colors} />
            </Col>
            <Row className="justify-content-center">
            <GoogleAdsCards data={cardSeries} colors={colors} />
          </Row>
          </Row>        
        </div>
      </div>
    </React.Fragment>
  )
}
Socials.propTypes = {
  t: PropTypes.any
};
export default withRouter(withTranslation()(Socials));
//export default withRouter(withTranslation()(Socials))
