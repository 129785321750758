import React from 'react';
import { Row } from 'reactstrap';
import FbSocialMediaCard from './fbSocialMediaCard';

const FbSocialMediaCards = ({ secCards }) => {
  console.log('*********', secCards);
  return (
    <>
      <Row className="justify-content-center no-gutters">
        {/* First row: Engaged User, Likes, Followers */}
        {secCards.slice(0, 4).map((item) => (
          <div className="dashboardMinorCardItem">
            <FbSocialMediaCard item={item} key={item.id} />
          </div>
        ))}
      </Row>
      <Row className="justify-content-center no-gutters">
        {secCards.slice(4).map((item) => (
          <div className="dashboardMinorCardItem">
            <FbSocialMediaCard item={item} key={item.id} />
          </div>
        ))}
      </Row>
    </>
  );
};

export default FbSocialMediaCards;
