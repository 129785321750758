import React from 'react';
import ReactECharts from 'echarts-for-react';
import { logoMap } from '../../assets/integrations_images/logoMap';

// Import your custom icons here

const GoogleAdsIcon = `${process.env.PUBLIC_URL}/images/integrations_images/GoogleAdsLogo.svg`;
const FacebookAdsIcon = `${process.env.PUBLIC_URL}/images/integrations_images/AD8.png`;

const OverallCard = ({ data, type, style }) => {
  const tooltip = { trigger: 'item' };
  const legend = {
    top: '5%',
    left: 'center',
  };
  if (type === 'data') {
    tooltip['formatter'] = function (params) {
      return `
           <div style="display: flex; align-items: center; justify-content: center; padding: 10px; ${
             params.color
           }; width: 200px;">
        <div style="text-align: left; margin-right: 20px; margin-bottom:10px;">
          <img src="${logoMap[params.name]}" alt="${
        params.name
      }" style="width: 20px; height: 20px;">
        </div>
        <div style="text-align: left;">
          <span style="font-size: 16px; color: #888;">${params.name}</span>
          <br>
          <span style="font-size: 24px; font-weight: bold; color: #000;">${params.value?.toFixed(
            2
          )} USD</span>
        </div>
      </div>
        `;
    };
   
  }

  const options = {
    tooltip,
    legend,
    series: [
      {
        name: 'Access From',
        type: 'pie',
        radius: ['40%', '70%'], // Keeps the donut chart shape
        avoidLabelOverlap: false,
        padAngle: 5,
        itemStyle: {
          borderRadius: 10,
          borderWidth: 5,
          borderColor: '#fff',
        },
        label: {
          show: false,
          position: 'center',
        },
        emphasis: {
          label: {
            show: true,
            fontFamily: 'DM Sans',
            fontWeight: 'bold',
            formatter: (params) => {
              if (type === 'data') {
                return `{name|${params.name}}\n{value|${params.value.toFixed(
                  2
                )} USD}`;
              } else if (type === 'percentage') {
                return `{name|${params.name.toUpperCase()}}\n{percentage|${
                  params.percent
                }%}`;
              }
            },
            rich:
              type === 'data'
                ? {
                    name: {
                      fontSize: 20,
                      fontWeight: 'normal',
                      color: '#888',
                      lineHeight: 30,
                    },
                    value: {
                      fontSize: 20,
                      fontWeight: 'bold',
                      color: '#000',
                    },
                  }
                : {
                    name: {
                      fontSize: 20,
                      color: '#000000',
                      align: 'center',
                      fontFamily: 'DM Sans',
                    },
                    percentage: {
                      fontSize: 40,
                      fontWeight: 'bold',
                      fontFamily: 'DM Sans',
                      color: '#000000',
                      align: 'center',
                    },
                  },
          },
        },
        labelLine: {
          show: false,
        },
        data,
      },
    ],
  };

  return (
    <ReactECharts
      option={options}
      style={style}
      notMerge={true} // Don't merge new and old options
      lazyUpdate={true} // Lazy update for better performance on frequent updates
    />
  );
};

export default OverallCard;
