import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import jwt from 'jsonwebtoken';
import avatar from '../../../assets/images/users/avatar-1.jpg';
//i18n
import { withTranslation } from 'react-i18next';
// Redux
import { useSelector, useDispatch, connect } from 'react-redux';
import {
  editProfileRequest,
  getUserInfoRequest,
  logoutRequest,
} from '../../../store/auth/actions';

import { withRouter, Link } from 'react-router-dom';
// users
import user1 from '../../../assets/images/users/avatar-1.jpg';
export const logout = () => {
  const googleads_customer_currency_code = localStorage.getItem(
    'googleads_customer_currency_code'
  );
  const metaads_customer_currency_code = localStorage.getItem(
    'metaads_customer_currency_code'
  );
  localStorage.clear();

  localStorage.setItem(
    'googleads_customer_currency_code',
    googleads_customer_currency_code
  );
  localStorage.setItem(
    'metaads_customer_currency_code',
    metaads_customer_currency_code
  );
  window.location.replace('/');
};
const ProfileMenu = (props) => {
  const dispatch = useDispatch();

  const isLoggedOut = () => {
    dispatch(logoutRequest());
    logout();
  };
  // var user = jwt.decode(window.localStorage.getItem("token")).user;
  const user = useSelector((state) => state.editProfile);

  const [menu, setMenu] = useState(false);
  const [username, setusername] = useState('Admin');
  useEffect(() => {
    if (localStorage.getItem('authUser')) {
      if (process.env.REACT_APP_DEFAULTAUTH === 'firebase') {
        const obj = JSON.parse(localStorage.getItem('authUser'));
        setusername(obj.displayName);
      } else if (
        process.env.REACT_APP_DEFAULTAUTH === 'fake' ||
        process.env.REACT_APP_DEFAULTAUTH === 'jwt'
      ) {
        const obj = JSON.parse(localStorage.getItem('authUser'));
        setusername(obj.username);
      }
    }
  }, [props.success]);

  useEffect(() => {
    dispatch(getUserInfoRequest());
  }, []);

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item bg-soft-light border-start border-end"
          id="page-header-user-dropdown"
          tag="button"
        >
          <img
            className="avatar-md rounded-circle img-thumbnail"
            src={user.user.Image ? user.user.Image : avatar}
            alt="Header Avatar"
          />
          <span className="d-none d-xl-inline-block ms-2 me-1">
            {user.user.firstname}
          </span>
          <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <div className="dropdown-divider" />
          <Link onClick={isLoggedOut} className="dropdown-item">
            <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
            <span>{props.t('Log out')}</span>
          </Link>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

ProfileMenu.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any,
};
export default withRouter(withTranslation()(ProfileMenu));
