import gql from 'graphql-tag';


const getOverviewReport = gql`query($startDate:String!,$endDate:String!){
    generalreport(startDate:$startDate,endDate:$endDate){
        store{
            delivred_orders,
            total_sales,
            total_orders,
            aov,
        },
        GoogleMetrics{
            totalClicks,
            totalCost,
            totalImpressions,
            totalConversions,
            totalconversionValue,
            totalinteractions,
            totalctr,
            totalAOV,
            totalCPC,
            totalCPA,
            totalROAS,
            totalCVR

        },
        facebookMetrics{
            totalClicks,
            totalCost,
            totalImpressions,
            totalConversions,
            totalconversionValue,
            totalLandingPageViews,
            totalCpc,
            totalCtr,
            totalReach
        }, facebookpage{
            fb_page_name,
            likes_numbre,
            fb_page_picture,
            followers_numbre,
            engageduser,
            new_likes,
            post_engagements,
            post_impressions,
            posts{
                full_picture,
                post_likes,
                post_impressions,
                post_clicks,
                createdAt,
                shares,,
                permalink_url,
                postReactionsTotal,
            }
        }

    }
}
`


export {getOverviewReport};