import gql from 'graphql-tag';


const getInformations = gql`query($years:String!, $channel:String){
    pnl(year:$years, channel:$channel){
        created,
        sales,
        taxes,
        subProfit,
        costOfGoods,
        SnH,
        refunds,
        discounts,
        merchantFee,
        ads,
        gross,
        netProfit,
        miscCosts{name, total},
        units,
        orders
    }
}
`


export {getInformations};