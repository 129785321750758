// get getinstaPageData
export const  GET_INSTA_PAGE_DATA = 'GET_INSTA_PAGE_DATA';
export const  GET_INSTA_PAGE_DATA_SUCCESS = 'GET_INSTA_PAGE_DATA_SUCCESS';
export const  GET_INSTA_PAGE_DATA_FAILURE = 'GET_INSTA_PAGE_DATA_FAILURE';

export const getInstaPageData = (startDate,endDate) => ( {
    type: GET_INSTA_PAGE_DATA,
    payload: {startDate,endDate},
})
export const getInstaPageDataSuccess = (instaPageData) => ({
    type: GET_INSTA_PAGE_DATA_SUCCESS,
    payload: {instaPageData},
})

export const getInstaPageDataFailure = (error) => ({
    type: GET_INSTA_PAGE_DATA_FAILURE,
    payload: {error},
})

// get getinstpostdata
export const  GET_INSTA_POST_DATA = 'GET_INSTA_POST_DATA';
export const  GET_INSTA_POST_DATA_SUCCESS = 'GET_INSTA_POST_DATA_SUCCESS';
export const  GET_INSTA_POST_DATA_FAILURE = 'GET_INSTA_POST_DATA_FAILURE';

export const getInstaPostData = () => ( {
    type: GET_INSTA_POST_DATA,
    payload: {},
})

export const getInstaPostDataSuccess = (instaPostData) => ({
    type: GET_INSTA_POST_DATA_SUCCESS,
    payload: {instaPostData},
})

export const getInstaPostDataFailure = (error) => ({
    type: GET_INSTA_POST_DATA_FAILURE,
    payload: {error},
})

/// get  insta audience data
export const  GET_INSTA_AUDIENCE_DATA = 'GET_INSTA_AUDIENCE_DATA';
export const  GET_INSTA_AUDIENCE_DATA_SUCCESS = 'GET_INSTA_AUDIENCE_DATA_SUCCESS';
export const  GET_INSTA_AUDIENCE_DATA_FAILURE = 'GET_INSTA_AUDIENCE_DATA_FAILURE';


export const getInstaAudienceData = () => ( {
    type: GET_INSTA_AUDIENCE_DATA,
    payload: {},
})

 export const getInstaAudienceDataSuccess = (instaAudienceData) => ({
    type: GET_INSTA_AUDIENCE_DATA_SUCCESS,
    payload: {instaAudienceData},
 })

    export const getInstaAudienceDataFailure = (error) => ({
        type: GET_INSTA_AUDIENCE_DATA_FAILURE,
        payload: {error},
    })