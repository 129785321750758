import React, { useEffect, useState } from 'react';
import { Card, CardBody, Col, Row } from 'reactstrap';
import { Spin } from 'antd';
import { format } from 'date-fns';
import { withTranslation } from 'react-i18next';
import Highlighter from 'react-highlight-words';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getMetricsByCampaignsPerDate } from '../../store/googleAds/actions';
import {

  Select,
  Tooltip,
} from 'antd';
import { SearchOutlined, CloseOutlined } from '@ant-design/icons';
import { formatNumberWithSpaces } from '../GoogleAnalytics/common/HelperFunctions/utils';
import GoogleAdsCompaignTable from './GoogleAdsCompaignTable';
import Breadcrumbs from '../../components/Common/Breadcrumb';

const GoogleAdsIcon = `${process.env.PUBLIC_URL}/images/integrations_images/GoogleADS.svg`;
 



const GoogleAdsOverview = (props) => {
  // format number with spaces

  const dispatch = useDispatch();
  const formattedDateRange = {
    selection1: {
      startDate: format(
        new Date(props.dateRange.selection1.startDate),
        'yyyy-MM-dd'
      ),
      endDate: format(
        new Date(props.dateRange.selection1.endDate),
        'yyyy-MM-dd'
      ),
      key: props.dateRange.selection1.key,
    },
  };

  useEffect(() => {
    dispatch(
      getMetricsByCampaignsPerDate(
        formattedDateRange.selection1.startDate,
        formattedDateRange.selection1.endDate
      )
    );
  }, [props.dateRange]);

  const [selectedGroups, setSelectedGroups] = useState([]);
  const [hiddenCards, setHiddenCards] = useState([]);

  const handleHideCard = (cardIndex) => {
    setHiddenCards((prevHidden) => [...prevHidden, cardIndex]);
  };

  const handleSelectGroup = (selectedKey) => {
    const selectedOption = optionGroup.find(
      (option) => option.key === selectedKey
    );
    if (
      selectedOption &&
      !selectedGroups.find((group) => group.key === selectedKey)
    ) {
      setSelectedGroups((prevGroups) => [...prevGroups, selectedOption]);
    }
  };

  const optionGroup = [
    {
      key: 'spend',
      label: 'Spend',
      value: props.google
        ? `$${formatNumberWithSpaces(props.google.totalCost.toFixed(2) || 0)}`
        : '$0',
    },
    {
      key: 'Revenue',
      label: 'Revenue',
      value: props.google
        ? `$${formatNumberWithSpaces(
            props.google.totalconversionValue.toFixed(2) || 0
          )}`
        : '$0',
    },
    {
      key: 'Conversions',
      label: 'Conversions',
      value: props.google
        ? props.google.totalConversions
          ? props.google.totalConversions.toFixed(2)
          : 0
        : 0,
    },
    {
      key: 'CPA',
      label: 'CPA',
      value: props.google
        ? `$${formatNumberWithSpaces(props.google.totalCPA.toFixed(2) || 0)}`
        : '$0',
    },
    {
      key: 'Impressions',
      label: 'Impressions',
      value: props.google
        ? props.google.totalImpressions
          ? formatNumberWithSpaces(props.google.totalImpressions)
          : 0
        : 0,
    },
    {
      key: 'Interactions',
      label: 'Interactions',
      value: props.google
        ? props.google.totalinteractions
          ? formatNumberWithSpaces(props.google.totalinteractions)
          : 0
        : 0,
    },
    {
      key: 'Clicks',
      label: 'Clicks',
      value: props.google
        ? props.google.totalClicks
          ? formatNumberWithSpaces(props.google.totalClicks)
          : 0
        : 0,
    },
    {
      key: 'CTR',
      label: 'CTR',
      value:
        props.google && props.google.totalctr
          ? `${props.google.totalctr.toFixed(2) || 0}%`
          : '0%',
    },
    {
      key: 'CPC',
      label: 'CPC',
      value:
        props.google && props.google.totalCPC
          ? `$${formatNumberWithSpaces(props.google.totalCPC.toFixed(2))}`
          : '$0',
    },
    {
      key: 'AOV',
      label: 'AOV',
      value: props.google
        ? `$${formatNumberWithSpaces(props.google.totalAOV.toFixed(2) || 0)}`
        : '$0',
    },
    {
      key: 'CR',
      label: 'CR',
      value: props.google ? `${props.google.totalCVR.toFixed(2)}%` : '0%',
    },
    {
      key: 'ROAS',
      label: 'ROAS',
      value: props.google ? `x ${props.google.totalROAS.toFixed(2)}` : '0%',
    },
  ];

  const MetricCard = ({ selectedGroup, cardIndex, handleHideCard }) => {
    return (
      <Col xl={2} md={4} key={selectedGroup.key}  className="mb-4">
        <Card className="text-center">
          <CardBody style={{ padding: '20px' }}>
            <div className="my-0 text-primary d-flex justify-content-between align-items-center">
              <h5 style={{ color: '#0044BD', fontSize: '16px', margin: 0 }}>
                {selectedGroup.label}
              </h5>
              <Tooltip title="Delete Metric">
                <CloseOutlined
                  onClick={() => handleHideCard(cardIndex)}
                  style={{ cursor: 'pointer', fontSize: '12px' }}
                />
              </Tooltip>
            </div>
            <Row className="justify-content-center">
              <Col xs={12} className="d-flex justify-content-center">
                <div className="mt-3">
                  <h4
                    style={{ fontSize: '24px', fontWeight: 'bold', margin: 0 }}
                  >
                    {selectedGroup.value}
                  </h4>
                </div>
              </Col>
            </Row>
            <Row className="justify-content-center">
              <Col xs={12} className="d-flex justify-content-center">
                <div className="mt-3">
                  <span
                    style={{
                      display: 'inline-block',
                      backgroundColor: '#E6F7E6',
                      color: '#52C41A',
                      padding: '2px 6px',
                      borderRadius: '4px',
                      marginRight: '6px',
                    }}
                  >
                    28.3% ↑
                  </span>
                  <span style={{ color: '#BFBFBF', fontSize: '14px' }}>
                    Than last month
                  </span>
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>
    );
  };

  return (
    <div className='mt-5'>
      <Col xl={12}>
      <Breadcrumbs
              image={GoogleAdsIcon}
              title="Google Ads performance"
            />

        <Row>
          <Row className="justify-content-center">
            {selectedGroups.map(
              (selectedGroup, index) =>
                !hiddenCards.includes(index) && (
                  <MetricCard
                    key={index}
                    selectedGroup={selectedGroup}
                    cardIndex={index}
                    handleHideCard={handleHideCard}
                  />
                )
            )}
            <Col xl={2} md={4}>
            <Card
              className="text-center"
              style={{
                margin: '7px',
                border: '2px dashed #D3D3D3', // Dashed border like in the image
                borderRadius: '8px', // Rounded corners
                padding: '20px',
              }}
            >
              <div className="my-0 d-flex flex-column align-items-center">
                {/* Title styling */}
                <h5
                  className="mb-2"
                  style={{
                    color: '#0044BD',
                    fontWeight: '700',
                    fontSize: '13px',
                  }}
                >
                  {props.t('Customize your metrics')}
                </h5>

                {/* Dropdown Select */}
                <Select
                  placeholder={
                    <span>
                      {/* Plus sign icon */}
                      {props.t('Select metrics')}
                    </span>
                  }
                  onChange={(selectedKey) => handleSelectGroup(selectedKey)}
                  style={{
                    width: '100%',
                    borderColor: 'initial',
                    backgroundColor: '#E9E9EF', // Light gray background for dropdown
                    fontWeight: 'bold',
                    //  borderRadius: "8px", // Rounded corners for the dropdown
                  }}
                  dropdownStyle={{ zIndex: 9999 }}
                >
                  {optionGroup.map((option, index) => (
                    <Select.Option key={index} value={option.key}>
                      {option.label}
                    </Select.Option>
                  ))}
                </Select>
              </div>
            </Card>
            </Col>
          </Row>
        </Row>

        <Row>
          <GoogleAdsCompaignTable overview={true} />
        </Row>
      </Col>
    </div>
  );
};

GoogleAdsOverview.propTypes = {
  t: PropTypes.any,
};

export default withRouter(withTranslation()(GoogleAdsOverview));
