import React from 'react';
import { useSelector } from 'react-redux';
import ReactApexChart from 'react-apexcharts';

const RevenueChart = () => {
  const { dataDashboard } = useSelector((state) => state.facebookAds);

  // Extract clicks and calculate CPC
  const clicks = dataDashboard.reports.map((item) => item.clicks);
  const cpc = dataDashboard.reports.map((item) => {
    const calculatedCPC = item.clicks > 0 ? (item.spend / item.clicks).toFixed(3) : 0;
    return calculatedCPC;
  });

  const options = {
    chart: {
      toolbar: {
        show: false,
      },
    },
    dataLabels: {
      enabled: true,
    },
    stroke: {
      curve: 'smooth',
      width: 2,
    },
    colors: ['#0044BD', '#F6B43B'], // Clicks and CPC colors
    xaxis: {
      categories: dataDashboard?.reports.map((item) => {
        const date = new Date(item.date);
        const month = date.toLocaleDateString('fr-FR', { month: 'short' }); // Use 'short' for abbreviated month name
        return `${date.getDate()}${month}`;
      }),
      labels: {
        trim: false,
      },
    },
    yaxis: [
      {
        title: {
          text: 'Clicks',
        },
      },
      {
        opposite: true,
        title: {
          text: 'CPC',
        },
      },
    ],
    grid: {
      borderColor: '#f1f1f1',
    },
    tooltip: {
      x: {
        format: 'dd/MM',
      },
    },
  };

  const series = [
    {
      name: 'Clicks',
      type: 'column',
      data: clicks,
    },
    {
      name: 'CPC',
      type: 'line',
      data: cpc,
      yAxisIndex: 1, // Use the second y-axis for CPC
    },
  ];

  return (
    <ReactApexChart options={options} series={series} type="line" height="350" />
  );
};

export default RevenueChart;