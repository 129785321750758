import React from "react"
import MetaTags from 'react-meta-tags';
import {
  Table,
  Row,
  Col,
  Card,
  CardBody,
  CardSubtitle,
  CardHeader,
  Button,
  CardText
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { useForm } from 'react-hook-form';

const Accounts = () => {

const { register, handleSubmit } = useForm();
const onSubmit= handleSubmit((dataForm) => {
    console.log(dataForm);
  })
  const handleAddFrom = (bool,e) => {
    var el = document.getElementById('form-add');
    var btn = document.getElementById('add-buttton');
    if(bool){
        el.classList.remove('d-none');
        e.target.disabled = true;
    }
    else {
        el.classList.add('d-none');
        console.log(btn)
        btn.disabled = false;
        console.log("e.target.disabled", e.target.disabled)
    }
}
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Accounts | Live-Metrics - E-com SaaS</title>
        </MetaTags>
        <div className="container-fluid">
          <Breadcrumbs title="Settings" breadcrumbItem="Accounts" pageHeading="Manage Accounts" />
          <Row>
            <Col col={12}>
              <Card>
                <CardHeader>
                <h4 className="card-title mb-4">Account details</h4>
                <Row>
                    <Col col={12} >
                        <CardText>You can an account</CardText>
                    </Col>
                    <Col col={12} className="text-end">
                        <Button color="primary" id="add-buttton" className="ms-1" onClick={(e) => handleAddFrom(true,e)}>
                            Add Account
                        </Button>
                    </Col>
                </Row>  
                  <CardSubtitle>
                    <form onSubmit={onSubmit} className="d-none" id="form-add">
                        <div className="mb-3">
                        <div className="d-flex align-items-start">
                            <div className="flex-grow-1">
                            <label className="form-label">Account Name</label>
                            </div>
                        </div>
                        <div className="mb-3">
                            <input
                            name="acccount"
                            type="text"
                            id="acccount"
                            className="form-control"
                            {...register('acccount',{ required: true })} 
                            required
                            placeholder="Enter Acccount Name"
                            />
                        </div>
                        </div>
                        <div className="mb-3">
                        <Button type="submit" color="primary" className="ms-1">
                            Submit
                        </Button>{" "}
                        <Button type="reset" color="secondary" onClick={(e) => handleAddFrom(false,e)}>
                            Cancel
                        </Button>
                        </div>
                    </form>
                  </CardSubtitle>
                </CardHeader>
                <CardBody>
                  <div className="table-responsive">
                    <Table className="table mb-0">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Store Name</th>
                          <th>Owner Name</th>
                          <th>Create At</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <th scope="row">1</th>
                          <td>AlriqaSport</td>
                          <td>Karim</td>
                          <td>2022/05/20</td>
                        </tr>
                        <tr>
                          <th scope="row">2</th>
                          <td>TuttoBeauty</td>
                          <td>Karim</td>
                          <td>2022/09/01</td>
                        </tr>
                        <tr>
                          <th scope="row">3</th>
                          <td>TuttoSport</td>
                          <td>Amine</td>
                          <td>2022/11/28</td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}
export default Accounts
