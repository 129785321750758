import React from 'react';
import { Bar } from "react-chartjs-2";
import { Card, CardBody } from 'reactstrap';

function OrdersStatusBarChartCard({ data, chartOptions }) {

  return (
    
      <CardBody>
        <h5 style={{ color: '#0044BD', fontSize: '20px', marginBottom: '20px' }}>Bar Chart</h5>
        <Bar 
        data={data} 
        options={chartOptions} 
        width={1350}
        height={450}
        />
      </CardBody>
    
  );
}

export default OrdersStatusBarChartCard;
