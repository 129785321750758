import gql from 'graphql-tag';


const getFromShopify = gql`mutation($code:String!,$host:String!,$hmac:String!,$shop:String!,$timestamp:String!){
    integration(code:$code,host:$host,hmac:$hmac,shop:$shop,timestamp:$timestamp){
        redirectUrl,
        error
    }
}
`



const getSalesPlatform = gql`query{
    get{
        id,
        name,
        fixedMerchantFee,
        variableMerchantFee,
        storePlan,
        storeCurrency,
        url,
        storeIanaTimezone,
        storeCountryName,
        presta_round_number_orders_server_time_response,
        connectionstate

    }
}
`
const checkSales = gql`query{
    getIntegration{
        id,
        storeName,
        name
        fixedMerchantFee,
        variableMerchantFee,
        storePlan,
        storeCurrency, 
        url,
        connectionstate,
        storeIanaTimezone,
        storeCountryName,
        type  
         
        
    }
}
`

const editPlatform = gql`mutation($name:String!,$fixedFee:Float!,$variableFee:Float!){
    update(name:$name,fixedMF:$fixedFee,variableMF:$variableFee){
        status
    }
}`

const deletePlatform = gql`mutation{
    delete{
        status
    }
}`

const inject = gql`mutation($url:String!){
    inject(url:$url){
        status
    }
}`

export { getFromShopify, getSalesPlatform, editPlatform, deletePlatform, inject,checkSales };