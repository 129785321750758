import { Button, Checkbox, Dropdown, Menu, Space, Table } from 'antd';
import React, { useState } from 'react';
import { Card, CardBody } from 'reactstrap';
import i18next from 'i18next';

const InstagramLogo = `${process.env.PUBLIC_URL}/images/integrations_images/Insta1.svg`;

const InstagramSocialMediaTable = ({ instaPostData }) => {
  const [filteredInfo, setFilteredInfo] = useState({});
  const [sortedInfo, setSortedInfo] = useState({});
  const [selectedColumns, setSelectedColumns] = useState([]);

  // Initialize selectedColumns with all column keys
  React.useEffect(() => {
    setSelectedColumns(columns.map((column) => column.key));
  }, []);

  const handleChange = (pagination, filters, sorter) => {
    setFilteredInfo(filters);
    setSortedInfo(sorter);
  };

  const clearSorters = () => {
    setSortedInfo({});
  };

  const clearAll = () => {
    setFilteredInfo({});
    setSortedInfo({});
    setSelectedColumns(columns.map((column) => column.key));
  };

  const setImpressionsSort = () => {
    setSortedInfo({
      order: 'descend',
      columnKey: 'impressions',
    });
  };

  const handleMenuClick = (clickedKey) => {
    const updatedSelectedColumns = selectedColumns.includes(clickedKey)
      ? selectedColumns.filter((key) => key !== clickedKey)
      : [...selectedColumns, clickedKey];

    setSelectedColumns(updatedSelectedColumns);
  };

  const columns = [
    {
      title: 'Post Image',
      dataIndex: 'media_url',
      key: 'media_url',
      align: 'center',
      filters: [
        { text: 'Image', value: 'IMAGE' },
        { text: 'Video', value: 'VIDEO' },
        { text: 'Carousel Album', value: 'CAROUSEL_ALBUM' },
      ],
      filteredValue: filteredInfo.media_url || null,
      onFilter: (value, record) => record.media_type === value,
      ellipsis: true,
      render: (text, record) => (
        <a href={record.permalink} target="_blank" rel="noopener noreferrer">
          {text ? (
            <img
              src={text}
              alt={`Full Picture ${record.full_picture}`}
              style={{ maxWidth: '100px', maxHeight: '90px' }}
            />
          ) : (
            <span>{' There is Non-image '}</span>
          )}
        </a>
      ),
    },
    {
      title: 'Post Interactions',
      dataIndex: 'total_interactions',
      key: 'total_interactions',
      sorter: (a, b) => a.total_interactions - b.total_interactions,
      sortOrder:
        sortedInfo.columnKey === 'total_interactions' ? sortedInfo.order : null,
      ellipsis: true,
      align: 'center',
      render: (text) => <strong>{text}</strong>,
    },
    {
      title: 'Post Saves',
      dataIndex: 'saved',
      align: 'center',
      key: 'saved',
      sorter: (a, b) => a.saved - b.saved,
      sortOrder: sortedInfo.columnKey === 'saved' ? sortedInfo.order : null,
      ellipsis: true,
      render: (text) => <strong>{text}</strong>,
    },
    {
      title: 'Post Impressions',
      dataIndex: 'impressions',
      key: 'impressions',
      align: 'center',
      sorter: (a, b) => a.impressions - b.impressions,
      sortOrder:
        sortedInfo.columnKey === 'impressions' ? sortedInfo.order : null,
      ellipsis: true,
      render: (text) => <strong>{text || '-'}</strong>, // Render '-' if text is falsy
    },
    {
      title: 'Post Reach',
      dataIndex: 'reach',
      align: 'center',
      key: 'reach',
      sorter: (a, b) => a.reach - b.reach,
      sortOrder: sortedInfo.columnKey === 'reach' ? sortedInfo.order : null,
      ellipsis: true,
      render: (text) => <strong>{text}</strong>,
    },
    {
      title: 'Post Comments',
      dataIndex: 'comments',
      key: 'comments',
      align: 'center',
      sorter: (a, b) => a.comments - b.comments,
      sortOrder: sortedInfo.columnKey === 'comments' ? sortedInfo.order : null,
      ellipsis: true,
      render: (text) => <strong>{text}</strong>,
    },
    {
      title: 'Post Likes',
      dataIndex: 'likes',
      key: 'likes',
      sorter: (a, b) => a.likes - b.likes,
      sortOrder: sortedInfo.columnKey === 'likes' ? sortedInfo.order : null,
      ellipsis: true,
      align: 'center',
      render: (text) => <strong>{text}</strong>,
    },
    {
      title: 'Post Plays',
      dataIndex: 'plays',
      align: 'center',
      key: 'plays',
      sorter: (a, b) => a.plays - b.plays,
      sortOrder: sortedInfo.columnKey === 'plays' ? sortedInfo.order : null,
      ellipsis: true,
      render: (text) => <strong>{text || '-'}</strong>,
    },
    {
      title: 'Post Shares',
      dataIndex: 'shares',
      align: 'center',
      key: 'shares',
      sorter: (a, b) => a.shares - b.shares,
      sortOrder: sortedInfo.columnKey === 'shares' ? sortedInfo.order : null,
      ellipsis: true,
      render: (text) => <strong>{text}</strong>,
    },
    {
      title: 'Created At',
      dataIndex: 'timestamp',
      align: 'center',
      key: 'timestamp',
      sorter: (a, b) => new Date(a.timestamp) - new Date(b.timestamp),
      ellipsis: true,
      render: (text) => {
        const date = new Date(text);
        const formattedDate = date.toISOString().split('T')[0]; // Extracts date portion
        return <strong>{formattedDate}</strong>;
      },
    },
  ];

  const filteredColumns = columns.filter((column) =>
    selectedColumns.includes(column.key)
  );

  const menu = (
    <Menu>
      {columns.map((column) => (
        <Menu.Item key={column.key} onClick={() => handleMenuClick(column.key)}>
          <Checkbox
            checked={selectedColumns.includes(column.key)}
            style={{ marginRight: '8px' }}
          />
          <span
            style={
              selectedColumns.includes(column.key) ? { fontWeight: 'bold' } : {}
            }
          >
            {column.title}
          </span>
        </Menu.Item>
      ))}
    </Menu>
  );

  return (
    <Card>
      <CardBody style={{ padding: '45px' }}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <h4 className="card-title mb-4">Instagram Posts last 30 days</h4>
          <img
            className="img-intgration-icon"
            src={InstagramLogo}
            alt="Instagram Logo"
          />
        </div>
        <Space
          style={{
            marginBottom: 16,
          }}
        >
          <Button onClick={setImpressionsSort}>
            {i18next.t('Sort Impressions')}
          </Button>
          <Button onClick={clearSorters}>{i18next.t('Clear sorters')}</Button>
          <Button onClick={clearAll}>
            {i18next.t('Clear filters and sorters')}
          </Button>
          <Dropdown overlay={menu} trigger={['click']}>
            <Button>{i18next.t('Select Columns')}</Button>
          </Dropdown>
        </Space>
        <Table
          style={{ border: '1px solid #e8e8e8' }}
          columns={filteredColumns}
          dataSource={instaPostData}
          onChange={handleChange}
          pagination={{
            pageSize: 7, // Set the default page size
            pageSizeOptions: ['10', '20', '30'], // Provide the options for users to select from
            showSizeChanger: true, // Enable the page size selector
          }}
          scroll={{ y: 700 }} // Configure the vertical scroll if needed
        />
      </CardBody>
    </Card>
  );
};

export default InstagramSocialMediaTable;
