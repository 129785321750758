import React, { useState } from 'react'
import { Icon } from 'semantic-ui-react'
import ApolloClient from 'apollo-boost';
import { editCost, deleteSingleCost } from '../../queries/Costs/Costs';
import Swal from 'sweetalert2'
import urls from '../../routes/apiUrls';
import Select from "react-select"
import Flatpickr from "react-flatpickr"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { withTranslation } from "react-i18next"
import { withRouter } from "react-router-dom"
import PropTypes from 'prop-types';
const ApploCLI = new ApolloClient({
  uri: urls.cost
});
function ListCost(props) {
  const [edit, setEdit] = useState(false);
  const [title, setTitle] = useState(props.element.name);
  const [price, setPrice] = useState(props.element.price);
  const [activeDate, setActiveDate] = useState(new Date(props.element.activeDate * 1).toISOString().split("T")[0]);
  const [endDate, setEndDate] = useState(new Date(props.element.endedDate * 1).toISOString().split("T")[0]);
  const [saved, setSaved] = useState(false);
  const [isDeleted, setIsDeleted] = useState(false);
  const [costForm, setCostForm] = useState({ title: props.element.name, category: props.element.category, frequency: props.element.frequency, date: new Date(props.element.activeDate * 1).toISOString().split("T")[0], endedDate: new Date(props.element.endedDate * 1).toISOString().split("T")[0], price: props.element.price });
  const [isEmpty, setIsEmpty] = useState({ titleState: false, categoryState: false, frequencyState: false, dateState: false, endedDateState: false, priceState: false });
  const [dropDownV, setDropDownV] = useState({});
  const [dropDownVM, setDropDownVM] = useState({});
  const handleChangeD = (e) => {
    setDropDownV(e.value);
    console.log("handleChangeD", e.value);
    costForm.frequency = e.value
  }
  const handleChangeDM = (e) => {
    setDropDownVM(e.value);
    costForm.category = e.value
    console.log("costForm", costForm);
  }
  const options = [
    { label: 'Daily', value: 'Daily' },
    { label: 'Weekly', value: 'Weekly' },
    { label: 'Monthly', value: 'Monthly' },
    { label: 'Yearly', value: 'Yearly' },
    { label: 'Bi-weekly', value: 'Bi-weekly' },
  ]
  const MiscOptions = [
    { label: 'Marketing', value: 'Marketing' },
    { label: 'Advertising', value: 'Advertising' },
    { label: 'Salaries', value: 'Salaries' },
    { label: 'Outsourcing', value: 'Outsourcing' },
    { label: 'Subscription', value: 'Subscription' },
  ]
  const oldData = {
    title: props.element.name,
    category: props.element.category,
    frequency: props.element.frequency,
    date: new Date(props.element.activeDate * 1).toISOString().split("T")[0],
    endedDate: new Date(props.element.endedDate * 1).toISOString().split("T")[0],
    price: props.element.price
  };
  const changeCostForm = (name, value) => {
    setCostForm({ ...costForm, [name]: value });
  }
  const resetData = () => {
    setTitle(oldData.title)
    setDropDownVM(oldData.category)
    setDropDownV(oldData.frequency)
    setPrice(oldData.price);
  }
    function notifySuccess() {
          toast.success(' Cost Edited successfully !', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        })
    }
    function notifyError(){
      toast.error('can not edit Cost !', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        })
    }
    const [loadingState, setLoadingState] = useState(true);
  const save = () => {
    setLoadingState(false);
    setDropDownV(costForm.frequency)
    ApploCLI.mutate({
      mutation: editCost,
      variables: {
        title: costForm.title,
        oldTitle: oldData.title,
        category: costForm.category,
        frequency: costForm.frequency,
        startedDate: costForm.date,
        endedDate: costForm.endedDate,
        price: parseFloat(costForm.price)
      },
      context: {
        headers: {
          Authorization: window.localStorage.getItem("token") ? `Bearer ${window.localStorage.getItem("token")}` : ""
        }
      },
      errorPolicy: 'all',
    }).then(response =>{
      setLoadingState(true);
      if(response.errors){
        notifyError()
      }else
      notifySuccess()

    })
  }
  function handleChangeName(e) {
    if (e.target.value.length > 0) {
      setTitle(e.target.value);
      setIsEmpty({ ...isEmpty, titleState: false })
    } else {
      setTitle(e.target.value);
      setIsEmpty({ ...isEmpty, titleState: true })
    }
    changeCostForm('title', e.target.value);
  }
  function handleChangePrice(e) {
    if (e.target.value.length > 0) {
      setPrice(e.target.value);
      setIsEmpty({ ...isEmpty, priceState: false })
    } else {
      setPrice(e.target.value);
      setIsEmpty({ ...isEmpty, priceState: true })
    }
    changeCostForm('price', e.target.value);
  }
  function handleChangeDate(e) {
    var dateT = new Date(e[0]);
    var newdate= dateT.getFullYear() + '-' +  (dateT.getMonth() + 1) + '-' + dateT.getDate()  ;
    setActiveDate(newdate);
    console.log('first', newdate);
    setActiveDate(newdate);
    costForm.date = newdate;
  }
  function handleChangeEndDate(e) {
    let dateT = new Date(e[0]);
    let newdate= dateT.getFullYear() + '-' +  (dateT.getMonth() + 1) + '-' + dateT.getDate()  ;
    setEndDate(newdate);
    setActiveDate(newdate);
    costForm.endedDate = newdate;
    console.log('first', endDate, 'newdate ',newdate, 'costForm ', costForm);
  }
  const deleteit = (elementID) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.value) {
        setIsDeleted(true)
        ApploCLI.mutate({
          mutation: deleteSingleCost,
          variables: { id: elementID },
          context: {
            headers: {
              Authorization: window.localStorage.getItem("token") ? `Bearer ${window.localStorage.getItem("token")}` : ""
            }
          },
          errorPolicy: 'all',   
        })  
        window.location.replace('/costs');
      }
    })
  }
  return (
    <tr key={props.index} negative={isDeleted} >
      <td>
        <div className="mb-3">
          <input type="text" className="form-control" error={isEmpty.titleState}
            required
            defaultValue={title}
            disabled={!edit}
            value={title}
            onChange={(e) => handleChangeName(e)}
          />
        </div>
      </td>
      <td>
        <Select
          isDisabled={!edit}
          defaultValue={MiscOptions[MiscOptions.map((e, indx) => { return (e.value.indexOf(costForm.category) >= 0) ? indx : null }).filter(e => e != null)]}
          onChange={handleChangeDM}
          options={MiscOptions}
          classNamePrefix="select-10-selection"
        />
      </td>
      <td>
        <Select
          isDisabled={!edit}
          defaultValue={options[options.map((e, indx) => { return (e.value.indexOf(costForm.frequency) >= 0) ? indx : null }).filter(e => e != null)]}
          onChange={handleChangeD}
          options={options}
          classNamePrefix="select-13-selection"
        />
      </td>
      <td >
        <Flatpickr
          name="date"
          className="form-control d-block"
          defaultValue={costForm.date}
          onChange={(e) => handleChangeDate(e)}
          disabled={!edit}
          id="change_handler_example" />
      </td>
      <td>
        <Flatpickr
          name="endedDate"
          className="form-control d-block"
          defaultValue={costForm.endedDate}
          onChange={(e) => handleChangeEndDate(e)}
          disabled={!edit}
          id="change_handler_example2" />
      </td>
      <td>
        <input type="number" className="form-control" error={isEmpty.priceState}
          required
          defaultValue={price}
          disabled={!edit}
          value={price}
          name="price"
          onChange={(e) => handleChangePrice(e)}
        />
      </td>
      <td >
        {!isDeleted ?
          <div className="btnTab" >
            {!edit ?
              <div className="btnTab">
                <button className="btn btn-success" onClick={() => {setEdit(true); }}>{props.t("Edit")}</button>
                <ToastContainer />
                <button className="btn btn-danger ms-2" onClick={() => { deleteit(props.element.id);  }}>{props.t("Delete")}</button>
              </div>
              :
              <div className="btnTab">
                <button className="btn btn-success" onClick={() => { save(); setEdit(false); setSaved(true);  }}>{props.t("Save")}</button>
                <button className="btn btn-danger ms-2" onClick={() => { setEdit(false); setCostForm(oldData); resetData(); }}>{props.t("Cancel")}</button>
              </div>
            }
          </div> :
          <Icon name='close' size="large" />
        }
      </td>
    </tr>
  )
}
ListCost.propTypes = {
  t: PropTypes.any
};
export default withRouter(withTranslation() (ListCost));
