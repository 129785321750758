import { takeLatest, put, call,takeEvery} from 'redux-saga/effects';
import {getfbCamapignsperdate,getfbCamapigns,getadsperdate } from '../../queries/FacebookAds/queries';
import { getData } from '../../queries/Dashboard/FacebookDashboardQ';
import urls from '../../routes/apiUrls';
import ApolloClient from "apollo-boost";
import {
    GET_fACEBOOK_CAMPAIGNS_PER_DATE,
    getFacebookCampaignsPerDateSuccess,
    getFacebookCampaignsPerDateFailure,
    GET_FACEBOOK_CAMPAIGNS,
    getFacebookCampaignsSuccess,
    getFacebookCampaignsFailure,
    GET_FB_CAMPAIGN_METRICS,
    getFbCampaignMetricsSuccess,
    getFbCampaignMetricsFailure,
    GET_ADS_FACEBOOK,
    getAdsFacebookSuccess,
    getAdsFacebookFailure,
} from './actions';



const ApolloCliGetCampaigns = new ApolloClient({
    uri: urls.facebook,
  });
  const ApploCLI = new ApolloClient({
    uri: urls.facebookdashboard,
  });
  

function * getFacebookCampaignsPerDate(action) {
    try {
        const {data}= yield call (ApolloCliGetCampaigns.query,{
        query: getfbCamapignsperdate,
        variables:{
            startDate:action.payload.startDate,
            endDate:action.payload.endDate,
        },
        context: {
          headers: {
            Authorization: window.localStorage.getItem("token")
              ? `Bearer ${window.localStorage.getItem("token")}`
              : "",
          },
        },
        errorPolicy: "all",
        fetchPolicy: "no-cache", //allow me to refetch data
      })
      if(data){
        yield put(getFacebookCampaignsPerDateSuccess(data.getfacebookcampaignsmetricsperday));
      }else{
        yield put(getFacebookCampaignsPerDateFailure("err"));
      }
   
      
    } catch (error) {
      yield put(getFacebookCampaignsPerDateFailure(error));
    }
  }

  function * getfbcamapigns(){
    try{

 const {data}= yield call (ApolloCliGetCampaigns.query,{
        query: getfbCamapigns,
        
        context: {
          headers: {
            Authorization: window.localStorage.getItem("token")
              ? `Bearer ${window.localStorage.getItem("token")}`
              : "",
          },
        },
        errorPolicy: "all",
        fetchPolicy: "no-cache", //allow me to refetch data
      })
      if(data){
        
        yield put(getFacebookCampaignsSuccess(data.getfbCampaigns));
      }else{
        yield put(getFacebookCampaignsFailure("err"));
      }
      
    }catch(error){
      yield put(getFacebookCampaignsFailure(error));
    }

  }

  function * getFbCampaignMetrics(action){
    // try{
    //     const {data}= yield call (ApploCLI.query,{
    //         query: getData,
    //         variables:{
    //             startDate:action.payload.startDate,
    //             endDate:action.payload.endDate,
    //             compare_to:action.payload.compare_to,
    //         },
    //         context: {
    //           headers: {
    //             Authorization: window.localStorage.getItem("token")
    //               ? `Bearer ${window.localStorage.getItem("token")}`
    //               : "",
    //           },
    //         },
    //         errorPolicy: "all",
    //         fetchPolicy: "no-cache", //allow me to refetch data
    //       })
    //       if(data){
    //         yield put(getFbCampaignMetricsSuccess(data.get));
    //       }else{
    //         yield put(getFbCampaignMetricsFailure("err"));
    //       }
          
    //     }catch(error){
    //       yield put(getFbCampaignMetricsFailure(error));
    //     }


    try {
      const variables = {
        startDate: action.payload.startDate,
        endDate: action.payload.endDate,
      };
  
      if (action.payload.compareStartDate) {
        variables.compareStartDate = action.payload.compareStartDate;
      }
  
      if (action.payload.compareEndDate) {
        variables.compareEndDate = action.payload.compareEndDate;
      }
      const { data } = yield call(ApploCLI.query, {
        query: getData,
        variables,
        context: {
          headers: {
            Authorization: window.localStorage.getItem('token')
              ? `Bearer ${window.localStorage.getItem('token')}`
              : '',
          },
        },
        errorPolicy: 'all',
        fetchPolicy: 'no-cache', //allow me to refetch data
      });
      if (data) {
        // console.log("helooo",data.get);
        yield put(getFbCampaignMetricsSuccess(data.get));
      } else {
        yield put(getFbCampaignMetricsFailure('err'));
      }
    } catch (error) {
      yield put(getFbCampaignMetricsFailure(error));
    }
  }

  function * getadsfacebook (action){
    try{
      const {data}= yield call (ApolloCliGetCampaigns.query,{
        query: getadsperdate,
        variables:{
            startDate:action.payload.startDate,
            endDate:action.payload.endDate,
        },
        context: {
          headers: {
            Authorization: window.localStorage.getItem("token")
              ? `Bearer ${window.localStorage.getItem("token")}`
              : "",
          },
        },
        errorPolicy: "all",
        fetchPolicy: "no-cache", //allow me to refetch data
      })

      if(data){
        yield put(getAdsFacebookSuccess(data.getadsperdate));
      }else{
        yield put(getAdsFacebookFailure("err"));
      }



    }catch(error){
      yield put(getAdsFacebookFailure(error));
    }
  }

export default function* facebookAdsSaga() {
    yield takeEvery(GET_fACEBOOK_CAMPAIGNS_PER_DATE, getFacebookCampaignsPerDate);
    yield takeEvery(GET_FACEBOOK_CAMPAIGNS, getfbcamapigns);
    yield takeEvery(GET_FB_CAMPAIGN_METRICS, getFbCampaignMetrics);
    yield takeEvery(GET_ADS_FACEBOOK, getadsfacebook);
}





