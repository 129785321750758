import React, { useState, useEffect, useRef } from 'react';

import ApolloClient from 'apollo-boost';
import urls from '../../../routes/apiUrls';
import { DateRange } from 'react-date-range';
import { useDispatch, useSelector } from 'react-redux';
import {
  getGAAffiliationsCompraisonRequest,
  getGAAffiliationsRequest,
  getGATrafficAcquisitionCompraisonRequest,
  getGATrafficAcquisitionRequest,
} from '../../../store/actions';
import GATrafficAcquisitionTable from './GATrafficAcquisitionTable';
import GAEcommerceAffilitions from './GAEcommerceAffilition';
import { datePattern, formatDate } from '../common/HelperFunctions/utils';
import GAActiveAccountBar from '../common/GAActiveAccountBar';
import Breadcrumb from '../../../components/Common/Breadcrumb';
import { logoMap } from '../../../assets/integrations_images/logoMap';

const ApploCLI = new ApolloClient({
  uri: urls.analytics,
});

function GAEcommerceAffiliation(props = { timeZone: 'UTC' }) {
  // const getInitialDate = (key, defaultValue) => {
  //   const storedValue = window.localStorage.getItem(key);
  //   return storedValue
  //     ? new Date(storedValue)
  //     : new Date(
  //         new Date().toLocaleDateString('en-US', { timeZone: props.timeZone })
  //       );
  // };

  const [EcommerceAffiliationDashboard, setEcommerceAffiliationDashboard] =
    useState([]);
  const [loadingState, setLoadingState] = useState(false);

  const [
    comparedEcommerceAffiliationDashboard,
    setComparedEcommerceAffiliationDashboard,
  ] = useState([]);

  const { dateRange } = useSelector((state) => state.dateRangeReducer);

  const dispatch = useDispatch();
  useEffect(() => {
    const newStartDate = formatDate(dateRange.selection1.startDate);
    const newEndDate = formatDate(dateRange.selection1.endDate);

    if (!newStartDate || !newEndDate) {
      console.error('Invalid date format');
      return;
    }

    if (dateRange.selection2.startDate && dateRange.selection2.startDate) {
      const compareStartDateFormatted = formatDate(
        dateRange.selection2.startDate
      );
      const compareEndDateFormatted = formatDate(
        dateRange.selection2.endDate
      );

      if (!compareStartDateFormatted || !compareEndDateFormatted) {
        console.error('Invalid comparison date format');
        return;
      }
      console.log('compareStartDateFormatted', compareStartDateFormatted);

      dispatch(
        getGAAffiliationsCompraisonRequest(
          newStartDate,
          newEndDate,
          compareStartDateFormatted,
          compareEndDateFormatted
        )
      );
      dispatch(
        getGATrafficAcquisitionCompraisonRequest(
          newStartDate,
          newEndDate,
          compareStartDateFormatted,
          compareEndDateFormatted
        )
      );
    } else {
      dispatch(getGAAffiliationsRequest(newStartDate, newEndDate));
      dispatch(getGATrafficAcquisitionRequest(newStartDate, newEndDate));
    }
  }, [dateRange]);

  const { gaAffiliations, loading, gaTrafficAcquisition } = useSelector(
    (state) => state.googleAnalytics
  );

  //allow me to refecth data
  useEffect(() => {
    if (gaAffiliations && gaAffiliations.length > 0) {
      const sortedData = gaAffiliations[0].sort(
        (a, b) => new Date(a.day) - new Date(b.day)
      );

      setEcommerceAffiliationDashboard(sortedData);
      setComparedEcommerceAffiliationDashboard([]);

      if (dateRange.selection2.startDate && dateRange.selection2.startDate) {
        const sortedComparedData = gaAffiliations[1].sort(
          (a, b) => new Date(a.day) - new Date(b.day)
        );

        setComparedEcommerceAffiliationDashboard(sortedComparedData);
      }
      setLoadingState(true);
    } else {
      setLoadingState(false);
    }
  }, [gaAffiliations]);

  return (
    <React.Fragment>
      <div className="page-content">
        <GAActiveAccountBar />
        <Breadcrumb
          title="Google Analytics Affiliation Overview"
          breadcrumbItem="Ecommerce Affiliation"
          pageHeading="Google Analytics Dashboard"
          image={logoMap['Google Analytics']}
        />
        <GAEcommerceAffilitions
          EcommerceAffiliationDashboard={EcommerceAffiliationDashboard}
          comparedEcommerceAffiliationDashboard={
            comparedEcommerceAffiliationDashboard
          }
          loadingState={loadingState}
        />
        <Breadcrumb
          title="Total Revenue through Session Primary Channel Group"
          image={logoMap['Google Analytics']}
        />
        <GATrafficAcquisitionTable
          gaTrafficAcquisition={gaTrafficAcquisition}
        />
      </div>
    </React.Fragment>
  );
}
export default GAEcommerceAffiliation;
