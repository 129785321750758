import { Button, Checkbox, Dropdown, Menu, Space, Table } from 'antd';
import React, { useState } from 'react';
import { Card, CardBody, Row } from 'reactstrap';
import { formatNumberWithSpaces } from '../GoogleAnalytics/common/HelperFunctions/utils';

const FacebookSocialMediaIcon = `${process.env.PUBLIC_URL}/images/integrations_images/fb1.svg`;

const FbSocialMediaPostsTable = ({ dataSource }) => {
    
  const [filteredInfo, setFilteredInfo] = useState({});
  const [sortedInfo, setSortedInfo] = useState({});
    const columns = [
      {
        title: 'Full Picture',
        dataIndex: 'full_picture',
        key: 'full_picture',
        align: 'center',
        ellipsis: true,
        render: (text, record) => {
          if (!text) {
            return (
              <a
                href={record.permalink_url}
                target="_blank"
                rel="noopener noreferrer"
              >
                <span>There is No Image</span>
              </a>
            );
          } else {
            return (
              <a
                href={record.permalink_url}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={text}
                  alt={`Full Picture ${record.full_picture}`}
                  style={{ maxWidth: '100px', maxHeight: '90px' }}
                />
              </a>
            );
          }
        },
      },
      {
        title: 'Post Clicks',
        dataIndex: 'post_clicks',
        key: 'post_clicks',
        align: 'center',
        sorter: (a, b) => a.post_clicks - b.post_clicks,
        sortOrder:
          sortedInfo.columnKey === 'post_clicks' ? sortedInfo.order : null,
        ellipsis: true,
        render: (text) => <strong>{formatNumberWithSpaces(text)}</strong>,
      },
      {
        title: 'Post Likes',
        dataIndex: 'post_likes',
        key: 'post_likes',
        align: 'center',
        sorter: (a, b) => a.post_likes - b.post_likes,
        sortOrder:
          sortedInfo.columnKey === 'post_likes' ? sortedInfo.order : null,
        ellipsis: true,
        render: (text) => <strong>{formatNumberWithSpaces(text)}</strong>,
      },
      {
        title: 'Post Total Reactions',
        dataIndex: 'postReactionsTotal',
        key: 'postReactionsTotal',
        align: 'center',
        sorter: (a, b) => a.postReactionsTotal - b.postReactionsTotal,
        sortOrder:
          sortedInfo.columnKey === 'postReactionsTotal'
            ? sortedInfo.order
            : null,
        ellipsis: true,
        render: (text) => <strong>{formatNumberWithSpaces(text)}</strong>,
      },
      {
        title: 'Post Impressions',
        dataIndex: 'post_impressions',
        key: 'post_impressions',
        align: 'center',
        sorter: (a, b) => a.post_impressions - b.post_impressions,
        sortOrder:
          sortedInfo.columnKey === 'post_impressions' ? sortedInfo.order : null,
        ellipsis: true,
        render: (text) => <strong>{formatNumberWithSpaces(text)}</strong>,
      },
      {
        title: 'Post Engagements',
        dataIndex: 'post_engagements',
        key: 'post_engagements',
        align: 'center',
        sorter: (a, b) => a.post_engagements - b.post_engagements,
        sortOrder:
          sortedInfo.columnKey === 'post_engagements' ? sortedInfo.order : null,
        ellipsis: true,
        render: (text) => <strong>{formatNumberWithSpaces(text)}</strong>,
      },
      {
        title: 'Impressions Paid',
        dataIndex: 'post_impressions_paid',
        key: 'post_impressions_paid',
        align: 'center',
        sorter: (a, b) => a.post_impressions_paid - b.post_impressions_paid,
        sortOrder:
          sortedInfo.columnKey === 'post_impressions_paid'
            ? sortedInfo.order
            : null,
        ellipsis: true,
        render: (text) => <strong>{formatNumberWithSpaces(text)}</strong>,
      },
      {
        title: 'Negative Feedback',
        dataIndex: 'post_negative_feedback',
        key: 'post_negative_feedback',
        align: 'center',
        sorter: (a, b) => a.post_negative_feedback - b.post_negative_feedback,
        sortOrder:
          sortedInfo.columnKey === 'post_negative_feedback'
            ? sortedInfo.order
            : null,
        ellipsis: true,
        render: (text) => <strong>{formatNumberWithSpaces(text)}</strong>,
      },
      {
        title: 'Shares',
        dataIndex: 'shares',
        key: 'shares',
        align: 'center',
        sorter: (a, b) => a.shares - b.shares,
        sortOrder: sortedInfo.columnKey === 'shares' ? sortedInfo.order : null,
        ellipsis: true,
        render: (text) => <strong>{formatNumberWithSpaces(text)}</strong>,
      },
      {
        title: 'Created At',
        dataIndex: 'createdAt',
        key: 'createdAt',
        align: 'center',
        render: (text) => {
          const formattedDate = text ? new Date(text).toLocaleDateString() : '';
          return <strong>{formattedDate}</strong>;
        },
      },
    ];
  const [selectedColumns, setSelectedColumns] = useState(
    columns.map((column) => column.key)
  );



  const handleMenuClick = (clickedKey) => {
    const updatedSelectedColumns = selectedColumns.includes(clickedKey)
      ? selectedColumns.filter((key) => key !== clickedKey)
      : [...selectedColumns, clickedKey];

    setSelectedColumns(updatedSelectedColumns);
  };

  const filteredColumns = columns.filter((column) =>
    selectedColumns.includes(column.key)
  );

  const menu = (
    <Menu>
      {columns.map((column) => (
        <Menu.Item key={column.key} onClick={() => handleMenuClick(column.key)}>
          <Checkbox
            checked={selectedColumns.includes(column.key)}
            style={{ marginRight: '8px' }}
          />
          <span
            style={
              selectedColumns.includes(column.key) ? { fontWeight: 'bold' } : {}
            }
          >
            {column.title}
          </span>
        </Menu.Item>
      ))}
    </Menu>
  );

  const [paginationOptions, setPaginationOptions] = useState({
    pageSize: 5,
    pageSizeOptions: ['5', '10', '20'],
  });

  const handleChange = (pagination, filters, sorter) => {
    setFilteredInfo(filters);
    setSortedInfo(sorter);
    setPaginationOptions({
      ...paginationOptions,
      pageSize: pagination.pageSize,
    });
  };

  const clearAll = () => {
    setSortedInfo({});
    setSelectedColumns(columns.map((column) => column.key));
  };

  const setAgeSort = () => {
    setSortedInfo({
      order: 'descend',
      columnKey: 'post_impressions',
    });
  };

  return (
    <Row>
      <Card>
        <CardBody style={{ padding: '45px' }}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <h4 className="card-title mb-4">
              Facebook Page posts last 30 days
            </h4>
            <img
              className="img-intgration-icon"
              src={FacebookSocialMediaIcon}
              alt="Facebook Icon"
            />
          </div>
          <Space
            style={{
              marginBottom: 16,
            }}
          >
            <Button onClick={setAgeSort}>Sort Impressions</Button>
            <Button onClick={clearAll}>Clear Sorters</Button>
            <Dropdown overlay={menu} trigger={['click']}>
              <Button>Select Columns</Button>
            </Dropdown>
          </Space>
          <Table
            style={{ border: '1px solid #e8e8e8' }}
            columns={filteredColumns}
            dataSource={dataSource}
            onChange={handleChange}
            pagination={{
              ...paginationOptions,
              showSizeChanger: true,
            }}
          />
        </CardBody>
      </Card>
    </Row>
  );
};

export default FbSocialMediaPostsTable;
