import MetaTags from 'react-meta-tags';
import React, { useState, useEffect } from 'react';
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  Button,
} from 'reactstrap';
import { useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import Breadcrumb from '../../components/Common/Breadcrumb';
import avatar from '../../assets/images/users/avatar-1.jpg';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import ChangePasswordFrom from './ChangePasswordFrom'; // Ensure this path is correct
import axios from 'axios';
import { editProfileRequest } from '../../store/auth/actions';
import Paiement from './Paiement';

function UserProfile(props) {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.editProfile);
  const { handleSubmit } = useForm();
  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [mobile, setMobile] = useState('');
  const [expirationDate, setExpirationDate] = useState('');
  const [imageFile, setImageFile] = useState(null);
  const [expirationStatus, setExpirationStatus] = useState('true');

  useEffect(() => {
    if (user.user) {
      console.log("mamamama",user);
      setEmail(user.user.email);
      setFirstName(user.user.firstname);
      setLastName(user.user.lastname);
      setMobile(user.user.mobile);
      setExpirationDate(user.user.expirationDate);
      if (new Date(expirationDate) > new Date()) {
        setExpirationStatus('true');
      }
    }
  }, [user.user]);

  const handleImageChange = (e) => {
    setImageFile(e.target.files[0]);
  };

  const uploadImageToCloudinary = async (file) => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('upload_preset', 'livemetrics'); // Replace with your Cloudinary upload preset

    try {
      const response = await axios.post(
        'https://api.cloudinary.com/v1_1/ducork8ep/image/upload',
        formData
      );
      return response.data.secure_url;
    } catch (error) {
      console.error('Error uploading image to Cloudinary', error);
      return null;
    }
  };

  const editUserAccount = async () => {
    let imageUrl = user.user.Image || avatar;
    if (imageFile) {
      imageUrl = await uploadImageToCloudinary(imageFile);
    }

    dispatch(
      editProfileRequest({
        firstName,
        lastName,
        email,
        mobile,
        imageUrl,
      })
    );
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Profile | LiveMetrics - E-com SaaS</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumb
            title="Settings"
            breadcrumbItem={props.t("Profile")}
            pageHeading="Manage Profile"
          />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <div className="d-flex">
                    <div className="ms-3">
                      <img
                        src={user.user.Image ? user.user.Image : avatar}
                        alt=""
                        className="avatar-md rounded-circle img-thumbnail"
                      />
                    </div>
                    <div className="flex-grow-1 align-self-center ms-3">
                      <div className="text-muted">
                        <h5>{firstName}</h5>
                        <p className="mb-1">{email}</p>
                      </div>
                    </div>
                    <div className="text-sm-end">
                      <p className="text-muted mb-2">
                        <i
                          className={
                            expirationStatus === 'true'
                              ? 'fas fa-check-circle'
                              : 'fas fa-times-circle'
                          }
                          style={{
                            color: expirationStatus === 'true' ? 'green' : 'red',
                            fontSize: '15px',
                          }}
                        ></i>
                        {props.t("Expiration Date:")} {expirationDate ? expirationDate.split('T')[0] : props.t('No Expiration Date')}
                      </p>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col xs={12} lg={6}>
              <Card>
                <CardHeader>
                  <h4 className="card-title">{props.t('Change User Info')}</h4>
                </CardHeader>
                <CardBody>
                  <form onSubmit={handleSubmit(editUserAccount)}>
                    <div className="mb-3">
                      <div className="d-flex align-items-start">
                        <div className="flex-grow-1">
                          <label className="form-label">
                            {props.t('First Name')}
                          </label>
                        </div>
                      </div>
                      <div className="mb-3">
                        <input
                          name="firstName"
                          value={firstName}
                          type="text"
                          id="firstName"
                          onChange={(e) => setFirstName(e.target.value)}
                          className="form-control"
                          placeholder={props.t('Enter First Name')}
                        />
                      </div>
                    </div>
                    <div className="mb-3">
                      <div className="d-flex align-items-start">
                        <div className="flex-grow-1">
                          <label className="form-label">
                            {props.t('Last Name')}
                          </label>
                        </div>
                      </div>
                      <div className="mb-3">
                        <input
                          name="lastName"
                          value={lastName}
                          type="text"
                          id="lastName"
                          className="form-control"
                          onChange={(e) => setLastName(e.target.value)}
                          placeholder={props.t('Enter Last Name')}
                        />
                      </div>
                    </div>
                    <div className="mb-3">
                      <div className="d-flex align-items-start">
                        <div className="flex-grow-1">
                          <label className="form-label">
                            {props.t('Email')}
                          </label>
                        </div>
                      </div>
                      <div className="mb-3">
                        <input
                          name="email"
                          type="email"
                          value={email}
                          autoComplete="off"
                          id="email"
                          disabled
                          className="form-control"
                          placeholder={props.t('Enter Email')}
                        />
                      </div>
                    </div>
                    <div className="mb-3">
                      <div className="d-flex align-items-start">
                        <div className="flex-grow-1">
                          <label className="form-label">
                            {props.t('Phone')}
                          </label>
                        </div>
                      </div>
                      <div className="mb-3">
                        <input
                          name="mobile"
                          type="tel"
                          id="mobile"
                          className="form-control"
                          value={mobile}
                          onChange={(e) => {
                            setMobile(e.target.value);
                          }}
                          placeholder={props.t('Enter Your Phone Number')}
                        />
                      </div>
                    </div>
                    <div className="mb-3">
                      <div className="d-flex align-items-start">
                        <div className="flex-grow-1">
                          <label className="form-label">
                            {props.t('Profile Image')}
                          </label>
                        </div>
                      </div>
                      <div className="mb-3">
                        <input
                          type="file"
                          accept="image/*"
                          className="form-control"
                          onChange={handleImageChange}
                        />
                      </div>
                    </div>
                    <div className="mb-3">
                      <Button type="submit" color="primary" className="ms-1">
                        {props.t('Submit')}
                      </Button>{' '}
                      <Button type="reset" color="secondary">
                        {props.t('Cancel')}
                      </Button>
                    </div>
                  </form>
                </CardBody>
              </Card>
            </Col>  
          {!user.user.isVerified ? (
              <Col col={12} lg={6}>
                <ChangePasswordFrom />
              </Col>
            ) : null}
          </Row>
          <Row>
            <Col xs={12} lg={12}>
              <Paiement PlanType={user.user.PlanType} />
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
}

export default withRouter(withTranslation()(UserProfile));
