import React, { useRef } from 'react';
import { Row, Col, Card, CardBody, Button, CardHeader } from 'reactstrap';

import { withTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ApolloClient from 'apollo-boost';
import urls from '../../routes/apiUrls';
import Swal from 'sweetalert2';
import { resetPass } from '../../queries/Users/userQuery';
import { resetPasswordRequest } from '../../store/auth/actions';
const ApploCLI = new ApolloClient({
  uri: urls.user,
});
function ChangePasswordFrom(props) {
  const dispatch = useDispatch();
  const pass = useRef(null);
  const Rpass = useRef(null);
  // change password methode , connected to the backend endpoint , takes one param : password
  const changePassword = () => {
    const password = pass.current.value.toString();
    if (pass.current.value !== Rpass.current.value) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'passwordsnot',
      }).then((result) => {
        window.location.reload();
      });
    } else {
      dispatch(resetPasswordRequest(password));
    }
  };
  return (
    <React.Fragment>
      <Row>
        <Col col={12} lg={12}>
          <Card>
            <CardHeader>
              <h4 className="card-title">{props.t('Reset Password')}</h4>
            </CardHeader>
            <CardBody>
              {/* <h4 className="card-title mb-4">{props.t("Reset Password")}</h4> */}
              <div className="mb-3">
                <div className="d-flex align-items-start">
                  <div className="flex-grow-1">
                    <label className="form-label">{props.t('Password')}</label>
                  </div>
                </div>
                <div className="mb-3">
                  <input
                    name="password"
                    type="password"
                    ref={pass}
                    id="password"
                    className="form-control"
                    required
                    placeholder={props.t('Enter Password')}
                  />
                </div>
              </div>
              <div className="mb-3">
                <div className="d-flex align-items-start">
                  <div className="flex-grow-1">
                    <label className="form-label">
                      {props.t('Repeat Password')}
                    </label>
                  </div>
                </div>
                <div className="mb-3">
                  <input
                    name="Rpassword"
                    ref={Rpass}
                    //value="123456"
                    type="password"
                    id="Rpassword"
                    className="form-control"
                    required
                    placeholder={props.t('Enter Password')}
                  />
                </div>
              </div>
              <div className="mb-3">
                <Button
                  type="submit"
                  color="primary"
                  className="ms-1"
                  onClick={changePassword}
                >
                  {props.t('Submit')}
                </Button>{' '}
                <Button type="reset" color="secondary">
                  {props.t('Cancel')}
                </Button>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
}
export default withRouter(withTranslation()(ChangePasswordFrom));
