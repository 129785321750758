import React from 'react'
import { Card, CardBody, Row } from 'reactstrap';
import i18next from 'i18next';
import { Bar } from 'react-chartjs-2';

const InstagramSocialMediaAge = ({ chartData }) => {
  return (
    <Card className="card-h-100">
      <CardBody>
        <div className="d-flex flex-wrap align-items-center mb-4">
          <h5 className="card-title me-2">
            {i18next.t('Social Media Audience ( Age )')}{' '}
          </h5>
        </div>
        <Row className="align-items-center">
          <div className="col-sm">
            <div id="wallet-balance" className="apex-charts">
              <Bar
                data={chartData}
                options={{
                  title: {
                    display: true,
                    text: i18next.t('Gender and Age Group Sum'),
                    fontSize: 20,
                  },
                  legend: {
                    display: true,
                  },
                  scales: {
                    yAxes: [
                      {
                        ticks: {
                          beginAtZero: true,
                        },
                        scaleLabel: {
                          display: true,
                          labelString: 'Count',
                        },
                      },
                    ],
                  },
                }}
              />
            </div>
          </div>
        </Row>
      </CardBody>
    </Card>
  );
};

export default InstagramSocialMediaAge
