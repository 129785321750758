import PropTypes from 'prop-types';
import React from 'react';
import { Switch, BrowserRouter as Router, Route } from 'react-router-dom';
import { connect, Provider } from 'react-redux';
import { userRoutes, authRoutes } from './routes/allRoutes';
import AuthExpirationMiddleware from './routes/middleware/Authmiddleware';
import VerticalLayout from './components/VerticalLayout/';
import NonAuthLayout from './components/NonAuthLayout';
import store from './store/index';
import './assets/scss/theme.scss';
import './assets/scss/preloader.scss';
import ApolloClient, { HttpLink, InMemoryCache } from 'apollo-boost';
import { ApolloLink, ApolloProvider } from '@apollo/react-hooks';
import urls from './routes/apiUrls';
import Error404 from './pages/Utility/Error404';
import ForgetPassword from './pages/Authentication/ForgetPassword';
import { GoogleOAuthProvider } from '@react-oauth/google';


const App = (props) => {
  function getLayout() {
    let layoutCls = VerticalLayout;
    switch (props.layout.layoutType) {
      default:
        layoutCls = VerticalLayout;
        break;
    }
    return layoutCls;
  }

  const client = new ApolloClient({
    uri: urls.login,
    request: async (operation) => {
      operation.setContext({
        fetchOptions: {
          credentials: 'include',
        },
      });
    },
  });

  // Custom fetch function using Axios
  // const customFetch = async (uri, options) => {
  //   try {
  //     console.log('im in custom fetch')
  //     const response = await axiosInstance({
  //       url: uri,
  //       method: options.method,
  //       headers: options.headers,
  //       data: options.body,
  //     });
  //     return {
  //       text: async () => JSON.stringify(response.data),
  //     };
  //   } catch (error) {
  //     console.error('Fetch error:', error);
  //     throw error;
  //   }
  // };

  // // Error handling link
  // const errorLink = onError(({ graphQLErrors, networkError }) => {
  //   if (graphQLErrors)
  //     graphQLErrors.forEach(({ message, locations, path }) =>
  //       console.log(
  //         `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
  //       )
  //     );

  //   if (networkError) console.log(`[Network error]: ${networkError}`);
  // });

  // const httpLink = new HttpLink({
  //   uri: urls.login,
  //   fetch: customFetch, // Use the custom fetch function
  // });

  // const client = new ApolloClient({
  //   link: ApolloLink.from([errorLink, httpLink]),
  //   cache: new InMemoryCache(),
  // });

  const Layout = getLayout();
  const allowedPaths = [
    '/login',
    '/logout',
    '/forgot-password',
    '/register',
    '/pages-maintenance',
    '/pages-404',
    '/pages-500',
    '/settings-profile',
  ];
  const restrictedPaths = [
    '/login',
    '/logout',
    '/forgot-password',
    '/register',
    '/pages-404',
    '/pages-500',
  ];

  return (
    <React.Fragment>
      <Router>
        <GoogleOAuthProvider clientId="985668313706-03v30u5817mhdao99eg6f6916deamu7m.apps.googleusercontent.com">
          <Provider store={store}>
            <ApolloProvider client={client}>
              <Switch>
                {authRoutes.map((route, idx) => (
                  <AuthExpirationMiddleware
                    path={route.path}
                    layout={NonAuthLayout}
                    component={route.component}
                    key={idx}
                    isAuthProtected={false}
                    allowedPaths={allowedPaths}
                    restrictedPaths={restrictedPaths}
                    exact
                  />
                ))}

                {userRoutes.map((route, idx) => (
                  <AuthExpirationMiddleware
                    path={route.path}
                    layout={Layout}
                    component={route.component}
                    key={idx}
                    isAuthProtected={true}
                    restrictedPaths={restrictedPaths}
                    allowedPaths={allowedPaths}
                    exact
                  />
                ))}
                {/* <Route path="/forgot-password">
                  <ForgetPassword />
                </Route> */}
                <Route path="/*">
                  <Error404 />
                </Route>
              </Switch>
            </ApolloProvider>
          </Provider>
        </GoogleOAuthProvider>
      </Router>
    </React.Fragment>
  );
};

App.propTypes = {
  layout: PropTypes.any,
};

const mapStateToProps = (state) => {
  return {
    layout: state.Layout,
    dateRange: state.dateRangeReducer,
  };
};

export default connect(mapStateToProps)(App);
