import{
    GET_INSTA_PAGE_DATA,
    GET_INSTA_PAGE_DATA_SUCCESS,
    GET_INSTA_PAGE_DATA_FAILURE,
    GET_INSTA_POST_DATA,
    GET_INSTA_POST_DATA_SUCCESS,
    GET_INSTA_POST_DATA_FAILURE,
    GET_INSTA_AUDIENCE_DATA,
    GET_INSTA_AUDIENCE_DATA_SUCCESS,
    GET_INSTA_AUDIENCE_DATA_FAILURE,
}from './actions';
const initialState = {
    instaPageData: [],
    instaPostData: [],
    instaAudienceData: [],
    error: null,
};

export default function Instagram(state = initialState, action) {
    switch (action.type) {
        case GET_INSTA_PAGE_DATA:
            return {
                ...state,
                error: null,
            };
        case GET_INSTA_PAGE_DATA_SUCCESS:
            return {
                ...state,
                instaPageData: action.payload.instaPageData,
            }
        case GET_INSTA_PAGE_DATA_FAILURE:
            return {
                ...state,
                error: action.payload,
            }
        case GET_INSTA_POST_DATA:
            return {
                ...state,
                error: null,
            };
        case GET_INSTA_POST_DATA_SUCCESS:
            return {
                ...state,
                instaPostData: action.payload.instaPostData,
            }
        case GET_INSTA_POST_DATA_FAILURE:
            return {
                ...state,
                error: action.payload,
            }
        case GET_INSTA_AUDIENCE_DATA:
            return {
                ...state,
                error: null,
            };
        case GET_INSTA_AUDIENCE_DATA_SUCCESS:
            return {
                ...state,
                instaAudienceData: action.payload.instaAudienceData,
            }
        case GET_INSTA_AUDIENCE_DATA_FAILURE:
            return {
                ...state,
                error: action.payload,
            }

        default:
            return state;
    }
}