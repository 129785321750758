import React, { useMemo } from 'react';
import ReactECharts from 'echarts-for-react';
import { useSelector } from 'react-redux';

// Function to generate a chart based on your array
const generateTransactionChart = (item, startDate) => {
  let processedChart = [];
  let processedCompareChart = [];

  if (!item || item.length === 0) return [[], []];

  const processSeriesData = (series, startDate) => {
    const uniqueDays = new Map();
    series.data.forEach((dataPoint, index) => {
      const date = new Date(startDate);
      date.setDate(date.getDate() + index); // Increment the date by the index
      const formattedDate = date.toLocaleDateString('en', {
        month: 'short',
        day: '2-digit',
      });
  
      // Check if dataPoint is a valid number before calling toFixed
      if (typeof dataPoint === 'number' && !isNaN(dataPoint)) {
        uniqueDays.set(formattedDate, dataPoint.toFixed(3));
      } else {
        // Handle the case where dataPoint is not a number (e.g., set it to null or some default value)
        uniqueDays.set(formattedDate, null); // or some default value
      }
    });
  
    return Array.from(uniqueDays, ([x, y]) => ({ x, y }));
  };

  processedChart = processSeriesData(item[0], startDate);

  if (item[1]) {
    processedCompareChart = processSeriesData(item[1], startDate);
  }

  return [processedChart, processedCompareChart];
};

const GoogleAdsCardChart = ({ item, colors }) => {
  const { dateRange } = useSelector((state) => state.dateRangeReducer);

  const [currentChartData, comparedChartData] = useMemo(() => {
    return generateTransactionChart(item, dateRange.selection1.startDate);
  }, [item, dateRange.selection1.startDate]);



  const series = [
    {
      name: item[0].name,
      data: currentChartData.map((item) => item.y),
    },

    {
      name: item[1]?.name,
      data: comparedChartData?.map((item) => item.y),
    },
  ];
  //chart options
  const options = {
    tooltip: {
      trigger: 'axis',
    },
    xAxis: {
      type: 'category',
      boundaryGap: false,
      data: currentChartData.map((item) => item.x), //show the date
    },
    yAxis: {
      type: 'value',
      show: false,
    },
    series: series.map((serie, index) => ({
      name: serie.name,
      type: 'line',
      smooth: true,
      data: serie.data,
      showSymbol: false,
      emphasis: {
        focus: 'series',
        itemStyle: {
          color: index === 0 ? colors[0].lineColor : colors[1].lineColor,
        },
        label: {
          show: false,
        },
        symbol: 'circle',
        symbolSize: 6,
      },
      lineStyle: {
        width: 2,
        color: index === 0 ? colors[0].lineColor : colors[1].lineColor, // Blue for the first series, orange for the second
      },
      areaStyle: {
        color: {
          type: 'linear',
          x: 0,
          y: 0,
          x2: 0,
          y2: 1,
          colorStops: index === 0 ? colors[0].fillColor : colors[1].fillColor,
          global: false,
        },
      },
      z: index === 0 ? 2 : 1, // Ensure the blue series is on top
    })),
    grid: {
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      containLabel: false,
    },
    color: [colors[0].lineColor, colors[1].lineColor], // Base colors for the series
  };

  return (
    <ReactECharts option={options} style={{ width: '100%', height: 110 }} />
  );
};

export default GoogleAdsCardChart;
