import React from 'react'
import { Row, Col, CardBody, Card } from 'reactstrap';
import { formatNumberWithSpaces } from '../GoogleAnalytics/common/HelperFunctions/utils';
const FacebookSocialMediaIcon = `${process.env.PUBLIC_URL}/images/integrations_images/fb1.svg`;

const FbSocialMediaCard = ({item}) => {
  return (
    <Card className="card-h-100">
      <CardBody>
        <div className="my-0 text-primary d-flex justify-content-between align-items-center">
          {item.name}
            <img
              className="img-intgration-icon"
              src={FacebookSocialMediaIcon}
              alt='facebook icon'
            />
        </div>
        <Row className="align-items-center">
          <Col xs={6}>
            <div className="mb-3">
              <h4>{formatNumberWithSpaces(item.data)}</h4>
            </div>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
}

export default FbSocialMediaCard
